import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import {getDateFormat, getConfig} from '../../utils/Library'
import moment from "moment";
import "moment/locale/nb";

import { ConfigContext } from '../../contexts/ConfigContext'
import { AccountContext } from '../../contexts/AccountContext'

import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, Button, TextField, FormControl, InputLabel, Select, InputAdornment, MenuItem } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

moment.locale("nb");

const Payment = ({open, setOpen, selectedAR, setSelectedAR, arOverview, setAROverview}) => {
  const { accounts } = useContext(AccountContext);
  const { config } = useContext(ConfigContext);
  const DOC_INIT = {
    memberId: "",
    invoiceId: "",
    arId: "",
    memberName: "",
    bankAccount: "",
    arAccount: "",
    amount: 0,
    payDate: null,
    docName: ""
  }
  const [ clDoc, setClDoc ] = useState(DOC_INIT)

  /////////////////////
  // Server interaction
  /////////////////////

  const savePayment = (clDoc) => {
    //console.log(arOverview, clDoc);

    var bodyFormData = new FormData();
    bodyFormData.set('clubId',  config.clubId);
    bodyFormData.set('date',    getDateFormat(clDoc.payDate));
    bodyFormData.set('description', "Innbetaling fra "+clDoc.memberName);
    bodyFormData.set('amount',  clDoc.amount);
    bodyFormData.set('fromAct', clDoc.bankAccount);
    bodyFormData.set('toAct',   clDoc.arAccount);
    bodyFormData.set('periodId',accounts.currentPeriod);
    bodyFormData.set('postingType', 4);
    bodyFormData.set('memberId', clDoc.memberId);
    bodyFormData.set('invoiceId', clDoc.invoiceId);
    bodyFormData.set('arId', clDoc.arId);
    bodyFormData.append('attachmentFile', clDoc.docName)

    //console.log(arOverview, clDoc);

    axios({
      method: 'post',
      url: config.server+'accounting/document/new/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        //console.log(arOverview)
        if(res.status===200) {

          let newOverview = arOverview.filter(ar => ar.id!==clDoc.arId);
          //console.log(arOverview, newOverview, clDoc)
          let newItem = arOverview.filter(ar => ar.id===clDoc.arId);
          //console.log(arOverview, newItem, clDoc)
          newItem[0].open_amount -= clDoc.amount;
          if(newItem[0].open_amount!=0) {
            newOverview.push(newItem[0])
          }
          setAROverview(newOverview);
          closeDialog();
        }
      })
      .catch(res => {
          //handle error
          console.log(res);
          closeDialog();
      })
  }  

  ////////////////////
  // useEffect area
  ////////////////////

  // Set clDoc with selected document information
  useEffect(() => {
    //console.log(arOverview)
    let item = arOverview.find(ar => ar.id==selectedAR);
    if(item) {
      //console.log(arOverview, item)
      setClDoc({
        memberId: item.member_id,
        invoiceId: item.invoice_id,
        arId: item.id,
        memberName: item.name,
        bankAccount: item.payment_account_id,
        arAccount: item.ar_account_id,
        amount: item.open_amount,
        payDate: new Date(),
        docName: ''
      })  
      //console.log(arOverview, item)
    }
  }, [selectedAR])

  /*useEffect(() => {
    console.log(arOverview)
  }, [arOverview])*/
  
  ////////////////////
  // Action handlers
  ////////////////////

  // Handle the date
  const handleSetDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setClDoc({...clDoc, payDate: selectedDate.toDate()})
    } else {
      setClDoc({...clDoc, payDate: null})
    }
    //setDocument({...document, date: selectedDate.toDate()})
  }

  // Handle attachment
  const handleFileAttachment = (e) => {
    setClDoc({...clDoc, docName: e.target.files[0]});
  }

  const handleSave = () => {
    //console.log("Save")
    savePayment(clDoc)
  }

  // Closing the dialog
  const closeDialog = () => {
    setClDoc(DOC_INIT);
    setSelectedAR(null);
    setOpen(false);
  }


  ///////////
  // UI part
  ///////////

  const seletableAccounts = accounts.bankAccounts ? (
    accounts.bankAccounts.sort((a,b)=> (a.account_no>b.account_no ? 1 : -1)).map(act => {
      return (
        <MenuItem key={act.id} value={act.account_id}>{act.account_id+" / "+act.account_no+" - "+act.description}</MenuItem>
      )
    })
  ) : (
    null
  )

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
      <div>
        <Dialog open={open} onClose={(() => closeDialog())} >
          <DialogTitle>Registrer innbetaling</DialogTitle>
          <DialogContent>
            <div style={{display:"flex", flexDirection:"column", marginTop:"10px"}}>
            <Stack spacing={2} >
              <TextField
                id="select-from-label"
                select
                required
                label="Konto / Bankkonto"
                value={clDoc.bankAccount}
                onChange={(e) => setClDoc({...clDoc, bankAccount: e.target.value})}
                helperText="Velg konto" 
              >
                {seletableAccounts}
              </TextField>
              <TextField required id="standard-start-adornment" label="Innbetalt beløp" InputProps={{startAdornment: <InputAdornment position="start">Kr</InputAdornment>}} defaultValue={clDoc.amount} onChange={(e) => setClDoc({...clDoc, amount:isNaN(parseInt(e.target.value))? 0 : parseInt(e.target.value)})} />
              <DatePicker
                disableToolbar
                required
                variant="inline"
                renderInput={(params) => <TextField {...params}/>}
                margin="normal"
                format="L"
                id="date-picker-inline"
                label="Dato"
                mask="__.__.____"
                //value={(clDoc.payDate=='' || clDoc.payDate==null) ? null : new Date()} // clDoc.payDate
                value={(clDoc.payDate=='' || clDoc.payDate==null) ? null : clDoc.payDate} // clDoc.payDate
                onChange={handleSetDate}        
                autoOk={true}      
              />
              <InputLabel id="select-standard-label">Bilag / vedlegg</InputLabel>
              <TextField id="standard-required" type="File" onChange={(e) => handleFileAttachment(e)}/>
            </Stack>
            </div>
          </DialogContent>
          <DialogActions>
          <Button color="primary" onClick={handleSave}>
              Lagre
            </Button>
            <Button color="primary" onClick={closeDialog}>
              Avbryt
            </Button>
          </DialogActions>          
        </Dialog>                 
      </div>
    </LocalizationProvider>
  )
}

export default Payment;
