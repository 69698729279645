import React, { createContext, useReducer } from 'react'
import InvoiceReducer from '../reducers/InvoiceReducer'

export const InvoiceContext = createContext();
 
const InvoiceContextProvider = (props) => {

    // Test/dummy data
    const [invoice, invoiceDispatch] = useReducer(InvoiceReducer , {
      costCategories: [], 
      costCategoryItems: [],
      invoiceHeaders: [],
      invoiceConfig: [],
      invoiceMembers: [],
      invoiceMemberItems: [],
      invoiceSteps: [
        {id: 0, name: "INVOICE_NEW", text: "Fakturatekst/forfall"},
        {id: 1, name: "INVOICE_CONFIGURED", text: "Bestem fakturalinjer"},
        {id: 2, name: "INVOCIE_MEMBER_ITEMS", text: "Bestem fakturamottakere"},
        {id: 3, name: "INVOICE_POSTED", text: "Forhåndsvisning - Klar for bokføring i regnskapet"},
        {id: 4, name: "INVOICE_SENT", text: "Bokført - Klar for utsending til mottakere"},
        {id: 5, name: "INVOICE_COMPLETED", text: "Sendt til medlemmene"}
      ]
    });

    return (
        <InvoiceContext.Provider value={{ invoice, invoiceDispatch }}>
            {props.children}
        </InvoiceContext.Provider>
    ); 
}

export default InvoiceContextProvider;