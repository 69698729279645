import React, {useContext, useState} from 'react'
import axios from 'axios'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment, MenuItem, Select, InputLabel, FormControl} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';

import { InvoiceContext } from '../../contexts/InvoiceContext'
import { ConfigContext } from '../../contexts/ConfigContext'

const Notify = ({open, setOpen, item, setItem}) => {

  const { invoice, invoiceDispatch } = useContext(InvoiceContext);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState({plainContent:'',htmlContent:''});
  const { config } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////

  const sendMessage = () => {
    axios.post(config.server+'invoice/remind/send/'+config.clubId, { 
      invoiceId: item,
      subject: subject,
      content: content
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      console.log(res.data);
      //memberDispatch({type: 'ADD_MEMBER', newMember: res.data.newMember});
    })
    .catch(err => {
        console.log('Error: ',err)
    })          
  }

  
  ////////////////////
  // Action handlers
  ////////////////////

  const closeDialog = () => {
    setOpen(false)
  }

  const handleSend = () => {
    sendMessage();
    setOpen(false);
  }

  const handleEditorChange = (content, editor) => {
    setContent({plainContent: editor.getContent({format:'text'}), htmlContent: editor.getContent()});
  }

  ///////////
  // UI part
  ///////////

  const title = () => {
    if(item) {
      const header = invoice.invoiceHeaders.find(ih => ih.id==item)
      return header.description+" (id:"+item+")";
    } else {
      return "Ukjent faktura"
    }
  }

  return (
      <div>
        <Dialog open={open} onClose={closeDialog} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{title()}</DialogTitle>
          <DialogContent>
            <TextField id="head" required fullwidth label="Emne" value={subject} onChange={(e) => setSubject(e.target.value)} />
            <div key={2} style={{width: "100%"}}>
              <Editor
                initialValue=""
                apiKey="khxs2tmi69i85gb8lkanh1sf24p1k4s9q0szldp7rtss4ync"
                init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image', 
                    'charmap print preview anchor help',
                    'searchreplace visualblocks code',
                    'insertdatetime media table paste wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic | \
                    alignleft aligncenter alignright | \
                    bullist numlist outdent indent | help'
                }}
                onEditorChange={handleEditorChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSend}>
              Send påminnelse
            </Button>
            <Button color="primary" onClick={closeDialog}>
              Avbryt
            </Button>
          </DialogActions>          
        </Dialog>
      </div>
    )
  }

export default Notify;
