import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import { Checkbox, Paper, Button, Typography, TextField, Tooltip, TableHead, TableContainer, Table, TableBody, TableRow, TableCell, Fab, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Switch } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import { ConfigContext } from '../../contexts/ConfigContext'
import {getConfig, getConfigVal} from './../../utils/Library'

const AccountingConfig = ({saveAll}) => {

  const INIT_ACCOUNT = {
    id: null,
    account_no: '',
    description: '',
    account_id: '',
    standard: 0
  }
  const { config, configDispatch } = useContext(ConfigContext);
  const [accountList, setAccountList] = useState([]);
  const [open, setOpen] = useState(false);
  const [newAccount, setNewAccount] = useState(INIT_ACCOUNT);

  /////////////////////
  // Server interaction
  /////////////////////

  const getAccountList = () => {
    axios.get(config.server+'accounting/account/bankAccounts/all/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setAccountList(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })     
  }

  const createNewAccount = (newAccount) => {
    axios.post(config.server+'accounting/account/bankAccounts/create/'+config.clubId, {
      account: newAccount
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        newAccount.id = res.data.newId;
        setAccountList([...accountList, newAccount]);
        setOpen(false);
        setNewAccount(INIT_ACCOUNT);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  const changeAccount = (newAccount) => {
    axios.patch(config.server+'accounting/account/bankAccounts/change/'+config.clubId, {
      account: newAccount
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        var newList = accountList.filter(n => n.id != newAccount.id);
        setAccountList([...newList, newAccount]);
        setOpen(false);
        setNewAccount(INIT_ACCOUNT);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  const deleteAccount = (id) => {
    axios.delete(config.server+'accounting/account/bankAccounts/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        var newList = accountList.filter(n => n.id != id);
        setAccountList([...newList]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })               
  }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    getAccountList()
  }, []) 
  
  ////////////////////
  // Action handlers
  ////////////////////
  const handleConfigChange = (e) => {
    configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: e.target.value})
  }

  const handleDelete = (e) => {
    deleteAccount(e.currentTarget.id)
  }

  const handleAdd = (e) => {
    setNewAccount(INIT_ACCOUNT)
    setOpen(true)
  }

  const handleChange = (e) => {
    var chgId = e.currentTarget.id;
    var actChg = accountList.filter(act => act.id==chgId)
    setNewAccount(actChg[0])
    setOpen(true)
  }  

  const handleCancel = (e) => {
    setOpen(false);
    setNewAccount(INIT_ACCOUNT);
  }

  const handleSave = () => {
    if(newAccount.id) {
      changeAccount(newAccount)
    } else {
      createNewAccount(newAccount)
    }
  }

  // Handle switches
  const handleSwitchChange = (e) => {
    //console.log(e.target.name, e.target.checked, config.accessLevel)
    var newValue = e.target.checked ? "1":"0";
    configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: newValue})
  }


  ///////////
  // UI part
  ///////////

  // Dialog title
  const titleStr = newAccount.id ? ("Endre bankkkonto") : ("Legg til en bankkonto")

  // Pop-up dialog to add a new number
  const dialog = (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{titleStr}</DialogTitle>
      <DialogContent>
        <div style={{display:"flex", flexFlow:"flex-start"}}>
          <div>
            <Tooltip title="Bankkontonummer - dette brukes bl.a. på fakturaen, dersom denne kontoen defineres som mottakskonto">
              <TextField
                key={1}
                required 
                margin="dense"
                id="header"
                label="Bankkontonummer"
                fullWidth
                value={newAccount.account_no}          
                onChange={(e) => setNewAccount({...newAccount, account_no: e.target.value})}
                autoFocus
              /> 
            </Tooltip>
            <Tooltip title="Beskrivelse av bankkontoen f.eks. driftskonto, sparekonto, eller lignende">
              <TextField
                key={2}
                required 
                margin="dense"
                id="header"
                label="Bankkonto - beskrivelse/formål"
                fullWidth
                value={newAccount.description}          
                onChange={(e) => setNewAccount({...newAccount, description: e.target.value})}
              /> 
            </Tooltip>
            <Tooltip title="Regnskapskontoen som bankkontoen er knyttet til (1:1)">
              <TextField
                key={3}
                required 
                margin="dense"
                id="header"
                label="Regnskapskontonummer"
                fullWidth
                value={newAccount.account_id}          
                onChange={(e) => setNewAccount({...newAccount, account_id: e.target.value})}
              /> 
            </Tooltip>
            <Tooltip title="Kontoen som foreslås ved bokføring av kostnadsbilag [endre til checkbox]. Husk å bare krysse av på en av kontoene">
              <FormControlLabel
                control={<Checkbox checked={newAccount.standard==1?true:false} onChange={(e) => setNewAccount({...newAccount, standard: (e.target.checked?1:0)})} name="std" color="primary" />}
                label="Standard konto"
              />
            </Tooltip>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Lagre
        </Button>
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  );

  var stdCounter = 0;
  const list = accountList ? (
    accountList.map(account => {

      if(account.standard==1) {
        stdCounter++;
      }

      const deleteButton = (
        <Tooltip title="Slett konto" aria-label="add">
          <DeleteIcon color="primary" fontSize="small" value={account.id} id={account.id} onClick={handleDelete} />
        </Tooltip>  
      ); 

      const changeButton = (
        <Tooltip title="Endre konto" aria-label="chg">
          <EditIcon color="primary" fontSize="small" value={account.id} id={account.id} onClick={handleChange} />
        </Tooltip>  
      );       

      return (
        <TableRow key={account.id}>
          <TableCell>{account.account_no}</TableCell>
          <TableCell>{account.description}</TableCell>
          <TableCell>{account.account_id}</TableCell>
          <TableCell><Checkbox checked={account.standard==1?true:false} color="primary" /></TableCell>
          <TableCell>{changeButton}</TableCell>
          <TableCell>{deleteButton}</TableCell>
        </TableRow>
      )
    })
  ):(
    null
  )

  const warningText = stdCounter>1 ? (
    <Typography variant="subtitle1" style={{fontWeight:"bold", color:"red"}}>Du bør endre slik at bare en konto er valgt som standard</Typography>
  ) : (null)

  return (
      <div>
        {dialog}
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", flexWrap:"wrap" }} >
          <div style={{order:1, maxWidth:"300px" }}>
          <Paper elevation={6} style={{margin: "15px", padding: "10px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Konfigurering av regnskapsoppsettet</Typography>
            <Tooltip title="Ved periodeavslutning så er det denne kontoen som automatisk belastes med periodes overskudd/underskudd" >
              <TextField
                  key={301} required margin="dense" id="ctrl" label="Konto for opptjent egenkapital" name="ACCOUNTING_RETAINED_EQUITY"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  fullWidth value={getConfigVal(config, "ACCOUNTING_RETAINED_EQUITY")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Mulighet til å fakturere kontingent pr. medlem">
              <FormControlLabel
                  control={<Switch color="primary" checked={getConfig(config, "ACCOUNTING_INVOICE_BY_MEMBER_OPT")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="ACCOUNTING_INVOICE_BY_MEMBER_OPT" />}
                  label="Kontingent pr medlem"
                />            
            </Tooltip>
          </Paper>                      
            <Button color="primary" onClick={(e) => saveAll(e)}>
              Lagre
            </Button>                         
          </div>

          <div style={{order:2, width:"700px", minWidth:"400px", maxWidth:"1000px"}}>
          <Paper elevation={6} style={{margin: "15px", padding: "10px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Bankkontoer</Typography>
            <Tooltip title="Legg til en ny sperrelinje" aria-label="add">
              <Fab color="primary" size="small" aria-label="add" >
                <AddIcon onClick={handleAdd}/>
              </Fab>
            </Tooltip>   
            <TableContainer component={Paper}>
              <Table size="small" padding="none" >
                <TableHead>
                  <TableRow>
                    <TableCell>Bankkonto</TableCell>
                    <TableCell>Beskrivelse/formål</TableCell>
                    <TableCell>Regnskapskonto</TableCell>
                    <TableCell>Standard</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list}
                </TableBody>
              </Table>
            </TableContainer>
            {warningText}
          </Paper>
          </div>
        </div>        
      </div>
    )
  }

export default AccountingConfig;
