import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import { Paper, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Property from './property'
import {getConfig, getConfigVal} from './../../utils/Library'
import {dynamicSortNorm} from '../../utils/Library' 
import { ConfigContext } from '../../contexts/ConfigContext'
import { PropertyContext } from '../../contexts/PropertyContext'
import { MemberContext } from './../../contexts/MemberContext'

const PropertyAdm = () => {

  const { config, configDispatch } = useContext(ConfigContext);
  const { members, memberDispatch } = useContext(MemberContext);
  const { properties, propertyDispatch } = useContext(PropertyContext);
  const [ showDetails, setShowDetails ] = useState(false);
  const [sortField, setSortField] = useState("sortKey");


  /////////////////////
  // Server interaction
  /////////////////////

  // Get all properties from the server
  const getAllProperties = () => {
    axios.get(config.server+'properties/club/all/'+config.clubId, { 
      'headers': { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+config.accessToken
           },
      })
      .then(res => {
        propertyDispatch({type: 'SET_ALL_PROPERTIES', propertyList: res.data});
      })
      .catch(error => {
          console.log(error)
      });    
  }

  // Get all members from the server
  const getAllMembers = () => {
    axios.get(config.server+'members/'+config.clubId, { 
      'headers': { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+config.accessToken
           },
      })
      .then(res => {
        memberDispatch({type: 'SET_MEMBERS', members: res.data});
      })
      .catch(error => {
          console.log(error)
      });    
  }  

  // Delete a property
  const deleteProperty = (id) => {
    axios.delete(config.server+'properties/delete/one/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        propertyDispatch({type: 'DELETE_PROPERTY', propertyId: id});
      })
      .catch(err => {
          console.log('Error: ',err)
      })             
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Eiendomsoversikt"});
    getAllProperties();
    getAllMembers();
  }, [])
  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleChange = (id, e) => {
    propertyDispatch({type: 'SET_SELECTED', selected: id});
    setShowDetails(true);
  }

  const handleAdd = () => {
    //console.log("Open dialog to add a property...")
    setShowDetails(true);
  }

  const handleDelete = (id, e) => {
    //console.log("Call delete function here")
    deleteProperty(id)
  }

  ///////////
  // UI part
  ///////////

  // Property list

 const propertyList = properties.propertyList.length ? (
    //properties.propertyList.sort((a,b) => (a.sortKey > b.sortKey) ? 1 : (a.sortKey < b.sortKey) ? -1 : 0 ).map(property => {
    properties.propertyList.sort(dynamicSortNorm(sortField)).map(property => {      

      const deleteIcon = property.ownerId&&property.ownerId>0?(
        <Tooltip title="Det er bare mulig å slette eiendommer uten eier" aria-label="delete">
          <DeleteIcon color="disabled" fontSize="small" />
        </Tooltip>
      ):(
        <Tooltip title="Slett eiendom" aria-label="delete">
          <DeleteIcon color="primary" fontSize="small" value={property.id} id={property.id} onClick={(e) => handleDelete(property.id, e)} />
        </Tooltip>
      )

      const extraRowColor = (property.polyExtra && property.extraParking==0) || (property.extraParking>0 && !property.polyExtra) ? "moccasin" : "white";

      const parkingSpaceUnits = getConfig(config, "SNOWCLEARING_PARKING_SPACE_UNITS")==1 ? 
        <React.Fragment>
          <TableCell style={{backgroundColor: extraRowColor}}>{property.extraParking}</TableCell>
          <TableCell style={{backgroundColor: extraRowColor}}>{property.polyExtra ? "Ja" : "Nei"}</TableCell>
        </React.Fragment>   
        : null;

      const parkingSpaceArea = getConfig(config, "SNOWCLEARING_PARKING_SPACE_AREA")==1 ? 
            <TableCell> {property.scArea}</TableCell>
        : null;          

      // Table layout
      return (
        <TableRow key={property.id} >
          <TableCell>{property.id}</TableCell>
          <TableCell>{property.address?property.address:""}</TableCell>
          <TableCell>{property.street?property.street:""}</TableCell>
          <TableCell>{property.companyName ? property.companyName : (property.firstname ? property.firstname + ' ' + property.lastname : "")}</TableCell>
          <TableCell>{property.gnr?property.gnr:""}</TableCell>
          <TableCell>{property.bnr?property.bnr:""}</TableCell>
          <TableCell>{property.latitude ? property.latitude+","+property.longitude : ''}</TableCell>
          <TableCell>{property.polyPerm ? "Ja" : "Nei"}</TableCell>
          {parkingSpaceUnits}
          {parkingSpaceArea}
          <TableCell>
            <Tooltip title="Endre eiendomsinformasjon" aria-label="change">
              <EditIcon color="primary" fontSize="small" value={property.id} id={property.id} onClick={(e) => handleChange(property.id, e)} />
            </Tooltip>
          </TableCell>
          <TableCell>
            {deleteIcon}
          </TableCell>
        </TableRow>
      )
    })
  ):(
    <TableRow key={100}>
      <TableCell>Ingen eiendommer</TableCell>
    </TableRow>
  );  

  const parkingSpaceUnitHeading = getConfig(config, "SNOWCLEARING_PARKING_SPACE_UNITS")==1 ? 
      <React.Fragment>
        <TableCell onClick={() => setSortField("-extraParking")}>Ekstra p-plasser</TableCell>
        <TableCell onClick={() => setSortField("polyExtra")}>Markert ekstra</TableCell>
      </React.Fragment>   
    : null;

  const parkingAreaHeading = getConfig(config, "SNOWCLEARING_PARKING_SPACE_AREA")==1 ? 
      <React.Fragment>
        <TableCell onClick={() => setSortField("scArea")}>Brøyteareal</TableCell>
      </React.Fragment>   
    : null;    

  return (
    <div>
      <Tooltip title="Legg til en ny eiendom" aria-label="add">
        <Fab color="primary" aria-label="add" size="small">
          <AddIcon onClick={handleAdd}/>
        </Fab>
      </Tooltip>      
      <Property open={showDetails} setOpen={setShowDetails} />        
      <Paper style={{display:"flex", flexWrap: "wrap", marginBottom: "20px"}} >
        <TableContainer component={Paper}>
          <Table style={{minWidth: 400}} size="small" padding="none" aria-label="a dense table">
            <TableHead>
              <TableRow style={{backgroundColor: "silver"}} >
                <TableCell onClick={() => setSortField("id")}>Id</TableCell>
                <TableCell onClick={() => setSortField("sortKey")}>Adresse</TableCell>
                <TableCell onClick={() => setSortField("sortKey")}>Gate</TableCell>
                <TableCell onClick={() => setSortField("lastname")}>Eier</TableCell>
                <TableCell onClick={() => setSortField("gnr")}>Gnr</TableCell>
                <TableCell onClick={() => setSortField("bnr")}>Bnr</TableCell>
                <TableCell onClick={() => setSortField("latitude")}>Posisjon</TableCell>
                <TableCell onClick={() => setSortField("polyPerm")}>Markert areal</TableCell>
                {parkingSpaceUnitHeading}
                {parkingAreaHeading}
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {propertyList}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>        
    </div>
    )
  }

export default PropertyAdm;
