
//
// Function to splitt up the string for describing parking area into an array of positions
// Splits position pairs by #
export const splitt = (str) => {
  if(str) {
    let result = [];
    let arr = str.split('#');
    arr.map(pair => {
      let duo = pair.split(',');
      let point = [duo[0], duo[1]];
      result.push(point);
    })
    return result;
  } 
  return str;
}    

// Same as above, but also splits into multiple elements (arrays)
// Splits position arrays by &
export const multiSplitt = (str) => {
  if(str) {
    let result = [];
    let arr = str.split('&');
    //console.log(arr);
    // If only one object, no need to spilt 
    if(arr.length<=1) {
      return splitt(str);
    } else {
      arr.map(obj => {
        result.push(splitt(obj))
      })
    }
    //console.log(result)
    return result;  
  } 
  return str;
}


//
// Function to format date and time
//
export const getTimeFormat = (datestr) => {
  let dt = new Date(datestr);
  return ('0' + dt.getDate()).slice(-2) + '.' + ('0' + (dt.getMonth()+1)).slice(-2) + '.' + dt.getFullYear() + ' ' + ('0'+(dt.getHours()-1)).slice(-2)+':'+('0'+dt.getMinutes()).slice(-2);
}

//
// Function to format date 
//
export const getDateFormat = (datestr) => {
  //console.log(datestr);
  let dt = new Date(datestr);
  return ('0' + dt.getDate()).slice(-2) + '.' + ('0' + (dt.getMonth()+1)).slice(-2) + '.' + dt.getFullYear();
}



//
// Generic array sort function - revers
//
export const dynamicSort = (property) => {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return (a,b) => {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? 1 : (a[property] > b[property]) ? -1 : 0;
      return result * sortOrder;
  }
}

//
// Generic array sort function
//
export const dynamicSortNorm = (property) => {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}


//
// Get config parameter 
//
export const getConfig = (config, param) => {
  //console.log(config,param);
  if(param==='') {
    return null;
  }
  if(config.clubConfig) {
    var result = null;
    config.clubConfig.map(p => {
      if(p.parameter === param) {
        //console.log(p)
        result=p.value;
      }
    })
    return result
  } else {
    return null
  }
}

// Same as getConfig, but returns '' when no config instead of null
export const getConfigVal = (config, param) => {
  return getConfig(config, param)?getConfig(config, param):'';
}

//
// Format a number
//
export const numberFormat = (x) => {
  var nb = 0.00;
  //console.log(nb, x, isNaN(x), isFinite(x), parseFloat(x))
  if(x && isFinite(x) ) {
    nb=x;
  }
  return parseFloat(nb).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

// 
// Only unique objects
//
export const unique = (data, key) => {
  //console.log(data, key)
  return [
    ...new Map(
      data.map(x=> [key(x), x])
    ).values()
  ]
}

//
// Takes a given year (y) then returns Date object of Easter Sunday
//
export const easterDate = ( y ) => { 
	/*
		Easter Date Function for JavaScript implemented by Furgelnod ( https://furgelnod.com )
		Using algorithm published at The Date of Easter (on aa.usno.navy.mil, Oct 2007) 
		(https://web.archive.org/web/20071015045929/http://aa.usno.navy.mil/faq/docs/easter.php)
		The algorithm is credited to J.-M. Oudin (1940) and is reprinted in the 
		Explanatory Supplement to the Astronomical Almanac, ed. P. K. Seidelmann (1992). 
		See Chapter 12, "Calendars", by L. E. Doggett.
	*/
	try {
		y = Number( y );
		if ( y != y ) { 
			throw new TypeError( "Value must be a number." );
		}
		else if ( y > 275760 || y < -271820 ) {
			throw new RangeError( "Value be between -271820 and 275760 due to technical limitations of Date constructor." );
		}
	}
	catch ( e ) { console.error( e ); }
 
	y = Math.floor( y );
	var c = Math.floor( y / 100 );
	var n = y - 19 * Math.floor( y / 19 );
	var k = Math.floor( ( c - 17 ) / 25 );
	var i = c - Math.floor( c / 4 ) - Math.floor( ( c - k ) / 3 ) + 19 * n + 15;
	i = i - 30 * Math.floor( i / 30 );
	i = i - Math.floor( i / 28 ) * ( 1 - Math.floor( i / 28 ) * Math.floor( 29 / ( i + 1 ) ) * Math.floor( ( 21 - n ) / 11 ) );
	var j = y + Math.floor( y / 4 ) + i + 2 - c + Math.floor( c / 4 );
	j = j - 7 * Math.floor( j / 7 );
	var l = i - j;
	var m = 3 + Math.floor( ( l + 40 ) / 44 );
	var d = l + 28 - 31 * Math.floor( m / 4 );
	var z = new Date();
	z.setFullYear( y, m-1, d );
	return z;
} // -- easterDate