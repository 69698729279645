import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import { ConfigContext } from './contexts/ConfigContext'
import { DmsContext } from './contexts/DmsContext'
import axios from 'axios'

import Navbar from './layout/Navbar'
import News from './components/news/news'
import NewsAdm from './components/news/newsadm'
import MembersAdm from './components/members/membersAdm'
import BoatSpaceAdm from './components/boatSpace/boatSpaceAdm'
import ElPointAdm from './components/elPoints/elPointAdm'
import MemberList from './components/members/memberlist'
import MessageLog from './components/messages/messageLog'
import AccountingOverview from './components/accounting/accountingOverview'
import PropertyAdm from './components/properties/propertyAdm'
import MyPage from './components/myPage/myPage'
import AdminMainPage from './components/admin/adminMainPage'
import ElPointLease from './components/elPoints/elPointLease'
import BoatSpaceLease from './components/boatSpace/boatSpaceLease'
import DmsMainPage from './components/dms/dmsMainPage'
import InvoiceMainPage from './components/invoice/invoiceMainPage'
import AccountsPayable from './components/accounting/accountsPayable'
import DisplayFolder from './components/dms/displayFolder'
import Board from './components/board'
import ConfigOverview from './components/config/configOverview'
import TestComponent from './components/testComponent'

import NotFound from './components/notFound'
import Listoyl from './clubInit/listoyl'                        // 1
import Demo from './clubInit/demo'                              // 2
import Flamyrane from './clubInit/flamyrane'                    // 3
import Haukelifjell from './clubInit/haukelifjell'              // 4
import Raudberg from './clubInit/raudberg'                      // 6
import Arbuviki from './clubInit/arbuviki'                      // 7
import BotnVest from './clubInit/botnvest'                      // 8
import Storrlineset from './clubInit/storrlineset'              // 9
import Stangelandsvagen from './clubInit/stangelandsvagen'      // 11

const AppRouter = () => {
  const {config, configDispatch} = useContext(ConfigContext);
  const { dms } = useContext(DmsContext)

  /////////////////////
  // Server interaction
  /////////////////////

  // Get information about the selected club
  const getClubInfo = () => {
    axios.get(config.server+'clubs/basic/'+config.clubId, {
        'headers': {
            'Content-Type': 'application/json'}
        })
        .then(res => {
          configDispatch({type: 'SET_CLUB_INFO',  clubName: res.data[0].name, 
                                                  clubShortName: res.data[0].shortname,
                                                  clubOrgNo: res.data[0].orgno,
                                                  clubPlace: res.data[0].place})
        })
        .catch(err => {
            console.log('Error: ',err)
        })      
  }

  // Get basic club configuration
  const getBasicClubConfig = () => {
    axios.get(config.server+'config/basic/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        configDispatch({type: 'SET_CLUB_CONFIG', clubConfig: res.data})
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }
  
  // Get full club configuration
  const getClubConfig = () => {
    axios.get(config.server+'config/member/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+config.accessToken
        }
      })
      .then(res => {
        configDispatch({type: 'SET_CLUB_CONFIG', clubConfig: res.data})
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  // Get the club board members
  const getClubBoard = () => {
    axios.get(config.server+'clubs/board/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json'}
      })
      .then(res => {
        //console.log(res.data)
        configDispatch({type: 'SET_CLUB_BOARD', clubBoard: res.data})
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  // Get the club menu
  const getClubMenu = () => {
    axios.get(config.server+'config/menu/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json'}
      })
      .then(res => {
        configDispatch({type: 'SET_MENU_ITEMS', menuItems: res.data})
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  // Get system release info 
  const getSystemReleases = () => {
    axios.get(config.server+'config/release', {
      'headers': {
          'Content-Type': 'application/json'}
      })
      .then(res => {
        configDispatch({type: 'SET_RELEASE_INFO', releases: res.data})
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  ////////////////////
  // useEffect area
  ////////////////////

  // Get club information when the club id is set
  useEffect(() => {

    //console.log(config.clubId)

    // Set club id based on URL
    if(window.location.hostname==='listoyl.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 1})
      //config.clubId = 1;
    }
    if(window.location.hostname==='clubonline.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 2})
      //config.clubId = 2;
    }
    if(window.location.hostname==='flamyrane.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 3})
      //config.clubId = 3;
    }
    if(window.location.hostname==='haukelifjellhytteforening.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 4})
      //config.clubId = 4;
    }
    if(window.location.hostname==='raudberg.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 6})
      //config.clubId = 6;
    }
    if(window.location.hostname==='arbuviki.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 7})
      //config.clubId = 7;
    }
    if(window.location.hostname==='botnvest.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 8})
      //config.clubId = 8;
    }
    if(window.location.hostname==='storrlinesethyttegrend.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 9})
      //config.clubId = 9;
    }
    if(window.location.hostname==='svsf.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 11})
      //config.clubId = 11;
    }
    if(window.location.hostname==='stangelandsvaagen.no') {
      configDispatch({type: 'SET_CLUB_ID', clubId: 11})
      //config.clubId = 11;
    }

    // Default solution
    /*if(config.clubId===0) {
      configDispatch({type: 'SET_CLUB_ID', clubId: 2})
      //config.clubId = 2;
    }*/

    getSystemReleases();

    if(config.clubId!==0) {

      // Get club info  
      getClubInfo();

      // Get club board
      getClubBoard();

      // Get club menu
      getClubMenu();

      // Get basic club config
      getBasicClubConfig();
    }
  }, [config.clubId]);

  // Get club config after login
  useEffect(() => {
    getClubConfig();
  }, [config.accessToken])

  // Get menu after DMS folder changes
  useEffect(() => {
    getClubMenu();
  }, [dms.folders])

  // Set club name in header
  useEffect(() => {
    if(config.clubShortName) {
      document.title = config.clubShortName
    }
  }, [config.clubShortName])


  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  // Ensure redirect to a dummy "not found" page if no club id has been set
  // This has been temporarily change for test conveniance
  // "<Route exact path='/' render={(props) => <NotFound /> } />" replace by "<Route exact path='/' render={(props) => <Stangelandsvagen /> } />"  
  // "<Route exact path='/' render={(props) => <Stangelandsvagen /> } />"
  
  const rootPage = config.clubId===0 ? (
    <div>
      <Route exact path='/' render={(props) => <Demo /> } />
    </div>    
  ) : (
    <div>
      <Route exact path='/' render={(props) => <Navbar><News {...props} /></Navbar> } />
    </div>
  );

  return (
      <BrowserRouter>
        <main>
          <div>
            {rootPage}
            <Route exact path='/init/listoyl' render={(props) => <Listoyl />  } />
            <Route exact path='/init/demo' render={(props) => <Demo />  } />
            <Route exact path='/init/flamyrane' render={(props) => <Flamyrane />  } />
            <Route exact path='/init/haukelifjell' render={(props) => <Haukelifjell />  } />
            <Route exact path='/init/raudberg' render={(props) => <Raudberg />  } />
            <Route exact path='/init/arbuviki' render={(props) => <Arbuviki />  } />
            <Route exact path='/init/botnvest' render={(props) => <BotnVest />  } />
            <Route exact path='/init/storrlineset' render={(props) => <Storrlineset />  } />
            <Route exact path='/init/stangelandsvagen' render={(props) => <Stangelandsvagen />  } />

            <Route exact path='/adm/news' render={(props) => <Navbar><NewsAdm {...props} /></Navbar>  } />
            <Route exact path='/adm/members' render={(props) => <Navbar><MembersAdm {...props} /></Navbar>  } />
            <Route exact path='/adm/boatspace' render={(props) => <Navbar><BoatSpaceAdm {...props} /></Navbar>  } />
            <Route exact path='/adm/boatcontracts' render={(props) => <Navbar><BoatSpaceLease {...props} /></Navbar>  } /> 
            <Route exact path='/adm/elpoints' render={(props) => <Navbar><ElPointAdm {...props} /></Navbar>  } /> 
            <Route exact path='/adm/elcontracts' render={(props) => <Navbar><ElPointLease {...props} /></Navbar>  } /> 
            <Route exact path='/memberlist' render={(props) => <Navbar><MemberList {...props} /></Navbar>  } />   
            <Route exact path='/adm/messagelog' render={(props) => <Navbar><MessageLog {...props} /></Navbar>  } />      
            <Route exact path='/adm/accountingoverview' render={(props) => <Navbar><AccountingOverview {...props} /></Navbar>  } />     
            <Route exact path='/adm/accountsPayable' render={(props) => <Navbar><AccountsPayable {...props} /></Navbar>  } />     
            <Route exact path='/adm/properties' render={(props) => <Navbar><PropertyAdm {...props} /> </Navbar> } />
            <Route exact path='/adm/admin' render={(props) => <Navbar><AdminMainPage {...props} /> </Navbar> } />
            <Route exact path='/adm/dms' render={(props) => <Navbar><DmsMainPage {...props} /> </Navbar> } />
            <Route exact path='/adm/invoice' render={(props) => <Navbar><InvoiceMainPage {...props} /> </Navbar> } />
            <Route exact path='/doc' render={(props) => <Navbar><DisplayFolder {...props} /> </Navbar> } />
            <Route exact path='/mypage' render={(props) => <Navbar><MyPage {...props} /> </Navbar> } />
            <Route exact path='/board' render={(props) => <Navbar><Board {...props} /> </Navbar> } />
            <Route exact path='/adm/config' render={(props) => <Navbar><ConfigOverview {...props} /> </Navbar> } />

            <Route exact path='/test' render={(props) => <Navbar><TestComponent {...props} /> </Navbar> } />
          </div>
        </main>
     
      </BrowserRouter>
  );
}

export default AppRouter;
