import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';
import {getDateFormat, getConfig, getTimeFormat} from './../../utils/Library'

import { MyDataContext } from '../../contexts/MyDataContext'
import { ConfigContext } from '../../contexts/ConfigContext'

import ScOrderDialog from './scOrderDialog'

import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, FormControlLabel, Switch, Tooltip,Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const MySnowClearingOrders = ({propertyId}) => {
  const [showHistory, setShowHistory] = useState(false);
  const {myData, myDataDispatch} = useContext(MyDataContext);
  const {config} = useContext(ConfigContext);
  const snowClearingComments = (getConfig(config, "SNOWCLEARING_ALLOW_COMMENTS") == "1") ? true : false;
  const [openDialog, setOpenDialog] = useState(false);
  const [editId, setEditId] = useState(0);
  const confirm = useConfirm();
  

  /////////////////////
  // Server interaction
  /////////////////////

  const deleteOrder = (id) => {
    //console.log("Deleting...", id);
    axios.delete(config.server+'snowClearingOrders/member/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        myDataDispatch({type: 'DELETE_SC_ORDER', orderId: id});
        setEditId(0);
      })
      .catch(err => {
          console.log('Error: ',err)
      })                
  }


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleHistorySwitch = (e) => {
    setShowHistory(e.target.checked)
  }

  // Add new snow clearing order
  const handleAdd = (e) => {
    setEditId(0);
    setOpenDialog(true);
  }

  const handleDelete = (e) => {
    const id = e.currentTarget.id
    confirm({ 
      title: "Er du sikker?",
      description: "Er du sikker på at du vil slette denne brøytebestillingen?",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    }).then(() => { 
        deleteOrder(id)
    })
    .catch(() => { 
      console.log("Sletting avbrutt")  
    });  
  }

  const handleChange = (e) => {
    //console.log(e.currentTarget.id)
    setEditId(e.currentTarget.id);
    setOpenDialog(true);    
  }

  ///////////
  // UI part
  ///////////
  
  const propertyOrderList = myData.scOrders ? myData.scOrders.filter(sc => sc.propertyId==propertyId) : [];
  const scOrderList = showHistory ? propertyOrderList : propertyOrderList.filter(sc => new Date(sc.toDate)>new Date());
  //console.log(showHistory, propertyOrderList, scOrderList)
  const scOrderRows = scOrderList ? (
    scOrderList.sort((a,b) => a.fromDate<b.fromDate?1:-1).map(order => {
      const cmnts = snowClearingComments ? <TableCell style={{paddingRight: "5px"}}>{order.comment}</TableCell> : null
      const editButton = order.completed==1 || new Date(order.toDate)<new Date() ? null : <Tooltip title="Endre bestilling" aria-label="add"><EditIcon color="primary" id={order.id} value={order.id} onClick={(e) => handleChange(e)}/></Tooltip>   
      const deleteButton = order.completed==1 || new Date(order.toDate)<new Date() ? null : <Tooltip title="Slett bestilling" aria-label="add"><DeleteIcon color="primary" id={order.id} value={order.id} onClick={(e) => handleDelete(e)}/></Tooltip>   
      return (
        <TableRow key={order.id}>
          <TableCell style={{paddingRight: "5px"}}>{getDateFormat(order.fromDate)}</TableCell>
          <TableCell style={{paddingRight: "5px"}}>{order.fromTime}</TableCell>
          <TableCell style={{paddingRight: "5px"}}>{getDateFormat(order.toDate)}</TableCell>
          <TableCell style={{color: "lightgrey"}}>{getTimeFormat(order.regTime)}</TableCell>
          {cmnts}
          <TableCell style={{paddingRight: "5px"}}>{order.completedDate ? getTimeFormat(order.completedDate) : null}</TableCell>
          <TableCell>{editButton}</TableCell>
          <TableCell>{deleteButton}</TableCell>
        </TableRow>
      )
    })
  ) : null


  const cmntsHeader = snowClearingComments ? <TableCell style={{paddingRight: "5px"}}>Utført</TableCell> : null

  return (
      <div style={{margin: "20px", maxWidth:"800px", display:"flex", flexDirection:"column"}}>
        <ScOrderDialog open={openDialog} setOpen={setOpenDialog} editId={editId} setEditId={setEditId} allowComments={snowClearingComments} memberId={config.userId} propertyId={propertyId}/>
        <Typography variant="h6">Brøytebestillinger</Typography>
        <div>
          <Tooltip title="Bestill brøyting" aria-label="add"><Fab  style={{margin:"5px"}} color="primary" aria-label="add" size="small"><AddIcon onClick={handleAdd}/></Fab></Tooltip>
          <FormControlLabel style={{margin:"5px"}} control={<Switch checked={showHistory} onChange={handleHistorySwitch} name="checkedB" color="primary"/> } label="Vis historie" />
        </div>
        <TableContainer component={Paper}>
          <Table padding="none" size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{paddingRight: "5px"}}>Ankomst</TableCell>
                <TableCell style={{paddingRight: "5px"}}>Tidspunkt</TableCell>
                <TableCell style={{paddingRight: "5px"}}>Avreise</TableCell>
                <TableCell style={{color: "lightgrey", paddingRight: "5px"}}>Bestilt</TableCell>                
                {cmntsHeader}
                <TableCell style={{paddingRight: "5px"}}>Utført</TableCell>                
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scOrderRows}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

export default MySnowClearingOrders;
