import React, {useContext, useState} from 'react'
import axios from 'axios'

import {Typography, Paper, TextField, InputLabel, Button} from '@mui/material'
import FeedbackDialog from '../utils/feedbackDialog'

import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'

const SmsPanel = ({open, setOpen, message, setMessage, INIT_MESSAGE}) => {

  const { config } = useContext(ConfigContext);
  const { members } = useContext(MemberContext);  
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [feedbackHeader, setFeedbackHeader] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState('')

  /////////////////////
  // Server interaction
  /////////////////////

  const sendSmsToAll = (message) => {
    // Lookup information
    const sender = config.clubConfig.find(config => config.parameter === 'CLUB_SMS_SENDER');
    axios.post(config.server+'msg/new/sms/all/'+config.clubId, {
        sender: sender.value,
        senderType: 'cam',
        content: message.content
      },{
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data);
      setFeedbackHeader('SMS sendt!')
      setFeedbackMessage('SMS har blitt sendt til alle')
      setFeedbackOpen(true)
    })
    .catch(err => {
        console.log('Error: ',err)
    })       
  }

  const sendSmsToOne = (message) => {
    // Lookup information
    const sender = config.clubConfig.find(config => config.parameter === 'CLUB_SMS_SENDER');
    const member = members.find(member => member.id==message.receiverId);    
    axios.post(config.server+'msg/new/sms/one/'+config.clubId, {
        sender: sender.value,
        senderType: 'cam',
        content: message.content,
        memberId: member.id,
        memberMobile: member.mobile
      },{
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data);
      setFeedbackHeader('SMS sendt!')
      setFeedbackMessage('SMS har blitt sendt')
      setFeedbackOpen(true)
    })
    .catch(err => {
        console.log('Error: ',err)
    })       
  }


  ////////////////////
  // useEffect area
  ////////////////////


  ////////////////////
  // Action handlers
  ////////////////////

  const handleSendMessage = () => {
    // Send SMS

    //console.log(message)

    //if(message.sms) {

      //console.log("Sending")

      if(message.all) {
        // Sending SMS message to all members
        sendSmsToAll(message);
      } else {
        // Sending SMS message to one member
        sendSmsToOne(message);
      }
    //}

    // Close and clean up
    setOpen(false);
    setMessage(INIT_MESSAGE);
  }

  // Cancel or exit message dialogue
  const handleMessageCancel = () => {
    setOpen(false);
  }      


  ///////////
  // UI part
  ///////////  

  return (
    <div>
      <FeedbackDialog open={feedbackOpen} setOpen={setFeedbackOpen} header={feedbackHeader} message={feedbackMessage} />
      <div style={{marginTop: "10px", marginBottom: "10px"}}>
        <Paper elevation={3} style={{width: "100%", padding:"3px"}}>
          <Typography style={{fontSize: "12px", flexBasis: '33.33%', flexShrink: 0}}>En SMS koster 86 øre pr. 160 tegn ( = en melding)</Typography>
          <Typography style={{fontSize: "12px", flexBasis: '33.33%', flexShrink: 0}}>Lengre tekster deles automatisk opp i flere meldinger</Typography>
        </Paper>
      </div>
      <Paper elevation={3} style={{width: "100%"}}>
        <div key={3} style={{width: "100%"}}>
          <TextField
            required 
            margin="dense"
            id="header"
            label="SMS melding"
            fullWidth
            value={message.content}          
            onChange={(e) => setMessage({...message, content: e.target.value})}
          />         
        </div>
        <div style={{display: "flex", alignContent: "space-between"}}>
          <Button color="primary" onClick={handleSendMessage}>
            Send SMS
          </Button>
          <Button color="primary" onClick={handleMessageCancel}>
            Avbryt
          </Button>
        </div>
      </Paper>
    </div>
  )
}


export default SmsPanel;