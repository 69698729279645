import React, { createContext, useReducer } from 'react'
import memberReducer from '../reducers/MemberReducer'

export const MemberContext = createContext();

const MemberContextProvider = (props) => {
    const [members, memberDispatch] = useReducer(memberReducer , []);

    return (
        <MemberContext.Provider value={{ members, memberDispatch}}>
            {props.children}
        </MemberContext.Provider>
    ); 
}

export default MemberContextProvider;