import React, {useContext} from 'react'
import {getTimeFormat} from './../../utils/Library'

import {Dialog, DialogTitle, DialogContent, Typography} from '@mui/material'
import {Button, Tabs, Tab, TabPanel} from '@mui/material'
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';

import { ConfigContext } from '../../contexts/ConfigContext'

const DisplayMessageDialog = ({open, setOpen, message, setMessage}) => {

  const { config } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////


  ////////////////////
  // Action handlers
  ////////////////////


  const handleClose = () => {
    setOpen(false)
  }


  ///////////
  // UI part
  ///////////

  const type = message && message.msgType=='e' ? 'ePost' : 'SMS';

  const subject = message && message.msgType=='e' ? (
    <TableRow key={4}>
      <TableCell><Typography variant="subtitle2" color="primary">Emne</Typography></TableCell>
      <TableCell><Typography variant="body2" color="textSecondary">{message.subject} </Typography></TableCell>
    </TableRow>
  ):(
    null
  )

  const content = message && message.msgType=='e' ? (
    <TableRow key={5}>
      <TableCell><Typography variant="subtitle2" color="primary">Innhold</Typography></TableCell>
      <TableCell><Typography variant="body2" color="textSecondary"><span dangerouslySetInnerHTML={{__html: message.htmlcontent}} /></Typography></TableCell>
    </TableRow>
  ) : (
    <TableRow key={5}>
      <TableCell><Typography variant="subtitle2" color="primary">Innhold</Typography></TableCell>
      <TableCell><Typography variant="body2" color="textSecondary">{message.content}</Typography></TableCell>
    </TableRow>
  )

  const attachment = message && message.msgType=='e' && message.attachment ? (
    <TableRow key={6}>
      <TableCell><Typography variant="subtitle2" color="primary">Vedlegg</Typography></TableCell>
      <TableCell><Button variant="outlined" color="primary" size="small" href={config.server.substring(0, config.server.length - 1)+message.attachment} target="_blank">Link</Button></TableCell>
    </TableRow> 
  ) : (
    null
  )

  return (  
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={false} maxWidth={'lg'}>
      <DialogTitle id="form-dialog-title">Meldingsinnhold</DialogTitle>
      <DialogContent>
      <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableBody>
              <TableRow key={1}>
                <TableCell style={{width:"120px"}}><Typography variant="subtitle2" color="primary">Dato/Tid</Typography></TableCell>
                <TableCell><Typography variant="body2" color="textSecondary">{getTimeFormat(message.time)} </Typography></TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell><Typography variant="subtitle2" color="primary">Fra</Typography></TableCell>
                <TableCell><Typography variant="body2" color="textSecondary">{message.sender} </Typography></TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell><Typography variant="subtitle2" color="primary">ePost/SMS</Typography></TableCell>
                <TableCell><Typography variant="body2" color="textSecondary">{type}</Typography></TableCell>
              </TableRow>
              {subject}
              {content}
              {attachment}
              <TableRow key={7}>
                <TableCell><Typography variant="subtitle2" color="primary">Mottakere</Typography></TableCell>
                <TableCell><Typography variant="body2" color="textSecondary">{message.recipients} </Typography></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Button color="primary" onClick={() => setOpen(false)}>
          Lukk
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default DisplayMessageDialog;