import React, { createContext, useReducer } from 'react'
import PropertyReducer from '../reducers/PropertyReducer'

export const PropertyContext = createContext();
 
const PropertyContextProvider = (props) => {
    const [properties, propertyDispatch] = useReducer(PropertyReducer , {
      propertyList: [],
      selected: null
    });

    return (
        <PropertyContext.Provider value={{ properties, propertyDispatch }}>
            {props.children}
        </PropertyContext.Provider>
    ); 
}

export default PropertyContextProvider;