import React, {useEffect, useContext, useState} from 'react'
import {getDateFormat} from './../../utils/Library'

import { MyDataContext } from '../../contexts/MyDataContext'

import { Paper, Card, CardContent, CardActionArea, Typography } from '@mui/material'

export const OVERVIEW = 0;
export const MEMBER_CARD_ID = 1;
export const PROPERTY_CARD_ID = 2;
export const MESSAGE_CARD_ID = 3;
export const INVOICE_CARD_ID = 4;
export const SNOW_CLEARING_CARD_ID = 5;
export const GATE_CARD_ID = 6;
//export const EXTRA_USERS_CARD_ID = 7;
export const LEASE_CARD_ID = 8;
export const VOL_CARD_ID = 9;

const MySummary = ({properties, leases, volWork, additionalUsers, gate, snowClearing, accounting, setSelected}) => {
  const {myData} = useContext(MyDataContext);
  
  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  /////////////
  //Member card
  /////////////
  const memberDetails = myData.person ? (
    <div style={{display:"flex", flexDirection:"column"}}>
      <span>{(myData.person.companyName) ? (myData.person.companyName) : (myData.person.firstName+' '+myData.person.lastName)}</span>
      <span>{myData.person.address}</span>
      <span>{myData.person.zipCode+" "+myData.person.city}</span>
      <span style={{marginTop:"5px"}}>{myData.person.eMail}</span>
      <span>{myData.person.mobile}</span>
      <span style={{marginTop:"10px"}}>{"Trykk for å endre informasjon eller passord"}</span>
    </div>
  ) : (null);

  const memberCard = (
    <div key={MEMBER_CARD_ID} style={{margin:"5px", maxWidth:"200px"}}>
      <Card style={{backgroundColor:"lightgrey"}}>
        <CardActionArea onClick={() => setSelected(MEMBER_CARD_ID)}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">Medlem</Typography>
            {memberDetails}
          </CardContent>
        </CardActionArea>
      </Card>      
    </div>
  )

  ////////////////
  //Property card
  ////////////////
  const propertyCard = properties && myData.properties ? (
    myData.properties.sort((a,b) => a.id>b.id?1:-1).map((property, index) => {
      let key = (PROPERTY_CARD_ID*100)+index;

      const nextOrderDate = property.next_sc_date ? (
        <span>Neste bestilling {getDateFormat(property.next_sc_date)} </span>
      ) : null;
      const lastScDate = property.last_sc_date ? (
        <span>Sist brøytet {getDateFormat(property.last_sc_date)} </span>
      ) : null
      const ordersCurrSeason = property.sc_orders_curr_season ? (
        <span>Antall bestillinger denne sesongen {property.sc_orders_curr_season} </span>
      ) : null
      const ordersPrevSeason = property.sc_orders_prev_season ? (
        <span>Antall bestillinger forrige sesongen {property.sc_orders_prev_season} </span>
      ) : null
      
      const sc = snowClearing ? (
        <React.Fragment>
          <span style={{marginTop:"10px", color:"black", fontWeight:"bold" }}>Brøyting</span>
          <span>Ekstra p-plasser {property.extraParking} </span>
          {lastScDate}
          {nextOrderDate}
          {ordersCurrSeason}
          {ordersPrevSeason}
        </React.Fragment>
      ): null

      const propertyDetails = (
        <div style={{display:"flex", flexDirection:"column"}}>
          <span>{property.street}</span>
          <span>Gnr {property.gnr}, Bnr {property.bnr}</span>
          <span>Posisjon {property.latitude}, {property.longitude}</span>
          {sc}
          <span style={{marginTop:"10px"}}>{"Trykk for se detaljer om eiendommen og bestille brøyting"}</span>
        </div>
      )

      return (
        <div key={key} style={{margin:"5px", maxWidth:"300px"}}>
          <Card style={{backgroundColor:"lightgrey"}}>
            <CardActionArea onClick={() => setSelected(key)}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">{property.address}</Typography>
                {propertyDetails}
              </CardContent>
            </CardActionArea>
          </Card>      
        </div>
      )

    })
  ) : null

  /////////////
  //Gate card
  /////////////

  const gateDetails = myData.gateNumbers ? (
    myData.gateNumbers.map(gateNo => {
      return (
        <span key={gateNo.id} >{gateNo.number} - {gateNo.description}</span>
      )
    })
  ) : (null);

  const gateCard = gate ? (
    <div key={GATE_CARD_ID} style={{margin:"5px", maxWidth:"200px"}}>
      <Card style={{backgroundColor:"lightgrey"}}>
        <CardActionArea onClick={() => setSelected(GATE_CARD_ID)}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">Bom/Tlf nr</Typography>
            <div style={{display:"flex", flexDirection:"column"}}>
              {gateDetails}
              <span style={{marginTop:"10px"}}>{"Trykk for detaljer / endre telefonnummer"}</span>
            </div>
            
          </CardContent>
        </CardActionArea>
      </Card>      
    </div>
  ) : null

  ////////////////
  //Message card
  ////////////////

  const messageDetails = myData.messages && myData.messages.length>0 ? (
    <div style={{display:"flex", flexDirection:"column"}}>
        <span>Siste melding: {getDateFormat(myData.messages.sort((a,b) => a.time > b.time ? -1 : 1)[0].time)}</span>
        <span>SMS'er: {myData.messages.filter(m => m.msgType=="s").length} stk</span>
        <span>ePoster: {myData.messages.filter(m => m.msgType=="e").length} stk</span>
        <span style={{marginTop:"10px"}}>{"Trykk for å se detaljer"}</span>
    </div>
  ) : (
    <div style={{display:"flex", flexDirection:"column"}}>
        <span>Det er ingen meldinger til deg</span>
    </div>    
  );

  const messageCard = (
    <div key={MESSAGE_CARD_ID} style={{margin:"5px", maxWidth:"200px"}}>
      <Card style={{backgroundColor:"lightgrey"}}>
        <CardActionArea onClick={() => setSelected(MESSAGE_CARD_ID)}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">Meldinger</Typography>
            
              {messageDetails}
            
          </CardContent>
        </CardActionArea>
      </Card>      
    </div>
  )

  ////////////////
  //Invoice card
  ////////////////

  const invoiceText = myData.invoices && myData.invoices.filter(i => i.open_amount!=0).length>0 ? <span style={{color: "red"}}>Utestående fakturaer</span> : <span style={{color: "green"}}>Ingenting utestående</span>
  const invoiceDetails = myData.invoices ? (
    myData.invoices.filter(i => i.open_amount!=0).map(invoice => {
      return (
        <span key={invoice.id}>{invoice.description} på {invoice.open_amount}kr med forfall {getDateFormat(invoice.due_date)} </span>
      )
    })
  ) : (<span>Ingen</span>);

  const invoiceCard = accounting ? (
    <div key={INVOICE_CARD_ID} style={{margin:"5px", maxWidth:"200px"}}>
      <Card style={{backgroundColor:"lightgrey"}}>
        <CardActionArea onClick={() => setSelected(INVOICE_CARD_ID)}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">Fakturaer</Typography>
            <div style={{display:"flex", flexDirection:"column"}}>
              {invoiceText}
              {invoiceDetails ? invoiceDetails : <span>Ingen</span>}
              <span style={{marginTop:"10px"}}>{"Trykk for å se detaljer"}</span>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>      
    </div>
  ) : null


  /////////////
  //Lease card
  /////////////

  const leaseDetails = myData.person ? (
    <div style={{display:"flex", flexDirection:"column"}}>
        <span>Båtplass(er): {myData.person.boatspace}</span>
        <span>El punkt(er): {myData.person.elpoint}</span>
        <span style={{marginTop:"10px"}}>{"Trykk for å se detaljer"}</span>
    </div>
  ) : (null);

  const leaseCard = leases ? (
    <div key={LEASE_CARD_ID} style={{margin:"5px", maxwidth:"200px"}}>
      <Card style={{backgroundColor:"lightgrey"}}>
        <CardActionArea onClick={() => setSelected(LEASE_CARD_ID)}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">Leiekontrakter</Typography>
            {leaseDetails}
          </CardContent>
        </CardActionArea>
      </Card>      
    </div>
  ) : null

  //////////////////////
  //Volantairy work card
  //////////////////////

  const volDetails = myData.person ? (
    <div style={{display:"flex", flexDirection:"column"}}>
        <span>Dugnadstimer i år: {myData.person.vol_hrs_curr_per}</span>
        <span>Dugnadstimer i fjor: {myData.person.vol_hrs_prev_per}</span>
        <span style={{marginTop:"10px"}}>{"Trykk for å se detaljer"}</span>
    </div>
  ) : (null);

  const volWorkCard = volWork ? (
    <div key={VOL_CARD_ID} style={{margin:"5px", maxwidth:"200px"}}>
      <Card style={{backgroundColor:"lightgrey"}}>
        <CardActionArea onClick={() => setSelected(VOL_CARD_ID)}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">Dugnad</Typography>
            {volDetails}
          </CardContent>
        </CardActionArea>
      </Card>      
    </div>
  ) : null



  return (
      <div>
        <Paper elevation={6} >
          <div style={{display:"flex", flexWrap:"wrap"}}>
            {memberCard}
            {invoiceCard}
            {leaseCard}
            {propertyCard}
            {gateCard}
            {messageCard}
            {volWorkCard}
          </div>
        </Paper>
      </div>
    )
  }

export default MySummary;
