import React, {useContext, useState} from 'react'
import axios from 'axios'

import { Paper, TextField, InputLabel, Button} from '@mui/material'
import { Editor } from '@tinymce/tinymce-react';
import FeedbackDialog from '../utils/feedbackDialog'

import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'

const EmailPanel = ({open, setOpen, message, setMessage, INIT_MESSAGE}) => {

  const { config } = useContext(ConfigContext);
  const { members } = useContext(MemberContext);  

  const [file, setFile] = useState(null);
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [feedbackHeader, setFeedbackHeader] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState('')

  /////////////////////
  // Server interaction
  /////////////////////

  // Send email message to one member
  const sendEMailToOne = (message, file) => {
    // Lookup information
    const member = members.find(member => member.id==message.receiverId);
    const sender = config.clubConfig.find(config => config.parameter === 'CLUB_EMAIL_SENDER');
    const domain = config.clubConfig.find(config => config.parameter === 'MAIL_URL');
    
    var bodyFormData = new FormData();
    bodyFormData.set('clubId', config.clubId);
    bodyFormData.set('sender', sender.value);
    bodyFormData.set('domain', domain.value);
    bodyFormData.set('senderType', 'cam');
    bodyFormData.set('subject', message.subject);
    bodyFormData.set('content', message.content);
    bodyFormData.set('htmlcontent', message.htmlContent);
    bodyFormData.set('memberId', message.receiverId);
    bodyFormData.set('memberEmail', member.eMail);
    bodyFormData.set('sendDirect', "false");
    bodyFormData.append('attachmentFile', file);

    axios({
      method: 'post',
      url: config.server+'msg/new/mail/one/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        //console.log(res.data);
        setFile(null);
        setFeedbackHeader('ePost sendt!')
        setFeedbackMessage('ePost har blitt sendt')
        setFeedbackOpen(true)
        }
      )
      .catch(err => {
          console.log(err);
      })    
  } 

  const sendEMailToAll = (message, file) => {
    // Lookup information
    const sender = config.clubConfig.find(config => config.parameter === 'CLUB_EMAIL_SENDER');
    const domain = config.clubConfig.find(config => config.parameter === 'MAIL_URL');

    var bodyFormData = new FormData();
    bodyFormData.set('clubId', config.clubId);
    bodyFormData.set('sender', sender.value);
    bodyFormData.set('domain', domain.value);
    bodyFormData.set('senderType', 'cam');
    bodyFormData.set('subject', message.subject);
    bodyFormData.set('content', message.content);
    bodyFormData.set('htmlcontent', message.htmlContent);
    bodyFormData.set('sendDirect', "false");
    bodyFormData.append('attachmentFile', file);

    axios({
      method: 'post',
      url: config.server+'msg/new/mail/all/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        //console.log(res.data);
        setFile(null);
        setFeedbackHeader('ePost sendt!')
        setFeedbackMessage('ePost har blitt sendt til alle medlemmer')
        setFeedbackOpen(true)
        }
      )
      .catch(err => {
          console.log(err);
      })        
  }

  ////////////////////
  // useEffect area
  ////////////////////


  ////////////////////
  // Action handlers
  ////////////////////

  const handleEditorChange = (content, editor) => {
    setMessage({...message, content: editor.getContent({format:'text'}), htmlContent: editor.getContent()});
    //console.log(message);
  }

  const handleFileAttachment = (e) => {
    setFile(e.target.files[0]);
  }    

  // Cancel or exit message dialogue
  const handleMessageCancel = () => {
    setOpen(false);
  }    

  const handleSendMessage = () => {

    // Send eMail
    if(message.eMail) {
      if(message.all) {
        // Sending email message to all members
        sendEMailToAll(message, file);
      } else {
        // Sending email message to one member
        sendEMailToOne(message, file);
      }
    }

    // Close and clean up
    setOpen(false);
    setMessage(INIT_MESSAGE);
  }

  ///////////
  // UI part
  ///////////  

  return (
    <Paper elevation={3} style={{width: "100%"}}>
      <FeedbackDialog open={feedbackOpen} setOpen={setFeedbackOpen} header={feedbackHeader} message={feedbackMessage} />
      <div key={1} style={{width: "100%"}}>
        <TextField
          required 
          margin="dense"
          id="subject"
          label="Emne"
          fullWidth
          value={message.subject}          
          onChange={(e) => setMessage({...message, subject: e.target.value})}
        />         
      </div>
      <div key={2} style={{width: "100%"}}>
        <Editor
          initialValue=""
          apiKey="khxs2tmi69i85gb8lkanh1sf24p1k4s9q0szldp7rtss4ync"
          cloudChannel='5-stable'
          init={{
            height: 250,
            menubar: false,
            plugins: [
              'advlist autolink lists link image', 
              'charmap print preview anchor help',
              'searchreplace visualblocks code',
              'insertdatetime media table paste wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic | \
              alignleft aligncenter alignright | \
              bullist numlist outdent indent | help'
          }}
          onEditorChange={handleEditorChange}
        />
      </div>
      <div>
        <InputLabel id="select-standard-label">Velg vedlegg</InputLabel>
        <TextField id="standard-required" type="File" onChange={(e) => handleFileAttachment(e)}/>     
      </div>
      <div style={{display: "flex", alignContent: "space-between"}}>
        <Button color="primary" onClick={handleSendMessage}>
          Send ePost
        </Button>
        <Button color="primary" onClick={handleMessageCancel}>
          Avbryt
        </Button>
      </div>            
    </Paper>    
  )
}


export default EmailPanel;