import React, {useEffect, useState, useContext} from 'react'
import Chart from 'react-google-charts'
import axios from 'axios'

import { AccountContext } from '../../contexts/AccountContext'
import { ConfigContext } from '../../contexts/ConfigContext'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ShowTrend = ({open, setOpen, selectedAccount, setSelectedAccount, selectedGroup, setSelectedGroup }) => {

  const { accounts } = useContext(AccountContext);
  const { config } = useContext(ConfigContext);

  // Chart setup
  const options = {
    title: "Utvikling periode for periode",
    hAxis: { title: "Periode" },
    //vAxis: { title: "Beløp", viewWindow: { min: 0, max: 5000 } },
    vAxis: { title: "Beløp" },
    legend: "none"
  };
  var data = [
    ["Periode", "Beløp"]
  ]

  // Get account name
  const getAccountName = (accountId) => {
    const res = accounts.accumulated.filter(a => (a.account_id==accountId && a.period_id==accounts.selectedPeriod));
    //console.log(res)
    //console.log(res[0].account_name)
    return res[0].account_name;
  }

  /////////////////////
  // Server interaction
  /////////////////////



  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    //console.log(accounts.accumulated)
    //console.log(selectedAccount)
    if(selectedAccount) {
      accounts.accumulated.filter(a => a.account_id==selectedAccount).sort((a,b) => a.period_id-b.period_id).map(act => {
        data.push([act.period_description, Math.abs(act.amount)])  
      })
    }
    //console.log(data);
  }, [selectedAccount])
  
  useEffect(() => {
    if(selectedGroup) {
      var currPeriodId = 0;
      var currPeriodDesc = '';
      var currAmount = 0;
      accounts.accumulated.filter(a => a.account_group_id==selectedGroup).sort((a,b) => a.period_id-b.period_id).map(act => {
        if(act.period_id!==currPeriodId && currPeriodId!==0) {
          data.push([currPeriodDesc, Math.abs(currAmount)])
        }
        if(act.period_id!=currPeriodId) {
          currPeriodId=act.period_id;
          currPeriodDesc=act.period_description;
          currAmount=act.amount;
        }        
        if(act.period_id==currPeriodId) {
          currAmount+=act.amount;
        }
      });
      if(currPeriodId!=0) {
        data.push([currPeriodDesc, Math.abs(currAmount)])
      }
      //console.log(data);
    }
  }, [selectedGroup])
  

  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  // Heading
  const title = (selectedAccount && selectedAccount != 'All') ? (
    <React.Fragment>{selectedAccount + "-" +getAccountName(selectedAccount)}</React.Fragment>
  ) : (
    <React.Fragment>Trend</React.Fragment>
  )

  return (
      <div>
      <Dialog open={open} onClose={(() => setOpen(false))} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Chart
              width={400}
              height={400}
              chartType="LineChart"
              data={data}
              options={options}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={(() => setOpen(false))}>
              Lukk
            </Button>
          </DialogActions>          
        </Dialog>         
      </div>
    )
  }

export default ShowTrend;
