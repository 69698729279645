import React, { createContext, useReducer } from 'react'
import AccountReducer from '../reducers/AccountReducer'

export const AccountContext = createContext();
 
const AccountContextProvider = (props) => {
    const [accounts, accountDispatch] = useReducer(AccountReducer , {
      currentPeriod: 0,
      selectedPeriod: 0,
      allPeriods: [],
      accumulated: [], // On account level
      transactions: [], // All transaction details (9771 for Listøyl sin del - siden mars 2008 dvs. 12 år => ca 814 pr år)
      accountGroups: [],
      bankAccounts: []
    });

    return (
        <AccountContext.Provider value={{ accounts, accountDispatch}}>
            {props.children}
        </AccountContext.Provider>
    ); 
}

export default AccountContextProvider;