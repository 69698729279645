import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import { MyDataContext } from '../../contexts/MyDataContext'
import { ConfigContext } from '../../contexts/ConfigContext'

import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, FormControlLabel, Switch, Tooltip,Fab, Stack } from '@mui/material'
import {Dialog, DialogActions, Button, DialogTitle, DialogContent, TextField} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';


const MyGateNumbers = () => {

  const {myData, myDataDispatch} = useContext(MyDataContext);
  const {config} = useContext(ConfigContext);
  const [open, setOpen] = useState(false);
  const INIT = {
    id: null,
    number: '',
    description: ''
  }
  const [newNumber, setNewNumber] = useState(INIT)

  /////////////////////
  // Server interaction
  /////////////////////

  const saveNewNumber = (newNumber) => {
      axios.post(config.server+'gate/new/member/'+config.clubId, {
        memberId: config.userId,
        newNumber: newNumber
      },{
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      var newId = res.data.id;
      newNumber.id = newId;
      myDataDispatch({type: 'ADD_GATE_NUMBER', newNum: newNumber});
      setOpen(false);
      setNewNumber(INIT);
    })
    .catch(err => {
        console.log('Error: ',err)
    })       
  }

  const deleteNumber = (id) => {
    axios.delete(config.server+'gate/delete/member/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        myDataDispatch({type: 'DELETE_GATE_NUMBER', id: id});
      })
      .catch(err => {
          console.log('Error: ',err)
      })                    
  }


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleAdd = () => {
    setOpen(true)
  }

  const handleDelete = (e) => {
    deleteNumber(e.currentTarget.id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = () => {
    saveNewNumber(newNumber)
  }


  ///////////
  // UI part
  ///////////

  const gateNumbers = myData.gateNumbers ? (
    myData.gateNumbers.map(num => {
      return (
        <TableRow key={num.id}>
          <TableCell>{num.number}</TableCell>
          <TableCell>{num.description}</TableCell>
          <TableCell>
            <Tooltip title="Slett nummer" aria-label="add"><DeleteIcon color="primary" id={num.id} value={num.id} onClick={(e) => handleDelete(e)}/></Tooltip> 
          </TableCell>
        </TableRow>
      )
    })
  ) : null
  

  const gateTextConfig = config.clubConfig.find(conf => conf.parameter=="GATE_INFO_TEXT");
  const gateText = gateTextConfig ? gateTextConfig.value : null;

  const addDialog = (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Legg til telefonnummer</DialogTitle>
          <DialogContent>
            <div style={{display:"flex", flexDirection:"column"}}>
              <Stack spacing={1}>
                <TextField required id="num" label="Telefonnummer" value={newNumber.number} onChange={(e) => setNewNumber({...newNumber, number:e.target.value})} />
                <TextField id="desc" label="Person/Beskrivelse" value={newNumber.description} onChange={(e) => setNewNumber({...newNumber, description:e.target.value})} />
              </Stack>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSave}>
              Lagre
            </Button>
            <Button color="primary" onClick={handleClose}>
              Avbryt
            </Button>
          </DialogActions>          
        </Dialog>            
  )

  const gateNumbersPerMember = config.clubConfig.find(conf => conf.parameter=="GATE_NO_OF_USER_NUMBERS"); 
  const addButton = gateNumbersPerMember && myData.gateNumbers.length<parseInt(gateNumbersPerMember.value) ? (
        <div>
          <Tooltip title="Legg til nummer" aria-label="add"><Fab  style={{margin:"5px"}} color="primary" aria-label="add" ><AddIcon onClick={handleAdd}/></Fab></Tooltip>
        </div>  
  ) : null;


  return (
      <div style={{margin: "20px", maxWidth:"600px", display:"flex", flexDirection:"column"}}>        
        <Typography variant="h6">Telefonnummer til bommen</Typography>
        <Typography variant="subtitle2" style={{backgroundColor:"lightgrey"}}>
          <div dangerouslySetInnerHTML={{ __html: gateText }} />          
        </Typography>
        {addButton}
        {addDialog}
        <TableContainer component={Paper}>
          <Table padding="none" size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{paddingRight: "5px"}}>Telefonnumer</TableCell>
                <TableCell style={{paddingRight: "5px"}}>Person/Beskrivelse</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gateNumbers}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

    )
  }

export default MyGateNumbers;
