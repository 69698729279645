import React, {useState, useContext, useEffect} from 'react'
import axios from 'axios'

import { ConfigContext } from '../../contexts/ConfigContext'
import { DmsContext } from '../../contexts/DmsContext'

import { Stack, TextField, Typography, Button, TableContainer, Table, TableRow, TableCell } from '@mui/material'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FolderDialog = ({folderDialogOpen, setFolderDialogOpen}) => {
  
  const { config } = useContext(ConfigContext);

  const INIT_FOLDER = {
    id: null,
    club_id: config.clubId,
    name: '',
    description:'',
    publishing_id: 1,
    access: '',
    menu: 0,
    parent_folder:0
  }

  const {dms, dmsDispatch } = useContext(DmsContext);
  const [folder, setFolder] = useState(INIT_FOLDER);


  /////////////////////
  // Server interaction
  /////////////////////

  const saveFolder = (folder) => {
    //console.log("Server URL:",config.server+'docsys/folders/new/'+config.clubId);
    //console.log(folder);
    axios.post(config.server+'docsys/folders/new/'+config.clubId, {
      folder: folder
      },{
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        let newFolder = res.data.folder;
        dmsDispatch({type: "ADD_FOLDER", folder: newFolder});
      })
      .catch(err => {
          console.log('Error: ',err)
      })              
  }
  
  const updateFolder = (folder) => {
    axios.patch(config.server+'docsys/folders/update/'+config.clubId, {
      folder: folder
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      dmsDispatch({type: 'UPDATE_FOLDER', folder: folder});
    })
    .catch(err => {
        console.log('Error: ',err)
    })               
  }



  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    if(dms.act=="NEW") {
      setFolder(INIT_FOLDER)
      //console.log(folder)
    } else {
      let chgFolder = dms.folders.find(f => f.id==dms.selected);
      if(chgFolder) {
        setFolder(chgFolder)
      }
      //console.log(folder)
      //console.log(chgFolder)
    }
  }, [dms.selected, dms.act])

  
  ////////////////////
  // Action handlers
  ////////////////////
  
  const handleMenuCheckbox = (e) => {
    //console.log(e.target.checked);
    if(e.target.checked==true) {
      setFolder({...folder, menu:1})
    } else {
      setFolder({...folder, menu:0})
    }
  }

  const handleSave = () => {
    //console.log("Save: ", folder);
    if(dms.act=="NEW") {
      //console.log(dms.selected);
      if(dms.selected && dms.selected>0) {
        //console.log("Selected")
        folder.parent_folder = dms.selected;  
      } else {
        //console.log("0")
        folder.parent_folder = 0;
      }
      //folder.parent_folder = dms.selected;
      saveFolder(folder);  
    } else {
      updateFolder(folder);
    }
    setFolderDialogOpen(false);
  }

  const closeDialog = () => {
    setFolderDialogOpen(false);
    setFolder(INIT_FOLDER);
  }

  const handleAccess = (e) => {
    let pubId = e.target.value;
    let access = dms.accessOptions.find(a => a.id==pubId);
    setFolder({...folder, publishing_id: pubId, access: access.description})    
  }

  ///////////
  // UI part
  ///////////

  const headerText = dms.act=="NEW" ? "Ny mappe" : "Endre mappe";

  const accessList = dms.accessOptions ? (
    dms.accessOptions.sort((a,b) => (a.id>b.id) ? 1:-1).map(ao => {
      return (
        <MenuItem key={ao.id} value={ao.id} id={ao.id} >{ao.description}</MenuItem>
      )    
    })
  ) : (
    null
  );
  
  return (
      <div style={{display:"flex"}}>
        <Dialog open={folderDialogOpen} onClose={closeDialog} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{headerText}</DialogTitle>
          <DialogContent>
            <div style={{display:"flex", flexDirection:"column"}}>
            <Stack spacing={1} >
              <TextField sx={{marginTop: "5px"}} required id="x1" label="Mappe navn" value={folder.name} onChange={(e) => setFolder({...folder, name:e.target.value})} />
              <TextField required id="x2" label="Beskrivelse" value={folder.description} onChange={(e) => setFolder({...folder, description:e.target.value})} />
              <TextField
                id="select-publish-label"
                sx={{marginTop: "5px"}}
                select
                required
                label="Tilgang"
                value={folder.publishing_id}
                onChange={handleAccess}
              >
                {accessList}
              </TextField>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={folder.menu==1 ? true : false}
                      onChange={handleMenuCheckbox}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Vis i menyen"
                />                     
              </FormControl>
            </Stack>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSave}>
              Lagre
            </Button>
            <Button color="primary" onClick={closeDialog}>
              Avbryt
            </Button>
          </DialogActions>                    
        </Dialog>
      </div>
    )
  }

export default FolderDialog;
