import React, { createContext, useReducer } from 'react'
import MyDataReducer from '../reducers/MyDataReducer'

export const MyDataContext = createContext();
 
const MyDataContextProvider = (props) => {
    const [myData, myDataDispatch] = useReducer(MyDataReducer , {
      person: null,
      extraUsers: [],
      gateNumbers: [],
      properties: [],
      messages: [],
      invoices: [],
      scOrders: [],
      extraUsers: []
    });

    return (
        <MyDataContext.Provider value={{ myData, myDataDispatch }}>
            {props.children}
        </MyDataContext.Provider>
    ); 
}

export default MyDataContextProvider;