import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Typography } from '@mui/material'


const FeedbackDialog = ({open, setOpen, header, message}) => {


  /////////////////////
  // Server interaction
  /////////////////////
  

  ////////////////////
  // Hooks area
  ////////////////////


  ////////////////////
  // Action handlers
  ////////////////////

  const handleClose = () => {
    setOpen(false);
  };



  ///////////
  // UI part
  ///////////  

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{header}</DialogTitle>
      <DialogContent>
        <Typography variant='p'>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeedbackDialog
