import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios' 

import { AccountContext } from '../../contexts/AccountContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import {numberFormat, unique} from '../../utils/Library'

import AddAttatchment from './addAttachment'

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd'; 
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Typography, Tooltip } from '@mui/material'
import { TableContainer, Table, TableHead, TableRow, TableCell } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

const PostingList = ({open, setOpen, selectedAccount, setSelectedAccount, reload, setReload}) => {

  const { accounts } = useContext(AccountContext);
  const { config } = useContext(ConfigContext);
  var key = 0;
  const [addAttachmentOpen, setAddAttachmentOpen] = useState(false)
  const [addAttachmentDoc, setAddAttachmentDoc] = useState(null)
  const confirm = useConfirm();

  /////////////////////
  // Server interaction
  /////////////////////
  const cancelDocument = (id, docType, periodId) => {
    axios.post(config.server+'accounting/document/cancel/'+config.clubId, {
      docId: id, 
      docType: docType,
      periodId: periodId
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setReload(!reload);
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    //console.log(selectedAccount);
  }, [selectedAccount])
  
  ////////////////////
  // Action handlers
  ////////////////////

  const reverseDocument = (e) => {
    let doc = accounts.transactions.find(trx => trx.id==e.currentTarget.id);
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så reverseres regnskapsdokument "+doc.id+". Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        // Cancel accounting document
        cancelDocument(doc.id, doc.postingType, doc.period_id);
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
  }

  const handleAddAttatchment = (e) => {
    //console.log(e.currentTarget.id)
    const doc = filterlist.find(d => d.id==e.currentTarget.id)
    //console.log(doc)
    setAddAttachmentDoc(doc)
    setAddAttachmentOpen(true)
  }


  ///////////
  // UI part
  ///////////
  //console.log(selectedAccount)
  const title = selectedAccount=="All" ? <DialogTitle id="form-dialog-title">Posteringsliste</DialogTitle> : <DialogTitle id="form-dialog-title">Posteringsliste - {selectedAccount}</DialogTitle>
  const filterlist = selectedAccount=="All" ? unique(accounts.transactions, tr => tr.id) : accounts.transactions.filter(a => a.account_id == selectedAccount);
  //const filterlist = selectedAccount=="All" ? accounts.transactions : accounts.transactions.filter(a => a.account_id == selectedAccount);
  const list = filterlist ? (
    //filterlist.sort((a,b) => new Date(a.date) > new Date(b.date) ? -1 : 1).map(a => {
    //filterlist.sort((a,b) => new Date(a.sortKey) > new Date(b.sortKey) ? 1 : -1).map(a => {
    filterlist.sort((a,b) => new Date(a.sortKey) - new Date(b.sortKey) || a.id - b.id ).map(a => {  
      const cn = (a.cancelled || a.postingType==9) ? 'line-through':'none';
      const bg = (a.cancelled || a.postingType==9) ? 'LightGrey':null;
      //const revText = (a.cancelled || a.postingType==9) ? "REVERSERT":null;
      //console.log(a.id, cn);
      const tx = accounts.transactions.filter(t => a.id===t.id).map((ta, index) => {
        //<tr key={a.id+index+1} style={{textDecoration: cn, backgroundColor: bg}}>
        return (
          <tr key={a.id+index+1} style={{textDecoration: cn, textDecorationColor: "LightGrey", textDecorationStyle: "wavy"}}>
            <td></td>
            <td>{ta.account_id} - {ta.account_name}</td>
            <td style={{textAlign: "right"}} >{numberFormat(ta.amount)}</td>
          </tr>
        )
      })
      const btn = a.ref ? <Button color="primary" href={config.server.substring(0, config.server.length - 1)+a.ref} target="_blank">Bilag</Button> : null
      const delBtn = (a.cancelled || a.postingType==9) ? null : <Button id={a.id} size="small" color="primary" onClick={reverseDocument}>Reverser</Button>
      const addDocBtn = (a.cancelled || a.postingType==9) ? null : 
        <Tooltip title="Legg  til vedlegg">
          <IconButton id={a.id} size="small" onClick={(e) => handleAddAttatchment(e)}>
            <UploadFileIcon color="primary" />
          </IconButton>
        </Tooltip>

      //<tr key={a.id} style={{textDecoration: cn, fontWeight: "bold"}}>
      return (
        <React.Fragment key={a.id}>
          <tr key={a.id} style={{textDecoration: cn, fontWeight: "bold", textDecorationColor: "LightGrey", textDecorationStyle: "wavy"}}>
            <td>{a.id}</td>
            <td>{a.description}</td>
            <td>{a.date}</td>
            <td>{btn}</td>
            <td>{delBtn}</td>
            <td>{addDocBtn}</td>
          </tr>
          {tx}
        </React.Fragment>
      )
    }) 
  ) : (
    null
  )

  return (
      <div key={key++}>
        <AddAttatchment open={addAttachmentOpen} setOpen={setAddAttachmentOpen} docId={addAttachmentDoc ? addAttachmentDoc.id : null} document={addAttachmentDoc} />
        <Dialog open={open} onClose={(() => setOpen(false)) } maxWidth="lg" aria-labelledby="form-dialog-title">
          {title}
          <DialogContent>
            <div style={{display:"flex", flexDirection:"column"}}>
                <table>
                  <tbody>
                    {list}
                  </tbody>
                </table>                
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={(() => setOpen(false))}>
              Lukk
            </Button>
          </DialogActions>          
        </Dialog>            
      </div>
    )
  }

export default PostingList;
