import React, {useEffect, useContext} from 'react'

import moment from "moment";
import "moment/locale/nb";

import { InvoiceContext } from '../../contexts/InvoiceContext'
import { AccountContext } from '../../contexts/AccountContext'

import { Paper, TextField, FormControl, InputLabel, Select, MenuItem} from '@mui/material'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

moment.locale("nb");

const InvoiceHeader = ({stateInvoiceHeader, setStateInvoiceHeader, consistentData, setConsistentData}) => {

  const { invoice } = useContext(InvoiceContext);
  const { accounts } = useContext(AccountContext);


  //const [activity, setActivity] = useState(null); // null = no activity, NEW = new invoice, CHG = changing invoice

  /////////////////////
  // Server interaction
  /////////////////////



  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    // Data consistency check
    //console.log(stateInvoiceHeader, Number.isInteger(stateInvoiceHeader.income_account), Number.isInteger(stateInvoiceHeader.accounts_receivable_account))
    if(stateInvoiceHeader.description.length>0 && 
       stateInvoiceHeader.due_date && stateInvoiceHeader.due_date!="Invalid Date" && 
       Number.isInteger(stateInvoiceHeader.income_account) &&
       Number.isInteger(stateInvoiceHeader.accounts_receivable_account)) {
      let cons = [...consistentData];
      //console.log(cons)
      cons[0] = true;
      setConsistentData(cons);
      //console.log(cons)
    } else {
      let cons = [...consistentData];
      cons[0] = false;
      setConsistentData(cons);
      //console.log(cons)
    }
  }, [stateInvoiceHeader])

  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleDueDate = (selected) => {
    if(stateInvoiceHeader.accounting_doc_no) {
      
    } else {
      if(selected && selected.isValid) {
        setStateInvoiceHeader({...stateInvoiceHeader, due_date: selected.toDate()})
      } else {
        setStateInvoiceHeader({...stateInvoiceHeader, due_date: null})
      }      
    }
  }

  ///////////
  // UI part
  ///////////

  const seletableAccounts = accounts.bankAccounts ? (
    accounts.bankAccounts.sort((a,b)=> (a.account_no>b.account_no ? 1 : -1)).map(act => {
      //console.log(act);
      return (
        <MenuItem key={act.account_no} value={act.account_no} >{act.fullDescription}</MenuItem>
      )
    })
  ) : (
    null
  )


  // Invoice header
  //console.log(stateInvoiceHeader, invoice.invoiceSteps)
  //const statusObj = invoice.invoiceSteps ? invoice.invoiceSteps.find(is => is.id==stateInvoiceHeader.status) : null;
  //const statusValue = statusObj ? statusObj.text : null;
  //console.log(statusObj, statusValue)


/*
            <div sx={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
              <div sx={{maxWidth:"30px", marginLeft: "10px", marginRight: "10px"}}>
                <TextField id="id" variant="standard" disabled label="Id" value={stateInvoiceHeader.id} />
              </div>
              <div sx={{flexGrow: 10, marginLeft: "10px", marginRight: "10px"}}>
                <TextField id="desc" variant="standard" required fullWidth label="Fakturatekst" value={stateInvoiceHeader.description} onChange={(e) => setStateInvoiceHeader({...stateInvoiceHeader, description: stateInvoiceHeader.accounting_doc_no ? stateInvoiceHeader.description : e.target.value })} />
              </div>
              <div sx={{maxWidth:"140px", marginLeft: "20px", marginRight: "20px"}}>
                <DatePicker
                      disableToolbar
                      variant="inline"
                      renderInput={(params) => <TextField variant="standard" {...params}/>}
                      margin="normal"
                      format="L"
                      id="Forfallsdato"
                      label="Forfallsdato"
                      mask="__.__.____"
                      value={(stateInvoiceHeader.due_date=='' || stateInvoiceHeader.due_date==null) ? null : stateInvoiceHeader.due_date}
                      onChange={handleDueDate}        
                      autoOk={true}      
                    />  
              </div>  
              <div sx={{maxWidth:"100px", marginLeft: "10px", marginRight: "10px"}}>
                <TextField required variant="standard"  id="revenueAct" label="Inntektskonto" 
                           value={stateInvoiceHeader.income_account} 
                           onChange={(e) => setStateInvoiceHeader({...stateInvoiceHeader, income_account: stateInvoiceHeader.accounting_doc_no ? stateInvoiceHeader.income_account : (Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : '') })} />
              </div>
              <div sx={{maxWidth:"100px", marginLeft: "10px", marginRight: "10px"}}>
                <TextField required variant="standard"  id="balanceAct" label="Balansekonto" 
                           value={stateInvoiceHeader.accounts_receivable_account} 
                           onChange={(e) => setStateInvoiceHeader({...stateInvoiceHeader, accounts_receivable_account: stateInvoiceHeader.accounting_doc_no ? stateInvoiceHeader.accounts_receivable_account : (Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : '') })} />
              </div>     
              <div sx={{maxWidth:"200px", minWidth:"200px", marginLeft: "10px", marginRight: "10px"}}>
                <TextField
                  id="select-bank_account-label"
                  variant="standard" 
                  select
                  required
                  label="Bankkonto"
                  value={stateInvoiceHeader.bank_account_no}
                  onChange={(e) => setStateInvoiceHeader({...stateInvoiceHeader, bank_account_no: e.target.value})} 
                >
                  {seletableAccounts}
                </TextField>             
              </div>           
            </div>
*/



  return (
      <div>
        <Paper elevation={5} disabled={stateInvoiceHeader.accounting_doc_no} sx={{margin:"10px", padding:"10px", backgroundColor: "lightgrey"}}>
          <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
          <div sx={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
              <div sx={{maxWidth:"30px", marginLeft: "10px", marginRight: "10px"}}>
                <TextField id="id" variant="standard" disabled label="Id" value={stateInvoiceHeader.id} />
              </div>
              <div sx={{flexGrow: 10, marginLeft: "10px", marginRight: "10px"}}>
                <TextField id="desc" variant="standard" required sx={{width: "500px"}} label="Fakturatekst" value={stateInvoiceHeader.description} onChange={(e) => setStateInvoiceHeader({...stateInvoiceHeader, description: stateInvoiceHeader.accounting_doc_no ? stateInvoiceHeader.description : e.target.value })} />
              </div>
              <div sx={{maxWidth:"140px", marginLeft: "20px", marginRight: "20px"}}>
                <DatePicker
                      disableToolbar
                      variant="inline"
                      renderInput={(params) => <TextField variant="standard" {...params}/>}
                      margin="normal"
                      format="L"
                      id="Forfallsdato"
                      label="Forfallsdato"
                      mask="__.__.____"
                      value={(stateInvoiceHeader.due_date=='' || stateInvoiceHeader.due_date==null) ? null : stateInvoiceHeader.due_date}
                      onChange={handleDueDate}        
                      autoOk={true}      
                    />  
              </div>  
              <div sx={{maxWidth:"100px", marginLeft: "10px", marginRight: "10px"}}>
                <TextField required variant="standard"  id="revenueAct" label="Inntektskonto" 
                           value={stateInvoiceHeader.income_account} 
                           onChange={(e) => setStateInvoiceHeader({...stateInvoiceHeader, income_account: stateInvoiceHeader.accounting_doc_no ? stateInvoiceHeader.income_account : (Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : '') })} />
              </div>
              <div sx={{maxWidth:"100px", marginLeft: "10px", marginRight: "10px"}}>
                <TextField required variant="standard"  id="balanceAct" label="Balansekonto" 
                           value={stateInvoiceHeader.accounts_receivable_account} 
                           onChange={(e) => setStateInvoiceHeader({...stateInvoiceHeader, accounts_receivable_account: stateInvoiceHeader.accounting_doc_no ? stateInvoiceHeader.accounts_receivable_account : (Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : '') })} />
              </div>     
              <div sx={{maxWidth:"200px", minWidth:"200px", marginLeft: "10px", marginRight: "10px"}}>
                <TextField
                  id="select-bank_account-label"
                  variant="standard" 
                  select
                  required
                  label="Bankkonto"
                  value={stateInvoiceHeader.bank_account_no}
                  onChange={(e) => setStateInvoiceHeader({...stateInvoiceHeader, bank_account_no: e.target.value})} 
                >
                  {seletableAccounts}
                </TextField>             
              </div>           
            </div>
          </LocalizationProvider>
        </Paper>
      </div>
    )
  }

export default InvoiceHeader;
