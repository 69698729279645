import React, { createContext, useReducer } from 'react'
import ConfigReducer from '../reducers/ConfigReducer'

export const ConfigContext = createContext();

const ConfigContextProvider = (props) => {
    const [config, configDispatch] = useReducer(ConfigReducer , {
        //server: 'http://localhost:4001/api/',
        server: 'https://clubonline.no/api/',
        clubId: 0,
        clubName: null,
        clubShortName: null,
        clubOrgNo: null,
        clubPlace: null,
        signedIn: false,
        authError: false,
        authErrorMessage: null,
        userId: null,
        username: null,
        userFullname: null,
        userInitials: null,
        userEmail: null,
        accessLevel: 0,
        accessToken: null,
        goToPage: null,
        clubConfig: [],
        clubBoard: [],
        currentFunction: null,
        menuItems: [],
        releases: []
    });

    return (
        <ConfigContext.Provider value={{ config, configDispatch}}>
            {props.children}
        </ConfigContext.Provider>
    ); 
}

export default ConfigContextProvider;