import React, {useEffect, useContext, useState} from 'react'
import { ConfigContext } from '../contexts/ConfigContext'

const NavFooter = ({drawerWidth}) => {
  const { config } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////
  //width: `calc(100% - ${drawerWidth}px)`

  ///////////
  // UI part
  ///////////

  const FIXED_TEXT = " leveres av Bryggesoft AS";
  const link = "http://"+config.clubConfig ? (
    config.clubConfig.filter(conf => conf.parameter=="SYSTEM_LINK").map(conf => {
      return conf.value 
    })
  ) : ("http://bryggesoft.no");

  const release = config.releases ? config.releases.filter(rel => rel.status==1).sort((a,b) => a.release_date > b.release_date ? 1 : -1)[0] : null;

  const text = config.clubConfig ? (
    config.clubConfig.filter(conf => conf.parameter=="SYSTEM").map(conf => {
      return <a key={1} style={{textDecoration: "none"}} href={link} target="_blank">{conf.value+FIXED_TEXT}</a> 
    })
  ) : (<a key={1} style={{textDecoration: "none"}} href="http://bryggesoft.no" target="_blank">Bryggesoft AS</a>)

  return (
      <div key={0} style={{position: "fixed", fontSize: 10, textAlign:"center", bottom: "0px", height: 30, backgroundColor: "WhiteSmoke"}}>
        <span style={{marginLeft: "30px", marginBottom: "5px"}}>{text}</span> <br />
        <span style={{marginLeft: "30px", marginBottom: "5px"}}>Versjon {release ? release.version : "Ukjent"}</span>
      </div>
    )
  }

export default NavFooter;
