import React, { useState, useContext, useEffect } from 'react';
import {useHistory} from 'react-router-dom'

import axios from 'axios'
import {ConfigContext} from '../contexts/ConfigContext'
import { Link } from 'react-router-dom';
//import Footer from './Footer'
import {signIn} from './../utils/Server'
import NavFooter from './NavFooter'

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';

import {Tooltip, Toolbar, List, Typography, Divider, Hidden, ListItem, ListItemIcon, ListItemText, TextField, Button, Avatar, ListItemButton} from '@mui/material';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';
import { useMediaQuery } from '@mui/material';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailIcon from '@mui/icons-material/Mail';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import DirectionsBoatRoundedIcon from '@mui/icons-material/DirectionsBoatRounded';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import PermDataSettingRoundedIcon from '@mui/icons-material/PermDataSettingRounded';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PowerIcon from '@mui/icons-material/Power';
import SubjectIcon from '@mui/icons-material/Subject';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GroupIcon from '@mui/icons-material/Group';



export const ENCRYPT_KEY = 5261;

const drawerWidth = 240;

  ////////////////////
  // Styling
  ////////////////////  
  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));


////////////////////////
// Function w/variables
////////////////////////

const NavBar = (props) => {

  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const BREAKPOINT = 1280;

  const [loginOpen, setLoginOpen] = useState(false); 
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {config, configDispatch} = useContext(ConfigContext);
  const [openPassword, setOpenPassword] = useState(false);  

  const iconTag = (iconName) => {
      switch (iconName) {
        case "NEWS": 
            return <MessageRoundedIcon color="primary" />
        case "MYPAGE":
            return <PersonRoundedIcon color="primary" />
        case "MEMBERLIST":
            return <PeopleRoundedIcon color="primary" />            
        case "ADMNEWS":
            return <NoteAddRoundedIcon color="primary" />            
        case "ADMMEMBERS":
            return <PersonAddRoundedIcon color="primary" />            
        case "AMDPROPERTIES":
            return <HouseRoundedIcon color="primary" />            
        case "ADMBOATPLACES":
            return <DirectionsBoatRoundedIcon color="primary" />            
        case "ADMELPLACES":
          return <PowerIcon color="primary" />            
        case "ADMRENT":
          return <AssignmentIcon color="primary" />            
          case "AMDSNOWCLEARING":
          return <AcUnitRoundedIcon />            
        case "MESSAGELOG":
            return <MailIcon color="primary" />            
        case "DMS":
            return <AttachFileRoundedIcon color="primary" />            
        case "ACCOUNTS":
            return <AccountBalanceRoundedIcon color="primary" />            
        case "CONFIG":
            return <PermDataSettingRoundedIcon color="primary" />            
        case "DMSCONTENT":
            return <MailIcon />        
        case "DOC":
            return <SubjectIcon color="primary" />   
        case "BOARD":
            return <GroupIcon color="primary" />
        default:
            return <MailIcon />
      }
  }


  ////////////////////
  // Functions
  ////////////////////

  const checkValidity = (requirement) => {
    if(requirement==='') {
      return true;
    }
    if(config.clubConfig) {
      //console.log(requirement);
      var result = false;
      config.clubConfig.map(param => {
        //console.log(param);
        if(param.parameter === requirement && param.value ==='1') {
          //console.log("param Match");
          result=true;
        }
      })
      //console.log(result);
      return result
    } else {
      return false
    }
  }

  /////////////////////
  // Server interaction
  /////////////////////

  // Send new password to the user
  const sendPwd = (username) => {
    axios.post(config.server+'members/email/newPassword/'+config.clubId, { 
        clubId: config.clubId,
        email: username
    },{ 
        'headers': { 
                'Content-Type': 'application/json' 
                },
        })
    .then(res => {
      if(res.status===200) {
        console.log("Password sent")
      }
    })
    .catch((err) => {
      console.log('Error', err);
    })            
  }

  ////////////////////
  // Hooks area
  ////////////////////

  useEffect(() => {
    //console.log("Auth error", config.authError);
    //authFailed();
  }, [config.authError]);

  useEffect(() => {
    if(config.signedIn) {
      setLoginOpen(false);
    }
    if(config.goToPage) {
      history.push('/mypage');
      configDispatch({type: 'RESET_GOTO_PAGE'});
    }
  }, [config.signedIn]);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [])

  useEffect(() => {
    //console.log(width)
    if(width>=BREAKPOINT) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [width])


  ////////////////////
  // Action handlers
  ////////////////////

  const openLogin = () => {
    //console.log("Login button clicked");
    setLoginOpen(true);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Login action
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    signIn(config, username, password, configDispatch);
  }

  // Cancel action
  const handleLoginCancel = (e) => {
    setLoginOpen(false);
    configDispatch({type: 'RESET_LOGIN_ERROR'});
  }

  const handleSendPwd = (e) => {
    sendPwd(username);
    setOpenPassword(false);
  }

  const handlePwdCancel = (e) => {
    setOpenPassword(false);
    setUsername('');
  }


  ///////////
  // UI part
  ///////////

  // Check if logged in
  const loginOption = (config.signedIn === true) ? (
    <span style={{position: "absolute", right: 30}}><Avatar>{config.userInitials}</Avatar></span>
  ) : (
    <Button style={{position: "absolute", right: 30}} color="inherit" onClick={openLogin} > Login</Button>
  );

  // Return current page / function
  const funcDesc = config.currentFunction ? (
    <span> | {config.currentFunction}</span>
  ) : (
    null
  );

  // Return the club name
  const clubName = config.clubName ? (
    <span>{config.clubName} {funcDesc}</span>
  ) : (
    <span>Ukjent</span>
  );

  // If login failed, display this message
  const authFailed = config.authError ? (
    <h5 className="warningText"> <span> Feil epost eller passord </span></h5>
  ) : (
    null
  )

  // Menu items with routing and divider logic
  let currentSection = 0;
  const menu = config.menuItems ? config.menuItems.sort((a,b) => (a.section*10000+a.id)>(b.section*10000+b.id) ? 1:-1).map((item, index) => {
    const refKey = item.section>10 ? item.param*ENCRYPT_KEY+"R" : item.param*ENCRYPT_KEY;
    const path = refKey ? item.path+"?refKey="+refKey : item.path;
    //console.log(path);
    // Add divider if the section changes
    let divider = '';
    if(item.section !== currentSection) {
      divider = <Divider />
      currentSection = item.section;
    }
    const valid = checkValidity(item.reqirements);
    if(item.access<=config.accessLevel && valid) {
    return (
      <React.Fragment key={item.id}>
        {divider}
        <ListItem button key={item.id} component={Link} to={path} style={{paddingTop:0, paddingBottom:0}}>
          <ListItemIcon>{iconTag(item.icon)}</ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItem>
      </React.Fragment>
      ) } else {
        return null
      }
  }) : null;  

  // Send new password dialog
  const pwd = (
    <Dialog onClose={handlePwdCancel} open={openPassword} fullScreen={false}>
      <DialogTitle>Send nytt passord</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Det kan ta noen minutter før passordet kommer
        </DialogContentText>
        <TextField
            autoFocus
            margin="dense"
            id="eMail"
            label="ePost"
            type="email"
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
        />
      </DialogContent>
      <DialogActions>
          <Button onClick={handleSendPwd} color="primary" >
            Send
          </Button>
          <Button onClick={handlePwdCancel} color="primary">
            Avbryt
          </Button>
      </DialogActions>
    </Dialog>    
  )

  return (
    <React.Fragment>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {clubName}
          </Typography>
          <Tooltip title="Glemt passord / Send nytt passord" aria-label="add">
            <VpnKeyIcon style={{position: "absolute", right: 120}} color="inherit" onClick={() => setOpenPassword(true)}/>
          </Tooltip>               
          {loginOption}          
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {menu}
        </List>        
        <NavFooter drawerWidth={drawerWidth} />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>

    {pwd}
    <Dialog onClose={() => setLoginOpen(false)} aria-labelledby="Login" open={loginOpen} fullScreen={false}>
        <DialogTitle>Innlogging for medlemmer</DialogTitle>
        <DialogContent>
            <form noValidate autoComplete="no">
                <DialogContentText>
                    Login med din brukernavn (epost) og passord
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="eMail"
                    label="ePost"
                    type="email"
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="password"
                    label="Passord"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                />       
            </form> 
            {authFailed}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleLoginSubmit} color="primary" >
              Login
            </Button>
            <Button onClick={handleLoginCancel} color="primary">
              Avbryt
            </Button>
        </DialogActions>
    </Dialog>

    </React.Fragment>
  );
}

export default NavBar; 



// Old version --> Material-UI v 4

/*import React, { useState, useContext, useEffect } from 'react';
import {useHistory} from 'react-router-dom'

import axios from 'axios'
import {ConfigContext} from '../contexts/ConfigContext'
import { Link } from 'react-router-dom';
//import Footer from './Footer'
import {signIn} from './../utils/Server'
import NavFooter from './NavFooter'

import clsx from 'clsx';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import {Tooltip, Toolbar, List, Typography, Divider, Hidden, ListItem, ListItemIcon, ListItemText, TextField, Button, Avatar} from '@mui/material';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
//import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,withMobileDialog} from '@mui/material';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';
import { useMediaQuery } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailIcon from '@mui/icons-material/Mail';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import DirectionsBoatRoundedIcon from '@mui/icons-material/DirectionsBoatRounded';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import PermDataSettingRoundedIcon from '@mui/icons-material/PermDataSettingRounded';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PowerIcon from '@mui/icons-material/Power';
import SubjectIcon from '@mui/icons-material/Subject';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GroupIcon from '@mui/icons-material/Group';

const drawerWidth = 240;
export const ENCRYPT_KEY = 5261;



// Toolbar or Footer
// Club board
// Club law / rules

function Navbar(props) {

  const history = useHistory();
  //const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const BREAKPOINT = 1280;

  const [loginOpen, setLoginOpen] = useState(false); 
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {config, configDispatch} = useContext(ConfigContext);
  const [openPassword, setOpenPassword] = useState(false);

  const iconTag = (iconName) => {
      switch (iconName) {
        case "NEWS": 
            return <MessageRoundedIcon color="primary" />
        case "MYPAGE":
            return <PersonRoundedIcon color="primary" />
        case "MEMBERLIST":
            return <PeopleRoundedIcon color="primary" />            
        case "ADMNEWS":
            return <NoteAddRoundedIcon color="primary" />            
        case "ADMMEMBERS":
            return <PersonAddRoundedIcon color="primary" />            
        case "AMDPROPERTIES":
            return <HouseRoundedIcon color="primary" />            
        case "ADMBOATPLACES":
            return <DirectionsBoatRoundedIcon color="primary" />            
        case "ADMELPLACES":
          return <PowerIcon color="primary" />            
        case "ADMRENT":
          return <AssignmentIcon color="primary" />            
          case "AMDSNOWCLEARING":
          return <AcUnitRoundedIcon />            
        case "MESSAGELOG":
            return <MailIcon color="primary" />            
        case "DMS":
            return <AttachFileRoundedIcon color="primary" />            
        case "ACCOUNTS":
            return <AccountBalanceRoundedIcon color="primary" />            
        case "CONFIG":
            return <PermDataSettingRoundedIcon color="primary" />            
        case "DMSCONTENT":
            return <MailIcon />        
        case "DOC":
            return <SubjectIcon color="primary" />   
        case "BOARD":
            return <GroupIcon color="primary" />
        default:
            return <MailIcon />
      }
  }

  ////////////////////
  // Styling
  ////////////////////  

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  ////////////////////
  // Functions
  ////////////////////

  const checkValidity = (requirement) => {
    if(requirement==='') {
      return true;
    }
    if(config.clubConfig) {
      //console.log(requirement);
      var result = false;
      config.clubConfig.map(param => {
        //console.log(param);
        if(param.parameter === requirement && param.value ==='1') {
          //console.log("param Match");
          result=true;
        }
      })
      //console.log(result);
      return result
    } else {
      return false
    }
  }

  /////////////////////
  // Server interaction
  /////////////////////

  // Send new password to the user
  const sendPwd = (username) => {
    axios.post(config.server+'members/email/newPassword/'+config.clubId, { 
        clubId: config.clubId,
        email: username
    },{ 
        'headers': { 
                'Content-Type': 'application/json' 
                },
        })
    .then(res => {
      if(res.status===200) {
        console.log("Password sent")
      }
    })
    .catch((err) => {
      console.log('Error', err);
    })            
  }

  ////////////////////
  // useEffect area
  ////////////////////

  useEffect(() => {
    //console.log("Auth error", config.authError);
    //authFailed();
  }, [config.authError]);

  useEffect(() => {
    if(config.signedIn) {
      setLoginOpen(false);
    }
    if(config.goToPage) {
      history.push('/mypage');
      configDispatch({type: 'RESET_GOTO_PAGE'});
    }
  }, [config.signedIn]);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [])

  useEffect(() => {
    //console.log(width)
    if(width>=BREAKPOINT) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [width])

  ////////////////////
  // Action handlers
  ////////////////////

  const openLogin = () => {
    //console.log("Login button clicked");
    setLoginOpen(true);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Login action
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    signIn(config, username, password, configDispatch);
  }

  // Cancel action
  const handleLoginCancel = (e) => {
    setLoginOpen(false);
    configDispatch({type: 'RESET_LOGIN_ERROR'});
  }

  const handleSendPwd = (e) => {
    sendPwd(username);
    setOpenPassword(false);
  }

  const handlePwdCancel = (e) => {
    setOpenPassword(false);
    setUsername('');
  }


  ///////////
  // UI part
  ///////////

  // Check if logged in
  const loginOption = (config.signedIn === true) ? (
    <span style={{position: "absolute", right: 30}}><Avatar>{config.userInitials}</Avatar></span>
  ) : (
    <Button style={{position: "absolute", right: 30}} color="inherit" onClick={openLogin} > Login</Button>
  );

  // Return current page / function
  const funcDesc = config.currentFunction ? (
    <span> | {config.currentFunction}</span>
  ) : (
    null
  );

  // Return the club name
  const clubName = config.clubName ? (
    <span>{config.clubName} {funcDesc}</span>
  ) : (
    <span>Ukjent</span>
  );

  // If login failed, display this message
  const authFailed = config.authError ? (
    <h5 className="warningText"> <span> Feil epost eller passord </span></h5>
  ) : (
    null
  )

  // Menu items with routing and divider logic
  let currentSection = 0;
  const menu = config.menuItems ? config.menuItems.sort((a,b) => (a.section*10000+a.id)>(b.section*10000+b.id) ? 1:-1).map((item, index) => {
    const refKey = item.section>10 ? item.param*ENCRYPT_KEY+"R" : item.param*ENCRYPT_KEY;
    const path = refKey ? item.path+"?refKey="+refKey : item.path;
    //console.log(path);
    // Add divider if the section changes
    let divider = '';
    if(item.section !== currentSection) {
      divider = <Divider />
      currentSection = item.section;
    }
    const valid = checkValidity(item.reqirements);
    if(item.access<=config.accessLevel && valid) {
    return (
      <React.Fragment key={item.id}>
        {divider}
        <ListItem button key={item.id} component={Link} to={path} style={{paddingTop:0, paddingBottom:0}}>
          <ListItemIcon>{iconTag(item.icon)}</ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItem>
      </React.Fragment>
      ) } else {
        return null
      }
  }) : null;  

  // Send new password dialog
  const pwd = (
    <Dialog onClose={handlePwdCancel} open={openPassword} fullScreen={false}>
      <DialogTitle>Send nytt passord</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Det kan ta noen minutter før passordet kommer
        </DialogContentText>
        <TextField
            autoFocus
            margin="dense"
            id="eMail"
            label="ePost"
            type="email"
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
        />
      </DialogContent>
      <DialogActions>
          <Button onClick={handleSendPwd} color="primary" >
            Send
          </Button>
          <Button onClick={handlePwdCancel} color="primary">
            Avbryt
          </Button>
      </DialogActions>
    </Dialog>    
  )

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ mr: 2, ...(open && { display: 'none' }) }} >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {clubName}
            </Typography>
            <Tooltip title="Glemt passord / Send nytt passord" aria-label="add">
              <VpnKeyIcon style={{position: "absolute", right: 120}} color="inherit" onClick={() => setOpenPassword(true)}/>
            </Tooltip>               
            {loginOption}
          </Toolbar>
        </AppBar>
        <Drawer sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', }, }} variant="persistent" anchor="left" open={open} >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>        
            <List>
              {menu}
            </List>
            <Divider />
            <NavFooter drawerWidth={drawerWidth} />
          </Drawer>    
          <Main open={open}>
            DrawerHeader />
            <div style={{height: "100%"}}>
              <div>
                {props.children}
              </div>
              {/*<Footer drawerWidth={drawerWidth} drawerOpen={open}/>*//*}
            </div>      
        </Main>


      {pwd}
      <Dialog onClose={() => setLoginOpen(false)} aria-labelledby="Login" open={loginOpen} fullScreen={false}>
          <DialogTitle>Innlogging for medlemmer</DialogTitle>
          <DialogContent>
              <form noValidate autoComplete="no">
                  <DialogContentText>
                      Login med din brukernavn (epost) og passord
                  </DialogContentText>
                  <TextField
                      autoFocus
                      margin="dense"
                      id="eMail"
                      label="ePost"
                      type="email"
                      onChange={(e) => setUsername(e.target.value)}
                      fullWidth
                  />
                  <TextField
                      margin="dense"
                      id="password"
                      label="Passord"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                  />       
              </form> 
              {authFailed}
          </DialogContent>
          <DialogActions>
              <Button onClick={handleLoginSubmit} color="primary" >
                Login
              </Button>
              <Button onClick={handleLoginCancel} color="primary">
                Avbryt
              </Button>
          </DialogActions>
      </Dialog>
      </Box>
  );


  /*return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open, })} >
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, open && classes.hide)} >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {clubName}
            </Typography>
            <Tooltip title="Glemt passord / Send nytt passord" aria-label="add">
              <VpnKeyIcon style={{position: "absolute", right: 120}} color="inherit" onClick={() => setOpenPassword(true)}/>
            </Tooltip>               
            {loginOption}
          </Toolbar>
        </AppBar>
        <Hidden mdDown>
          <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open} classes={{ paper: classes.drawerPaper, }} >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              {menu}
            </List>
            <Divider />
            <NavFooter drawerWidth={drawerWidth} />
          </Drawer>    
        </Hidden>    
        <Hidden lgUp>
          <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open} classes={{ paper: classes.drawerPaper, }} >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              {menu}
            </List>
            <Divider />
            <NavFooter drawerWidth={drawerWidth} />
          </Drawer>    
        </Hidden>    
        <main className={clsx(classes.content, { [classes.contentShift]: open, })}>
          <div className={classes.drawerHeader} />
            <div style={{height: "100%"}}>
              <div>
                {props.children}
              </div>
              {/*<Footer drawerWidth={drawerWidth} drawerOpen={open}/>*//*}
            </div>      
        </main>
      </div>

      {pwd}
      <Dialog onClose={() => setLoginOpen(false)} aria-labelledby="Login" open={loginOpen} fullScreen={false}>
          <DialogTitle>Innlogging for medlemmer</DialogTitle>
          <DialogContent>
              <form noValidate autoComplete="no">
                  <DialogContentText>
                      Login med din brukernavn (epost) og passord
                  </DialogContentText>
                  <TextField
                      autoFocus
                      margin="dense"
                      id="eMail"
                      label="ePost"
                      type="email"
                      onChange={(e) => setUsername(e.target.value)}
                      fullWidth
                  />
                  <TextField
                      margin="dense"
                      id="password"
                      label="Passord"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                  />       
              </form> 
              {authFailed}
          </DialogContent>
          <DialogActions>
              <Button onClick={handleLoginSubmit} color="primary" >
                Login
              </Button>
              <Button onClick={handleLoginCancel} color="primary">
                Avbryt
              </Button>
          </DialogActions>
      </Dialog>
  </React.Fragment>
  );
}

//export default withMobileDialog()(Navbar);
export default (Navbar);

//const useStyles = makeStyles(theme => ({
/*const useStyles = makeStyles()(theme => ({
    root: {
      display: 'flex',
      overflow: 'hidden'
    },
    appBar: {           
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),   
    },
    hide: {
      display: 'none',
    },
    drawer: {   
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }));*/
  


