const dmsReducer = (state, action) => {
  //console.log("DMS Reducer", action.type, action);
  const ROOT_FOLDER = {
    id:0, 
    name:"Mapper", 
    publishing_id: 3, 
    access:"Alle", 
    menu:0, 
    parent_folder:-1
  }
  switch(action.type) {
    case 'SET_FOLDERS':
      let f = action.folders;
      f.push(ROOT_FOLDER);
      return ( {...state, folders: f } )
    case 'SET_FOLDER_CONTENT':
      return ( {...state, selectedFolderContent: action.content} )
    case 'SET_EXPANDED':
      return ({...state, expanded: action.expanded})
    case 'SET_SELECTED':
      return ({...state, selected: action.selected})
    case 'ADD_FOLDER':
      let newState = {...state};
      newState.folders.push(action.folder);
      newState.selected=[action.folder.id];
      newState.expanded.push(action.folder.id);
      return (newState)
    case 'DELETE_FOLDER':
      let newFolders = state.folders.filter(f => f.id != action.id);
      return ({...state, folders:newFolders, selected: [0]})
    case 'SET_ACTION_NEW':
      return ({...state, act:"NEW"})
    case 'SET_ACTION_CHG':
      return ({...state, act:"CHG"})
    case 'UPDATE_FOLDER':
      let updFolders = state.folders.filter(f => f.id != action.folder.id);
      updFolders.push(action.folder);
      return ({...state, folders:updFolders})
    case 'ADD_DOCUMENT':
      let newDocState = {...state};
      newDocState.selectedFolderContent.push(action.doc);
      return (newDocState)
    case 'DELETE_DOCUMENT':
      let updDocs = state.selectedFolderContent.filter(d => d.id != action.id);
      return ({...state, selectedFolderContent: updDocs})
    default:
      return state;
  }
}

export default dmsReducer;