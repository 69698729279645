import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';
import { ConfigContext } from '../../contexts/ConfigContext'

import {getMembers} from '../../utils/Server'
import {getDateFormat} from '../../utils/Library'
import MeterReading from './meterReading'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import SpeedIcon from '@mui/icons-material/Speed';

const ElPointAdm = () => {

  const INIT_EL_POINT = {
    id: '',
    description: '',
    size: '',
    memberId: '', 
    memberName: ''
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };  
  const { config, configDispatch } = useContext(ConfigContext);
  const [ elPoints, setElPoints ] = useState();
  const [ currentElPoint, setCurrentElPoint ] = useState(INIT_EL_POINT);
  const [ dialogueOpen, setDialogueOpen ] = useState(false);
  const [ editState, setEditState ] = useState('ADD');
  const [ showMeterDialog, setShowMeterDialog] = useState(false);
  const [ selectedId, setSelectedId ] = useState(0);
  const [reload, setReload] = useState(false);
  const confirm = useConfirm();

  /////////////////////
  // Server interaction
  /////////////////////

  // Get all el points for the club
  const getElPoints = () => {
    axios.get(config.server+'elpoints/all/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setElPoints(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  };

  // Save a new el point
  const newElPoint = (ep) => {
    /*members.map(member => {
      if(member.id===ep.memberId) {
        ep.memberName=member.firstName+' '+member.lastName;
      }
    })*/
    axios.post(config.server+'elpoints/new/'+config.clubId, {
      elPoint: ep
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        console.log(res.data);
        currentElPoint.id = res.data.newId;
        setElPoints([...elPoints, currentElPoint]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  };

  // Update existing el point
  const updateElPoint = (ep) => {
    /*members.map(member => {
      if(member.id===ep.memberId) {
        ep.memberName=member.firstName+' '+member.lastName;
      }
    })*/
    axios.patch(config.server+'elpoints/update/'+config.clubId, {
      elPoint: ep
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        var newElPoints = elPoints.filter(n => n.id !== currentElPoint.id);
        setElPoints([...newElPoints, currentElPoint]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  // Delete el point
  const deleteElPoint = (id) => {
    axios.delete(config.server+'elpoints/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        var newElPoints = elPoints.filter(n => n.id != id);
        setElPoints([...newElPoints]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })            
  }

  ////////////////////
  // useEffect area
  // Load initial information (members and roles)
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Adm. elektriske punkter"});
    //getMembers(config, memberDispatch);
    getElPoints();
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }
  }, [config.clubId, reload])


  ////////////////////
  // Action handlers
  ////////////////////
  
  // Open dialogue to add a new boat space
  const handleAdd = () => {
    //console.log("Add");
    setCurrentElPoint(INIT_EL_POINT);
    setEditState("ADD");
    setDialogueOpen(true);
  } 

  // Cancel the open dialogue
  const handleCancel = () => {
    setDialogueOpen(false);
    setCurrentElPoint(INIT_EL_POINT);
  }  

  // Edit el point
  const handleEdit = (e) => {
    const selected = e.currentTarget.id;
    elPoints.map(space => {
      if(space.id == selected) {
        setCurrentElPoint(space);
        setEditState("CHG");
        setDialogueOpen(true);
      }
    })
  }

  // Delete el point
  const handleDelete = (e) => {
    const id = e.currentTarget.id
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes dette målepunktet. Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        deleteElPoint(id);
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
  }


  // Set selected member
  const handleSelectedMember = (e) => {
    setCurrentElPoint({...currentElPoint, memberId: e.target.value})
  } 

  // Save new/changed/delete member including role information
  const handleSave = (action) => {
    //console.log(action);
    setDialogueOpen(false);
    if(action==='NEW') {
      newElPoint(currentElPoint);
    } else {
      updateElPoint(currentElPoint);
    }
    setCurrentElPoint(INIT_EL_POINT);
  }

  // Meter reading
  const handleMeterOpen = (e) => {
    //console.log(e.currentTarget.id);
    setSelectedId(e.currentTarget.id);
    setShowMeterDialog(true);
  }

  const handleMeterClose = () => {
    setShowMeterDialog(false);
  }

  ///////////
  // UI part
  ///////////

  // Dialogue heading
  const diaTitle = (editState==='ADD') ? (
    "Legg til nytt el punkt"
  ) : (
    "Endre el punkt / leietaker"
  );

  // Dialogue toolbar
  const diaToolbar = (editState==='ADD') ? (
      <DialogActions>
        <Button onClick={() => handleSave("NEW")} color="primary">
          Lagre
        </Button>
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
  ) : (
      <DialogActions>
        <Button onClick={() => handleSave("CHG")} color="primary">
          Lagre
        </Button>
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
  )

  // Pop-up dialogue NEW or CHANGE
  const dialogue = (
    <Dialog open={dialogueOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{diaTitle}</DialogTitle>
      <DialogContent>
        <div style={{display:"flex", flexFlow:"flex-start"}}>
          <div style={{width: 200}}>
            <TextField
              key={1}
              required 
              margin="dense"
              id="header"
              label="El punkt"
              fullWidth
              value={currentElPoint.description}          
              onChange={(e) => setCurrentElPoint({...currentElPoint, description: e.target.value})}
              autoFocus
            /> 
          </div>
        </div>
      </DialogContent>
      {diaToolbar}
    </Dialog>
  );

  // Table content
  const tab = elPoints ? (
    elPoints.map(space => {

      const deleteButton = (space.memberId) ? (null) : (
        <Tooltip title="Slett dette el punktet" aria-label="add">
          <DeleteIcon color="primary" fontSize="small" value={space.id} id={space.id} onClick={handleDelete} />
        </Tooltip>  
      ); 

      return (
        <TableRow key={space.id}>
          <TableCell>{space.description}</TableCell>
          <TableCell>{space.memberName ? space.memberName + " siden " + getDateFormat(space.valid_from) : 'Ledig'}</TableCell>
          <TableCell>{space.last_reading_date ? getDateFormat(space.last_reading_date) : 'Ikke avlest'}</TableCell>
          <TableCell>{space.last_reading_value ? space.last_reading_value : 'Ikke avlest'}</TableCell>
          <TableCell>
            <Tooltip title="Måleravlesing">
              <SpeedIcon color="primary" fontSize="small" value={space.id} id={space.id} onClick={handleMeterOpen} />
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Endre infomrasjon om dette el punktet" aria-label="add">
              <EditIcon color="primary" fontSize="small" value={space.id} id={space.id} onClick={handleEdit} />
            </Tooltip>
          </TableCell>
          <TableCell>
            {deleteButton}
          </TableCell>
        </TableRow> 
      )
    })
  ) : (
    null
  );

  return (
    <div>
      {dialogue}
      <MeterReading open={showMeterDialog} setOpen={setShowMeterDialog} meterId={selectedId} elPoints={elPoints} setElPoints={setElPoints} reload={reload} setReload={setReload}/>
      <Tooltip title="Legg til et nytt elektrisk punkt" aria-label="add">
        <Fab color="primary" aria-label="add" >
            <AddIcon onClick={handleAdd}/>
        </Fab>
      </Tooltip>
      <TableContainer component={Paper}>
        <Table style={{minWidth: 650}} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Elektrisk punkt</TableCell>
              <TableCell>Utleid til</TableCell>
              <TableCell>Sist avlest</TableCell>
              <TableCell>Målerstand</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tab}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ElPointAdm;