import React, {useState, useEffect, useContext} from 'react'

import ItemTile from './itemTile'

import { ConfigContext } from '../../contexts/ConfigContext'
import { InvoiceContext } from '../../contexts/InvoiceContext'

import { Typography, Paper, TextField } from '@mui/material'

const InvoiceItems = ({stateInvoiceItems, setStateInvoiceItems, consistentData, setConsistentData, stepperStatus, stateInvoiceHeader}) => {

  const { invoice, invoiceDispatch } = useContext(InvoiceContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    // Check data consistency
    // Perform check here
    if(stateInvoiceItems && stateInvoiceItems.length>0){
      let cons = [...consistentData];
      stateInvoiceItems.map(item => item.price_element ? cons[1] = true : null)
      //cons[1] = true;
      setConsistentData(cons);
      //console.log(cons)  
    }
  }, [stateInvoiceItems])

  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleMemberCCI = (e, costCategoryItem) => {
    console.log(e, costCategoryItem)
  }

  ///////////
  // UI part
  ///////////


  const tiles = invoice.costCategories ? (
    invoice.costCategories.filter(cc => cc.valid==1).sort((a,b) => a.sort_order>b.sort_order ? 1 : -1).map(cc => {
      //console.log(stateInvoiceItems)
      return (
        <ItemTile key={cc.id}
                  tileName={cc.description} 
                  costCategoryId={cc.id} 
                  costCategoryTooltip={cc.long_description}
                  costCategoryItems={invoice.costCategoryItems.filter(cci => cci.cost_category_id==cc.id && cci.valid==1)}
                  invoiceItems={stateInvoiceItems} 
                  setInvoiceItems={setStateInvoiceItems}
                  stateInvoiceHeader={stateInvoiceHeader}
          />
      )
    })
  ) : (
    null
  )

  return (
      <div>
        <Paper elevation={5} sx={{display:"flex", flexWrap: "wrap", margin:"10px", padding:"10px", backgroundColor: "lightgrey"}}>
          {tiles}
        </Paper>
      </div>
    )
  }

export default InvoiceItems;
