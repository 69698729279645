import React, {useState, useContext, useEffect} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';

import { ConfigContext } from '../../contexts/ConfigContext'
import { DmsContext } from '../../contexts/DmsContext'

import moment from "moment";
import "moment/locale/nb";

import {getDateFormat} from '../../utils/Library'

import { Stack, Paper, TextField, Typography, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, InputLabel } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

moment.locale("nb");

const FolderContent = ({folders}) => {

  const { config } = useContext(ConfigContext);
  const { dms, dmsDispatch } = useContext(DmsContext);

  const FILE_INIT = {
    id: null,
    clud_id: config.clubId,
    description: '',
    date: new Date(),
    filename: '',
    folder_id: dms.selected
  }
  const [file, setFile] = useState(FILE_INIT);
  const [hideFileUpload, setHideFileUpload] = useState(false);
  const confirm = useConfirm();


  /////////////////////
  // Server interaction
  /////////////////////
  const saveDocument = (file) => {
    var bodyFormData = new FormData();
    bodyFormData.set('clubId', config.clubId);
    bodyFormData.set('description', file.description);
    bodyFormData.set('date', (file.date=='') ? '' : getDateFormat(file.date));
    bodyFormData.set('folderId', file.folder_id);
    bodyFormData.append('attachmentFile', file.filename);

    axios({
      method: 'post',
      url: config.server+'docsys/documents/new/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        if(res.status===200) {
          let doc = {
            id: res.data.docId,
            clud_id: config.clubId,
            description: res.data.description,
            //date: res.data.dt,
            date: file.date,
            filename: res.data.attachment,
            folder_id: res.data.folderId
          }
          dmsDispatch({type:"ADD_DOCUMENT", doc:doc})
          //console.log(doc);
        }
      })
      .catch(res => {
          //handle error
          console.log(res);
      })
  }

  const deleteDocument = (id) => {
    axios.delete(config.server+'docsys/documents/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        dmsDispatch({type: "DELETE_DOCUMENT", id: id});
      })
      .catch(err => {
          console.log('Error: ',err)
      })         
  }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    //console.log(dms.selected)
    if(dms.selected==0) {
      setHideFileUpload(true)
    } else {
      setHideFileUpload(false)
    }
    
  }, [dms.selected])

  
  ////////////////////
  // Action handlers
  ////////////////////
  const handleFileDate = (selected) => {
    if(selected && selected.isValid) {
      setFile({...file, date: selected.toDate()})
    } else {
      setFile({...file, date: null})
    }
  }

  const handleFileAttachment = (e) => {
    setFile({...file, filename: e.target.files[0]});
  }

  const handleUpload = (e) => {
    file.folder_id=dms.selected;
    //console.log("Save doc ", file);
    saveDocument(file);
    setFile(FILE_INIT);

  }

  const handleDelete = (e) => {
    const id = e.currentTarget.id
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes dette dokumentet. Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        deleteDocument(id);
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });    
  }


  ///////////
  // UI part
  ///////////

  // List all files in the folder
  const overview = dms.selectedFolderContent ? (
    dms.selectedFolderContent.map(doc => {
      //console.log(doc);
      const linkBtn = doc.filename ? <Button color="primary" href={config.server.substring(0, config.server.length - 1)+doc.filename} target="_blank">Dokument</Button> : null   
      return (
        <TableRow key={doc.id}>
          <TableCell>{doc.description}</TableCell>
          <TableCell>{getDateFormat(doc.date)}</TableCell>
          <TableCell>{linkBtn}</TableCell>
          <TableCell>
            <Tooltip title="Slett dokumentet" aria-label="add">
              <DeleteIcon color="primary" fontSize="small" value={doc.id} id={doc.id} onClick={handleDelete} />
            </Tooltip>
          </TableCell>
        </TableRow>
      )
    })
  ):(
    null
  )
  
  // File upload dialog
  

  return (
      <div>
        <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
          <Paper elevation={5} style={{margin:"10px"}}>
            <Accordion disabled={hideFileUpload}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                <Typography>Last opp fil</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{display:"flex", flexDirection:"column"}}>
                <Stack spacing={1}>
                  <TextField required id="x4" label="Dokumentbeskrivelse" value={file.description} onChange={(e) => setFile({...file, description:e.target.value})} />
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    renderInput={(params) => <TextField {...params}/>}
                    margin="normal"
                    format="L"
                    id="date"
                    required
                    label="Dokumentdato"
                    mask="__.__.____"
                    value={(file.date=='' || file.date==null) ? null : file.date}
                    onChange={handleFileDate}        
                    autoOk={true}      
                  />
                  <InputLabel id="select-standard-label">Velg fil for opplasting</InputLabel>
                  <TextField id="standard-required" type="File" onChange={(e) => handleFileAttachment(e)}/>     
                  <Button color="primary" onClick={handleUpload}>Last opp dokument</Button>               
                </Stack>
                </div>                    
              </AccordionDetails>
            </Accordion>
          </Paper>
        </LocalizationProvider>
        <Paper elevation={5} style={{margin:"10px"}}>
          <TableContainer>
            <Table size="small" >
              <TableHead>
                <TableRow>
                  <TableCell>Dokumentbeskrivelse</TableCell>
                  <TableCell>Dokumentdato</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {overview}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
  )
}

export default FolderContent;
