import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import md5  from 'md5'
import { useConfirm } from 'material-ui-confirm';

import { MyDataContext } from '../../contexts/MyDataContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import { getConfig } from '../../utils/Library'

import ExtraUser from './extraUser'

import { Paper, TextField, Typography, Button, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Fab, Tooltip} from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const Person = ({additionalUsers}) => {

  const { config } = useContext(ConfigContext);
  const { myData, myDataDispatch } = useContext(MyDataContext);
  const [person, setPerson] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    address: '',
    zipCode: 0,
    city: '',
    mobile: '',
    eMail:''
  });
  const [password, setPassword] = useState({
    current: '',
    new1: '',
    new2: '',
    returnMessage: ''
  });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const confirm = useConfirm();


  /////////////////////
  // Server interaction
  /////////////////////

  // Save changes to member
  const saveMemberChanges = (person) => {
    axios.patch(config.server+'members/changeMember/'+config.clubId, { 
      member: person
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      console.log(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })      
  }

  // Save new password
  const savePasswordToServer = (password) => {
    axios.post(config.server+'members/set/password/'+config.clubId+'/'+myData.person.id, { 
      newPassword: password.new2
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setPassword({...password, current: '', new1: '', new2: '',returnMessage: "Passordet er endret"});
      setPerson({...person, password:res.data.newPassword});
      //console.log(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })         
  }

    // Delete extra user
    const deleteExtraUser = (id) => {
      axios.delete(config.server+'members/extrausers/delete/'+config.clubId+'/'+id, {
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
        })
        .then(res => {
          myDataDispatch({type: 'DELETE_EXTRA_USER', userId: id});
        })
        .catch(err => {
            console.log('Error: ',err)
        })            
    }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    //console.log(myData.person)
    if(myData.person) {
      setPerson({...myData.person})
    }
  }, [myData.person])
  
  ////////////////////
  // Action handlers
  ////////////////////

  // Save changes to personal information
  const handleSave = () => {
    //console.log("Saving data ", person);
    saveMemberChanges(person);
  }

  // Save new password
  const savePassword = () => {
    //console.log("Set new password ", password);
    savePasswordToServer(password);
  }

  // Open dialog to edit user
  const editUser = (e) => {
    //console.log(e.currentTarget.id)
    setSelectedUserId(e.currentTarget.id)
    setOpen(true)
  }

  // Delete user
  const deleteUser = (e) => {
    const id = e.currentTarget.id
    console.log(id)
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes denne ekstrabrukeren",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        console.log("Deleting "+id)
        deleteExtraUser(id)
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
  }

  // Add user
  const addUser = () => {
    //console.log("Add")
    setOpen(true)
  }


  ///////////
  // UI part
  ///////////

  const changePasswordButton = ((md5(password.current) === person.password) && (password.new1===password.new2) && (password.new1.length>3)) ? (
    <Button variant="contained" color="primary" onClick={(e) => savePassword()}>Endre passord</Button>  
  ):(
    <Button disabled variant="contained" color="primary">Endre passord</Button>  
  );

  const newPasswordFields = (md5(password.current) === person.password) ? (
    <React.Fragment>
    <TextField required margin="dense" type="password" id="newPwd" label="Nytt passord" value={password.new1} onChange={(e) => setPassword({...password, new1: e.currentTarget.value})}></TextField> 
    <TextField required margin="dense" type="password" id="newPwd2" label="Gjenta nytt passord" value={password.new2} onChange={(e) => setPassword({...password, new2: e.currentTarget.value})}></TextField> 
    {changePasswordButton}
    </React.Fragment>
  ) : (
    <React.Fragment>
    <TextField disabled margin="dense" id="newPwd" label="Nytt passord" value={password.new1}></TextField> 
    <TextField disabled margin="dense" id="newPwd2" label="Gjenta nytt passord" value={password.new2}></TextField> 
    {changePasswordButton}
    </React.Fragment>
  );

  const extraUsers = additionalUsers && myData.extraUsers ? (
    myData.extraUsers.map(user => {
      return (
        <TableRow key={user.id}>
          <TableCell style={{fontSize:"10px"}}>{user.email}</TableCell>
          <TableCell style={{fontSize:"10px"}}>{user.name}</TableCell>
          <TableCell style={{fontSize:"10px"}}>{user.phone}</TableCell>
          <TableCell align="center" style={{fontSize:"10px"}}>{user.sendEmail}</TableCell>
          <TableCell align="center" style={{fontSize:"10px"}}>{user.sendSMS}</TableCell>
          <TableCell align="center" style={{fontSize:"10px"}}>{user.canLogin}</TableCell>
          <TableCell><Tooltip title="Endre"><EditIcon fontSize="small" color="primary" id={user.id} onClick={(e) => editUser(e)}/></Tooltip></TableCell>
          <TableCell><Tooltip title="Slett"><DeleteIcon fontSize="small" color="primary" id={user.id} onClick={(e) => deleteUser(e)}/></Tooltip></TableCell>
        </TableRow>
      )
    })
  ) : null;

  const extraUserPanel = additionalUsers ? (
    <Paper style={{display: "flex", flexDirection: "column", width:"400px", margin: "20px" }}>
      <div style={{display:"flex", justifyContent:"space-between"}}>
        <Typography variant="subtitle2" gutterBottom>Ekstra brukere</Typography>
        <Tooltip title="Legg til ny ekstrabruker" aria-label="add"> 
          <Fab size="small" color="primary" aria-label="add" >
            <AddIcon  onClick={(e) => addUser(e)}/>
          </Fab>
        </Tooltip>
      </div>
      <TableContainer component={Paper}>
        <Table padding="none" size="small" style={{fontSize:"10px"}}>
          <TableHead>
            <TableRow>
              <TableCell style={{fontSize:"10px"}}>ePost adr</TableCell>
              <TableCell style={{fontSize:"10px"}}>Navn</TableCell>
              <TableCell style={{fontSize:"10px"}}>Mobil</TableCell>
              <TableCell style={{fontSize:"10px"}}>ePost</TableCell>
              <TableCell style={{fontSize:"10px"}}>SMS</TableCell>
              <TableCell style={{fontSize:"10px"}}>Login</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {extraUsers}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ) : (null)



  return (
      <div style={{display: "flex", flexFlow: "wrap" }} >
        <ExtraUser open={open} setOpen={setOpen} selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId}/>
        <div>
          <Paper style={{display: "flex", flexDirection: "column", width:"300px", margin: "20px" }} >
            <Typography variant="h6" gutterBottom>Kontaktinformasjon</Typography>
            <TextField margin="dense" id="firstname" label="Fornavn" value={person.firstName} onChange={(e) => setPerson({...person, firstName: e.currentTarget.value})}></TextField>
            <TextField margin="dense" id="lastname" label="Etternavn" value={person.lastName} onChange={(e) => setPerson({...person, lastName: e.currentTarget.value})}></TextField>
            <TextField margin="dense" id="company" label="Firmanavn" value={person.companyName} onChange={(e) => setPerson({...person, companyName: e.currentTarget.value})}></TextField>
            <TextField margin="dense" id="street" label="Adresse" value={person.address} onChange={(e) => setPerson({...person, address: e.currentTarget.value})}></TextField>
            <TextField margin="dense" id="zip" label="Postnummer" value={person.zipCode} onChange={(e) => setPerson({...person, zipCode: e.currentTarget.value})}></TextField>
            <TextField margin="dense" id="city" label="Sted" value={person.city} onChange={(e) => setPerson({...person, city: e.currentTarget.value})}></TextField>
            <TextField required margin="dense" id="mobile" label="Mobil" value={person.mobile} onChange={(e) => setPerson({...person, mobile: e.currentTarget.value})}></TextField>
            <TextField required margin="dense" id="email" label="ePost" value={person.eMail} onChange={(e) => setPerson({...person, eMail: e.currentTarget.value})}></TextField> 
            <Button variant="contained" color="primary" onClick={handleSave}>Lagre endringer</Button>
          </Paper>
        </div>
        <div>
          <Paper style={{display: "flex", flexDirection: "column", width:"400px", margin: "20px" }} >
            <Typography variant="h6" gutterBottom>Bytt passord</Typography>
            <TextField required margin="dense" id="pwd" type="password" label="Passord" value={password.current} onChange={(e) => setPassword({...password, current: e.currentTarget.value})}></TextField> 
            {newPasswordFields}
            <Typography variant="caption" display="block" gutterBottom>{password.returnMessage}</Typography>
          </Paper>
          {extraUserPanel}
        </div>
      </div>
    )
  }

export default Person;
