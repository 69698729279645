import React, {useState, useContext} from 'react'

import { ConfigContext } from '../../contexts/ConfigContext'

import { Paper, TextField, Typography, Button } from '@mui/material'


const AdminMainPage = () => {
  const [newUserId, setNewUserId] = useState('');
  const { config, configDispatch } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////
  const handleSetUserId = () => {
    configDispatch({type: 'SET_SIMULATION_USER', userId: newUserId})
  } 


  ///////////
  // UI part
  ///////////

  return (
      <div>
        <Paper style={{display: "flex", flexDirection: "column"}}>
          <Typography>{config.userId}</Typography>
          <TextField margin="dense" id="userId" label="Simulate user id" value={newUserId} onChange={(e) => setNewUserId(e.currentTarget.value)}></TextField>
          <Button variant="contained" color="primary" onClick={handleSetUserId}>Simuler bruker</Button>
        </Paper>
      </div>
    )
  }

export default AdminMainPage;
