import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'

import {getDateFormat, getConfig} from '../../utils/Library'

import moment from "moment";
import "moment/locale/nb";

import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'

import {Button, Stack} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

//import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import MomentUtils from '@date-io/moment';

moment.locale("nb");

const BoatSpaceLeaseEdit = ({open, setOpen, selectedLease, setSelectedLease, reload, setReload }) => {

  const { config } = useContext(ConfigContext);
  const { members } = useContext(MemberContext);

  const LEASE_INIT = {
    id: null,
    member_id: '',
    boat_space_id: '',
    valid_from: new Date(),
    valid_to: '',
    deposit: '',
    attachment: '',
    completed: 0
  }
  const [lease, setLease] = useState(LEASE_INIT);
  const [bsList, setBsList] = useState([]);

  /////////////////////
  // Server interaction
  /////////////////////
  const getAllBoatSpaces = () => {
    axios.get(config.server+'boatspaces/all/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        setBsList(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })                    
  }

  const saveUpdate = (lease) => {
    //console.log(lease);

    var bodyFormData = new FormData();
    bodyFormData.set('clubId', config.clubId);
    bodyFormData.set('lease_id', lease.id);
    bodyFormData.set('member_id', lease.member_id);
    bodyFormData.set('boat_space_id', lease.boat_space_id);
    bodyFormData.set('valid_from', (lease.valid_from=='' || lease.valid_from==null) ? '' : getDateFormat(lease.valid_from));
    bodyFormData.set('valid_to', (lease.valid_to=='' || lease.valid_to==null) ? '' : getDateFormat(lease.valid_to));
    bodyFormData.set('deposit', lease.deposit);
    bodyFormData.set('completed',lease.completed);
    //console.log(bodyFormData);
    bodyFormData.append('attachmentFile', lease.attachment);
    //console.log(lease.attachment);

    axios({
      method: 'patch',
      url: config.server+'boatspaces/lease/update/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        if(res.status===200) {
          //console.log(res.data);
          setLease(LEASE_INIT);
          setReload(!reload);
        }
      })
      .catch(res => {
          //handle error
          console.log(res);
      })
  }

  const saveNew = (lease) => {
    //console.log(getDateFormat(document.date));

    var bodyFormData = new FormData();
    bodyFormData.set('clubId', config.clubId);
    bodyFormData.set('member_id', lease.member_id=='' ? 0 : lease.member_id);
    bodyFormData.set('boat_space_id', lease.boat_space_id=='' ? 0 : lease.boat_space_id);
    bodyFormData.set('valid_from', (lease.valid_from=='') ? '' : getDateFormat(lease.valid_from));
    bodyFormData.set('valid_to', (lease.valid_to=='') ? '' : getDateFormat(lease.valid_to));
    bodyFormData.set('deposit', lease.deposit=='' ? 0 : lease.deposit);
    bodyFormData.set('completed',lease.completed);
    bodyFormData.append('attachmentFile', lease.attachment);
    //console.log(lease.attachment);

    axios({
      method: 'post',
      url: config.server+'boatspaces/lease/new/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        if(res.status===200) {
          //console.log(res.data);
          setLease(LEASE_INIT);
          setReload(!reload);
        }
      })
      .catch(res => {
          //handle error
          console.log(res);
      })
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    getAllBoatSpaces();
  }, [reload]);

  useEffect(() => {
    if(selectedLease) {
      setLease(selectedLease);
    }
  }, [selectedLease]);
  
  ////////////////////
  // Action handlers
  ////////////////////

  const closeDialog = () => {
    setOpen(false);
    setLease(LEASE_INIT);
    setSelectedLease(null);
  }

  const handleValidFromDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setLease({...lease, valid_from: selectedDate.toDate()})
    } else {
      setLease({...lease, valid_from: null})
    }
  }

  const handleValidToDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setLease({...lease, valid_to: selectedDate.toDate()})
    } else {
      setLease({...lease, valid_to: null})
    }    
  }

  const handleSave = () => {
    //console.log("Save", lease);
    if(lease.id) {
      //console.log("Update", lease);
      saveUpdate(lease);
    } else {
      //console.log("New", lease);
      saveNew(lease);
    }
    setOpen(false);
  }

  const handleFileAttachment = (e) => {
    setLease({...lease, attachment: e.target.files[0]});
  }

  const handleCompletedCheckbox = (e) => {
    //console.log(e.target.checked);
    if(e.target.checked==true) {
      setLease({...lease, completed:1})
    } else {
      setLease({...lease, completed:0})
    }
  }

  ///////////
  // UI part
  ///////////

  const memberList = members ? (
    members.sort((a,b)=> (a.lastName>b.lastName)? 1:0).map(member => {
      //console.log(member);
      return (
        <MenuItem key={member.id} value={member.id} >{member.companyName ? (member.companyName) : (member.firstName+' '+member.lastName)}</MenuItem>
      )
    })
  ) : (
    null
  );

  //console.log(elList);
  const filterdList = (list) => {
    if(list) {
      if(selectedLease) {
        return list.filter(bs => (bs.memberId===null || bs.id==selectedLease.boat_space_id))
      } else {
        return list.filter(bs => bs.memberId===null)
      }
    } else {
      return list
    } 
  };

  //elList.filter(el => el.memberId===null).sort((a,b) => (a.description>b.description) ? 1:0).map(el => {
  const boatSpaceList = bsList ? (
    filterdList(bsList).sort((a,b) => (a.description>b.description) ? 1:0).map(bs => {
    //console.log(el);
      return (
        <MenuItem key={bs.id} value={bs.id} >{bs.description}</MenuItem>
      )    
    })
  ) : (
    null
  );

  const headerText = (lease.id) ? "Endre leiekontrakt" : "Ny leiekontrakt"; 

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
      <div key={1}>
        <Dialog open={open} onClose={closeDialog} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{headerText}</DialogTitle>
          <DialogContent>
            <div style={{display:"flex", flexDirection:"column"}}>
            <Stack spacing={1} >
            <TextField
                id="select-from-label"
                sx={{marginTop: "5px"}}
                select
                required
                label="Medlem"
                value={lease.member_id}
                onChange={(e) => setLease({...lease, member_id: e.target.value})}
              >
                {memberList}
              </TextField>
              <TextField
                id="select-from-label"
                sx={{marginTop: "5px"}}
                select
                required
                label="Båtplass"
                value={lease.boat_space_id}
                onChange={(e) => setLease({...lease, boat_space_id: e.target.value})}
              >
                {boatSpaceList}
              </TextField>
              <DatePicker
                disableToolbar
                required
                variant="inline"
                renderInput={(params) => <TextField {...params}/>}
                margin="normal"
                format="L"
                id="fromDate"
                label="Gyldig fra"
                mask="__.__.____"
                value={(lease.valid_from=='' || lease.valid_from==null) ? null : lease.valid_from}
                onChange={handleValidFromDate}        
                autoOk={true}      
              />
              <TextField required id="x1" label="Depositum" value={lease.deposit} onChange={(e) => setLease({...lease, deposit:e.target.value})} />
              <Divider />           
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lease.completed==1 ? true : false}
                    onChange={handleCompletedCheckbox}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Avsluttet"
              />     
              <DatePicker
                disableToolbar
                variant="inline"
                renderInput={(params) => <TextField {...params}/>}
                margin="normal"
                format="L"
                id="toDate"
                label="Gyldig til"
                mask="__.__.____"
                value={(lease.valid_to=='' || lease.valid_to==null) ? null : lease.valid_to}
                onChange={handleValidToDate}        
                autoOk={true}      
              />
              <InputLabel id="select-standard-label">Kontrakt / vedlegg</InputLabel>
              <TextField id="standard-required" type="File" onChange={(e) => handleFileAttachment(e)}/>
            </Stack>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSave}>
              Lagre
            </Button>
            <Button color="primary" onClick={closeDialog}>
              Avbryt
            </Button>
          </DialogActions>          
        </Dialog>        
      </div>
    </LocalizationProvider>
    )
  }

export default BoatSpaceLeaseEdit;
