import React, {useEffect, useContext, useState} from 'react'

import { InvoiceContext } from '../../contexts/InvoiceContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'
import { AccountContext } from '../../contexts/AccountContext'

import {getDateFormat, numberFormat} from '../../utils/Library'

import { Paper, Typography, DialogContent, DialogActions, DialogTitle, Dialog, Button, TableHead, TableRow, TableContainer, TableCell, Table, TableBody } from '@mui/material'

const InvoicePreview = ({invoice_id, consistentData, setConsistentData, stateInvoiceMembers, processing }) => {

  const { config } = useContext(ConfigContext);
  const { invoice } = useContext(InvoiceContext);
  const { members } = useContext(MemberContext);
  const { accounts } = useContext(AccountContext);

  //const [open, setOpen] = useState(invoice.invoiceMembers ? invoice.invoiceMembers.filter(im=> im.invoice_id==invoice_id).map(m => {
  const [open, setOpen] = useState(stateInvoiceMembers ? stateInvoiceMembers.map(m => {
    return {id: m.member_id, open: false}
  }) : ([]));
  
  //const [open, setOpen] = useState(false);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  // Check data consistency to allow user to navigate to the next step
  useEffect(() => {
    console.log(processing)
    if(!processing) {
      let cons = [...consistentData];
      cons[3] = true;
      setConsistentData(cons);
    }
    //console.log(cons)  
  }, [processing])

  useEffect(() => {
    //console.log(invoice.invoiceMembers);
  }, [invoice.invoiceMembers])
  
  ////////////////////
  // Action handlers
  ////////////////////
  const handleClick = (e) => {
    let newOpen = open.filter(f => f.id!=e.currentTarget.id);
    let obj = open.find(f => f.id==e.currentTarget.id);
    obj.open=true;
    newOpen.push(obj);
    setOpen(newOpen);
  }

  const closeDialog =(e) => {
    let newOpen = [...open];
    newOpen.map(o => o.open=false);
    setOpen(newOpen);
  }

  const isOpen = (id) => {
    //console.log(open)
    if(open) {
      let obj = open.find(e => e.id==id)
      return obj.open;  
    } else return false
  }

  ///////////
  // UI part
  ///////////

  const name = (member_id) => {
    let mem = members.find(m => m.id==member_id);
    return mem.companyName!='' ? mem.companyName : mem.firstName+" "+mem.lastName;
  }



  const invHead = invoice.invoiceHeaders ? (
    invoice.invoiceHeaders.filter(ih => ih.id==invoice_id).map(inv => {
      return (
        <div key={"Header"}>
          <Typography variant="h6">{inv.description}</Typography>
          <Typography variant="subtitle2">Forfall {getDateFormat(inv.due_date)}</Typography>
        </div>
      )
    })
  ) :(
    null
  );

  // Show processing text
  const processingText = processing ? (
    <Paper elevation={5} style={{margin:"10px", backgroundColor: "brown", minWidth:"300px", maxWidth:"650px"}} >
      <Typography align="center" variant="h6">Vennligst vent mens systemet genererer fakturaene</Typography>
    </Paper>
  ) : null

    

  //console.log(invoice.invoiceMembers);
  const getMemberTotal = (invoice_id, member_id) => {
    let total = 0;
    if(invoice.invoiceMemberItems) {
      invoice.invoiceMemberItems.filter(ii => ii.member_id==member_id && ii.invoice_id==invoice_id).sort((a,b) => a.item_no>b.item_no ? 1 : -1).map(item => {
        total += parseFloat(item.price_element);
      })  
    }
    return total;
  }

  const getTotal = (invoice_id) => {
    let total = 0;
    if(invoice.invoiceMemberItems) {
      invoice.invoiceMemberItems.filter(ii => ii.invoice_id==invoice_id).map(item => {
        total += parseFloat(item.price_element);
      })  
    }
    return total;
  }

  const invoicebuttons = invoice.invoiceMembers ? (
    invoice.invoiceMembers.filter(im => im.invoice_id==invoice_id && getMemberTotal(invoice_id, im.member_id)!=0 ).map(member => {
      //console.log(member);
      //console.log(invoice.invoiceMemberItems);

      const invItems = invoice.invoiceMemberItems ? (
        invoice.invoiceMemberItems.filter(ii => ii.member_id==member.member_id && ii.invoice_id==invoice_id).sort((a,b) => a.item_no>b.item_no ? 1 : -1).map(item => {
          let costCategoryItem = invoice.costCategoryItems.find(cci => cci.cost_category_item==item.cost_category_item);
          let costCategory = invoice.costCategories.find(cc => cc.id==costCategoryItem.cost_category_id);
          const volume = item.volume ? "("+item.volume+")" : null;
          //console.log(costCategory, costCategoryItem);
          return (
            <TableRow key={costCategoryItem.cost_category_item}>
              <TableCell align="left">{costCategory.description + " - " + costCategoryItem.category_item_text}</TableCell>
              <TableCell>{costCategoryItem.price_element}</TableCell>
              <TableCell align="right">{volume}</TableCell>
              <TableCell align="right">{numberFormat(item.price_element)}</TableCell>
              <TableCell align="left">kr</TableCell>
            </TableRow>
          )
        })
      ):(null);

      //console.log(member)
      const pdfLinkBtn = member.pdf ? <Button color="primary" href={config.server.substring(0, config.server.length - 1)+member.pdf} target="_blank">PDF</Button> : null 

      return (
        <div key={member.member_id} style={{margin:"3px"}}>
          <Button id={member.member_id} size="small" variant="contained" color="primary" onClick={handleClick}>
            {name(member.member_id)}
          </Button>
          <Dialog id={member.member_id} open={isOpen(member.member_id)} onClose={closeDialog} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{name(member.member_id)}</DialogTitle>
          <DialogContent>
            <div style={{display:"flex", flexDirection:"column"}}>
              {invHead}
              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Tekst</TableCell>
                        <TableCell>Enhet</TableCell>
                        <TableCell align="right">Volum</TableCell>
                        <TableCell align="right">Beløp</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invItems}
                      <TableRow>
                        <TableCell align="left">Totalt</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">{numberFormat(getMemberTotal(invoice_id, member.member_id))}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={closeDialog}>
              Lukk
            </Button>
            {pdfLinkBtn}
          </DialogActions>          
        </Dialog>        
        </div>
      )
    })
  ) : (null)

  //console.log(invoice)
  const invHeader = invoice.invoiceHeaders ? invoice.invoiceHeaders.find(ih => ih.id==invoice_id) : {}
  const incomeAct = accounts.accumulated && invoice.invoiceHeaders ? accounts.accumulated.find(act => act.account_id==invHeader.income_account) : {}
  const balanceAct = accounts.accumulated && invoice.invoiceHeaders ? accounts.accumulated.find(act => act.account_id==invHeader.accounts_receivable_account) : {}
  //console.log(invHeader, accounts.accumulated, incomeAct, balanceAct)

  return (
      <div>
        <Paper elevation={5} style={{margin:"10px", backgroundColor: "lightgrey", minWidth:"300px", overflow: "auto"}} >
          <Typography variant="subtitle1">Forhåndsvisning - se fakturaene før de bokføres</Typography>
          <div style={{display:"flex", flexWrap:"wrap"}}>
            {invoicebuttons}
          </div>
        </Paper>
        {processingText}
        <Paper elevation={5} style={{margin:"10px", backgroundColor: "lightgrey", minWidth:"300px", maxWidth:"650px"}} >
          <Typography variant="subtitle1">Slik vil fakturaene bli bokført i regnskapet</Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">Inntektskonto (kredit):</TableCell>
                    <TableCell align="left">{incomeAct ? incomeAct.account_id+" - "+incomeAct.account_name : null}</TableCell>
                    <TableCell align="right">{numberFormat(getTotal(invoice_id)*-1)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">Balansekonto (debit):</TableCell>
                    <TableCell align="left">{balanceAct ? balanceAct.account_id+" - "+balanceAct.account_name : null}</TableCell>
                    <TableCell align="right">{numberFormat(getTotal(invoice_id))}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>          
          {}
        </Paper>
      </div>
    )
  }

export default InvoicePreview;
