import React, {useEffect, useContext, useState} from 'react'
import {getDateFormat} from './../../utils/Library'

import { MyDataContext } from '../../contexts/MyDataContext'

import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

const MyMessages = () => {
  const {myData} = useContext(MyDataContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  const messageItems = myData.messages ? (
    myData.messages.sort((a,b) => a.time > b.time ? -1 : 1).map(msg => {
      return (
        <TableRow key={msg.id+msg.receiver_address}>
          <TableCell align="center">{getDateFormat(msg.time)}</TableCell>
          <TableCell align="center">{msg.msgType}</TableCell>
          <TableCell>{msg.subject}</TableCell>
          <TableCell>{msg.content}</TableCell>
        </TableRow>        
      )
    })
  ) : null;

  return (
      <div style={{overflow: "scroll", maxHeight: "max-content"}}>
        <Paper>
          <TableContainer component={Paper}>
            <Table padding="none" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Tidspunkt</TableCell>
                  <TableCell>Kanal</TableCell>
                  <TableCell>Overskrift</TableCell>
                  <TableCell>Innhold</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messageItems}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    )
  }

export default MyMessages;
