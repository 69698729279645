import React, {useEffect, useContext, useState} from 'react'
import { Paper, TextField, Typography, Tooltip, Button } from '@mui/material'

import {getConfigVal} from './../../utils/Library'
import { ConfigContext } from '../../contexts/ConfigContext'

const HomePageConfig = ({saveAll}) => {
  
  const { config, configDispatch } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////
  const handleConfigChange = (e) => {
    var str = e.target.value
    if(str && str.length<12) {
      configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: e.target.value})
    }
  }

  ///////////
  // UI part
  ///////////

  return (
      <div>
        <div style={{display: "flex", flexDirection:"column"}}>
          <Paper elevation={6} style={{margin: "5px", padding: "10px", maxWidth:"400px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Konfigurering av hjemmesiden</Typography>
            <TextField
                  key={1} required margin="dense" id="img1" label="Forsidebilde 1 (filnavn)" name="FRONTPAGE_IMAGE_NAME_1"
                  fullWidth value={getConfigVal(config, "FRONTPAGE_IMAGE_NAME_1")}    
                  disabled={parseInt(config.accessLevel)===999 ? false:true}      
                  onChange={handleConfigChange}
            />
            <TextField
                  key={2} required margin="dense" id="img2" label="Forsidebilde 2 (filnavn)" name="FRONTPAGE_IMAGE_NAME_2"
                  fullWidth value={getConfigVal(config, "FRONTPAGE_IMAGE_NAME_2")}    
                  disabled={parseInt(config.accessLevel)===999 ? false:true}      
                  onChange={handleConfigChange}
            />
            <TextField
                  key={3} required margin="dense" id="pos" label="Forsidebilde posisjon (top, bottom, left, right)" name="FRONTPAGE_IMAGE_POSISION"
                  fullWidth value={getConfigVal(config, "FRONTPAGE_IMAGE_POSISION")}    
                  disabled={parseInt(config.accessLevel)===999 ? false:true}      
                  onChange={handleConfigChange}
            />
          </Paper>
        </div>
        <Button color="primary" onClick={(e) => saveAll(e)}>
          Lagre
        </Button>              
      </div>
    )
  }

export default HomePageConfig;
