const invoiceReducer = (state, action) => {
  //console.log("Invoice Reducer", action.type, action);
  switch(action.type) {
    case 'SET_COST_CATEGORIES':
      return ( {...state, costCategories: action.cc} )
    case 'SET_COST_CATEGORY_ITEMS':
      return ({...state, costCategoryItems: action.cci})
    case 'SET_INVOICE_HEADERS':
      //console.log("Setting inv headers")
      return ({...state, invoiceHeaders: action.invoices})
    case 'ADD_INVOICE_HEADER':
      var newHeaders = [...state.invoiceHeaders];
      newHeaders.push(action.invoice);
      return ({...state, invoiceHeaders: newHeaders})
    case 'UPDATE_INVOICE_HEADER':
      var newHeaders2 = state.invoiceHeaders.filter(ih => ih.id!=action.invoice.id);
      newHeaders2.push(action.invoice);
      return ({...state, invoiceHeaders: newHeaders2})
    case 'DELETE_INVOICE':
      var newInvHeaders = state.invoiceHeaders.filter(ih => ih.id!=action.invoiceId);
      return ({...state, invoiceHeaders: newInvHeaders})
    case 'SET_INVOICE_CONFIG':
      //console.log(action.config)
      return ({...state, invoiceConfig: action.config})
    case 'UPDATE_INVOICE_CONFIG':
      var updatedValues = action.config;
      var newConfigList = state.invoiceConfig.filter(conf => conf.invoice_id!=action.invoiceId);
      newConfigList.push(updatedValues);
      return ({...state, invoiceConfig: newConfigList})
    case 'SET_INVOICE_MEMBERS':
      return ({...state, invoiceMembers: action.members})
    case 'SET_INVOICE_MEMBER_ITEMS':
      //console.log(action.memberItems)
      return ({...state, invoiceMemberItems: action.memberItems})
    case 'UPDATE_INVOICE_MEMBERS':
      var updatedInvoiceMembers = action.invoiceMembers;
      //console.log(updatedInvoiceMembers);
      var newMemberList = state.invoiceMembers.filter(conf => conf.invoice_id!=action.invoiceId);
      newMemberList.push(...updatedInvoiceMembers);
      return ({...state, invoiceMembers: newMemberList, invoiceMemberItems: action.invoiceMemberItems})
    default:
      return state;
  }
}

export default invoiceReducer;