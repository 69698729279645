import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'

import { AccountContext } from '../../contexts/AccountContext'
import { ConfigContext } from '../../contexts/ConfigContext'

import {Button,Stack} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography, Tooltip, TextField } from '@mui/material'
import Paper from '@mui/material/Paper';

import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';


const ManageAccounts = ({open, setOpen, reload, setReload }) => {

  const INIT_ACCOUNT = {
    id: '',
    name: '',
    deactivated: '', 
    group_id: '',   
    group_name: '',
    noOfTransactions: 0,
    config: 0
  };
  const [account, setAccount] = useState(INIT_ACCOUNT);

 
  const { config } = useContext(ConfigContext);
  const { accounts } = useContext(AccountContext);
  const [accountList, setAccountList] = useState([]);


  /////////////////////
  // Server interaction
  /////////////////////

  // Call the "getAccountList" server function
  const getAccountList = () => {
    axios.get(config.server+'accounting/account/list/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        setAccountList(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })        
  }

  // Call the activte account server function
  const activateAccount = (accountId, action) => {
    console.log(accountId, action);
    axios.patch(config.server+'accounting/account/activate/'+config.clubId, {
      accountId: accountId,
      action: action
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      var account = accountList.find(act => act.id==accountId);
      action=='A' ? (account.deactivated = null) : (account.deactivated = 1);
      const newList = accountList.filter(a => a.id!=accountId);
      setAccountList([...newList, account]);
      setReload(true)
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  // Call server function to save a new account
  const saveNewAccount = (account) => {
    axios.post(config.server+'accounting/account/create/'+config.clubId, {
      account: account
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setAccountList([...accountList, account]);
      setReload(true)
    })
    .catch(err => {
        console.log('Error: ',err)
    })              
  }

  // Call server function to save a new account
  const saveUpdatedAccount = (account) => {
    axios.patch(config.server+'accounting/account/change/'+config.clubId, {
      account: account
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      const newList = accountList.filter(a => a.id!=account.id);
      setAccountList([...newList, account]);
      setReload(true)
    })
    .catch(err => {
        console.log('Error: ',err)
    })              
  }  

  // Call server function to delete an account
  const deleteAccount = (id) => {
    axios.delete(config.server+'accounting/account/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      const newList = accountList.filter(a => a.id != id);
      setAccountList([...newList]);
      setReload(true)
    })
    .catch(err => {
        console.log('Error: ',err)
    })               
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    getAccountList();
    //console.log("Accounts loaded")
  }, [])

  ////////////////////
  // Action handlers
  ////////////////////

  // Close dialogue
  const handleClose = () => {
    setOpen(false);
    //setPeriod(INIT_PERIOD);
    setReload(!reload);
  }

  // Actiate / Deactivate an account
  const handelActivate = (e,action) => {
    //console.log(e.currentTarget.id, action);
    activateAccount(e.currentTarget.id, action);
  }

  // Handle input from the text fields
  const handleInput = (e) => {
    if(e.target.id=='id') {
      const grp = accounts.accountGroups.find(ag => ag.from_no<=e.target.value && ag.to_no>=e.target.value);
      if(grp) {
        setAccount({...account, id: e.target.value, group_id: grp.id, group_name: grp.name});
      } else {
        setAccount({...account, id: e.target.value, group_id: '', group_name: ''});
      }
    }

    if(e.target.id=='name') {
      setAccount({...account, name: e.target.value});
    }
  }

  // Save new or ipdated account information
  const handleSave = () => {
    const exitingAccount = accountList.find(a => a.id==account.id);
    if(exitingAccount) {
      saveUpdatedAccount(account);
    } else {
      saveNewAccount(account);
    }
    setAccount(INIT_ACCOUNT);
  }

  // Delete an account
  const handleDelete = (e) => {
    deleteAccount(e.currentTarget.id);
    setAccount(INIT_ACCOUNT);
  }

  const handleEdit = (e) => {
    const editId = e.currentTarget.id
    const exitingAccount = accountList.find(a => a.id==editId);
    setAccount({...account, id: exitingAccount.id, group_id: exitingAccount.group_id, group_name: exitingAccount.group_name, name: exitingAccount.name});
    /*const INIT_ACCOUNT = {
      id: '',
      name: '',
      deactivated: '', 
      group_id: '',   
      group_name: '',
      noOfTransactions: 0,
      config: 0
    };
    const [account, setAccount] = useState(INIT_ACCOUNT);*/
    //console.log(accountList)

  }

  ///////////
  // UI part
  ///////////

  const accountListOverview = accountList ? (
    accountList.sort((a,b) => a.id-b.id).map(act => {
      const activateButton = act.deactivated=='1' ? (
          <Tooltip title="Aktiver konto">
            <IconButton id={act.id} onClick={(e) => handelActivate(e,'A')} >
              <PowerIcon color="primary" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Deaktiver konto">
            <IconButton id={act.id}  onClick={(e) => handelActivate(e,'D')} >
              <PowerOffIcon color="primary" />
            </IconButton>
          </Tooltip>
        )

      const deleteButton = (act.noOfTransactions == 0 && act.config == 0) ? (
        <Tooltip title="Slett konto">
          <IconButton id={act.id} onClick={(e) => handleDelete(e) } >
            <DeleteIcon color="secondary" />
          </IconButton>
        </Tooltip>
      ) : (
        null
      )
      
      const changeButton = (
        <TableCell>
        <Tooltip title="Endre konto informasjon">
          <IconButton id={act.id} onClick={(e) => handleEdit(e)} >
            <EditIcon color="primary" />
          </IconButton>
        </Tooltip>
      </TableCell>        
      )

      return (
        <TableRow key={act.id} style={act.deactivated=='1' ? { textDecoration: "line-through"} : {}}>
          <TableCell style={{paddingRight: "5px"}}>{act.id}</TableCell>
          <TableCell style={{paddingRight: "5px"}}>{act.name}</TableCell>
          <TableCell>{act.group_name}</TableCell>
          <TableCell>{changeButton}</TableCell>
          <TableCell>{activateButton}</TableCell>
          <TableCell>{deleteButton}</TableCell>
        </TableRow>
      )
    })
  ) : (null)

  const allowSave = (account.id&& account.name && account.group_id) ? true : false

  return (
      <div>
      <Dialog open={open} onClose={(() => setOpen(false))} aria-labelledby="form-dialog-title" maxWidth="xl">
      <DialogTitle id="form-dialog-title">Administrer kontoer</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table padding="none" size="small" stickyHeader style={{minWidth: 500 }} >
                <TableHead>
                  <TableRow>
                    <TableCell style={{paddingRight: "5px"}}></TableCell>
                    <TableCell style={{paddingRight: "5px"}}>Kontonavn</TableCell>
                    <TableCell>Gruppe</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accountListOverview}
                </TableBody>
              </Table>
            </TableContainer>
              <Paper elevation={3} style={{display: "flex", flexDirection:"column", marginTop: 40, padding:"5px"}} >
                <Stack spacing={1}>
                <Typography variant="h6" sx={{fontSize: "15px"}} >Opprett ny eller endre konto</Typography>
                <TextField required id="id" label="Id" size="small" onChange={(e) => handleInput(e)} value={account.id} />
                <TextField required id="name" label="Navn" size="small" onChange={(e) => handleInput(e)} value={account.name} />
                <TextField disabled id="group" label="Gruppe" size="small" value={account.group_name} />
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                  <Button color="primary" variant="contained" onClick={() => setAccount(INIT_ACCOUNT)}>Ny</Button>
                  <Button color="primary" variant="contained" disabled={!allowSave} onClick={(e) => handleSave(e)} > Lagre </Button>
                </div> 
                </Stack>               
              </Paper>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={(() => handleClose())}>
              Lukk
            </Button>
          </DialogActions>          
        </Dialog>         
      </div>
    )
  }

export default ManageAccounts;
