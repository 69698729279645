

const propertyReducer = (state, action) => {
  //console.log("Reducer", action.type, action);
  switch(action.type) {
    case 'SET_ALL_PROPERTIES':
      return ({...state, propertyList: action.propertyList })
    case 'SET_SELECTED':
      return ({...state, selected: action.selected})
    case 'UPDATE_PROPERTY':
      let newPropList = state.propertyList.filter(p => p.id!=action.property.id);
      newPropList.push(action.property);
      return ({...state, propertyList: newPropList, selected: null})
    case 'NEW_PROPERTY':
      let newList = [...state.propertyList, action.property]
      return({...state, propertyList: newList, selected: null})
    case 'DELETE_PROPERTY':
      let newDelList = state.propertyList.filter(p => p.id!=action.propertyId);
      return ({...state, propertyList: newDelList, selected: null})
    default:
      return state;
  }
}

export default propertyReducer;