import React, {useEffect, useContext} from 'react'
import L from 'leaflet'

import { ConfigContext } from '../../contexts/ConfigContext'
import {getConfig, splitt} from './../../utils/Library'

const STATKART_ATTRIBUTION = '<a href="http://www.kartverket.no/">Kartverket</a>';

const BlockLineView = ({blockLine}) => { 

  const { config } = useContext(ConfigContext);
  

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    const mapURL = getConfig(config, "SNOWCLEARING_MAP_URL")
    //const mapCenter = (splitt(getConfig(config, "SNOWCLEARING_MAP_CENTER")))[0]
    //console.log("mapCenter="+mapCenter, mapCenter[0], mapCenter[1])
    const line = splitt(blockLine.coordinates)
    const mapContainerName = "map"+blockLine.id;

    const map = L.map(mapContainerName, {
      //center: mapCenter,
      zoom: 19,
      zoomControl: false,
      layers: [
        L.tileLayer(mapURL, {
          attribution: STATKART_ATTRIBUTION
        }),
      ],
    });
    var polyline = L.polyline(line, {color: 'red'}).addTo(map);
    map.fitBounds(polyline.getBounds());
  }, [])

  
  ////////////////////
  // Action handlers
  ////////////////////



  ///////////
  // UI part
  ///////////

  return (
      <div key={1001} style={{width:"500px"}}>
        <div id={"map"+blockLine.id} style={{height:"500px", width:"500px"}}></div>
      </div>
  )
}

export default BlockLineView;
