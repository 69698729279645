import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import {getDateFormat} from '../../utils/Library'

import { ConfigContext } from '../../contexts/ConfigContext'
import { MyDataContext } from '../../contexts/MyDataContext'
import { Typography, Paper, TableRow, TableCell, TableContainer, Table, TableHead, TableBody } from '@mui/material'

const VolWork = () => {

  const {config, configDispatch} = useContext(ConfigContext);
  const { myData, myDataDispatch } = useContext(MyDataContext);
  const [hrs, setHrs] = useState([]);
  const [groupSum, setGroupSum] = useState([]);

  /////////////////////
  // Server interaction
  /////////////////////
  const getVolWork = () => {
    //console.log("getData", config.userId)
    axios.get(config.server+'volWork/work/member/'+config.clubId+'/'+config.userId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        setHrs(res.data);
      })
      .catch(err => {
        console.log('Error: ',err)
      })    
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    getVolWork();
  }, [])

  useEffect(() => {
    var result = [];
    hrs.reduce((res, value) => {
      if (!res[value.period_id]) {
        res[value.period_id] = { Id: value.period_id, desc:value.period_desc, sum: 0 };
        result.push(res[value.period_id])
      }
      res[value.period_id].sum += value.hrs;
      return res;
    }, {});

    //console.log(result);
    setGroupSum(result);
  }, [hrs])
  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  /*const list = hrs ? (
    hrs.sort((a,b)=> a.period_id>b.period_id ? 1: -1).sort((a,b) => a.work_date>b.work_date).map(rec => {
      return (
        <Typography>
          {rec.work_date}
          {console.log(rec.work_date)}
        </Typography>
      )
    })
  ) : (null);*/

  const period = groupSum ? (
    groupSum.sort((a,b) => a.period_id>b.period_id?1:-1).map((period, index) => {
      const list = hrs ? (
        hrs.filter(h=> h.period_id==period.Id).sort((a,b) => a.work_date>b.work_date).map(rec => {
          return (
            <TableRow key={rec.id}>
              <TableCell>{getDateFormat(rec.work_date)}</TableCell>
              <TableCell align="right">{rec.hrs}</TableCell>
            </TableRow>
          )
        })
      ) : (null);      
      return (
          <Paper key={index} elevation={5}  style={{display:"flex", flexDirection:"column", margin:"10px", padding:"5px", maxWidth:"150px"}}>
            <Typography variant="subtitle1">{"Samlet " + period.sum + " timer i " + period.desc}</Typography>
            <TableContainer>
              <Table padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell>Dato</TableCell>
                    <TableCell align="right">Timer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>     
      )
    })
  ) : (null);

  return (
      <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
        {period}
      </div>
    )
  }

export default VolWork;
