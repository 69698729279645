import React, { useContext, useEffect, useState } from 'react'
import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';

import {dynamicSortNorm, getConfig, getDateFormat} from '../../utils/Library'
import {getMembers} from '../../utils/Server'
import VolWorkDialog from './volWorkDialog'
import MessageDialog from './messageDialog' 
import EditDialog from './editDialog'
import FeedbackDialog from '../utils/feedbackDialog'

import { Fab, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, Paper, Tooltip } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import SmsIcon from '@mui/icons-material/Sms';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import BuildIcon from '@mui/icons-material/Build';


const MembersAdm = () => {

  const [roles, setRoles] = useState(null);
  
  const INIT_MEMEBER = {
    id: null,
    firstName: '',
    lastName: '',
    companyName: '', 
    address: '',
    zipCode: '',
    city: '',
    mobile: '',
    eMail: '',
    password: '',
    comment: '',
    deleted: '',
    regDate: null,
    memberRoles: roles ? (roles) : null
  };
  const INIT_MESSAGE = {
    all: false,
    receiverId: 0,
    name: '',
    eMail: true, 
    sms: false,
    subject: '',
    content: '',
    htmlContent: ''
  };

  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogeOpen] = useState(false);
  const [message, setMessage] = useState(INIT_MESSAGE);
  const [editState, setEditState] = useState('NEW');
  const [member, setMember] = useState(INIT_MEMEBER);
  const [volWorkOpen, setVolWorkOpen] = useState(false);
  const [volWorkSelectedId, setVolWorkSelectedId] = useState(null);
  const [reload , setReload] = useState(false);
  const [sortField, setSortField] = useState("sortName");

  const { config, configDispatch } = useContext(ConfigContext);
  const { members, memberDispatch } = useContext(MemberContext);
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [feedbackHeader, setFeedbackHeader] = useState('')
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const confirm = useConfirm();


  /////////////////////
  // Server interaction
  /////////////////////

  // Get one club member including role information
  const getMemberAndOpenDialogue = (id) => {
    axios.get(config.server+'members/'+config.clubId+'/'+id, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        axios.get(config.server+'roles/one/'+config.clubId+'/'+id, {
          'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
          })
          .then(rolesRes => {
            const m = res.data;
            m.memberRoles = rolesRes.data;
            setMember(m);
            setDialogueOpen(true);
          })
          .catch(err => {
            console.log('Error: ',err)
          })      
      })
      .catch(err => {
        console.log('Error: ',err)
      })  
  };

  // Get all valid roles for the club
  const getRoles = () => {
    axios.get(config.server+'roles/all/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setRoles(res.data);
      })
      .catch(err => {
        console.log('Error: ',err)
      })  
  }

  // Send password
  const sendPasswordServer = (memberId) => {
    axios.post(config.server+'members/newPassword/'+config.clubId+'/'+memberId, { 
      },{
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setFeedbackHeader('Passord sendt!')
      setFeedbackMessage('Passordet har blitt sendt')
      setFeedbackOpen(true)
      //console.log(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })       
  }


  ////////////////////
  // useEffect area
  // Load initial information (members and roles)
  ////////////////////

  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Adm. medlemmer"});
    getRoles();
    getMembers(config, memberDispatch);
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }
  }, [config.clubId])

  useEffect(() => {
    getMembers(config, memberDispatch);
  }, [reload])

 
  ////////////////////
  // Action handlers
  ////////////////////

  const handleAdd = () => {
    setEditState('ADD');
    setMember(INIT_MEMEBER);
    console.log("Add member")
    setDialogueOpen(true);
  };

  const handleEdit = (id) => {
    setEditState('EDIT');
    setMember(INIT_MEMEBER);
    getMemberAndOpenDialogue(id);
  }

  const handleCancel = () => {
    setDialogueOpen(false);
    setMember(INIT_MEMEBER);
  };

  const selectMember = (e) => {
    handleEdit(e.currentTarget.id);
  }

  const sendPassword = (e) => {
    const memberId = e.currentTarget.id;
    const member = members.find(m => m.id==memberId)
    const name = (member.firstName && member.firstName.length) ? (member.firstName+' '+member.lastName) : member.companyName

    confirm({ 
      title: "Send passord?",
      description: "Bekreft for å sende nytt passord til "+name,
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    }).then(() => { 
      sendPasswordServer(memberId);
    })
    .catch(() => { 
      console.log("Sletting avbrutt")  
    });

  }

  // Button handler - Open message dialogue / Message to one member
  const handleSendToOne = (e) => {
    const mid = e.currentTarget.id;
    const mem = members.find(m => m.id==mid);
    setMessage({...INIT_MESSAGE, all: false, receiverId: mid, name: mem.name});
    setMessageDialogeOpen(true);
  }

  // Button handler - Open message dialogue / Message to all members
  const handleSendToAll = (e) => {
    setMessage({...INIT_MESSAGE, all: true});
    setMessageDialogeOpen(true);
  }

  const volWorkDialog = (e) => {
    //console.log("Open dugnad dialog", e.currentTarget.id)
    setVolWorkSelectedId(e.currentTarget.id);
    setVolWorkOpen(true);
  }


  ///////////
  // UI part
  ///////////

  const newMemberList = (members && members.length) ? (
    members.sort(dynamicSortNorm(sortField)).filter(x => x.deleted!=='x').map(m => {
      // Display name
      const name = (m.firstName && m.firstName.length) ? (
        <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold"}}><span>{m.firstName} {m.lastName}</span></div>
      ) : (
        <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold"}}><span>{m.companyName}</span></div>
      )
      
      // Display cabins
      const cabin = (getConfig(config, "CLUB_CABINS")==1) ? (
        <TableCell style={{fontSize:"12px", maxWidth:"120px"}}>{m.cabin}</TableCell>        
      ): (null);

      // Display boat space
      const boatspace = (getConfig(config, "CLUB_BOATS")==1) ? (
        <TableCell style={{fontSize:"12px"}}>{m.boatspace}</TableCell>        
      ): (null);

      // Display el point
      const elpoint = (getConfig(config, "CLUB_EL_POINTS")==1) ? (
        <TableCell style={{fontSize:"12px"}}>{m.elpoint}</TableCell>        
      ): (null);

      // Display voluntary_work
      const volWork = (getConfig(config, "CLUB_VOLUNTARY_WORK")==1) ? (
        <React.Fragment>
          <TableCell align="center" style={{fontSize:"12px"}}>{m.vol_hrs_curr_per}</TableCell>        
          <TableCell align="center" style={{fontSize:"12px"}}>{m.vol_hrs_prev_per}</TableCell>        
          <TableCell>
            <Tooltip title="Registrer dugnadsinnsats">
              <BuildIcon color="primary" id={m.id} onClick={(e) => volWorkDialog(e)}/>            
            </Tooltip>
          </TableCell>
        </React.Fragment>
      ) : (null);

      return (
        <TableRow key={m.id}>
          <TableCell align="right" style={{fontSize:"12px", paddingRight:"5px"}}>{m.id}</TableCell>
          <TableCell style={{fontSize:"12px", paddingRight:"5px"}}>{name}</TableCell>
          <TableCell style={{fontSize:"12px", paddingRight:"5px"}}>{m.eMail}</TableCell>
          <TableCell style={{fontSize:"12px", paddingRight:"5px"}}>{m.mobile}</TableCell>
          <TableCell align="right" style={{paddingRight: "5px", fontSize:"12px"}} >{m.amount_due?m.amount_due:0} kr </TableCell>
          <TableCell align="center" style={{fontSize:"12px", paddingRight:"5px"}}>{getDateFormat(m.lastLogin)}</TableCell>
          {cabin}
          {boatspace}
          {elpoint}
          <TableCell>
            <Tooltip title="Endre medlemsinformasjon" aria-label="add">
              <EditIcon color="primary" id={m.id} onClick={(e) => selectMember(e)}/>
            </Tooltip>   
          </TableCell>
          <TableCell>
            <Tooltip title="Send nytt passord til medlemmet">
              <VpnKeyIcon color="primary" id={m.id} onClick={(e) => sendPassword(e)}/>
            </Tooltip>
          </TableCell>
          <TableCell>
              <Tooltip title="Send melding" aria-label="add">
                <SmsIcon color="primary" id={m.id} value={m.name} onClick={(e) => handleSendToOne(e)}/>
              </Tooltip>
          </TableCell>
          {volWork}
        </TableRow>
      )
    })
  ):(
    (
      <TableRow key={0}>
      <TableCell>Dessverre - det er ingen medlemmer enda</TableCell>
      </TableRow>
    )
  );

  // Display cabins
  const cabinCol = (getConfig(config, "CLUB_CABINS")==1) ? (
    <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("cabin")}>Eiendom</TableCell>        
  ): (null);

  // Display boat space
  const boatspaceCol = (getConfig(config, "CLUB_BOATS")==1) ? (
    <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("boatspace")}>Båtplass</TableCell>        
  ): (null);

  // Display el point
  const elpointCol = (getConfig(config, "CLUB_EL_POINTS")==1) ? (
    <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("elpoint")}>Elpunkt</TableCell>        
  ): (null);  

  // Display voluntary_work
  const volWorkCols = (getConfig(config, "CLUB_VOLUNTARY_WORK")==1) ? (
    <React.Fragment>
      <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("vol_hrs_curr_per")}>Dugnad i år</TableCell>        
      <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("vol_hrs_prev_per")}>Dugnad i fjor</TableCell>       
      <TableCell></TableCell> 
    </React.Fragment>
  ) : (null);

  return (
    <div> 
      <FeedbackDialog open={feedbackOpen} setOpen={setFeedbackOpen} header={feedbackHeader} message={feedbackMessage} />
      <VolWorkDialog open={volWorkOpen} setOpen={setVolWorkOpen} memberId={volWorkSelectedId} reload={reload} setReload={setReload} />
      <EditDialog open={dialogueOpen} setOpen={setDialogueOpen} member={member} setMember={setMember} INIT_MEMEBER={INIT_MEMEBER} editState={editState} />
      <MessageDialog open={messageDialogOpen} setOpen={setMessageDialogeOpen} message={message} setMessage={setMessage} INIT_MESSAGE={INIT_MESSAGE} />
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <Tooltip title="Legg til et nytt medlem" aria-label="add">
          <Fab color="primary" aria-label="add" >
            <AddIcon onClick={handleAdd}/>
          </Fab>
        </Tooltip>
        <Tooltip title="Send melding til alle medlemmene" aria-label="add">
          <Fab>
            <SmsIcon color="primary" onClick={(e) => handleSendToAll(e)}/>
          </Fab>
        </Tooltip>
      </div>
      <TableContainer component={Paper}>
        <Table style={{minWidth: 400}} padding="none" size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{backgroundColor: "silver"}} >
              <TableCell align="right" style={{fontSize:"12px", paddingRight:"5px"}} onClick={() => setSortField("id")}>Id</TableCell>
              <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("sortName")}>Navn/Selskap</TableCell>
              <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("eMail")}>ePost</TableCell>
              <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("mobile")}>Mobil</TableCell>
              <TableCell align="right" style={{paddingRight: "5px", fontSize:"12px"}} onClick={() => setSortField("-amount_due")}>Utestående</TableCell>
              <TableCell align="center" style={{fontSize:"12px"}} onClick={() => setSortField("-lastLogin")}>Siste login</TableCell>
              {cabinCol}
              {boatspaceCol}
              {elpointCol}
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              {volWorkCols}
            </TableRow>
          </TableHead>
          <TableBody>
            {newMemberList}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default MembersAdm;
