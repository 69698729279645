import React from 'react'
import ConfigContextProvider from './contexts/ConfigContext'
import AppRouter from './AppRouter'
import MemberContextProvider from './contexts/MemberContext'
import AccountContextProvider from './contexts/AccountContext'
import PropertyContextProvider from './contexts/PropertyContext'
import MyDataContextProvider from './contexts/MyDataContext'
import DmsContextProvider from './contexts/DmsContext'
import InvoiceContextProvider from './contexts/InvoiceContext'
import { ConfirmProvider } from 'material-ui-confirm';

const App = () => {

  return (
    <ConfirmProvider>
      <ConfigContextProvider>
        <MemberContextProvider>
          <AccountContextProvider>
            <PropertyContextProvider>
              <MyDataContextProvider>
                <DmsContextProvider>
                  <InvoiceContextProvider>
                    <AppRouter />
                  </InvoiceContextProvider>
                </DmsContextProvider>
              </MyDataContextProvider>
            </PropertyContextProvider>
          </AccountContextProvider>
        </MemberContextProvider>
      </ConfigContextProvider>
    </ConfirmProvider>
  );
}

export default App;
