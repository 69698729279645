import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import {Card, CardContent, TextField, Fab, Dialog, DialogActions, DialogContent, DialogTitle, Button, Tooltip} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'
import { useConfirm } from 'material-ui-confirm';

import { Editor } from '@tinymce/tinymce-react';

import { getDateFormat, dynamicSort } from '../../utils/Library'
import { ConfigContext } from '../../contexts/ConfigContext'


const NewsAdm = () => {
  
  const { config, configDispatch } = useContext(ConfigContext);
  const [news, setNews] = useState([]);
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [heading, setHeading] = useState('');
  const [content, setContent] = useState("");
  const confirm = useConfirm();


  /////////////////////
  // Server interaction
  /////////////////////
  const getClubNews = () => {
    axios.get(config.server+'messages/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json'}
      })
      .then(res => {
        //console.log(res.data);
        setNews(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  };

  const saveMessage = (format) => {
    axios.post(config.server+'messages/member/'+config.clubId, { 
        clubId: config.clubId,
        title: heading, 
        content: content,
        author: config.userId,
        date: getDateFormat(new Date()),
        type: "TEXT"
    },{ 
        'headers': { 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer '+config.accessToken
                },
        })
    .then(res => {
        if(res.status===200) {
          const message = res.data.createdMessage;
          //console.log(message.type);
          setHeading("");
          setContent("");
          setNews(news => [...news, message]);          
        }
    })
    .catch((err) => {
        console.log('Error', err);
    })
  }; 

  const deleteMessage = (id) => {
    //console.log('Delete ', id);
    axios.delete(config.server+'messages/member/'+config.clubId+'/'+id,{
        'headers': { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
          },
      })
      .then(res => {
        //console.log(res.status);
        if(res.status===200) {
            console.log('Message deleted');
            var newNews = news.filter(n => n.id !== id);
            setNews(newNews);
        }
      })
      .catch((err) => {
          console.log('Error', err);
      })        
    };



  ////////////////////
  // useEffect area
  ////////////////////

  // Load news
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Adm. nyheter"});
    if(config.clubId!==0) {
      // Get club news  
      getClubNews();
    }
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null})
    }
  }, []);


  ////////////////////
  // Action handlers
  ////////////////////

  // Handle news dialogue
  const handleClickOpen = () => {
    setContent("");
    setDialogueOpen(true);
  };

  const handleClose = () => {
    setDialogueOpen(false);
  };

  const handleSave = () => {
    setDialogueOpen(false);
    //saveMessage("JSON");
    saveMessage();
  }

  const handleDelete = (id) => {
    confirm({ 
      title: "Er du sikker?",
      description: "Er du sikker på at du vil slette denne nyhetsmeldingen?",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    }).then(() => { 
      deleteMessage(id);
    })
    .catch(() => { 
      console.log("Sletting avbrutt")  
    });    
    
  }

  const handleEditorChange = (content, editor) => {
    setContent(editor.getContent());
}

  ///////////
  // UI part
  ///////////

  const newList  = news.length ? (
    news.sort(dynamicSort("id")).map(message => {
      return (
        <Card className="messageCard" key={message.id} style={{margin: 5, backgroundColor: "gainsboro"}}>
          <CardContent>
            <span className="messageCardHeader" style={{fontWeight: "bold"}}>{message.title}</span>
            <div dangerouslySetInnerHTML={{ __html: message.content }} />            
            <p className="grey-text" style={{fontSize:10 }}>Publisert {message.date}</p>
            <Tooltip title="Slett denne meldingen" aria-label="delete">
              <Fab size="small" color="secondary" aria-label="delete">
                <DeleteIcon onClick={() => handleDelete(message.id)}/>
              </Fab>
            </Tooltip>
          </CardContent>
        </Card>
      )
    })
  ) : (
    <div className="center">
        <p>Ingen nyheter</p>    
    </div>
  );

  const dialogue = (
      <Dialog open={dialogueOpen} maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ny nyhetsmelding / publisering</DialogTitle>
        <DialogContent>
          <TextField
            required 
            margin="dense"
            id="header"
            label="Overskrift"
            onChange={(e) => setHeading(e.target.value)}
            fullWidth
            autoFocus
          /> 
          <div style={{width: "100%"}}>
            <Editor
              initialValue=""
              apiKey="khxs2tmi69i85gb8lkanh1sf24p1k4s9q0szldp7rtss4ync"
              init={{
                height: 250,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image', 
                  'charmap print preview anchor help',
                  'searchreplace visualblocks code',
                  'insertdatetime media table paste wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic | \
                  alignleft aligncenter alignright | \
                  bullist numlist outdent indent | help'
              }}
              onEditorChange={handleEditorChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Lagre
          </Button>
          <Button onClick={handleClose} color="primary">
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
  );

  return (
    <div>
      {dialogue}
      <Tooltip title="Legg til en nyhet / melding som skal publiseres på forsiden" aria-label="add">
        <Fab color="primary" aria-label="add" >
          <AddIcon onClick={handleClickOpen}/>
        </Fab>
      </Tooltip>
      {newList}
    </div>
  );
}

export default NewsAdm;