import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import {getDateFormat} from '../../utils/Library'
import {ConfigContext} from '../../contexts/ConfigContext'
import {MemberContext} from '../../contexts/MemberContext'
import {AccountContext} from '../../contexts/AccountContext'

import moment from "moment";
import "moment/locale/nb";

import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, withMobileDialog } from '@mui/material';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { TextField, Button, Paper, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

moment.locale("nb");


const VolWorkDialog = ({open, setOpen, memberId, reload, setReload}) => {

  //const [open, setOpen] = useState(true);
  const [volWork, setVolWork] = useState([]);
  const [newDate, setNewDate] = useState(null);
  const [newHrs, setNewHrs] = useState('');
  const {config} = useContext(ConfigContext);
  const {members} = useContext(MemberContext);
  const {accounts, accountDispatch} = useContext(AccountContext);

  /////////////////////
  // Server interaction
  /////////////////////
  const getVolWork = () => {
    axios.get(config.server+'volWork/work/member/'+config.clubId+'/'+memberId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setVolWork(res.data);
      })
      .catch(err => {
        console.log('Error: ',err)
      })  
  }

  const saveWork = () => {
    axios.post(config.server+'volWork/work/new/'+config.clubId, { 
      memberId: memberId,
      workDate: getDateFormat(newDate),
      workHrs: newHrs
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      console.log(res.data)
      let newVolWork = [...volWork];
      newVolWork.push({id: res.data.WorkId, member_id: memberId, work_date: newDate, hrs: newHrs});
      setVolWork(newVolWork);
      setNewDate(null);
      setNewHrs('');
    })
    .catch(err => {
        console.log('Error: ',err)
    })          
  }

  const deleteWork = (id) => {
    axios.delete(config.server+'volWork/work/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        let newList = volWork.filter(work => work.id!=id);
        setVolWork(newList);
      })
      .catch(err => {
          console.log('Error: ',err)
      })            
  }

  // Get period overview  
  const getPeriodOverview = () => {
    axios.get(config.server+'accounting/period/overview/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        if(res.data) {
          res.data.map(p => {
            if(p.active==1) {
              accountDispatch({type: 'SET_CURRENT_PERIOD', period: p.id});
            }
          })
        }
        accountDispatch({type: 'SET_ALL_PERIODS', periods: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  ////////////////////
  // useEffect area
  ////////////////////
  /*useEffect(() => {
    getPeriodOverview();
  }, [])*/

  useEffect(() => {
    if(memberId) {
      getVolWork();
    }
  }, [memberId])
  
  ////////////////////
  // Action handlers
  ////////////////////
  const closeDialog = () => {
    setReload(!reload);
    setOpen(false);
  }

  const handleDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setNewDate(selectedDate.toDate())
    } else {
      setNewDate(null)
    }
  }
  
  const handleSave = () => {
    //console.log("Save")
    saveWork();
    closeDialog();
  }

  const handleDelete = (e) => {
    //console.log("Delete ", e.currentTarget.id)
    deleteWork(e.currentTarget.id);
  }

  ///////////
  // UI part
  ///////////

  const volWorkList = volWork ? (
    volWork.sort((a,b) => a.work_date > b.work_date ? 1 : -1).map(work => {
      return (
        <TableRow key={work.id}>
          <TableCell>{getDateFormat(work.work_date)}</TableCell>
          <TableCell>{work.hrs}</TableCell>
          <TableCell>
            <Tooltip title="Slett disse timene" aria-label="delete">
              <DeleteIcon color="primary" fontSize="small" value={work.id} id={work.id} onClick={handleDelete} />
            </Tooltip>
          </TableCell>
        </TableRow>
      )
    })
  ):(null)

  const memberName = members && memberId ? (
    members.filter(m => m.id==memberId).map(member => {
      return member.companyName ? member.companyName : member.firstName+' '+member.lastName;
    })
  ):(" Ukjent");

/*  const content = accounts.allPeriods && accounts.allPeriods.length>0 ? (

  ) : (
    <Typography variant="h6">Dugnadsarbeid er knyttet til regnskapsperioder. Det må være minst en definert regnskapsperiode før det kan registreres dugnadsarbeid</Typography>
  )*/

  return (
      <div>
      <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
          <div>
            <Dialog open={open} onClose={closeDialog} maxWidth="lg" aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Dugnadsarbeid - {memberName}</DialogTitle>
              <DialogContent>
                <Paper style={{margin:"20px", padding:"10px"}}>
                  <TableContainer>
                    <Table padding='none'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Dato</TableCell>
                          <TableCell>Timer</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {volWorkList}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <Paper style={{margin:"20px", padding:"10px"}}>
                  <div style={{display:"flex", flexDirection:"column"}}>
                    <Stack spacing={1}>
                    <DatePicker
                      disableToolbar
                      required
                      variant="inline"
                      renderInput={(params) => <TextField {...params}/>}
                      margin="normal"
                      format="L"
                      id="date"
                      label="Dato"
                      mask="__.__.____"
                      value={newDate=='' || newDate==null ? null : newDate}
                      onChange={handleDate}        
                      autoOk={true}      
                    />
                    <TextField required id="x1" label="Antall timer" value={newHrs} onChange={(e) => setNewHrs(e.target.value)} />
                    </Stack>
                  </div>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={handleSave}>
                  Lagre
                </Button>
                <Button color="primary" onClick={closeDialog}>
                  Avbryt
                </Button>
              </DialogActions>          
            </Dialog>        
          </div>
        </LocalizationProvider>
      </div>
    )
  }

export default VolWorkDialog;
