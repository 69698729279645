import React, {useEffect, useContext, useState} from 'react'

import { ConfigContext } from '../contexts/ConfigContext'
import { Paper, Stack, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

const Board = () => {

  const { config } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // hooks area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////



  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
    <div>
      <Paper style={{maxWidth:"900px", margin: 5, backgroundColor: "gainsboro"}}>
        <div>
            <Stack spacing={4} sx={{ width: '250px' }}>
                <DatePicker 
                    label='Dato'
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </div>
      </Paper>
    </div>
    </LocalizationProvider>
  )
}

export default Board;
