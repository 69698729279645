import React, {useEffect, useContext, useState} from 'react'
import {TextField, FormControlLabel, Switch, Paper, Typography, Button} from '@mui/material'

import { ConfigContext } from '../../contexts/ConfigContext'
import {getConfig, getConfigVal} from './../../utils/Library'

const BasicConfig = ({saveAll}) => {

  const { config, configDispatch } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////

  // Handle switches
  const handleSwitchChange = (e) => {
    //console.log(e.target.name, e.target.checked, config.accessLevel)
    var newValue = e.target.checked ? "1":"0";
    configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: newValue})
  }

  // Handle other config input
  const handleConfigChange = (e) => {
    configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: e.target.value})
  }

  // Handle club name
  const handleClubName = (e) => {
    configDispatch({type: 'SET_CLUB_NAME', clubName: e.target.value})
  }

  // Handle club shortname
  const handleClubShortName = (e) => {
    configDispatch({type: 'SET_CLUB_SHORTNAME', clubShortName: e.target.value})
  }

  // Handle club place
  const handleClubPlace = (e) => {
    configDispatch({type: 'SET_CLUB_PLACE', clubPlace: e.target.value})
  }

  // Handle club org number
  const handleClubOrgno = (e) => {
    configDispatch({type: 'SET_CLUB_ORGNO', clubOrgNo: e.target.value})
  }

  ///////////
  // UI part
  ///////////
  return (
    <div>
      <div style={{display:"flex", flexDirection:"row", flexWrap: "wrap"}}>
        <div style={{display:"flex", flexDirection:"column"}} >
          <Paper elevation={6} style={{margin: "5px", padding: "10px", maxWidth:"400px"}}> 
            <div style={{display:"flex", flexDirection:"column"}} >
              <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Aktivering av funksjoner</Typography>
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_SNOW_CLEARING")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="CLUB_SNOW_CLEARING" />}
                label="Brøyting"
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_DOC_ARCHIVE")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="CLUB_DOC_ARCHIVE" />}
                label="Dokumentarkiv"
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_ACCOUNTING")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="CLUB_ACCOUNTING" />}
                label="Regnskapsmodul" 
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_GATE")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="CLUB_GATE" />}
                label="Veibom" 
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_ADDITIONAL_USERS")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="CLUB_ADDITIONAL_USERS" />}
                label="Ekstra brukere" 
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_WAITING_LIST")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="CLUB_WAITING_LIST" />}
                label="Venteliste for medlemskap" 
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_VOLUNTARY_WORK")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="CLUB_VOLUNTARY_WORK" />}
                label="Dugnadsarbeid" 
              />

              <br/>
              <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Aktivering av funksjoner(leverandør)</Typography>
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_SMS_SERVICE")==1?true:false} disabled={parseInt(config.accessLevel)===999 ? false:true} onChange={handleSwitchChange} name="CLUB_SMS_SERVICE" />}
                label="SMS tjeneste" 
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_CABINS")==1?true:false} disabled={parseInt(config.accessLevel)===999 ? false:true} onChange={handleSwitchChange} name="CLUB_CABINS" />}
                label="Eiendomsregister" 
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_BOATS")==1?true:false} disabled={parseInt(config.accessLevel)===999 ? false:true} onChange={handleSwitchChange} name="CLUB_BOATS" />}
                label="Båtplassregister" 
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_EL_POINTS")==1?true:false} disabled={parseInt(config.accessLevel)===999 ? false:true} onChange={handleSwitchChange} name="CLUB_EL_POINTS" />}
                label="El. punkter" 
              />
              <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "CLUB_FRONTPAGE_IMAGE")==1?true:false} disabled={parseInt(config.accessLevel)===999 ? false:true} onChange={handleSwitchChange} name="CLUB_FRONTPAGE_IMAGE" />}
                label="Hjemmesidetilpassning" 
              />                          
            </div>
          </Paper>
        </div>        
        <div style={{display:"flex", flexDirection:"column"}}>
          <Paper elevation={6} style={{margin: "5px", padding: "10px", maxWidth:"300px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Basisinformasjon</Typography>
            <TextField
                  key={1} required margin="dense" id="name" label="Navn på foreningen"
                  fullWidth value={config.clubName} autoFocus
                  onChange={handleClubName}
                />           
            <TextField
                  key={2} required margin="dense" id="shortName" label="Kortnavn på foreningen"
                  fullWidth value={config.clubShortName}          
                  onChange={handleClubShortName}
                />           
            <TextField
                  key={3} required margin="dense" id="place" label="Stedet der foreningen holder til"
                  fullWidth value={config.clubPlace}          
                  onChange={handleClubPlace}
                />           
            <TextField
                  key={4} required margin="dense" id="orgno" label="Foreningens organisasjonsnummer"
                  fullWidth value={config.clubOrgNo}          
                  onChange={handleClubOrgno}
                /> 
          </Paper>
        </div>
        <div style={{display:"flex", flexDirection:"column"}}>
          <Paper elevation={6} style={{margin: "5px", padding: "10px", maxWidth:"300px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Adresser</Typography>
              <TextField
                key={7} required margin="dense" id="padr" label="Postadresse/Gate" name="POSTAL_ADDRESS"
                fullWidth value={getConfigVal(config, "POSTAL_ADDRESS")}          
                onChange={handleConfigChange}      
              />           
              <TextField
                key={8} required margin="dense" id="pzip" label="Postnummer" name="POSTAL_ZIP_CODE"
                fullWidth value={getConfigVal(config, "POSTAL_ZIP_CODE")}          
                onChange={handleConfigChange}      
              />           
              <TextField
                key={9} required margin="dense" id="pplace" label="Poststed" name="POSTAL_CITY"
                fullWidth value={getConfigVal(config, "POSTAL_CITY")}          
                onChange={handleConfigChange}      
              />           
          </Paper>
        </div>
        <div style={{display:"flex", flexDirection:"column"}}>
          <Paper elevation={6} style={{margin: "5px", padding: "10px", maxWidth:"300px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Teknisk konfig (leverandør)</Typography>
            <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "MAIL_ADDRESS_BOARD_FWD")==1?true:false} disabled={parseInt(config.accessLevel)===999 ? false:true} onChange={handleSwitchChange} name="MAIL_ADDRESS_BOARD_FWD" />}
                label="Videresend ePost til styret" 
            />
            <TextField
                key={5} margin="dense" id="bemail" label="ePost adresse til styret" name="MAIL_ADDRESS_BOARD"
                disabled={parseInt(config.accessLevel)===999 ? false:true}
                fullWidth value={getConfigVal(config, "MAIL_ADDRESS_BOARD")}    
                onChange={handleConfigChange}      
            />     
            <FormControlLabel
                control={<Switch color="primary" checked={getConfig(config, "MAIL_ADDRESS_ACCOUNTANT_FWD")==1?true:false} disabled={parseInt(config.accessLevel)===999 ? false:true} onChange={handleSwitchChange} name="MAIL_ADDRESS_ACCOUNTANT_FWD" />}
                label="Videresend ePost til kasserer" 
            />
            <TextField
                key={6} margin="dense" id="actemail" label="ePost adresse til kasserer" name="MAIL_ADDRESS_ACCOUNTANT"
                disabled={parseInt(config.accessLevel)===999 ? false:true}
                fullWidth value={getConfigVal(config, "MAIL_ADDRESS_ACCOUNTANT")}          
                onChange={handleConfigChange}      
            />           
            <TextField
                key={10} required margin="dense" id="url" label="Nettside/URL" name="CLUB_URL"
                disabled={parseInt(config.accessLevel)===999 ? false:true}
                fullWidth value={getConfigVal(config, "CLUB_URL")}          
                onChange={handleConfigChange}      
              />           
              <TextField
                key={26} required margin="dense" id="eMailSender" label="ePost avsender" name="CLUB_EMAIL_SENDER"
                disabled={parseInt(config.accessLevel)===999 ? false:true}
                fullWidth value={getConfigVal(config, "CLUB_EMAIL_SENDER")}          
                onChange={handleConfigChange}      
              />           
              <TextField
                key={11} required margin="dense" id="murl" label="ePost domene (teknisk)" name="MAIL_URL"
                disabled={parseInt(config.accessLevel)===999 ? false:true}
                fullWidth value={getConfigVal(config, "MAIL_URL")}          
                onChange={handleConfigChange}      
              />     
              <TextField
                key={15} required margin="dense" id="system" label="System" name="SYSTEM"
                disabled={parseInt(config.accessLevel)===999 ? false:true}
                fullWidth value={getConfigVal(config, "SYSTEM")}          
                onChange={handleConfigChange}      
              />           
              <TextField
                key={16} required margin="dense" id="syslink" label="System link" name="SYSTEM_LINK"
                disabled={parseInt(config.accessLevel)===999 ? false:true}
                fullWidth value={getConfigVal(config, "SYSTEM_LINK")}          
                onChange={handleConfigChange}      
              />           
          </Paper>          
        </div>   
      </div>
      <Button color="primary" onClick={(e) => saveAll(e)}>
        Lagre
      </Button>                  
    </div>

    )
  }

export default BasicConfig;
