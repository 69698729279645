import React, {useState, useContext} from 'react'
import axios from 'axios'
import {Dialog, DialogTitle, DialogContent} from '@mui/material'
import {AppBar, Tabs, Tab, TabPanel} from '@mui/material'


import EmailPanel from './emailPanel'
import SmsPanel from './smsPanel'

import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'

const MessageDialog = ({open, setOpen, message, setMessage, INIT_MESSAGE}) => {

  const [activeTab, setActivteTab] = useState(0);

  const [selectedTab, setSlectedTab] = useState(0);

  const { config, configDispatch } = useContext(ConfigContext);
  const { members, memberDispatch } = useContext(MemberContext);  

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////


  ////////////////////
  // Action handlers
  ////////////////////

  const handleTabSwitch = (e, newValue) => {
    //console.log(newValue)
    setSlectedTab(newValue);
  }  

  const handleClose = () => {
    setOpen(false)
  }


  ///////////
  // UI part
  ///////////

  const messageDialogueTitle = message.all ? "Send melding til alle medlemmene" : "Send melding til "+message.name 

  const smsConfig = config.clubConfig.find(c => c.parameter=='CLUB_SMS_SERVICE');
  const smsTab = (smsConfig && smsConfig.value=='1') ? (
    <Tab label="SMS melding" onClick={(e) => setActivteTab(1)} />
  ):(
    null
  )
  const smsPanel = (smsConfig && smsConfig.value=='1') ? (
    <div id={1} hidden={(selectedTab==1) ? false : true} >
      <SmsPanel value={selectedTab} index={1} hidden={(selectedTab==1) ? false : true} open={open} setOpen={setOpen} message={message} setMessage={setMessage} INIT_MESSAGE={INIT_MESSAGE} />
    </div>
  ) : (
    null
  )
  
  
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="form-dialog-title">{messageDialogueTitle}</DialogTitle>
      <DialogContent>
        <AppBar position="static">
          <Tabs value={activeTab} onChange={handleTabSwitch} aria-label="simple tabs example">
            <Tab label="ePost melding" onClick={(e) => setActivteTab(0)} />
            {smsTab}
          </Tabs>
        </AppBar>
        <div id={0} hidden={(selectedTab==0) ? false : true} >
          <EmailPanel value={selectedTab} index={0} hidden={(selectedTab==0) ? false : true} open={open} setOpen={setOpen} message={message} setMessage={setMessage} INIT_MESSAGE={INIT_MESSAGE} />
        </div>
        {smsPanel}
      </DialogContent>
    </Dialog>
  )
}

export default MessageDialog;