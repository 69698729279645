import React, {useState, useContext, useEffect} from 'react'

import Map from '../properties/Map'
import NewMap from '../properties/NewMap'

import MySnowClearingOrders from './mySnowClearingOrders'
import { getConfig, splitt } from '../../utils/Library'

import { MyDataContext } from '../../contexts/MyDataContext'
import { ConfigContext } from '../../contexts/ConfigContext'

import { Paper, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TextField } from '@mui/material'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Stack } from '@mui/system'

const MyProperty = ({sc, propertyId, hidden}) => {

  const { config } = useContext(ConfigContext);
  const { myData, myDataDispatch } = useContext(MyDataContext);
  const [currentProperty, setCurrentProperty] = useState(null);


  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // Hooks area
  ////////////////////
  useEffect(() => {
    if(myData.properties) {
      myData.properties.map(property => {
        if(property.id == propertyId) {
          setCurrentProperty(property)
        }
      })
    }
  }, [myData.properties]);
  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////
  //console.log(currentProperty);

  /*
 
      
      <NewMap  markerLat={currentProperty.latitude} 
            markerLon={currentProperty.longitude} 
            scSetting={currentProperty.snowClearing} 
            scPerm={currentProperty.polyPerm} 
            scExtra={currentProperty.polyExtra} 
            height={300}
            propertyId={currentProperty.id}
            mapURL={getConfig(config, "SNOWCLEARING_MAP_URL")}
            defaultMapCenter={splitt(getConfig(config, "SNOWCLEARING_MAP_CENTER"))?(splitt(getConfig(config, "SNOWCLEARING_MAP_CENTER")))[0]:null}
            defaultZoom={parseInt(getConfig(config, "SNOWCLEARING_MAP_ZOOM"))}
      />      
  */


  const displayMap = (currentProperty) ? (
    <div style={{width: "300px", margin: "10px"}}>
     <Map  markerLat={currentProperty.latitude} 
            markerLon={currentProperty.longitude} 
            scSetting={currentProperty.snowClearing} 
            scPerm={currentProperty.polyPerm} 
            scExtra={currentProperty.polyExtra} 
            height={320}
            propertyId={currentProperty.id}
            mapURL={getConfig(config, "SNOWCLEARING_MAP_URL")}
            defaultMapCenter={splitt(getConfig(config, "SNOWCLEARING_MAP_CENTER"))?(splitt(getConfig(config, "SNOWCLEARING_MAP_CENTER")))[0]:null}
            defaultZoom={parseInt(getConfig(config, "SNOWCLEARING_MAP_ZOOM"))}
            centerMap={true}    
            updateable={true}   
      />  
    </div>
  ): (null);

  const displayInformation = (currentProperty) ? (
    <div style={{margin: "10px"}}>
      <Stack spacing={1}>
      <div style={{display:"flex", flexDirection: "row"}} >
        <FormControlLabel label="Bedriftseid" control={ <Switch checked={currentProperty.corporateOwner==1 ? true:false} name="corp" color="primary" /> }  />
        <FormControlLabel label="Ubebygd" control={ <Switch checked={currentProperty.undeveloped==1 ? true:false} name="undeveloped" color="primary" /> }  />
      </div>
      <TextField id="cabAddress" label="Tomt/Adresse" value={currentProperty.address ? currentProperty.address : ''}  />
      <TextField  id="cabStreet" label="Gateadr" value={currentProperty.street ? currentProperty.street : ''}  />
      <div style={{display:"flex", flexDirection: "row"}}>
        <TextField  id="grn" label="Gnr" value={currentProperty.gnr ? currentProperty.gnr : ''} />
        <TextField  id="bnr" label="Bnr" value={currentProperty.bnr ? currentProperty.bnr : ''} />
      </div>
      <div style={{display:"flex", flexDirection: "row"}}>
        <TextField required id="lat" label="Pos.lat" value={currentProperty.latitude ? currentProperty.latitude : ''} />
        <TextField required id="lon" label="Pos.lon" value={currentProperty.longitude ? currentProperty.longitude : ''} />
      </div>
      <div style={{display:"flex", flexDirection: "row"}} >
        <FormControlLabel label="Ekstra brøyting" control={ <Switch checked={currentProperty.snowClearing==1 ? true:false} name="sc" color="primary" /> }  />
      </div>
      <TextField id="extraParking" label="# Ekstra park." value={currentProperty.extraParking} />
      </Stack>
    </div>
  ) : (
    null
  );

  const snowClearingDiv = sc ? <MySnowClearingOrders propertyId={propertyId} /> : null;

  return (
      <div>
        <Paper style={{display:"flex", flexDirection: "column"}}>
          <div style={{display:"flex", flexDirection: "row", flexWrap:"wrap"}}>
            {displayMap}    
            {displayInformation}
          </div>
          <div>
            {snowClearingDiv}
          </div>
        </Paper>
      </div>
    )
  }

export default MyProperty;
