import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import { ConfigContext } from '../../contexts/ConfigContext'

import {getDateFormat} from '../../utils/Library'

import {Card, CardActions, CardHeader, CardContent, Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const MyLeases = () => {

  const { config, configDispatch } = useContext(ConfigContext);
  const [boatLeases, setBoatLeases] = useState([]);
  const [elPointLeases, setElPointLeases] = useState([]);

  /////////////////////
  // Server interaction
  /////////////////////
  const getBoatLeases = () => {
    axios.get(config.server+'boatspaces/lease/member/'+config.clubId+'/'+config.userId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setBoatLeases(res.data);
      })
      .catch(err => {
        console.log('Error: ',err)
      })    
  }

  const getElPointLeases = () => {
    axios.get(config.server+'elpoints/lease/member/'+config.clubId+'/'+config.userId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setElPointLeases(res.data);
      })
      .catch(err => {
        console.log('Error: ',err)
      })    
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    getBoatLeases();
    getElPointLeases();
  }, []);
  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  const boatLeaseCards = boatLeases ? (
    boatLeases.filter(l=> l.completed==0).map(lease => {
      const btn = lease.attachment ? <Button color="primary" href={config.server.substring(0, config.server.length - 1)+lease.attachment} target="_blank">Vedlegg</Button> : null
      //console.log(lease);
      return (
        <div  key={lease.id} style={{margin: "20px"}}>
          <Card>
            <CardHeader 
                title={lease.boat_space_name} 
                avatar= { <Avatar style={{width: 25, height: 25}} alt="Båtplass" src="../../images/163236.jpeg" /> }
                />
            <CardContent style={{display:"flex", flexDirection: "column"}}>
              <Typography variant="body1" >Utrigger {lease.boat_space_size}</Typography>
              <Typography variant="body1" >Leieavtale inngått fra {getDateFormat(lease.valid_from)}</Typography>
              <Typography variant="body1" >Depositum {lease.deposit}</Typography>
              {btn}
            </CardContent>
          </Card>
        </div>
      )
    })
  ) : (
    null
  );

  const elPointLeaseCards = elPointLeases ? (
    elPointLeases.filter(l=> l.completed==0).map(lease => {
      const btn = lease.attachment ? <Button color="primary" href={config.server.substring(0, config.server.length - 1)+lease.attachment} target="_blank">Vedlegg</Button> : null
      const lastRead = lease.reading_date ? (<Typography variant="body1">Siste avlesing {getDateFormat(lease.reading_date)} var {lease.latest_reading}</Typography>) : (null);
      //console.log(lease);
      return (
        <div key={lease.id} style={{margin: "20px"}}>
          <Card>
            <CardHeader 
                title={lease.el_point_name} 
                avatar= { <Avatar style={{width: 25, height: 25}} alt="ElPoint" src="../../images/163236.jpeg" /> }
                />
            <CardContent style={{display:"flex", flexDirection: "column"}}>
              <Typography variant="body1">Leieavtale inngått fra {getDateFormat(lease.valid_from)}</Typography>
              <Typography variant="body1">Forventet årlig forbruk (kWh) {lease.planned_consumption}</Typography>
              <Typography variant="body1">Måler startet på {lease.meter_start}</Typography>
              {lastRead}
              {btn}
            </CardContent>
          </Card>
        </div>
      )
    })
  ) : (
    null
  );
  

  return (
      <div style={{display: "flex"}}>
        {boatLeaseCards}
        {elPointLeaseCards}
      </div>
    )
  }

export default MyLeases;
