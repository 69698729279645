import React from 'react';
import L from 'leaflet';

import { getConfig, splitt, multiSplitt } from '../../utils/Library'

const ATTRIBUTION = '<a href="http://www.kartverket.no/">Kartverket</a>';

class Map extends React.Component {

  state = {
    layerGroup: null,
    map: null,
    zoom: this.props.defaultZoom
  }

  componentDidMount() {
    const mapContainerName = "map"+this.props.propertyId;

    // create map
    //let centerMap = this.props.centerMap;
    //console.log(this.props.centerMap)
    let zoom = this.props.markerLat ? 18 : this.props.defaultZoom;
    let latitude = this.props.markerLat ? this.props.markerLat : this.props.defaultMapCenter[0];
    let longitude =  this.props.markerLon ? this.props.markerLon : this.props.defaultMapCenter[1];
    console.log("Default center and zoom ",latitude, longitude, zoom)

    this.map = L.map(mapContainerName, {
      center: [latitude, longitude],
      zoom: zoom,
      zoomControl: false,
      layers: [
        L.tileLayer(this.props.mapURL, {
          attribution: ATTRIBUTION,
          maxZoom: 20
        }),
      ],
    });

    // Add marker and save layer and map to state
    this.setState((state, props) => {
      //console.log("Add marker");
      let newLayerGroup = L.layerGroup().addTo(this.map);
      //let newMarker = L.marker([latitude, longitude]).addTo(this.map);
      L.marker([latitude, longitude]).addTo(newLayerGroup);
      return {
        ...state,
        layerGroup: newLayerGroup
      }
    });

    if(this.props.activePoly) {
      this.map.on('dblclick', (e) => {
        //console.log(e.latlng)
        if(this.props.activePoly=='PolyExtra') {
          var newPoly = (this.props.localProp.polyExtra) ? (
            (this.props.localProp.polyExtra.charAt(this.props.localProp.polyExtra.length-1)=="&") ? (
              this.props.localProp.polyExtra+e.latlng.lat+","+e.latlng.lng
            ) : (
              this.props.localProp.polyExtra+"#"+e.latlng.lat+","+e.latlng.lng
            )
          ) : (
            this.props.localProp.polyExtra+e.latlng.lat+","+e.latlng.lng
          );
          this.props.setLocalProp({...this.props.localProp, polyExtra: newPoly})
        } else if (this.props.activePoly=='PolyPerm') {
          var newPoly = (this.props.localProp.polyPerm) ? (
            (this.props.localProp.polyPerm.charAt(this.props.localProp.polyPerm.length-1)=="&") ? (
              this.props.localProp.polyPerm+e.latlng.lat+","+e.latlng.lng
            ):(
              this.props.localProp.polyPerm+"#"+e.latlng.lat+","+e.latlng.lng
            )
          ) : (
            this.props.localProp.polyPerm+e.latlng.lat+","+e.latlng.lng
          );
          this.props.setLocalProp({...this.props.localProp, polyPerm: newPoly})
        }
      });
    } else {
      //console.log("Skip dblclick listener")
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.centerMap) {
      this.map.invalidateSize(true);
    }
    //this.map.setView([this.props.markerLat, this.props.markerLon]);  
  }  

  isValid(arr) {
    //console.log(arr);
    let result = true;
    if (arr) {
      arr.map(e => {
        //console.log(e);
        if((!e[0] || !e[1])) {
          result = false;
        }
      })
      return result;
    } else {
      return false
    }
  }
  

  render() { 

    const mapContainerName = "map"+this.props.propertyId;
    let zoom = this.props.markerLat && this.props.markerLon ? 18 : this.props.defaultZoom;
    let latitude = this.props.markerLat ? this.props.markerLat : this.props.defaultMapCenter[0];
    let longitude =  this.props.markerLon ? this.props.markerLon : this.props.defaultMapCenter[1];
    //console.log(this.props.centerMap)
    if(this.map && this.props.centerMap) {
      this.map.setView([latitude, longitude]);  
    }

    // Clear marker layer
    if(this.state.layerGroup) {
      this.state.layerGroup.clearLayers();
    }

    // Set markers and zoom
    if(this.map) {
      //console.log(latitude, longitude, zoom, this.props.defaultMapCenter[0], this.props.defaultMapCenter[1])

      // Set cabin marker
      if(this.props.markerLat && this.props.markerLon) {
        L.marker([this.props.markerLat, this.props.markerLon]).addTo(this.state.layerGroup);
      }

      // Zoom to marker
      //console.log(this.map.getZoom())
      if(this.props.centerMap) {
        if(this.map.getZoom()==this.props.defaultZoom) {
          this.map.setView([latitude, longitude], zoom);   
          //console.log("Zooooom")
        } else {
          this.map.setView([latitude, longitude]);   
          //console.log("No zoom")
        }  
      }
    }


    // Add polygon layer for snow clearing area (permanent parking) if set and applicable
    let permlatlngs = multiSplitt(this.props.scPerm);
    //console.log("Permanen p ", permlatlngs, this.isValid(permlatlngs));
    if(this.isValid(permlatlngs) && this.state.layerGroup) {
      let polygon = L.polygon(permlatlngs, {color: 'blue'}).addTo(this.state.layerGroup);
    } 

    // Add polygon layer for snow clearing area (extra parking) if set and applicable
    let extraLatlngs = multiSplitt(this.props.scExtra);
    //console.log("Extra ", extraLatlngs, this.isValid(extraLatlngs));
    if(this.isValid(extraLatlngs) && this.state.layerGroup) {
      let polygon = L.polygon(extraLatlngs, {color: 'red'}).addTo(this.state.layerGroup);
    } 

    return (
      <div>
        <div style={{height: this.props.height, cursor:"crosshair"}} id={mapContainerName}></div>
      </div>
    )
  }
}

export default Map;