import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import { Paper, Typography, TextField, Tooltip, Button, Switch, FormControlLabel, Dialog, DialogContent, DialogTitle, DialogActions, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import Map from './Map'
//import CabinMap from './CabinMap'
import { getConfig, splitt, multiSplitt } from '../../utils/Library'
import { ConfigContext } from '../../contexts/ConfigContext'
import { PropertyContext } from '../../contexts/PropertyContext'
import { MemberContext } from './../../contexts/MemberContext'

const Property = ({ open, setOpen }) => {

  const INIT_PROP = {
    id: null,
    address: '',
    street: '',
    gnr: '',
    bnr: '',
    ownerId: 0,
    corporateOwner: 0,
    undeveloped: 0,
    snowClearing: 0,
    fixedParking: 0,
    extraParking: 0,
    latitude: '',
    longitude: '',
    polyPerm: "",
    polyExtra: "",
    scArea: 0
  }  
  const { properties, propertyDispatch } = useContext(PropertyContext);
  const { members } = useContext(MemberContext);
  const { config } = useContext(ConfigContext);
  const [ localProp, setLocalProp ] = useState(INIT_PROP);
  const [ activePoly, setActivePoly ] = useState("PolyPerm");
  const [ centerMap, setCenterMap ] = useState(true);


  /////////////////////
  // Server interaction
  /////////////////////

  // Save new property
  const saveNewProperty = (property) => {
    axios.post(config.server+'properties/new/one/'+config.clubId, { 
      property: property
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      let newId = res.data.newId
      property.id = newId;
      //console.log(newId)
      setCenterMap(true)
      propertyDispatch({type: 'NEW_PROPERTY', property: property, newId: newId});
      setLocalProp(INIT_PROP);
    })
    .catch(err => {
        console.log('Error: ',err)
    })          
  }

  // Save changes to one property
  const saveUpdatedProperty = (property) => {
    //console.log(property)
    axios.patch(config.server+'properties/change/one/'+config.clubId+'/'+property.id, {
      property: property
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setCenterMap(true)
      propertyDispatch({type: 'UPDATE_PROPERTY', property: property});
      setLocalProp(INIT_PROP);      
    })
    .catch(err => {
        console.log('Error: ',err)
    })              
  }  


  ////////////////////
  // useEffect area
  ////////////////////

  useEffect(() => {
    const property = properties.propertyList.find(p => p.id == properties.selected);
    //console.log(property);
    //console.log(localProp);
    if(property) {
      setLocalProp(property);
    } else {
      setLocalProp(INIT_PROP);
    }
  }, [properties.selected])

  /*useEffect(() => {
    console.log(localProp, activePoly)
  }, [localProp, activePoly])*/

  /*useEffect(() => {
    console.log(localProp.polyPerm)
  }, [localProp])*/
  
  ////////////////////
  // Action handlers
  ////////////////////

  // Save changes
  const handleSave = () => {
    //console.log("Save...", localProp)
    if(localProp.id) {
      saveUpdatedProperty(localProp);
    } else {
      saveNewProperty(localProp)
    }
    setOpen(false);
  }

  // Close without saving
  const closeDialogue = () => {
    setOpen(false);
    setLocalProp(INIT_PROP);
    propertyDispatch({type: 'SET_SELECTED', selected: null});
  }

  // Switches
  const handleSwitch = (id, e) => {
    //console.log(e.target.checked );
    let newValue = e.target.checked ? 1:0;
    if(id=='corp') {
      setLocalProp({...localProp, corporateOwner:newValue})    
    }
    if(id=='undeveloped') {
      setLocalProp({...localProp, undeveloped:newValue})    
    }
    if(id=='sc') {
      setLocalProp({...localProp, snowClearing:newValue})    
    }
  }

  // Parking polygon input filter
  const handlePolyInput = (e, polyType) => {
    console.log(polyType, e.target.value)
    let str = e.target.value;
    let str1 = str.replace(/[\/\\+()$~%'":*?<>{}æøå]/gi, '');
    //console.log(str1);
    let str2 = str1.replace(/[a-z]/gi, '');
    //console.log(str, str1, str2)
    if(polyType=='polyPerm') {
      setLocalProp({...localProp, polyPerm:str2})
    }
    if(polyType=='polyExtra') {
      setLocalProp({...localProp, polyExtra:str2})
    }
    //setActivePoly(null);
  }

  const handleClick = (name) => {
    //console.log(name)
    setActivePoly(name)
    setCenterMap(false)
  }

  const handleSetOwner = (e) => {
    let oid = e.target.value;
    if(oid && oid!=0) {
      let member = members.find(m => m.id==oid);
      setLocalProp({...localProp, ownerId:oid, companyName: member.companyName, firstname: member.firstName, lastname: member.lastName});  
    } else {
      setLocalProp({...localProp, ownerId:0, companyName: "", firstname: "", lastname: ""});
    }
  }


  ///////////
  // UI part
  ///////////

  const scPanel = (localProp.snowClearing==1 && getConfig(config, "CLUB_SNOW_CLEARING")==1 && getConfig(config, "SNOWCLEARING_PARKING_SPACE_UNITS")==1) ? (
    <React.Fragment>
      <div style={{display:"flex", flexDirection: "column"}}>
        <TextField id="extraParking" sx={{marginTop:"10px"}} label="Antall ekstra parkeringsplasser" value={localProp.extraParking} onChange={(e) => setLocalProp({...localProp, extraParking:e.target.value})} />
        <Tooltip title="Samme funksjon som for faste parkeringsplasser. Klikk i dette feltet først, så fylles koordnatene automatisk ut i dettet feltet.">
          <TextField 
              id="polyExtra" 
              multiline 
              rowsMax={3} 
              label="Koord. ekstra parkering" 
              value={localProp.polyExtra ? localProp.polyExtra : ''} 
              onChange={(e) => handlePolyInput(e, 'polyExtra')} 
              onClick={(e) => handleClick('PolyExtra')}
              sx={{marginTop:"10px"}}
          />
        </Tooltip>
      </div>
    </React.Fragment>
  ) : (
    null
  );

  const filteredMembers = [{id:0,companyName:"Ingen", sortName:"AAAAA"}, ...members.filter(m => m.deleted!="x")];
  //console.log(filteredMembers)
  var memberList = filteredMembers ? (
    filteredMembers.sort((a,b) => a.sortName>b.sortName?1:-1).map(member => {
      //console.log(member)
      return (
        <MenuItem key={member.id} value={member.id}>{member.companyName ? member.companyName : (member.firstName ? member.firstName + ' ' + member.lastName : "")}</MenuItem>
      )
    })
  ) : (<MenuItem key={0} value={0}>Ingen eier</MenuItem>)

  const fixedParking = getConfig(config, "SNOWCLEARING_PARKING_SPACE_UNITS")==1 ? 
      <TextField id="fixedParking" label="Antall faste p-plasser" sx={{width:"100px", marginTop:"10px", marginLeft:"10px"}} value={localProp.fixedParking} onChange={(e) => setLocalProp({...localProp, fixedParking:e.target.value})} />
    : null;

  const areaParking = getConfig(config, "SNOWCLEARING_PARKING_SPACE_AREA")==1 ? 
      <TextField id="parkingArea" label="Brøyteareal" sx={{width:"100px", marginTop:"10px", marginLeft:"10px"}} value={localProp.scArea} onChange={(e) => setLocalProp({...localProp, scArea:e.target.value})} />
    : null;    

  const extraParking = getConfig(config, "SNOWCLEARING_PARKING_SPACE_UNITS")==1 ? 
      <div style={{width: 700, display:"flex", flexDirection: "row"}} >
        <FormControlLabel label="Ekstra brøyting" control={ <Switch checked={localProp.snowClearing==1 ? true:false} onChange={(e) => handleSwitch('sc', e)} name="sc" color="primary" /> }  />
      </div>  
    : null;

  const propertyContent =  localProp ? (
    <Paper>
      <Typography>{localProp.address}</Typography>
      <div>
        <div>
          <Map  markerLat={localProp.latitude} 
                markerLon={localProp.longitude} 
                scSetting={localProp.snowClearing} 
                scPerm={localProp.polyPerm} 
                scExtra={localProp.polyExtra} 
                height={320} //200
                propertyId={localProp.id}
                activePoly={activePoly}
                setActivePoly={setActivePoly} 
                localProp={localProp}
                setLocalProp={setLocalProp}
                hidden={false}
                mapURL={getConfig(config, "SNOWCLEARING_MAP_URL")}
                defaultMapCenter={splitt(getConfig(config, "SNOWCLEARING_MAP_CENTER")) ? (splitt(getConfig(config, "SNOWCLEARING_MAP_CENTER")))[0]:null}
                defaultZoom={parseInt(getConfig(config, "SNOWCLEARING_MAP_ZOOM")) ? parseInt(getConfig(config, "SNOWCLEARING_MAP_ZOOM")) : null}         
                centerMap={centerMap}    
                updateable={true}   
          />

        </div>
        <div>
          <div style={{width: 800, display:"flex", flexDirection: "row"}} >
            <FormControlLabel label="Bedriftseiet" control={ <Switch checked={localProp.corporateOwner==1 ? true:false} onChange={(e) => handleSwitch('corp', e)} name="corp" color="primary" /> }  />
            <FormControlLabel label="Ubebygd" control={ <Switch checked={localProp.undeveloped==1 ? true:false} onChange={(e) => handleSwitch('undeveloped', e)} name="undeveloped" color="primary" /> }  />
          </div>

          <div style={{width: 800, display:"flex", flexDirection: "row"}} >
            <TextField required id="address" label="Tomt/Adresse" sx={{width:"200px", marginTop:"10px"}} value={localProp.address ? localProp.address : ''} onChange={(e) => setLocalProp({...localProp, address:e.target.value})} />
            <TextField  id="street" label="Gateadr" sx={{width:"200px", marginTop:"10px", marginLeft:"10px"}} value={localProp.street ? localProp.street : ''} onChange={(e) => setLocalProp({...localProp, street:e.target.value})} />

            <TextField
                id="outlined-select-currency-native"
                sx={{width:"200px", marginTop:"10px", marginLeft:"10px"}}
                select
                label="Eier"
                value={localProp.ownerId}
                onChange={(e) => handleSetOwner(e)}
              >
                {memberList}
              </TextField>            
              {fixedParking}
              {areaParking}
          </div>

          <div style={{display:"flex", flexDirection: "row"}}>
            <TextField  id="grn" label="Gnr" sx={{width:"80px", marginTop:"10px"}} value={localProp.gnr ? localProp.gnr : ''} onChange={(e) => setLocalProp({...localProp, gnr:e.target.value})} />
            <TextField  id="bnr" label="Bnr" sx={{width:"80px", marginTop:"10px", marginLeft:"10px"}} value={localProp.bnr ? localProp.bnr : ''} onChange={(e) => setLocalProp({...localProp, bnr:e.target.value})} />
            <TextField required id="lat" label="Pos.lat" sx={{width:"200px", marginTop:"10px", marginLeft:"10px"}} value={localProp.latitude ? localProp.latitude : ''} onChange={(e) => setLocalProp({...localProp, latitude:e.target.value})} />
            <TextField required id="lon" label="Pos.lon" sx={{width:"200px", marginTop:"10px", marginLeft:"10px"}} value={localProp.longitude ? localProp.longitude : ''} onChange={(e) => setLocalProp({...localProp, longitude:e.target.value})} />
          </div>

          <div sx={{display:"flex", flexDirection: "row"}}>
            <Tooltip title="Koordinater som indikerer hjørnene på brøyteområdet for det faste parkeringsarealet angitt som breddegrad, lengdegrad i formatet 59.567, 7.345. Skilletegn mellom punktkoordnatene er #. Dersom flere områder skal angis, bruk & som skilletegn mellom de ulike områdeneVed å dobbelklikke på kartet så fylles feltet ut automatisk.">
              <TextField 
                  id="polyPerm" 
                  multiline 
                  rowsMax={3} 
                  label="Koordinater, parkeringsareal" 
                  fullWidth 
                  value={localProp.polyPerm ? localProp.polyPerm : ''} 
                  onChange={(e) => handlePolyInput(e, 'polyPerm')} 
                  onClick={(e) => handleClick('PolyPerm')}
                  sx={{marginTop:"10px"}}
              />
            </Tooltip>
          </div>
          {extraParking}
          {scPanel}
        </div>
      </div>
    </Paper>
  ) : (
    null
  )

  //<Dialog maxWidth="sm" open={open} onClose={closeDialogue}> 
  return (
    <Dialog maxWidth="xl" open={open} onClose={closeDialogue}> 
      <DialogTitle>Eiendomsdetaljer</DialogTitle>
      <DialogContent>
        {propertyContent}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSave}>
          Lagre
        </Button>
        <Button color="primary" onClick={closeDialogue}>
          Lukk
        </Button>
      </DialogActions>                
    </Dialog>        
  )
}

export default Property;
