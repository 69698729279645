

const myDataReducer = (state, action) => {
  //console.log("Reducer", action.type, action);
  switch(action.type) {
    case 'SET_MEMBER_INFO':
      return ({...state, person: action.memberData})
    case 'SET_PROPERTY_INFO':
      return ({...state, properties: action.properties})
    case 'SET_MESSAGES':
      return ({...state, messages: action.messages })
    case 'SET_INVOICES':
      return ({...state, invoices: action.invoices})
    case 'SET_SC_ORDERS':
      return ({...state, scOrders: action.scOrders})
    case 'ADD_SC_ORDER':
      var newOrdersAdd = [...state.scOrders]
      newOrdersAdd.push(action.newOrder)
      return ({...state, scOrders: newOrdersAdd})
    case 'CHANGE_SC_ORDER':
      var changedOrder = action.order;
      var newOrdersChg = state.scOrders.filter(order => order.id!=changedOrder.id);
      newOrdersChg.push(changedOrder);
      return({...state, scOrders: newOrdersChg})
    case 'DELETE_SC_ORDER':
      var newOrdersDel = state.scOrders.filter(order => order.id!=action.orderId)
      return({...state, scOrders: newOrdersDel})
    case 'SET_GATE_NUMBERS':
      return ({...state, gateNumbers: action.gateNumbers})
    case 'DELETE_GATE_NUMBER':
      var delId = action.id;
      var newNumbersDel = state.gateNumbers.filter(num => num.id!=delId);
      return ({...state, gateNumbers: newNumbersDel})
    case 'ADD_GATE_NUMBER':
      var newNum = action.newNum;
      var newNumbersAdd = [...state.gateNumbers]
      newNumbersAdd.push(newNum)
      return ({...state, gateNumbers: newNumbersAdd})
    case 'SET_EXTRA_USERS':
      return ({...state, extraUsers: action.extraUsers})
    case 'ADD_EXTRA_USER':
      var newUsers = [...state.extraUsers]
      newUsers.push(action.user)
      return ({...state, extraUsers: newUsers})
    case 'UPDATE_EXTRA_USER':
      var user = action.user;
      var updUsers = state.extraUsers.filter(u => u.id!=user.id);
      updUsers.push(user);
      return ({...state, extraUsers: updUsers})
    case 'DELETE_EXTRA_USER':
      var delUsers = state.extraUsers.filter(u => u.id!=action.userId);
      return ({...state, extraUsers: delUsers})
    default:
      return state;
  }
}

export default myDataReducer;