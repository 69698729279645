

const configReducer = (state, action) => {
    //console.log("Reducer", action.type, action.clubName);
    switch(action.type) {
        case 'SET_CLUB_ID':
            return ({ ...state, clubId: action.clubId })
        case 'SET_CLUB_INFO':
            return ({...state, clubName: action.clubName, clubShortName: action.clubShortName, clubOrgNo: action.clubOrgNo, clubPlace: action.clubPlace})
        case 'SET_CLUB_NAME':
            return ({...state, clubName: action.clubName})
        case 'SET_CLUB_SHORTNAME':
            return ({...state, clubShortName: action.clubShortName})
        case 'SET_CLUB_ORGNO':
            return ({...state, clubOrgNo: action.clubOrgNo})
        case 'SET_CLUB_PLACE':
            return ({...state, clubPlace: action.clubPlace})
        case 'SET_CLUB_CONFIG':
            return ({...state, clubConfig: action.clubConfig})
        case 'SET_NEW_CLUB_CONFIG_VALUE':
            var param = action.param;
            var newValue = action.newValue;
            if(state.clubConfig) {
                var newConfig = [...state.clubConfig];
                var confItem = newConfig.find(c => c.parameter == param);
                confItem.value=newValue;
                return ({...state, clubConfig: newConfig})
            } 
            return state            
        case 'SET_CLUB_BOARD':
            return ({...state, clubBoard: action.clubBoard})
        case 'LOGIN_SUCCESS':
            return ({...state, 
                     signedIn: true, 
                     authError: null, 
                     userId: action.data.id, 
                     username: action.data.email, 
                     userFullname: action.data.name,
                     userInitials: action.data.initials, 
                     userEmail: action.data.email,
                     accessLevel: action.data.accessLevel, 
                     goToPage: "/mypage",
                     accessToken: action.data.token}) 
        case 'RESET_GOTO_PAGE':
            return ({...state, goToPage: null})
        case 'LOGIN_ERROR':
            return ({...state, authError: true, authErrorMessage: action.err, signedIn: false})
        case 'RESET_LOGIN_ERROR':
            return ({...state, authError: false, authErrorMessage: null, signedIn: false})
        case 'SET_CURRENT_FUNCTION':
            return ({...state, currentFunction: action.functionName})
        case 'SET_SIMULATION_USER':
            return ({...state, userId: action.userId})
        case 'SET_MENU_ITEMS':
            return ({...state, menuItems: action.menuItems})
        case 'SET_RELEASE_INFO':
            return ({...state, releases: action.releases})
        default:
            return state;
    }
}

export default configReducer;