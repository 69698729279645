import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import { Paper, Button, Typography, TextField, Tooltip, TableHead, TableContainer, Table, TableBody, TableRow, TableCell, Fab, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Switch } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility';

import { ConfigContext } from '../../contexts/ConfigContext'
import {getConfig, getConfigVal} from './../../utils/Library'
import BlockLineView from './blockLineView'

const SnowClearingConfig = ({saveAll}) => {

  const INIT_COORDINATES = {
    id: null,
    coordinates: ''
  }
  const { config, configDispatch } = useContext(ConfigContext);
  const [coordinateList, setCoordinateList] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [viewCoordinates, setViewCoordinates] = useState(null);
  const [newCoordinates, setNewCoordinates] = useState(INIT_COORDINATES);

  /////////////////////
  // Server interaction
  /////////////////////
  const getCoordinateList = () => {
    axios.get(config.server+'snowClearingOrders/map/block/all/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setCoordinateList(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })     
  }

  const addCoordinates = (newCoordinates) => {
    //console.log("Call post")
    axios.post(config.server+'snowClearingOrders/map/block/save/'+config.clubId, {
      newCoordinate: newCoordinates.coordinates
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        newCoordinates.id = res.data.newId;
        setCoordinateList([...coordinateList, newCoordinates]);
        setOpen(false);
        setNewCoordinates(INIT_COORDINATES);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  const deleteCoordinates = (id) => {
    axios.delete(config.server+'snowClearingOrders/map/block/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        var newList = coordinateList.filter(n => n.id != id);
        setCoordinateList([...newList]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })               
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    getCoordinateList()
  }, []) 
  
  ////////////////////
  // Action handlers
  ////////////////////

  // Handle switches
  const handleSwitchChange = (e) => {
    var newValue = e.target.checked ? "1":"0";
    configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: newValue})
  }

  const handleConfigChange = (e) => {
    configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: e.target.value})
  }

  const handleDelete = (e) => {
    deleteCoordinates(e.currentTarget.id)
  }

  const handleAdd = (e) => {
    setOpen(true);
  }

  const handleCancel = (e) => {
    setOpen(false);
    setNewCoordinates(INIT_COORDINATES);
  }

  const handleSave = () => {
    //console.log(newCoordinates)
    addCoordinates(newCoordinates)
  }

  const handleView = (e) => {
    var selected = e.currentTarget.id;
    var coord = coordinateList.find(c => c.id==selected)
    setViewCoordinates(coord)
    setViewOpen(true)
  }


  ///////////
  // UI part
  ///////////

  // Pop-up dialog to add a new number
  const dialog = (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Legg til 'sperre makringer' på kartet</DialogTitle>
      <DialogContent>
        <div style={{display:"flex", flexFlow:"flex-start"}}>
          <div>
            <Tooltip title="Koordinater som indikerer punktene langs en linje angitt som breddegrad, lengdegrad i formatet '59.567, 7.345'. Skilletegn mellom punktkoordnatene er #.">
            <TextField
              key={1}
              required 
              margin="dense"
              id="header"
              label="Koordinater"
              fullWidth
              value={newCoordinates.coordinates}          
              onChange={(e) => setNewCoordinates({...newCoordinates, coordinates: e.target.value})}
              autoFocus
            /> 
            </Tooltip>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Lagre
        </Button>
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  );

  const list = coordinateList ? (
    coordinateList.map(coord => {

      const deleteButton = (
        <Tooltip title="Slett linje" aria-label="add">
          <DeleteIcon color="primary" fontSize="small" value={coord.id} id={coord.id} onClick={handleDelete} />
        </Tooltip>  
      ); 

      const viewButton = (
        <Tooltip title="Se linjen på kartet">
          <VisibilityIcon color="primary" fontSize="small" value={coord.id} id={coord.id} onClick={handleView} />
        </Tooltip>  
      ); 

      return (
        <TableRow key={coord.id}>
          <TableCell>{coord.coordinates}</TableCell>
          <TableCell>{viewButton}</TableCell>
          <TableCell>{deleteButton}</TableCell>
        </TableRow>
      )
    })
  ):(
    null
  )

  const blockLineViewDialog = (
    <Dialog open={viewOpen} onClose={(e) => setViewOpen(false)} maxWidth="lg" aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Sperrelinje</DialogTitle>
      <DialogContent>
        <BlockLineView blockLine={viewCoordinates} />
      </DialogContent>   
      <DialogActions>
        <Button color="primary" onClick={(e) => setViewOpen(false)}>
          Lukk
        </Button>
      </DialogActions>                     
    </Dialog>    
  );

  return (
      <div>
        {dialog}
        {blockLineViewDialog}
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", flexWrap:"wrap" }} >
          <div style={{order:1, maxWidth:"300px" }}>
          <Paper elevation={6} style={{margin: "15px", padding: "10px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Konfigurering av brøytebestilling</Typography>
            <Tooltip title="Antall dager fram i tid som det er tillatt å bestille brøyting. Blankt felt = uendelig" >
              <TextField
                  key={401} required margin="dense" id="ctrl" label="Tillatt antall dager fram i tid" name="SNOWCLEARING_BOOK_FORWARD"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  fullWidth value={getConfigVal(config, "SNOWCLEARING_BOOK_FORWARD")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Avsender for meldinger som bekrefter at brøyting er utført, og SMS meldinger fra brøytemanskapet. Maksimalt 11 tegn." >
              <TextField
                  key={402} required margin="dense" id="nonr" label="SMS avsender, brøyting" name="SMS_SC_SENDER"
                  disabled={parseInt(config.accessLevel)===999 ? false:true}
                  fullWidth value={getConfigVal(config, "SMS_SC_SENDER")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Tillate kommentarer på brøytebestillingen" >
              <FormControlLabel key={403}
                control={<Switch color="primary" checked={getConfig(config, "SNOWCLEARING_ALLOW_COMMENTS")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="SNOWCLEARING_ALLOW_COMMENTS" />}
                label="Kommentarer på brøytebestilling"
              />       
            </Tooltip>      
            <Tooltip title="Brøyteområde basert på antall parkeringsplasser" >
              <FormControlLabel key={403}
                control={<Switch color="primary" checked={getConfig(config, "SNOWCLEARING_PARKING_SPACE_UNITS")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="SNOWCLEARING_PARKING_SPACE_UNITS" />}
                label="Brøyte parkeringsplasser"
              />       
            </Tooltip>      
            <Tooltip title="Brøyteområde basert på areal" >
              <FormControlLabel key={403}
                control={<Switch color="primary" checked={getConfig(config, "SNOWCLEARING_PARKING_SPACE_AREA")==1?true:false} disabled={parseInt(config.accessLevel)>=99 ? false:true} onChange={handleSwitchChange} name="SNOWCLEARING_PARKING_SPACE_AREA" />}
                label="Brøyte areal"
              />       
            </Tooltip>                              
            <Tooltip title="URL til Kartverkets server" >
              <TextField
                  key={404} required margin="dense" id="ctrl" label="URL, Kartverkets server" name="SNOWCLEARING_MAP_URL"
                  disabled={parseInt(config.accessLevel)===999 ? false:true}
                  fullWidth value={getConfigVal(config, "SNOWCLEARING_MAP_URL")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Senterposisjon på kartet (bredde og lengdegrad) som vises til brøytemanskapet" >
              <TextField
                  key={405} required margin="dense" id="ctrl" label="Kart, senterposisjon" name="SNOWCLEARING_MAP_CENTER"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  fullWidth value={getConfigVal(config, "SNOWCLEARING_MAP_CENTER")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Zoom nivå på kartet som vises til brøytemanskapet" >
              <TextField
                  key={406} required margin="dense" id="ctrl" label="Kart, zoom nivå" name="SNOWCLEARING_MAP_ZOOM"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  fullWidth value={getConfigVal(config, "SNOWCLEARING_MAP_ZOOM")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>

          </Paper>                      
            <Button color="primary" onClick={(e) => saveAll(e)}>
              Lagre
            </Button>                         
          </div>

          <div style={{order:2, width:"700px", minWidth:"400px", maxWidth:"1000px"}}>
          <Paper elevation={6} style={{margin: "15px", padding: "10px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Koordinater som markerer sperrelinjer på brøytekartet</Typography>
            <Tooltip title="Legg til en ny sperrelinje" aria-label="add">
              <Fab color="primary" size="small" aria-label="add" >
                <AddIcon onClick={handleAdd}/>
              </Fab>
            </Tooltip>   
            <TableContainer component={Paper}>
              <Table size="small" padding="none" >
                <TableHead>
                  <TableRow>
                    <TableCell>Linjekoordinater</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          </div>
        </div>        
      </div>
    )
  }

export default SnowClearingConfig;
