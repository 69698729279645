import React, {useState, useContext, useEffect} from 'react'
import axios from 'axios'
import { ConfigContext } from '../../contexts/ConfigContext'
import {getTimeFormat} from './../../utils/Library'

import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Typography, FormControlLabel, Switch, Button, FormControl, RadioGroup, Radio, Tooltip } from '@mui/material';

import { useConfirm } from 'material-ui-confirm';

import DisplayMessageDialog from './displayMessageDialog'

const MessageLog = () => {

  const confirm = useConfirm(); 

  const calcWidth = () => {
    const PAD = 40;
    //const fixed = window.innerWidth>1280 ? 1000 : 760;
    const fixed = window.innerWidth>1280 ? 1180 : 940;
    return { 
      totalWidth: window.innerWidth,
      timeWidth: 130,
      typeWidth: 113,
      subjectWidth: Math.floor((window.innerWidth-fixed)*0.3),
      contentWidth: Math.floor((window.innerWidth-fixed)*0.7),
      senderWidth: 140,
      recipientWidth: 140,
      cntWidth: 20,
      buttonWidth: 102
    }
  }

  const [messages, setMessages] = useState([]);
  const [width, setWidth] = useState(calcWidth());
  const [filter, setFilter] = useState({
    showEmail: true, 
    showReceived: false,
    showSms: true,
    showSc: false,
    showPwd: false,
    showGate: false,
    showInv: false,
    noRows: "1"
  })
  const [selectedMessage, setSelectedMessage] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);


  const { config, configDispatch } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////
  const loadMessages = () => {
    axios.post(config.server+'msg/history/filter/'+config.clubId, { 
        filter: filter
      },{
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setMessages(res.data);
      })
      .catch(err => {
        console.log('Error: ',err)
      })  
  }

  const copyMessage = (messageId) => {
    axios.post(config.server+'msg/repeat/msg/'+config.clubId, { 
      messageId: messageId
    },{
    'headers': {
      'Content-Type': 'application/json', 
      'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      const newId = res.data.msgId
      const msg = messages.find(m => m.id == messageId)
      const newMsg =  {...msg, id: newId, time: new Date()}
      setMessages([...messages, newMsg]);
    })
    .catch(err => {
      console.log('Error: ',err)
    })      
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Meldingslogg"});
  }, [])

  useEffect(() => {
    loadMessages();
  }, [filter])

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(calcWidth()));
  }, [])  

  ////////////////////
  // Action handlers
  ////////////////////

  const handleDetailsClick = (e) => {
    //console.log(e, e.currentTarget.id)
    const msgId = e.currentTarget.id
    const showMsg = messages.find(msg => msg.id == msgId)
    setSelectedMessage(showMsg)
    setDialogOpen(true)
  }

  const handleCopyMsg = (e) => {
    const msgId = e.currentTarget.id

    confirm({ 
      title: "Er du sikker?",
      description: "Vil du sende en kopi av denne meldingen?",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
    .then(() => { 
      copyMessage(msgId)
    })
    .catch(() => { 
      //console.log("Sending avbrutt")  
    });
  }


  ///////////
  // UI part
  ///////////


  const scControl = (
          <FormControlLabel
            control={
              <Switch
                checked={filter.showSc}
                onChange={(e) => setFilter({...filter, showSc: e.target.checked})}
                name="sc"
                color="primary"
              />
            }
            label="Brøytemeldinger"
          />
  )

  const gateControl = (
    <FormControlLabel
      control={
        <Switch
          checked={filter.showGate}
          onChange={(e) => setFilter({...filter, showGate: e.target.checked})}
          name="gate"
          color="primary"
        />
      }
      label="Bommeldinger"
    />
  )

  const smsControl = (
    <FormControlLabel
      control={
        <Switch
          checked={filter.showSms}
          onChange={(e) => setFilter({...filter, showSms: e.target.checked})}
          name="sc"
          color="primary"
        />
      }
      label="Sms'er"
    />
  )

  const emailControl = (
    <FormControlLabel
      control={
        <Switch
          checked={filter.showEmail}
          onChange={(e) => setFilter({...filter, showEmail: e.target.checked})}
          name="sc"
          color="primary"
        />
      }
      label="ePoster sendt"
    />
  )

  const pwdControl = (
    <FormControlLabel
      control={
        <Switch
          checked={filter.showPwd}
          onChange={(e) => setFilter({...filter, showPwd: e.target.checked})}
          name="sc"
          color="primary"
        />
      }
      label="Passordmeldinger"
    />
  )  

  const invControl = (
    <FormControlLabel
      control={
        <Switch
          checked={filter.showInv}
          onChange={(e) => setFilter({...filter, showInv: e.target.checked})}
          name="sc"
          color="primary"
        />
      }
      label="Fakturameldinger"
    />
  )  

  const receivedControl = (
    <FormControlLabel
      control={
        <Switch
          checked={filter.showReceived}
          onChange={(e) => setFilter({...filter, showReceived: e.target.checked})}
          name="sc"
          color="primary"
        />
      }
      label="ePoster mottatt"
    />
  )  

  const noRows = [
    <FormControl component="fieldset">
      <RadioGroup row  aria-label="gender" name="gender1" value={filter.noRows} onChange={(e) => setFilter({...filter, noRows: e.target.value})}>
        <FormControlLabel key={1} value="1" control={<Radio />} label="Siste 90 dager" />
        <FormControlLabel key={2} value="2" control={<Radio />} label="Siste år" />
        <FormControlLabel key={3} value="3" control={<Radio />} label="Alle" />
      </RadioGroup>
    </FormControl>    
  ]


  const listMessages = messages ? (
    messages.sort((a,b) => a.time>b.time ? -1 : 1).map(message => {
      const type = message.msgType=='e' ? 'ePost' : 'SMS';
      const senderItem = filter.showReceived ? <TableCell><Typography style={{width: width.senderWidth }} noWrap>{message.sender}</Typography></TableCell> : null
      const cpy = message.cnt==1 ? <Tooltip title="Sende meldingen omigjen" aria-label="add">
                                      <Button variant="outlined" color="primary" size="small" id={message.id} onClick={(e) => handleCopyMsg(e)} >Kopier</Button>
                                    </Tooltip> 
                                  : null
      return (
        <TableRow key={message.id}>
          <TableCell><Typography style={{width: width.timeWidth }}>{getTimeFormat(message.time)}</Typography></TableCell>
          <TableCell><Typography style={{width: width.typeWidth }}>{type}</Typography></TableCell>
          <TableCell><Typography style={{width: width.subjectWidth }} noWrap>{message.subject}</Typography></TableCell>
          <TableCell><Typography style={{width: width.contentWidth }} noWrap>{message.content}</Typography></TableCell>
          {senderItem}
          <TableCell><Typography style={{width: width.recipientWidth }} noWrap>{message.recipients}</Typography></TableCell>
          <TableCell><Typography style={{width: width.cntWidth }} noWrap>{message.cnt}</Typography></TableCell>
          <TableCell style={{width: width.buttonWidth }}><Button variant="outlined" color="primary" id={message.id} size="small" onClick={(e) => handleDetailsClick(e)} >Detaljer</Button></TableCell>
          <TableCell>{cpy}</TableCell>
        </TableRow>
      )
    })
  ):(
    null
  );  

  //console.log(width)
  const senderHdr = filter.showReceived ? (<TableCell>Avsender</TableCell>):(null)

  return (
      <div>
        <DisplayMessageDialog open={dialogOpen} setOpen={setDialogOpen} message={selectedMessage} setMessage={setSelectedMessage} />
        <div>
          {emailControl}
          {receivedControl}
          {smsControl}
          {scControl}
          {gateControl}
          {pwdControl}
          {invControl}
        </div>
        <div>
          {noRows}
        </div>
        <TableContainer component={Paper}>
          <Table style={{minWidth: 650}} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Dato/Tid</TableCell>
                <TableCell>ePost/SMS</TableCell>
                <TableCell>Emne</TableCell>
                <TableCell>Innhold</TableCell>
                {senderHdr}
                <TableCell>Mottakere</TableCell>
                <TableCell>#</TableCell>
                <TableCell>Se detaljer</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listMessages}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

export default MessageLog;
