const memberReducer = (state, action) => {
  //console.log(action.type);
  switch(action.type) {
      case 'SET_MEMBERS':
        //console.log(action.members);
        return ( [...action.members] )
      case 'ADD_MEMBER':
        //console.log(action.newMember);
        return ([...state, action.newMember])
      case 'UPDATE_MEMBER':
        //console.log(action.member);
        const m = action.member;
        var newMembers = state.filter(n => n.id != m.id);
        newMembers.push(m);
        //return ([...newMembers, m])
        return (newMembers)
      default:
        return state;
  }
}

export default memberReducer;