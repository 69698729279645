import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'
import {getDateFormat, getConfig} from '../../utils/Library'
import moment from "moment";
import "moment/locale/nb";

import { ConfigContext } from '../../contexts/ConfigContext'
import { AccountContext } from '../../contexts/AccountContext'

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment, MenuItem, Select, InputLabel, FormControl, Stack} from '@mui/material';

//import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
//import MomentUtils from '@date-io/moment';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
//import AddAttatchment from './addAttachment';

moment.locale("nb");

const PostDocument = ({open, setOpen, selectedAccount, setSelectedAccount, reloadTr, setReloadTr}) => {

  const { accounts, accountDispatch } = useContext(AccountContext);
  const { config } = useContext(ConfigContext);
  const DOC_INIT = {
    id: null,
    date: new Date(),
    description: '',
    amount: '',
    fromAcct: '',
    toAcct: '',
    postingType: 2,
    docName: ''
  }
  const [document, setDocument] = useState(DOC_INIT)
  var key = 0;

  /////////////////////
  // Server interaction
  /////////////////////
  const saveDocument = (document) => {
    //console.log(document);

    var bodyFormData = new FormData();
    bodyFormData.set('clubId',  config.clubId);
    bodyFormData.set('date',    getDateFormat(document.date));
    bodyFormData.set('description', document.description);
    bodyFormData.set('amount',  document.amount);
    bodyFormData.set('fromAct', document.fromAcct);
    bodyFormData.set('toAct',   document.toAcct);
    bodyFormData.set('periodId',accounts.currentPeriod);
    bodyFormData.set('postingType', document.postingType);
    bodyFormData.append('attachmentFile', document.docName)

    axios({
      method: 'post',
      url: config.server+'accounting/document/new/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        if(res.status===200) {
          const result = res.data;
          document.id=result.docId;
          //console.log(res.data)
          accountDispatch({type: 'ADD_POSTED_DOCUMENT', doc: document});      
          setReloadTr(!reloadTr);   
        }
      })
      .catch(res => {
          //handle error
          console.log(res);
      })
  }
  

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    //console.log(selectedAccount);
    //console.log(accounts);

    // Special handling for capital income
    if(selectedAccount && selectedAccount>=8000 && selectedAccount<8100) {
      setDocument({...document, fromAcct: parseInt(getConfig(config, "ACCOUNTING_BANK_ACCOUNT")), toAcct: parseInt(selectedAccount)})
    } else {
      // All other cases
      if(selectedAccount) {
        setDocument({...document, fromAcct: parseInt(selectedAccount), toAcct: parseInt(getConfig(config, "ACCOUNTING_BANK_ACCOUNT"))})
      }  
    }

  }, [selectedAccount])
  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleSave = () => {
    //if(!isNaN(document.amount) && document.fromAcct.length>1 && document.toAcct.length>1 && document.date.length>1) {
      //console.log("Saving");
      saveDocument(document);
    //} 
    closeDialogue();
  }

  const handleSetDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setDocument({...document, date: selectedDate.toDate()})
    } else {
      setDocument({...document, date: null})
    }
    //setDocument({...document, date: selectedDate.toDate()})
  }

  const handleFileAttachment = (e) => {
    setDocument({...document, docName: e.target.files[0]});
  }

  const closeDialogue = () => {
    setDocument(DOC_INIT);
    setSelectedAccount(null);
    setOpen(false);
  }

  const handleAmount = (e) => {
    var str = e.target.value;
    var res = str.replace(",",".");
    var newVal = isNaN(res) ? document.amount : res;
    //console.log(e.target.value, isNaN(e.target.value), res, res)
    setDocument({...document, amount: newVal})
  }

  const formatAmount = (value) => {
    var str = value;
    var res = str.replace(".",",");
    return res;
  }

  ///////////
  // UI part
  ///////////

  const seletableAccounts = accounts.accumulated ? (
    accounts.accumulated.filter(a=> a.period_id==accounts.currentPeriod).sort((a,b)=> (a.account_id-b.account_id)).map(act => {
      //console.log(act);
      return (
        <MenuItem key={key++} value={act.account_id} >{act.account_id+" - "+act.account_name}</MenuItem>
      )
    })
  ) : (
    null
  )

  const allowSave = () => {
    //console.log(str, str && /^\s*$/.test(str))
    //return (str && /^\s*$/.test(str));
    return (document.description.trim()=="" || document.amount.trim()=="" || document.fromAcct=="" || document.toAcct=="")
  }

  //      
  //      

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
      <div key={key++}>
        <Dialog open={open} onClose={closeDialogue} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Manuellt regnskapsbilag</DialogTitle>
          <DialogContent>
            
            <div style={{display:"flex", flexDirection:"column", marginTop:"10px"}}>
            <Stack spacing={2} >
              <DatePicker 
                disableToolbar
                required
                variant="inline"
                renderInput={(params) => <TextField {...params}/>}
                margin="normal"
                format="L"
                id="date-picker-inline"
                label="Dokumentdato"
                mask="__.__.____"
                value={(document.date=='' || document.date==null) ? null : document.date}
                onChange={handleSetDate}        
                autoOk={true}      
              />
              <TextField 
                required 
                id="standard-required" 
                label="Dokumentbeskrivelse" 
                defaultValue={document.description} 
                onChange={(e) => setDocument({...document, description:e.target.value})}/>
              <TextField 
                required 
                id="standard-start-adornment" 
                label="Beløp" 
                InputProps={{startAdornment: <InputAdornment position="start">Kr</InputAdornment>}} 
                value={formatAmount(document.amount)} onChange={(e) => handleAmount(e)} />
              <TextField
                id="outlined-select-currency-native"
                select
                label="Debet(+)"
                value={document.fromAcct}
                onChange={(e) => setDocument({...document, fromAcct: e.target.value})}
                helperText="Velg debet konto (+)" 
              >
                {seletableAccounts}
              </TextField>
              <TextField
                id="outlined-select-currency-native"
                select
                required
                label="Kredit(-)"
                value={document.toAcct}
                onChange={(e) => setDocument({...document, toAcct: e.target.value})}
                helperText="Velg kredit konto (-)"
              >
                {seletableAccounts}
              </TextField>
              <div>
                <InputLabel id="select-standard-label">Bilag / vedlegg</InputLabel>
                <TextField id="standard-required" type="File" onChange={(e) => handleFileAttachment(e)}/>
              </div>
              </Stack>
            </div>
        
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSave} disabled={allowSave()}>
              Lagre
            </Button>
            <Button color="primary" onClick={closeDialogue}>
              Avbryt
            </Button>
          </DialogActions>          
        </Dialog>        
      </div>
    </LocalizationProvider >
    )
}

export default PostDocument;
