import React, {useEffect, useContext, useState} from 'react'

import { ConfigContext } from '../contexts/ConfigContext'
import { TableCell, TableContainer, TableRow, Table, TableBody, Typography, Paper } from '@mui/material';

const Board = () => {

  const { config } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  const boardList = config.clubBoard.length ? (
    config.clubBoard.map((member, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{member.role}</TableCell>
            <TableCell>{member.firstname+" "+member.lastname}</TableCell>
            <TableCell>{member.email}</TableCell>
            <TableCell>{member.phone}</TableCell>
          </TableRow>
        )
    })
  ):(
      <h6>Ukjent</h6>
  )

  const header = config.clubName ? (
    <Typography variant="h6">Styret i {config.clubName}</Typography>
  ) : (
    <Typography variant="h6">"Ukjent klubb"</Typography>
  )

  return (
    <div>
      <Paper style={{maxWidth:"900px", margin: 5, backgroundColor: "gainsboro"}}>
        {header}
        <div>
          <TableContainer>
            <Table>
              <TableBody>
                {boardList}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    </div>
  )
}

export default Board;
