import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';

import { AccountContext } from '../../contexts/AccountContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import {getDateFormat} from '../../utils/Library'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography, Tooltip, TextField, Stack } from '@mui/material'
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import "moment/locale/nb";
//moment.locale("nb");

const ManagePeriods = ({open, setOpen, reload, setReload }) => {

  const INIT_PERIOD = {
    id: '',
    description: '',
    start_date: '', 
    end_date: '',   
    active: 0,
    noOfTransactions: 0
  };
  const { accounts, accountDispatch } = useContext(AccountContext);
  const { config } = useContext(ConfigContext);
  const [period, setPeriod] = useState(INIT_PERIOD);
  const confirm = useConfirm();

  /////////////////////
  // Server interaction
  /////////////////////

  const saveNew = (period) => {
    axios.post(config.server+'accounting/period/create/'+config.clubId, {
      period: period
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data);
      const newPeriod = res.data.period;
      accountDispatch({type: 'NEW_PERIOD', period: newPeriod});
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  const saveUpdate = (period) => {
    axios.patch(config.server+'accounting/period/update/'+config.clubId, {
      period: period
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data);
      const updatedPeriod = res.data.period;
      accountDispatch({type: 'UPDATE_PERIOD', period: updatedPeriod});
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  const saveDelete = (id) => {
    axios.delete(config.server+'accounting/period/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      console.log(res.data);
      accountDispatch({type: 'DELETE_PERIOD', periodId: id});
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  const saveActivate = (periodId) => {
    axios.patch(config.server+'accounting/period/activate/'+config.clubId, {
      periodId: periodId
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      accountDispatch({type: 'ACTIVATE_PERIOD', periodId: periodId});
      setReload(true);
    })
    .catch(err => {
        console.log('Error: ',err)
    })       
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    //console.log(accounts)
  }, [accounts])
  

  ////////////////////
  // Action handlers
  ////////////////////

  // Close dialogue
  const handleClose = () => {
    setOpen(false);
    setPeriod(INIT_PERIOD);
  }

  // Edit a period
  const handleEdit = (e) => {
    const selected = e.currentTarget.id;
    const edit = accounts.allPeriods.find(p => p.id==selected);
    setPeriod({id: edit.id, description: edit.description, start_date: new Date(edit.start_date), end_date: new Date(edit.end_date) });
  }

  // Save period changes
  const handleSave = () => {
    if(period.id=='') {
      //console.log("New", period);
      saveNew(period);
    } else {
      //console.log("Update", period);
      saveUpdate(period);
    }
    setPeriod(INIT_PERIOD);
  }

  const handleDescInput = (e) => {
    //console.log(e.target.value)
    setPeriod({...period, description: e.target.value})
  }

  const handleFromDateInput = (selectedDate) => {
    //console.log(selectedDate)
    if(selectedDate && selectedDate.isValid) {
      setPeriod({...period, start_date:selectedDate.toDate()})
    }
  }

  const handleToDateInput = (selectedDate) => {
    //console.log(selectedDate)
    if(selectedDate && selectedDate.isValid) {
      setPeriod({...period, end_date: selectedDate.toDate()})
    }
  }

  // Delete the period (only if the period is not active, and there is no transactions on it)
  const handleDelete = (e) => {
    const id = e.currentTarget.id
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes denne perioden. Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        saveDelete(id);
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
    
  }

  const handleActivate = (e) => {
    const id = e.currentTarget.id
    confirm({ 
      title: "Er du sikker?",
      description: "Bekreft for å aktivere en ny periode. Dette vil innebære at resultatet fra nåværende periode legges til som opptjent egenkapital og føres som inngående beholding i den nye perioden. Det er ikke mulig å angre på dette valget.",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        saveActivate(id);
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
    setOpen(false);
    setPeriod(INIT_PERIOD);    
  }

  ///////////
  // UI part
  ///////////
  
  var prevPeriodActive = false;
  const periodOverview = accounts.allPeriods ? (
    accounts.allPeriods.sort((a,b) => a.id - b.id).map(per => {
      const deleteButton = (per.active=='0' && per.noOfTransactions=='0') ? 
        (
          <Tooltip title="Slett periode">
            <IconButton id={per.id} onClick={(e) => handleDelete(e)} >
              <DeleteIcon color="secondary" />
            </IconButton>
          </Tooltip>
        ) : (
          null
        );
      const active = per.active == '1' ? ("Aktiv") : (null);
      const activate = (accounts.allPeriods.length==1 || prevPeriodActive) ? (
          <Tooltip title="Aktiver ny periode. NB! Dette innebærer regnskapsavslutning for forrige periode. Overskudd/underskudd avregnes til opptjent egenkapital, og UB balanse føres som IB inn i ny periode. Posteringer i forrige periode vil ikke lenger være mulig">
            <IconButton id={per.id} onClick={(e) => handleActivate(e)} >
              <PowerSettingsNewIcon />
            </IconButton>
          </Tooltip>          
        ) : (null);
      prevPeriodActive = per.active=='1' ? true : false;
      return (
        <TableRow key={per.id}>
          <TableCell>{per.id}</TableCell>
          <TableCell>{per.description}</TableCell>
          <TableCell>{getDateFormat(per.start_date)}</TableCell>
          <TableCell>{getDateFormat(per.end_date)}</TableCell>
          <TableCell>
            <Tooltip title="Endre periodeinformasjon">
              <IconButton id={per.id} onClick={(e) => handleEdit(e)} >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>{deleteButton}{active}</TableCell>
          <TableCell>{activate}</TableCell>
        </TableRow>
      )
    })
  ) : (
    null
  )

  return (
      <div>
      <Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title" maxWidth="xl">
      <DialogTitle id="form-dialog-title">Administrer perioder</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table style={{minWidth: 400}}  component={Paper} size="small" padding="none" >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Periode</TableCell>
                    <TableCell>Fra</TableCell>
                    <TableCell>Til</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {periodOverview}
                </TableBody>
              </Table>
            </TableContainer>
            <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
              <Paper elevation={3} style={{display: "flex", flexDirection:"column", width: "220px", marginTop: 40, padding:"10px"}} >
                <Stack spacing={1}>
                <Typography variant="h6">Ny eller endre periode</Typography>
                <TextField required id="standard-required" label="Periode" value={period.description} onChange={(e) => handleDescInput(e)} />
                <DatePicker
                  disableToolbar
                  variant="inline"
                  renderInput={(params) => <TextField {...params}/>}
                  margin="normal"
                  format="L"
                  id="date"
                  label="Fra (dato)"
                  mask="__.__.____"
                  value={period.start_date=='' ? null : period.start_date}
                  onChange={(e) => handleFromDateInput(e)}        
                  autoOk={true}      
                />
                <DatePicker
                  disableToolbar
                  variant="inline"
                  renderInput={(params) => <TextField {...params}/>}
                  margin="normal"
                  format="L"
                  id="date-picker-inline"
                  label="Til (dato)"
                  mask="__.__.____"
                  value={period.end_date=='' ? null : new Date(period.end_date)}
                  onChange={(e) => handleToDateInput(e)}        
                  autoOk={true}      
                />
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                  <Button color="primary" size="small" variant="contained" sx={{margin: "5px"}} onClick={(() => setPeriod(INIT_PERIOD))}>Ny</Button>
                  <Button color="primary" size="small" variant="contained" sx={{margin: "5px"}} onClick={handleSave}> Lagre </Button>
                </div>  
              </Stack>              
              </Paper>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={(() => handleClose())}>
              Lukk
            </Button>
          </DialogActions>          
        </Dialog>         
      </div>
    )
  }

export default ManagePeriods;
