import React, {useEffect} from 'react'
import { Typography, Paper, TextField, Tooltip, Switch, FormControlLabel } from '@mui/material'

const ItemTile = ({tileName, costCategoryId, costCategoryTooltip, costCategoryItems, invoiceItems, setInvoiceItems, stateInvoiceHeader}) => {

  const NEGSIGN = "-";

  //console.log(tileName, costCategoryId, costCategoryItems, invoiceItems)

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    //console.log(invoiceItems)
  }, [invoiceItems])
  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleSetValue = (e, costItem, input) => {
    //console.log("New value", e.target.value, costItem, input);
    if(stateInvoiceHeader.accounting_doc_no) {
      // Do nothing
    } else {
      var item = invoiceItems.find(ii => ii.cost_category_item==costItem);
      var newValue = 0;
      if(input=='TextField') {
        //newValue = isNaN(e.target.value) ? item.price_element : e.target.value;
        if(e.target.value=="-" || !isNaN(e.target.value)) {
          newValue = e.target.value;
        } else {
          newValue = item.price_element;
        }
      }
      if(input=="CheckBox") {
        if(e.target.checked) {
          newValue=1
        } else {
          newValue=0
        }
      }
      
      item.price_element=newValue;
      var filteredList = invoiceItems.filter(ii => ii.cost_category_item!=costItem);
      filteredList.push(item);
      setInvoiceItems(filteredList);  
    }
  }

  const getValue = (costItem, itemNo) => {
    var item = invoiceItems.find(ii => ii.cost_category_item==costItem);
    //console.log(item);
    if(item) {
      return item.price_element
    } else {
      var newItemList = [...invoiceItems];
      //var itemNo = newItemList.length;
      var newItem = {
        invoice_id: null,
        item_no: itemNo,
        cost_category_item: costItem,
        price_element: ''
      };
      newItemList.push(newItem);
      //console.log(newItemList);
      setInvoiceItems(newItemList);
      return newItem.price_element
    }
  }


  ///////////
  // UI part
  ///////////

  const costItems = (costCategoryItems && invoiceItems) ? (
    costCategoryItems.sort((a,b) => a.cost_category_item > b.cost_category_item ? 1 : -1).map((cci) => {

      // Field
      const inputField = (cci.input=="TextField") ? (
                        <TextField 
                          id={cci.cost_category_item} 
                          fullWidth
                          variant="standard"
                          label={cci.category_item_text+', '+cci.price_element} 
                          value={getValue(cci.cost_category_item, cci.item_no)} 
                          onChange={(e) => handleSetValue(e,cci.cost_category_item, cci.input)} 
                        />
                     ) : (cci.input=="CheckBox") ? (
                        <FormControlLabel label={cci.category_item_text+', '+cci.price_element} control={
                          <Switch
                            checked={getValue(cci.cost_category_item, cci.item_no)==1 ? true : false}
                            onChange={(e) => handleSetValue(e,cci.cost_category_item, cci.input)}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />}
                        />                 
                     ) : null;

      return (
        <div key={cci.cost_category_item}>
          {inputField}
        </div>
      )
    })
  ) : (null);

  return (
      <div key={costCategoryId} style={{margin:"10px", minWidth:"250px"}}>
        <Tooltip title={costCategoryTooltip}>
        <Paper>
          <div style={{display: "flex", flexDirection:"column", margin:"5px"}}>
            <Typography variant="h6">{tileName}</Typography>
            {costItems}
          </div>
        </Paper>
        </Tooltip>
      </div>
    )
  }

export default ItemTile;
