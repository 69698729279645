import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import moment from "moment";
import "moment/locale/nb";

import { ConfigContext } from '../../contexts/ConfigContext'
import { MyDataContext } from '../../contexts/MyDataContext'

import {Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack} from '@mui/material';

import MomentUtils from '@date-io/moment';
import { getDateFormat, getConfig, easterDate } from '../../utils/Library';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

moment.locale("nb");

const OrderDialog = ({open, setOpen, editId, setEditId, allowComments, memberId, propertyId}) => {
  const INIT = {
    id: 0,
    memberId: memberId,
    propertyId: propertyId,
    regTime: '',
    fromDate: '', 
    fromTime: "20:00",
    toDate: '', 
    comment: ""
  }
  const WEEKEND_START_DAY = 4; // Thursday
  const [localOrder, setLocalOrder] = useState(INIT);
  const [maxDate, setMaxDate] = useState(new Date("2100.01.01"));
  const [bookingLimits, setBookingLimits] = useState({
      weekdayLimit: false, // False if no weekday limits
      weekdays: [true,true,true,true,true,true,true], // false if a specific day cannot be booked 
      xmasLimit: false,
      xmas: [], // min and max date
      winterLimit: false,
      winter: [], // min and max date
      easterLimit: false,
      easter: [] // min and max date
    })
  const [dateFromValid, setDateFromValid] = useState(true);
  const [dateToValid, setDateToValid] = useState(true);
  const [invoicesPaied, setInvoicesPaied] = useState(true);
  const {myData, myDataDispatch} = useContext(MyDataContext);
  const {config} = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////

  const saveNewOrder = (localOrder) => {
    localOrder.fromDate = getDateFormat(localOrder.fromDate)
    localOrder.toDate = getDateFormat(localOrder.toDate)
    axios.post(config.server+'snowClearingOrders/member/'+config.clubId, {
        order: localOrder
      },{
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      var order = res.data.createdOrder;
      order.id = res.data.orderId;
      order.regTime = res.data.regTime;
      order.fromDate = moment(order.fromDate, "DD.MM.YYYY").toDate()
      order.toDate = moment(order.toDate, "DD.MM.YYYY").toDate()
      myDataDispatch({type: 'ADD_SC_ORDER', newOrder: order});
      setOpen(false);
      setLocalOrder(INIT);
    })
    .catch(err => {
        console.log('Error: ',err)
    })       
  }

  const saveOrderChange = (localOrder) => {
    //console.log("Save changes", localOrder)
    localOrder.fromDate = getDateFormat(localOrder.fromDate)
    localOrder.toDate = getDateFormat(localOrder.toDate)
    axios.patch(config.server+'snowClearingOrders/change/order/'+config.clubId, {
      order: localOrder
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},      
    })
    .then(res => {
      localOrder.fromDate = moment(localOrder.fromDate, "DD.MM.YYYY").toDate()
      localOrder.toDate = moment(localOrder.toDate, "DD.MM.YYYY").toDate()
      myDataDispatch({type: 'CHANGE_SC_ORDER', order: localOrder});
      setOpen(false);
      setLocalOrder(INIT);
      setEditId(0);
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {

    // Set max date for booking
    // Forward booking parameter
    var bookForward = parseInt(getConfig(config,"SNOWCLEARING_BOOK_FORWARD"));
    var maxBookingDate = moment();
    if(isNaN(bookForward)) {
      maxBookingDate.add(365, 'd');
    } else {
      maxBookingDate.add(bookForward, 'd');
    }

    // Set date limitations (if any)
    const limitString = getConfig(config,"SNOWCLEARING_PERIOD_LIMIATIONS");
    if(limitString) {
      const limits = limitString.split(";");
      const newBookingLimits = {...bookingLimits};
      limits.map(l => {

        // Winter holiday
        if(l=="WINTER") {
          newBookingLimits.winterLimit=true;
          const startDate = moment().isoWeek(6).startOf('isoweek').add(3,'d');
          const endDate = moment().isoWeek(10).endOf('isoweek');
          newBookingLimits.winter = [startDate.format(), endDate.format()];
          if(maxBookingDate.isBefore(endDate,'day') && (maxBookingDate.isSame(startDate,'day') || maxBookingDate.isAfter(startDate,'day'))) {
            maxBookingDate=moment(endDate.format());
          }
        } 
        
        // Christmas holiday
        if(l=="XMAS") {
          newBookingLimits.xmasLimit=true;
          const baseline = moment(moment().year()+"-12-22");
          const startCorrection = baseline.isoWeekday()<WEEKEND_START_DAY?(baseline.isoWeekday()+WEEKEND_START_DAY-1):(baseline.isoWeekday()-WEEKEND_START_DAY);
          const startDate = moment(moment().year()+"-12-22").add(startCorrection*-1, 'd');
          const endCorrection = 7 + (7 - baseline.isoWeekday());
          const endDate = moment(moment().year()+"-12-22").add(endCorrection,'d');
          newBookingLimits.xmas = [startDate.format(), endDate.format()];
          if(maxBookingDate.isBefore(endDate,'day') && (maxBookingDate.isSame(startDate,'day') || maxBookingDate.isAfter(startDate,'day'))) {
            maxBookingDate=moment(endDate.format());
            console.log("Max date changed", maxBookingDate)
          }
        }
        
        // Easter
        if(l=="EASTER") {
          newBookingLimits.easterLimit=true;
          const baseline = easterDate(moment().year());
          const startDate = moment(baseline).add(-10,'d');
          const endDate = moment(baseline).add(1,'d');
          newBookingLimits.easter = [startDate.format(), endDate.format()];
          if(maxBookingDate.isBefore(endDate,'day') && (maxBookingDate.isSame(startDate,'day') || maxBookingDate.isAfter(startDate,'day'))) {
            maxBookingDate=moment(endDate.format());
            console.log("Max date changed", maxBookingDate)
          }
        } 

        // Set weekday booking limits
        if(l.slice(0,1)=="W" && l!="WINTER") {
          newBookingLimits.weekdayLimit = true;
          let openStr = l.slice(1).split("");
          openStr.map(day => {
            newBookingLimits.weekdays[(day-1)]=false;
          })   
        }       
      })

      // Set the result in state
      setBookingLimits(newBookingLimits);
    }

    setMaxDate(maxBookingDate.format());
    //console.log(newBookingLimits, maxBookingDate);

    // Check for unpaied invoices
    myData.invoices.map(inv => {
      if(inv.open_amount>0) {
        setInvoicesPaied(false)
      }
    })
  }, [])

  useEffect(() => {
    if(editId==0) {
      setLocalOrder(INIT)
    } else {
      var rec = myData.scOrders.find(sc => sc.id==editId)
      setLocalOrder({
        id: rec.id,
        memberId: rec.memberId,
        propertyId: rec.propertyId,
        regTime: rec.regTime,
        fromDate: rec.fromDate, 
        fromTime: rec.fromTime,
        toDate: rec.toDate, 
        comment: rec.comment        
      })
    }
  }, [editId])
  
  ////////////////////
  // Action handlers
  ////////////////////

  const closeDialog = () => {
    setOpen(false);
  }

  const handleSave = () => {
    //console.log("Save")
    if(localOrder.id==0) {
      // New order
      saveNewOrder(localOrder);
    } else {
      // Change order
      saveOrderChange(localOrder);
    }
  }

  const handleSetFromDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setLocalOrder({...localOrder, fromDate: selectedDate.toDate()})
    } else {
      setLocalOrder({...localOrder, fromDate: null})
    }
  }

  const handleSetToDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setLocalOrder({...localOrder, toDate: selectedDate.toDate()})
    } else {
      setLocalOrder({...localOrder, toDate: null})
    }    
  }

  const handleFromDateError = (e) => {
    if(e || localOrder.fromDate=='') {
      console.log("from date OK")
      setDateFromValid(false)
    } else {
      setDateFromValid(true)
    }
  }

  const handleToDateError = (e) => {
    if(e || localOrder.toDate=='') {
      console.log("to date OK")
      setDateToValid(false)
    } else {
      setDateToValid(true)
    }
  }

  // Determine if a day is valid for booking snow clearing
  const handleDisableDate = (day) => {
    //console.log(bookingLimits)

    //console.log(day)
    //console.log(day.isAfter(bookingLimits.xmas[0] ,'day'))
    //console.log(day.isSame(bookingLimits.xmas[0] ,'day'))
    //console.log(day.isBefore(bookingLimits.xmas[1] ,'day'))
    //console.log(day.isSame(bookingLimits.xmas[1] ,'day'))

    // Xmas check
    if(bookingLimits.xmasLimit && 
      (day.isAfter(bookingLimits.xmas[0] ,'day') || day.isSame(bookingLimits.xmas[0] ,'day')) &&
      (day.isBefore(bookingLimits.xmas[1] ,'day') || day.isSame(bookingLimits.xmas[1] ,'day'))) {
      //console.log("Christmas rule applies")
      return false;
    } 

    // Winter holiday check
    if(bookingLimits.winterLimit && 
      (day.isAfter(bookingLimits.winter[0] ,'day') || day.isSame(bookingLimits.winter[0] ,'day')) &&
      (day.isBefore(bookingLimits.winter[1] ,'day') || day.isSame(bookingLimits.winter[1] ,'day'))) {
      //console.log("Winter rule applies")
      return false;
    }     

    // Easter holiday check
    if(bookingLimits.easterLimit && 
      (day.isAfter(bookingLimits.easter[0] ,'day') || day.isSame(bookingLimits.easter[0] ,'day')) &&
      (day.isBefore(bookingLimits.easter[1] ,'day') || day.isSame(bookingLimits.easter[1] ,'day'))) {
      //console.log("Easter rule applies")
      return false;
    }     

    // Regular week check 
    if(bookingLimits.weekdayLimit) {
      //console.log(day, day.isoWeekday())
      //console.log("Week limit applies")
      return(!bookingLimits.weekdays[(day.isoWeekday()-1)])  
    }

    // Default response if no limitations apply
    //console.log("Generally open")
    return false
  }

  const handleAccept = (date) => {
    //console.log(date)
  }

  const handleRenderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    //console.log(dayComponent)
    return dayComponent
  }

  const handleRifmFormatter = (str) => {
    //console.log(str)
    return str
  }

  ///////////
  // UI part
  ///////////

  const commentTF = allowComments ? <TextField required id="comment" label="Kommentar" value={localOrder.comment} onChange={(e) => setLocalOrder({...localOrder, comment:e.target.value})} /> : null
  const dateMessage = dateFromValid && dateToValid ? null : <Typography variant="subtitle1" gutterBottom>Ugylding dato</Typography>
  const deptMessage = invoicesPaied ? null : <Typography variant="subtitle1" style={{color: "red"}} gutterBottom>Ubetalt faktura</Typography>
  //const saveButton = dateFromValid && dateToValid && invoicesPaied ? <Button color="primary" onClick={handleSave}>Lagre</Button> : <Button color="primary" onClick={handleSave} disabled >Lagre</Button>
  const saveButton = invoicesPaied ? <Button color="primary" onClick={handleSave}>Lagre</Button> : <Button color="primary" onClick={handleSave} disabled >Lagre</Button>

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
      <div key={1}>
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Bestill brøyting</DialogTitle>
          <DialogContent>
            <div style={{display:"flex", flexDirection:"column", marginTop:"10px"}}>
            <Stack spacing={2} >
              <DatePicker
                disableToolbar
                disablePast
                required
                variant="inline"
                renderInput={(params) => <TextField {...params}/>}
                margin="normal"
                format="L"
                id="from_date"
                label="Ankomst"
                maxDate={maxDate}
                mask="__.__.____"
                value={(localOrder.fromDate=='' || localOrder.fromDate==null) ? null : localOrder.fromDate}
                onChange={handleSetFromDate}
                //onError={(e) => handleFromDateError(e)}  
                shouldDisableDate={(day) => handleDisableDate(day)}   
                onAccept={(date) => handleAccept(date)}
                //renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => handleRenderDay(day, selectedDate, dayInCurrentMonth, dayComponent)}
                rifmFormatter={(str) => handleRifmFormatter(str)}
                autoOk={true}     
              />
              <TextField required id="time" label="Ankomst tidspunkt" value={localOrder.fromTime} onChange={(e) => setLocalOrder({...localOrder, fromTime:e.target.value})} />
              <DatePicker
                disableToolbar
                disablePast
                required
                variant="inline"
                renderInput={(params) => <TextField {...params}/>}
                margin="normal"
                format="L"
                id="to_date"
                label="Avreise"
                maxDate={maxDate}
                mask="__.__.____"
                value={(localOrder.toDate=='' || localOrder.toDate==null) ? null : localOrder.toDate}
                onChange={handleSetToDate}    
                //onError={(e) => handleToDateError(e)}     
                shouldDisableDate={(day) => handleDisableDate(day)}   
                autoOk={true}      
              />
              {commentTF}
              {dateMessage}
              {deptMessage}
            </Stack>
            </div>
          </DialogContent>
          <DialogActions>
            {saveButton}
            <Button color="primary" onClick={closeDialog}>Avbryt</Button>
          </DialogActions>          
        </Dialog>        
      </div>
    </LocalizationProvider>
  )
}

export default OrderDialog;


