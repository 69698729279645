import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';
import { ConfigContext } from '../../contexts/ConfigContext'

import {getMembers} from '../../utils/Server'
import {getDateFormat} from '../../utils/Library'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const BoatSpaceAdm = () => {

  const INIT_BOAT_SPACE = {
    id: '',
    description: '',
    size: '',
    deposit: ''
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };  
  const { config, configDispatch } = useContext(ConfigContext);
  //const { members, memberDispatch } = useContext(MemberContext);
  const [ boatSpaces, setBoatSpaces ] = useState();
  const [ currentBoatSpace, setCurrentBoatSpace ] = useState(INIT_BOAT_SPACE);
  const [ dialogueOpen, setDialogueOpen ] = useState(false);
  const [ editState, setEditState ] = useState('ADD');
  const confirm = useConfirm();


  /////////////////////
  // Server interaction
  /////////////////////

  // Get all boat spaces for the club
  const getBoatSpaces = () => {
    axios.get(config.server+'boatspaces/all/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        setBoatSpaces(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  };

  // Save a new boat space
  const newBoatSpace = (bs) => {
    /*members.map(member => {
      if(member.id===bs.memberId) {
        bs.memberName=member.firstName+' '+member.lastName;
      }
    })*/
    axios.post(config.server+'boatspaces/new/'+config.clubId, {
      boatSpace: bs
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        currentBoatSpace.id = res.data.newId;
        setBoatSpaces([...boatSpaces, currentBoatSpace]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  };

  // Update existing boat space
  const updateBoatSpace = (bs) => {
    /*members.map(member => {
      if(member.id===bs.memberId) {
        bs.memberName=member.firstName+' '+member.lastName;
      }
    })*/
    axios.patch(config.server+'boatspaces/update/'+config.clubId, {
      boatSpace: bs
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        var newSpaces = boatSpaces.filter(n => n.id !== currentBoatSpace.id);
        setBoatSpaces([...newSpaces, currentBoatSpace]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  // Delete boat space
  const deleteBoatSpace = (id) => {
    axios.delete(config.server+'boatspaces/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        var newSpaces = boatSpaces.filter(n => n.id != id);
        setBoatSpaces([...newSpaces]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })            
  }

  ////////////////////
  // useEffect area
  // Load initial information (members and roles)
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Adm. båtplasser"});
    //getMembers(config, memberDispatch);
    getBoatSpaces();
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }
  }, [config.clubId])


  ////////////////////
  // Action handlers
  ////////////////////
  
  // Open dialogue to add a new boat space
  const handleAdd = () => {
    //console.log("Add");
    setCurrentBoatSpace(INIT_BOAT_SPACE);
    setEditState("ADD");
    setDialogueOpen(true);
  } 

  // Cancel the open dialogue
  const handleCancel = () => {
    setDialogueOpen(false);
    setCurrentBoatSpace(INIT_BOAT_SPACE);
  }  

  // Edit a boat space
  const handleEdit = (e) => {
    const selected = e.currentTarget.id;
    boatSpaces.map(space => {
      if(space.id == selected) {
        setCurrentBoatSpace(space);
        setEditState("CHG");
        setDialogueOpen(true);
      }
    })
  }

  // Mark a boat space as deleted
  const handleDelete = (e) => {
    const id = e.currentTarget.id
    //console.log(e.currentTarget.id);
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes denne båtplassen. Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        deleteBoatSpace(id);
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
  }


  // Set selected member
  /*const handleSelectedMember = (e) => {
    setCurrentBoatSpace({...currentBoatSpace, memberId: e.target.value})
  } */

  // Save new/changed/delete member including role information
  const handleSave = (action) => {
    //console.log(action);
    setDialogueOpen(false);
    if(action==='NEW') {
      newBoatSpace(currentBoatSpace);
    } else {
      updateBoatSpace(currentBoatSpace);
    }
    setCurrentBoatSpace(INIT_BOAT_SPACE);
  }

  ///////////
  // UI part
  ///////////

  // Dialogue heading
  const diaTitle = (editState==='ADD') ? (
    "Legg til ny båtplass"
  ) : (
    "Endre båtplass"
  );

  // Dialogue toolbar
  const diaToolbar = (editState==='ADD') ? (
      <DialogActions>
        <Button onClick={() => handleSave("NEW")} color="primary">
          Lagre
        </Button>
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
  ) : (
      <DialogActions>
        <Button onClick={() => handleSave("CHG")} color="primary">
          Lagre
        </Button>
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
  )

  // Pop-up dialogue NEW or CHANGE
  const dialogue = (
    <Dialog open={dialogueOpen} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{diaTitle}</DialogTitle>
      <DialogContent>
        <div style={{display:"flex", flexFlow:"flex-start"}}>
          <div style={{width: 200}}>
            <TextField
              key={1}
              required 
              margin="dense"
              id="header"
              label="Båtplass"
              fullWidth
              value={currentBoatSpace.description?currentBoatSpace.description:''}          
              onChange={(e) => setCurrentBoatSpace({...currentBoatSpace, description: e.target.value})}
              autoFocus
            /> 
            <TextField
              key={2}
              required 
              margin="dense"
              id="header"
              label="Størrelse"
              fullWidth
              value={currentBoatSpace.size?currentBoatSpace.size:''}          
              onChange={(e) => setCurrentBoatSpace({...currentBoatSpace, size: e.target.value})}
            /> 
            <TextField
              key={3} 
              margin="dense"
              id="header"
              label="Depositum"
              fullWidth
              value={currentBoatSpace.deposit?currentBoatSpace.deposit:''}          
              onChange={(e) => setCurrentBoatSpace({...currentBoatSpace, deposit: e.target.value})}
            /> 
          </div>
        </div>
      </DialogContent>
      {diaToolbar}
    </Dialog>
  );


  // Table content
  const tab = boatSpaces ? (
    boatSpaces.sort((a,b) => a.description > b.description ? 1 : -1).map(space => {

      const deleteButton = (space.memberId) ? (null) : (
        <Tooltip title="Slett denne båtplassen" aria-label="add">
          <DeleteIcon color="primary" fontSize="small" value={space.id} id={space.id} onClick={handleDelete} />
        </Tooltip>  
      ); 

      return (
        <TableRow key={space.id}>
          <TableCell>{space.description ? space.description : ''}</TableCell>
          <TableCell>{space.size ? space.size:''}</TableCell>
          <TableCell>{space.deposit ? space.deposit : ''}</TableCell>
          <TableCell>{space.memberName ? space.memberName + " siden " + getDateFormat(space.valid_from) : 'Ledig'}</TableCell>
          <TableCell>
            <Tooltip title="Endre infomrasjon om båtplassen" aria-label="add">
              <EditIcon color="primary" fontSize="small" value={space.id} id={space.id} onClick={handleEdit} />
            </Tooltip>
          </TableCell>
          <TableCell>
            {deleteButton}
          </TableCell>
        </TableRow> 
      )
    })
  ) : (
    null
  );

  return (
    <div>
      {dialogue}
      <Tooltip title="Legg til en ny båtplass" aria-label="add">
        <Fab color="primary" aria-label="add" >
            <AddIcon onClick={handleAdd}/>
        </Fab>
      </Tooltip>
      <TableContainer>
        <Table style={{minWidth: 650}} component={Paper} size="small" padding="none" >
          <TableHead>
            <TableRow>
              <TableCell>Båtplass</TableCell>
              <TableCell>Størrelse</TableCell>
              <TableCell>Depositum</TableCell>
              <TableCell>Utleid til</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tab}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default BoatSpaceAdm;