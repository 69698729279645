import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import { ConfigContext } from '../../contexts/ConfigContext'
import {ENCRYPT_KEY} from '../../layout/Navbar'
import {getDateFormat} from '../../utils/Library'
 
import { Typography, TableContainer, Table, Paper, TableCell, TableBody, TableRow, Button } from '@mui/material';
import queryString from 'query-string';

const DisplayFolder = (props) => {

  const { config, configDispatch } = useContext(ConfigContext);
  const [folders, setFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [folderId, setFolderId] = useState(null);

  /////////////////////
  // Server interaction
  /////////////////////

  const getFolders = () => {
    axios.get(config.server+'docsys/folders/all/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setFolders(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })   
  }
  
  const getOpenFolders = () => {
    axios.get(config.server+'docsys/folders/open/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setFolders(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })   
  }

  const getDocuments = () => {
    axios.get(config.server+'docsys/documents/all/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setDocuments(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })   
  }
  
  const getOpenDocuments = () => {
    axios.get(config.server+'docsys/documents/open/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setDocuments(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })   
  }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    let key = queryString.parse(props.location.search).refKey;
    let code = key.slice(-1);
    var folderId = 0;
    if(code==="R") {
      folderId=parseInt(key.substr(0,key.length-1))/ENCRYPT_KEY;
      getFolders();
      getDocuments();
    } else {
      folderId=parseInt(key)/ENCRYPT_KEY;
      getOpenFolders();
      getOpenDocuments();
    }
    setFolderId(folderId);
    //console.log("Get folders:", code, key, folderId)
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Vis mappe"});
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }
  }, [props.location.search])

  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  const showContent = (fid) => {
    if(folders) {

      return folders.filter(f => f.id == fid).map(folder => {

        //console.log(folder)

        // Recursive call to show sub-folders
        const subFolder = folders.sort((a,b) => a.name<b.name?1:-1).filter(sf => sf.parent_folder==folder.id).map(subFolder => {
          //console.log("Show sub folder ", subFolder.id)
          return showContent(subFolder.id)
        })

        const docs = documents ? (
          documents.filter(doc => doc.folder_id==folder.id).map(doc => {
            console.log(doc)
            const linkBtn = doc.filename ? <Button color="primary" href={config.server.substring(0, config.server.length - 1)+doc.filename} target="_blank">Dokument</Button> : null   
            return (
              <TableRow key={doc.id} style={{backgroundColor:"lightgrey"}}>
                <TableCell style={{paddingRight:"10px"}}>{doc.description}</TableCell>
                <TableCell style={{paddingRight:"10px",  width:"150px"}}>{getDateFormat(doc.date)}</TableCell>
                <TableCell style={{paddingRight:"10px",  width:"150px"}}>{linkBtn}</TableCell>
              </TableRow>
            )            
          })
        ):(null)

        return (
          <React.Fragment key={folder.id} >
            <Paper elevation={5} style={{margin:"5px", backgroundColor: "lightgrey", minWidth:"300px"}} >
              <Typography variant="h6">{folder.name}</Typography>
              <TableContainer component={Paper} >
                <Table size="small" padding="none" >
                  <TableBody>
                    {docs}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {subFolder}
          </React.Fragment>
        )
      })
    } else return null;
  }

  return (
      <div style={{display:"flex", flexDirection: "column", flexWrap: "wrap"}}>
        {showContent(folderId)}
      </div>
    )
  }

export default DisplayFolder;
