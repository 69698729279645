import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'

import moment from "moment";
import "moment/locale/nb";

import { ConfigContext } from '../../contexts/ConfigContext'
import { InvoiceContext } from '../../contexts/InvoiceContext'
import { AccountContext } from '../../contexts/AccountContext'
import { MemberContext } from '../../contexts/MemberContext'

import {getDateFormat} from '../../utils/Library'

import InvoiceConfig from './invoiceConfig'
import Notify from './notifiy'

import { TableContainer, Table, Tooltip, Paper, Fab, TableHead, TableRow, TableCell, TableBody, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useConfirm } from 'material-ui-confirm';

const InvoiceMainPage = () => {

  const { config, configDispatch } = useContext(ConfigContext);
  const { accounts } = useContext(AccountContext);
  const { invoice, invoiceDispatch } = useContext(InvoiceContext);
  const { members, memberDispatch } = useContext(MemberContext);

  const [activity, setActivity] = useState(null); // null = no activity, NEW = new invoice, CHG = changing invoice
  const [editItem, setEditItem] = useState(null); // The item no that will be edited

  const [openNotify, setOpenNotify] = useState(false);
  const confirm = useConfirm();

  /////////////////////
  // Server interaction
  /////////////////////

  // Get cost categories
  const getCostCategories = () => {
    axios.get(config.server+'invoice/config/costcategories/'+config.clubId, {
        'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        invoiceDispatch({type: 'SET_COST_CATEGORIES', cc: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  // Get cost category items
  const getCostCategoryItems = () => {
    axios.get(config.server+'invoice/config/costcategoryitems/'+config.clubId, {
        'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data)
        invoiceDispatch({type: 'SET_COST_CATEGORY_ITEMS', cci: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }  

  // Get existing invoice headers for current period 
  const getInvoiceHeaders = () => {
    axios.get(config.server+'invoice/invoice/header/'+config.clubId+'/'+accounts.selectedPeriod, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      invoiceDispatch({type: 'SET_INVOICE_HEADERS', invoices: res.data});
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  // Delete an invoice and all sub-information
  const deleteInvoice = (id) => {
    axios.delete(config.server+'invoice/invoice/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        invoiceDispatch({type: 'DELETE_INVOICE', invoiceId: id});
      })
      .catch(err => {
          console.log('Error: ',err)
      })                
  }

  // Get invoice config
  const getInvoiceConfig = () => {
    axios.get(config.server+'invoice/invoice/config/'+config.clubId+'/'+accounts.selectedPeriod, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      invoiceDispatch({type: 'SET_INVOICE_CONFIG', config: res.data});
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  // Get invoice members
  const getInvoiceMembers = () => {
    axios.get(config.server+'invoice/invoice/members/'+config.clubId+'/'+accounts.selectedPeriod, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      invoiceDispatch({type: 'SET_INVOICE_MEMBERS', members: res.data});
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  const getAllMembers = () => {
    axios.get(config.server+'members/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data)
      var activeMembers = res.data.filter(m => m.deleted!='x');
      //setMembers(activeMembers);
      memberDispatch({type: 'SET_MEMBERS', members: activeMembers })
    })
    .catch(err => {
        console.log('Error: ',err)
    })               
  }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Medlemskontingent"});
    getCostCategories();
    getCostCategoryItems();
    getAllMembers();
    getInvoiceHeaders();
    getInvoiceConfig();
    getInvoiceMembers();
    //getInvoiceMemberItems();
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }
  }, [])


  ////////////////////
  // Action handlers
  ////////////////////

  const handleAddInvoice = () => {
    setActivity("NEW");
    //console.log("Add invoice")
  }

  const handleEdit = (e) => {
    setEditItem(e.currentTarget.id);
    setActivity("CHG");
  }

  const handleDisplay = (e) => {
    setEditItem(e.currentTarget.id);
    setActivity("DSP");

  }

  const handleDelete = (e) => {
    const invoiceId = e.currentTarget.id
    //console.log(invoiceId)
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes denne fakturaen. Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        deleteInvoice(invoiceId)
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });        
  }

  const handleNotify = (e) => {
    // Open notification dialog
    setEditItem(e.currentTarget.id)
    setOpenNotify(true)
  }

  ///////////
  // UI part
  ///////////

  // Add button
  const addBtn = activity==null ? (
    <Tooltip title="Opprett ny faktura" aria-label="add">
      <Fab color="primary" aria-label="add" size="small">
          <AddIcon onClick={handleAddInvoice}/>
      </Fab>
    </Tooltip> 
  ) : null;

  // This shows the current period and the existing invoices for this period
  //console.log(invoice, invoice.invoiceHeaders);
  const invoiceOverviewitems = invoice.invoiceHeaders ? (
    invoice.invoiceHeaders.sort((a,b) => a.id>b.id ? 1 : -1).map(inv => {
      const dspBtn = inv.status==5 ? (<TableCell><VisibilityIcon color="primary" id={inv.id} onClick={handleDisplay} /></TableCell>) : null;
      const notifyBtn = inv.status==5 ? (<TableCell><Tooltip title="Send påminnelse/purring"><NotificationsIcon color="primary" id={inv.id} onClick={handleNotify} /></Tooltip></TableCell>) : (null);
      const chgBtn = inv.status<=4 && !activity ? (<TableCell><EditIcon color="primary" id={inv.id} onClick={handleEdit} /></TableCell>) : null;
      const delBtn = inv.status<=3 && !activity ? (<TableCell><DeleteIcon color="primary" id={inv.id} onClick={handleDelete} /></TableCell>) : null;
      const statusObj = invoice.invoiceSteps.find(is => is.id==inv.status);
      return (
        <TableRow key={inv.id}>
          <TableCell>{inv.id}</TableCell>
          <TableCell>{inv.description}</TableCell>
          <TableCell>{getDateFormat(inv.due_date)}</TableCell>
          <TableCell>{statusObj.text}</TableCell>
          {chgBtn}
          {delBtn}
          {dspBtn}
          {notifyBtn}
        </TableRow>        
      )
    })
  ) : (
    null
  );

  // Invoice overview 
  // Only show this if there is no invoice "in process"
  const invoiceOverview = activity ? (
    null
  ) : (
    <Paper elevation={5} style={{margin:"10px", backgroundColor: "lightgrey"}}>
      {addBtn}
      <Typography variant="h6">{accounts.allPeriods.filter(per => per.id==accounts.selectedPeriod).description}</Typography>         
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Fakturatekst</TableCell>
              <TableCell>Forfallsdato</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceOverviewitems}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const invoiceStepper = activity ? (
    <InvoiceConfig activity={activity} setActivity={setActivity} editItem={editItem} />
  ) : null;

  return (
      <div>
        <Notify open={openNotify} setOpen={setOpenNotify} item={editItem} setItem={setEditItem} />
        <div>
          {invoiceOverview}
        </div>
        {invoiceStepper}
      </div>
    )
  }

export default InvoiceMainPage;
