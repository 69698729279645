import React, {useEffect, useState, useContext} from 'react'
import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'

import {getMembers} from '../../utils/Server'
import {dynamicSortNorm, getDateFormat, getConfig} from '../../utils/Library'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Memberlist = () => {
  const { config, configDispatch } = useContext(ConfigContext);
  const { members, memberDispatch } = useContext(MemberContext);
  const [sortField, setSortField] = useState("sortName");


  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Medlemsliste"});
    getMembers(config, memberDispatch);
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }
  }, [config.clubId])

  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  // Boat space column - types "H" = Header, all others are Items
  const bsColumn = (bsText, type) => {
    if(getConfig(config, "CLUB_BOATS")!=1) {
      return null
    }
    if(type==="H") {
      return <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("boatspace")}>Båtplass</TableCell>
    }
    //console.log(bsText);
    return <TableCell style={{fontSize:"12px"}}>{bsText}</TableCell>
  }

  // El spot column - types "H" = Header, all others are Items
  const esColumn = (esText, type) => {
    if(getConfig(config, "CLUB_EL_POINTS")!=1) {
      return null
    }
    if(type==="H") {
      return <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("elpoint")}>El. punkt</TableCell>
    }
    return <TableCell style={{fontSize:"12px"}}>{esText}</TableCell>
  }

  // Cabin column - types "H" = Header, all others are Items
  const cabColumn = (cabText, type) => {
    if(getConfig(config, "CLUB_CABINS")!=1) {
      return null
    }
    if(type==="H") {
      return <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("cabin")}>Hytte</TableCell>
    }
    return <TableCell style={{fontSize:"12px"}}>{cabText}</TableCell>
  }

  // Check and filter for boat club waiting list
  // Removes members without a boatspace from the list if the waiting list param is on
  const memberFilter = (members) => {
    if(getConfig(config, "CLUB_BOATS")==="1" && getConfig(config, "CLUB_WAITING_LIST")==="1") {
      return members.filter(m => m.boatspace ? m : null).sort((a,b) => a.boatspace>b.boatspace ? 1 : -1)
    } else {
      return members;
    }
  }

  // Check and filter for boat club waiting list
  // Removes members without a boatspace from the list if the waiting list param is on
  const waitingListFilter = (members) => {
    if(getConfig(config, "CLUB_BOATS")==="1" && getConfig(config, "CLUB_WAITING_LIST")==="1") {
      return members.filter(m => m.boatspace ? null : m)
    } else {
      return [];
    }
  }


  // Lists the member table
  const memberTable = members ? (
    //memberFilter(members).filter(m => (m.deleted!=='x')).sort((a,b) => a.lastName>b.lastName?1:-1).map(member => {
    memberFilter(members).filter(m => (m.deleted!=='x')).sort(dynamicSortNorm(sortField)).map(member => {
      const rowColor = (member.amount_due && member.amount_due!=0) ? "pink" : "white";
      if(member.id==1007) {
        console.log(member)
      }
      return (
        <TableRow key={member.id} style={{backgroundColor: rowColor}}>
          <TableCell style={{fontSize:"12px"}}>{member.companyName && member.companyName.length>0 ? member.companyName : member.name}</TableCell>
          <TableCell style={{fontSize:"12px"}}>{member.fullAddress ? member.fullAddress :null}</TableCell>
          <TableCell style={{fontSize:"12px"}}>{member.mobile}</TableCell>
          <TableCell style={{fontSize:"12px"}}>{member.eMail}</TableCell>
          <TableCell style={{fontSize:"12px"}}>{member.regDate ? getDateFormat(member.regDate) : null}</TableCell>
          {bsColumn(member.boatspace, "I")}
          {esColumn(member.elpoint, "I")}
          {cabColumn(member.cabin, "I")}
        </TableRow>
      )
    })
  ):(
    <span>Ingen medlemmer</span>
  );

  // Waiting list members
  const waitingListMembers = members ? (
    members.filter(m => m.boatspace ? null : m).filter(m => (m.deleted!=='x')).sort((a,b) => a.regDate>b.regDate ? 1:-1).map((member, index) => {
      //var cnt = 1;
      return (
        <TableRow key={member.id}>
          <TableCell style={{fontSize:"12px"}}>{index+1}</TableCell>
          <TableCell style={{fontSize:"12px"}}>{member.name ? member.name : member.companyName}</TableCell>
          <TableCell style={{fontSize:"12px"}}>{getDateFormat(member.regDate)}</TableCell>
          <TableCell style={{fontSize:"12px"}}>{member.mobile}</TableCell>
          <TableCell style={{fontSize:"12px"}}>{member.eMail}</TableCell>
        </TableRow>   
      )   
    })
  ) : (
    <span>Ingen på venteliste</span>
  );

  // Waiting list UX if applicabale
  const waitingList = (getConfig(config, "CLUB_BOATS")==="1" && getConfig(config, "CLUB_WAITING_LIST")==="1") ? (
    <div>
      <br/>
      <br/>
      <span style={{fontWeight: "bold"}} >Venteliste</span>
      <TableContainer component={Paper}>
        <Table style={{minWidth: 400}} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{backgroundColor: "silver"}} >
              <TableCell style={{fontSize:"12px"}}>Nr</TableCell>
              <TableCell style={{fontSize:"12px"}}>Navn</TableCell>
              <TableCell style={{fontSize:"12px"}}>Reg. dato</TableCell>
              <TableCell style={{fontSize:"12px"}}>Mobil</TableCell>
              <TableCell style={{fontSize:"12px"}}>ePost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waitingListMembers}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : (
    null
  );


  // Core UX
  return (
    <div>
      <TableContainer component={Paper}>
        <Table style={{minWidth: 400}} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow style={{backgroundColor: "silver"}} >
              <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("sortName")}>Navn</TableCell>
              <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("fullAddress")}>Hjemme adresse</TableCell>
              <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("mobile")}>Mobil</TableCell>
              <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("eMail")}>ePost</TableCell>
              <TableCell style={{fontSize:"12px"}} onClick={() => setSortField("regDate")}>Medlem siden</TableCell>
              {bsColumn(null, "H")}
              {esColumn(null, "H")}
              {cabColumn(null, "H")}
            </TableRow>
          </TableHead>
          <TableBody>
            {memberTable}
          </TableBody>
        </Table>
      </TableContainer>
      {waitingList}
    </div>
  )
}

export default Memberlist;