import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import { Paper, Button, Typography, TextField, Tooltip, TableHead, TableContainer, Table, TableBody, TableRow, TableCell, Fab, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import { ConfigContext } from '../../contexts/ConfigContext'
import {getConfig, getConfigVal} from './../../utils/Library'

const GateConfig = ({saveAll}) => {

  const INIT_NUMBER = {
    id: null,
    number: '',
    description: ''
  }
  const { config, configDispatch } = useContext(ConfigContext);
  const [gateNumbers, setGateNumbers] = useState([]);
  const [open, setOpen] = useState(false);
  const [newNumber, setNewNumber] = useState(INIT_NUMBER);
 
  /////////////////////
  // Server interaction
  /////////////////////

  const getGenericGateNumbers = () => {
    axios.get(config.server+'gate/club/all/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        setGateNumbers(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  const addNumber = (newNumber) => {
    axios.post(config.server+'gate/club/new/'+config.clubId, {
      newNumber: newNumber
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        newNumber.id = res.data.newId;
        setGateNumbers([...gateNumbers, newNumber]);
        setOpen(false);
        setNewNumber(INIT_NUMBER);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  const deleteNumber = (id) => {
    axios.delete(config.server+'gate/club/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        var newList = gateNumbers.filter(n => n.id != id);
        setGateNumbers([...newList]);
      })
      .catch(err => {
          console.log('Error: ',err)
      })               
  }

  ////////////////////
  // useEffect area
  ////////////////////

  useEffect(() => {
    //console.log("Henter nummer som åpner bommen")
    getGenericGateNumbers()
  }, []) 
  
  ////////////////////
  // Action handlers
  ////////////////////
  const handleConfigChange = (e) => {
    configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: e.target.value})
  }

  const handleDelete = (e) => {
    deleteNumber(e.currentTarget.id)
  }

  const handleAdd = (e) => {
    setOpen(true);
  }

  const handleCancel = (e) => {
    setOpen(false);
    setNewNumber(INIT_NUMBER);
  }

  const handleSave = () => {
    addNumber(newNumber);
  }

  ///////////
  // UI part
  ///////////

  // Pop-up dialog to add a new number
  const dialog = (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Legg til telefonnummer</DialogTitle>
      <DialogContent>
        <div style={{display:"flex", flexFlow:"flex-start"}}>
          <div style={{width: 200}}>
            <TextField
              key={1}
              required 
              margin="dense"
              id="header"
              label="Telefonnummer"
              fullWidth
              value={newNumber.number}          
              onChange={(e) => setNewNumber({...newNumber, number: e.target.value})}
              autoFocus
            /> 
            <TextField
              key={2}
              required 
              margin="dense"
              id="header"
              label="Beskrivelse/Eier"
              fullWidth
              value={newNumber.description}          
              onChange={(e) => setNewNumber({...newNumber, description: e.target.value})}
            /> 
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave()} color="primary">
          Lagre
        </Button>
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
    </Dialog>
  );

  const numberList = gateNumbers ? (
    gateNumbers.map(nmbr => {

      const deleteButton = (
        <Tooltip title="Slett nummeret" aria-label="add">
          <DeleteIcon color="primary" fontSize="small" value={nmbr.id} id={nmbr.id} onClick={handleDelete} />
        </Tooltip>  
      ); 

      return (
        <TableRow key={nmbr.id}>
          <TableCell>{nmbr.number}</TableCell>
          <TableCell>{nmbr.description}</TableCell>
          <TableCell>{deleteButton}</TableCell>
        </TableRow>
      )
    })
  ):(
    null
  )

  return (
      <div>     
        {dialog}
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", flexWrap:"wrap" }} >
          <div style={{order:2, maxWidth:"500px" }}>
          <Paper elevation={6} style={{margin: "15px", padding: "10px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Konfigurering knyttet til veibom</Typography>
            <Tooltip title="Bomkontroller er typen styringsenhet som er installert i bommen. Ulike styringsenheter benytter ulike kommandosett for å oppdatere bommen" >
              <TextField
                  key={501} required margin="dense" id="ctrl" label="Bom kontroller" name="GATE_CONTROL_TYPE"
                  disabled={parseInt(config.accessLevel)===999 ? false:true}
                  fullWidth value={getConfigVal(config, "GATE_CONTROL_TYPE")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Antall telefonnummer som hvert medlem kan registrere" >
              <TextField
                  key={502} required margin="dense" id="nonr" label="Antall telefonnummer" name="GATE_NO_OF_USER_NUMBERS"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  fullWidth value={getConfigVal(config, "GATE_NO_OF_USER_NUMBERS")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Telefonnummeret til bommen" >
              <TextField
                  key={503} required margin="dense" id="gatenr" label="Telefonnummeret til bommen" name="GATE_PHONE_NUMBER"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  fullWidth value={getConfigVal(config, "GATE_PHONE_NUMBER")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Kommandoer til bommen sendes fra dette nummeret (simulert)" >
              <TextField
                  key={504} required margin="dense" id="nonr" label="Administrator nummer" name="GATE_ADMIN_PHONE_NUMBER"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  fullWidth value={getConfigVal(config, "GATE_ADMIN_PHONE_NUMBER")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Administrator kode til bommen" >
              <TextField
                  key={505} required margin="dense" id="nonr" label="Admin kode" name="GATE_ADMIN_CODE"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  fullWidth value={getConfigVal(config, "GATE_ADMIN_CODE")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>
            <Tooltip title="Informasjonsteksten som vises på 'Min side'. Format: HTML" >
              <TextField
                  key={506} required margin="dense" id="info" label="Informasjonstekst" name="GATE_INFO_TEXT"
                  disabled={parseInt(config.accessLevel)>=99 ? false:true}
                  multiline
                  rowsMax={8}
                  fullWidth value={getConfigVal(config, "GATE_INFO_TEXT")}          
                  onChange={handleConfigChange}      
                />                       
            </Tooltip>       
            <Button color="primary" onClick={(e) => saveAll(e)}>
              Lagre
            </Button>                         
          </Paper>                      
          </div>

          <div style={{order:1, width:"400px"}}>
          <Paper elevation={6} style={{margin: "15px", padding: "10px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>Telefonnummer som åpner bommen</Typography>
            <Tooltip title="Legg til et nytt telefonnummer" aria-label="add">
              <Fab color="primary" size="small" aria-label="add" >
                <AddIcon onClick={handleAdd}/>
              </Fab>
            </Tooltip>   
            <TableContainer component={Paper}>
              <Table size="small" padding="none" >
                <TableHead>
                  <TableRow>
                    <TableCell>Telefonnummer</TableCell>
                    <TableCell>Beskrivelse/Eier</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {numberList}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          </div>
        </div>
      </div>
    )
  }

export default GateConfig;
