import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import {numberFormat} from '../../utils/Library'

import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper, TextField, InputAdornment} from '@mui/material'
import {TableCell, TableRow, Table, TableContainer, TableBody, TableHead} from '@mui/material'

import { ConfigContext } from '../../contexts/ConfigContext'
import { AccountContext } from '../../contexts/AccountContext'

const Budget = ({open, setOpen, reload, setReload}) => {

  const { config } = useContext(ConfigContext)
  const { accounts, accountDispatch } = useContext(AccountContext)
  const [ localBudget, setLocalBudget ] = useState([]) // Structure: [{account_id, budgetValue}, {account_id,budgetValue}]

  /////////////////////
  // Server interaction
  /////////////////////

  const saveBudget = (period, budget) => {
    const extBudget = budget.map(act => {
      const actGrpIdObj = accounts.accumulated.find(item => item.account_id==act.account_id && item.period_id==period && item.club_id==config.club_id)
      return {account_id: act.account_id, budgetValue: act.budgetValue, actGrpId: actGrpIdObj.account_group_id} 
    })
    axios.post(config.server+'accounting/budget/save/'+config.clubId, {
      period: period,
      newBudget: extBudget
    },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data)
      setReload(true)
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }  

  ////////////////////
  // Hooks area
  ////////////////////
  /*useEffect(() => {
    console.log(accounts)
    return () => {
    }
  }, [accounts])*/


  ////////////////////
  // Action handlers
  ////////////////////
  const handleSave = () => {
    saveBudget(accounts.selectedPeriod, localBudget)
    setLocalBudget([])
    setOpen(false)
  }

  const handleClose = () => {
    setLocalBudget([])
    setOpen(false)
  }

  const handleChange = (e) => {
    //console.log(e.target.id, e.target.value)
    const newLocalBudget = localBudget.filter(lb => lb.account_id!=e.target.id)
    newLocalBudget.push({account_id:e.target.id, budgetValue:e.target.value})
    setLocalBudget(newLocalBudget) 
  }


  ///////////
  // UI part
  ///////////

  const periodStr = accounts && accounts.selectedPeriod ? accounts.allPeriods.find(p => p.id==accounts.selectedPeriod).description : 'ukjent periode'
  const prevPeriods =  accounts && accounts.selectedPeriod ? accounts.allPeriods.filter(p => p.id<accounts.selectedPeriod).sort((a,b) => a.id>b.id ? -1 : 1) : null
  const prevPeriod = prevPeriods && prevPeriods.length>0 ? prevPeriods.reduce((a, b) => (a.start_date > b.start_date ? a : b)) : null
  const prevPeriodBudgetIncome =  accounts && prevPeriod ? 
        accounts.accumulated.filter(act => act.period_id==prevPeriod.id && (act.account_group_id==3 ||act.account_group_id==5)).reduce((a,b) => typeof b.period_budget === 'number' ? a+b.period_budget : a, 0) 
        : 0
  const prevPeriodBudgetCost =  accounts && prevPeriod ? accounts.accumulated.filter(act => act.period_id==prevPeriod.id && (act.account_group_id>3 && act.account_group_id!=5)).reduce((a,b) => typeof b.period_budget === 'number' ? a+b.period_budget : a, 0) : 0
  const prevPeriodActualIncome =  accounts && prevPeriod ? accounts.accumulated.filter(act => act.period_id==prevPeriod.id && (act.account_group_id==3 ||act.account_group_id==5)).reduce((a,b) => typeof b.amount === 'number' ? a+b.amount : a, 0) : 0
  const prevPeriodActualCost =  accounts && prevPeriod ? accounts.accumulated.filter(act => act.period_id==prevPeriod.id && (act.account_group_id>3 && act.account_group_id!=5)).reduce((a,b) => typeof b.amount === 'number' ? a+b.amount : a, 0) : 0

  const budgetIncome = accounts && accounts.selectedPeriod ? accounts.accumulated.filter(act => act.period_id==accounts.selectedPeriod && (act.account_group_id==3 ||act.account_group_id==5)).reduce((a,b) => localBudget.find(bn => bn.account_id==b.account_id) ? a+Math.abs(parseFloat(localBudget.find(bn => bn.account_id==b.account_id).budgetValue)) : (typeof b.period_budget === 'number' ? a+Math.abs(parseFloat(b.period_budget)) : a), 0) : 0
  const budgetCost = accounts && accounts.selectedPeriod ? accounts.accumulated.filter(act => act.period_id==accounts.selectedPeriod && (act.account_group_id>3 && act.account_group_id!=5)).reduce((a,b) => localBudget.find(bn => bn.account_id==b.account_id) ? a+Math.abs(parseFloat(localBudget.find(bn => bn.account_id==b.account_id).budgetValue)) : (typeof b.period_budget === 'number' ? a+Math.abs(parseFloat(b.period_budget)) : a), 0) : 0

  const totalRow = (
    <TableRow key={10000}>
    <TableCell colSpan={2} sx={{paddingRight:"15px"}}>Resultat</TableCell>
    <TableCell sx={{paddingRight:"15px", backgroundColor: "lightgrey"}} align="right">{numberFormat((prevPeriodBudgetIncome*-1) - prevPeriodBudgetCost)}</TableCell>
    <TableCell sx={{paddingRight:"15px", backgroundColor: "lightgrey"}} align="right">{numberFormat((prevPeriodActualIncome*-1) - prevPeriodActualCost)}</TableCell>
    <TableCell sx={{width:"100px"}} align="right">{numberFormat(budgetIncome-budgetCost)}</TableCell>
  </TableRow>      
  )

  const actOverview = accounts ? accounts.accumulated.filter(act => act.period_id==accounts.selectedPeriod && act.account_group_id>2).sort((a,b) => a.account_id>b.account_id?1:-1).map(act => {
    const refPeriod = prevPeriod ? accounts.accumulated.find(a => a.period_id==prevPeriod.id && a.account_id==act.account_id) : null
    const newBudget = localBudget.find(bn => bn.account_id==act.account_id)
    const inputComponent = (
      <TextField 
        id={act.account_id.toString()}
        hiddenLabel
        variant="filled"
        size="small"
        inputProps={{min: 0, sx: { textAlign: 'right' }}}
        InputProps={{
          startAdornment: <InputAdornment position="start">kr  </InputAdornment>
        }}        
        value={newBudget ? Math.abs(newBudget.budgetValue) : (act.period_budget ? Math.abs(act.period_budget) : 0)}
        onChange={handleChange}
      />)
    return (
      <TableRow key={act.account_id}>
        <TableCell sx={{paddingRight:"15px"}}>{act.account_id}</TableCell>
        <TableCell sx={{paddingRight:"15px"}}>{act.account_name}</TableCell>
        <TableCell sx={{paddingRight:"15px", backgroundColor: "lightgrey"}} align="right">{numberFormat(refPeriod && Math.abs(refPeriod.period_budget) ? Math.abs(refPeriod.period_budget) : 0)}</TableCell>
        <TableCell sx={{paddingRight:"15px", backgroundColor: "lightgrey"}} align="right">{numberFormat(refPeriod && Math.abs(refPeriod.amount) ? Math.abs(refPeriod.amount) : 0)}</TableCell>
        <TableCell sx={{width:"120px"}} align="right">{inputComponent}</TableCell>
      </TableRow>      
    )
  }) : null
  
  return (
    <Dialog open={open} maxWidth={false} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Budsjett for {periodStr}</DialogTitle>
      <DialogContent>
        <Paper>
          <TableContainer component={Paper}>
            <Table style={{minWidth: 400 }} size="small" padding="none"> 
              <TableHead>
              <TableRow>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell sx={{backgroundColor: "silver", padding:"2px"}} colSpan={2} align="center">Forrige periode</TableCell>
                  <TableCell></TableCell>
                </TableRow>              
                <TableRow sx={{backgroundColor: "silver"}} >
                  <TableCell></TableCell>
                  <TableCell>Konto</TableCell>
                  <TableCell sx={{paddingLeft:"15px", paddingRight:"15px"}} align="right">Budsjett</TableCell>
                  <TableCell sx={{paddingLeft:"15px", paddingRight:"15px"}} align="right">Faktisk</TableCell>
                  <TableCell align="center">Budsjett</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actOverview}
                {totalRow}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSave}>
          Lagre
        </Button>
        <Button color="primary" onClick={handleClose}>
          Avbryt
        </Button>
        </DialogActions>                
    </Dialog>
  )
}

export default Budget