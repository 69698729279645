import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';
import {numberFormat, getDateFormat} from '../../utils/Library'

import { ConfigContext } from '../../contexts/ConfigContext'
import { AccountContext } from '../../contexts/AccountContext'

import Payment from './payment'

import { TableContainer, Table, TableHead, TableRow, TableCell, Paper, Typography, TableBody, Tooltip, IconButton } from '@mui/material'
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import PaidIcon from '@mui/icons-material/Paid';

const AccountsPayable = () => {
  const { config, configDispatch } = useContext(ConfigContext);
  const { accounts } = useContext(AccountContext);
  const [arOverview, setAROverview] = useState([]);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [selectedAR, setSelectedAR] = useState(null);
  const confirm = useConfirm();

  /////////////////////
  // Server interaction
  /////////////////////
  const getAROverview = () => {
    axios.get(config.server+'accounting/account/ar/overview/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        console.log(res.data)
        setAROverview(res.data)
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  const savePaymentCancellation = (item) => {
    //console.log(arOverview, clDoc);

    var bodyFormData = new FormData();
    bodyFormData.set('clubId',  config.clubId);
    bodyFormData.set('date',    getDateFormat(new Date()));
    bodyFormData.set('description', "Kansellering av fordring, "+item.name);
    bodyFormData.set('amount',  item.open_amount);
    bodyFormData.set('fromAct', item.income_account_id);
    bodyFormData.set('toAct',   item.ar_account_id);
    bodyFormData.set('periodId',accounts.currentPeriod);
    bodyFormData.set('postingType', 41);
    bodyFormData.set('memberId', item.member_id);
    bodyFormData.set('invoiceId', item.invoice_id);
    bodyFormData.set('arId', item.id);
    //bodyFormData.append('attachmentFile', clDoc.docName)
    
    //console.log(bodyFormData);

    
    axios({
      method: 'post',
      url: config.server+'accounting/document/new/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        if(res.status===200) {
          let newOverview = arOverview.filter(ar => ar.id!==item.id);
          let newItem = arOverview.filter(ar => ar.id===item.id);
          newItem[0].open_amount -= item.open_amount;
          if(newItem[0].open_amount!=0) {
            newOverview.push(newItem[0])
          }
          setAROverview(newOverview);
        }
      })
      .catch(res => {
          //handle error
          console.log(res);
      })
  }  


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Utestående fordringer"});
    getAROverview()
  }, []);
  
  ////////////////////
  // Action handlers
  ////////////////////

  const handlePayment = (e) => {
    const refId = e.currentTarget.id;

    setSelectedAR(refId)
    setPaymentOpen(true)
  }

  const handleCancellation = (e) => {
    const refId = e.currentTarget.id;
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes denne fordringen. Når en fordring slettes så føres det som en reversering av den opprinnelige fakturaen, der konto for inntekt reduseres og motposten er konto for utestående fordringer. Merk at det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        // Handle payment cancellation
        //console.log("Sletter id "+refId) 
        //setSelectedAR(refId)
        let item = arOverview.find(ar => ar.id==refId);
        //console.log(item)
        savePaymentCancellation(item)
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
  }


  ///////////
  // UI part
  ///////////

  const arContent = arOverview ? (
    arOverview.sort((a,b) => a.lastName>b.lastName ? 1 : -1).map(ar => {
      return (
        <TableRow key={ar.id}>
          <TableCell>{ar.name}</TableCell>
          <TableCell>
            <Tooltip title="Registrer innbetaling">
              <IconButton id={ar.id} onClick={(e) => handlePayment(e)}><PaidIcon color="primary" /></IconButton>
            </Tooltip>
          </TableCell>          
          <TableCell>{ar.invoice_id}</TableCell>
          <TableCell>{ar.description}</TableCell>
          <TableCell>{ar.bank_account_no}</TableCell>
          <TableCell>{getDateFormat(ar.due_date)}</TableCell>
          <TableCell align="right">{numberFormat(ar.amount)}</TableCell>
          <TableCell align="right">{numberFormat(ar.open_amount)}</TableCell>
          <TableCell>
            <Tooltip title="Registrer innbetaling">
              <IconButton id={ar.id} onClick={(e) => handlePayment(e)}><PaidIcon color="primary" /></IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Slett fordring">
              <IconButton id={ar.id} onClick={(e) => handleCancellation(e)}><DeleteIcon color="primary" /></IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      )
    })
  ) : null;

  return (
      <div>
        <Payment open={paymentOpen} setOpen={setPaymentOpen} selectedAR={selectedAR} setSelectedAR={setSelectedAR} arOverview={arOverview} setAROverview={setAROverview} />
        <Paper>
          <Typography variant="h6">Fakturert, ikke betalt</Typography>
          <TableContainer component={Paper}>
            <Table padding="none">
              <TableHead>
                <TableRow>
                  <TableCell>Medlem</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Faktura nr</TableCell>
                  <TableCell>Beskrivelse</TableCell>
                  <TableCell>Konto</TableCell>
                  <TableCell>Forfallsdato</TableCell>
                  <TableCell align="right">Totalbeløp</TableCell>
                  <TableCell align="right">Ikke betalt</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arContent}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        
      </div>
    )
  }

export default AccountsPayable;
