import React, { createContext, useReducer } from 'react'
import DmsReducer from '../reducers/DmsReducer'

export const DmsContext = createContext();
 
const DmsContextProvider = (props) => {
    const [dms, dmsDispatch] = useReducer(DmsReducer , {
      folders: [],
      selected: [],
      expanded: [],
      selectedFolderContent: [],
      act: "NEW",
      accessOptions: [{id: 1, description: 'Styret'},{id: 2, description: 'Medlemmer'},{id: 3, description: 'Alle'}]
    });

    return (
        <DmsContext.Provider value={{ dms, dmsDispatch}}>
            {props.children}
        </DmsContext.Provider>
    ); 
}

export default DmsContextProvider;