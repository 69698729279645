import React from 'react'

function NotFound() {
  return (
      <div>
          <h1>Ukjent klubb. Konfigurering mangler</h1>
      </div>
  );
}

export default NotFound;