import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'

import { ConfigContext } from '../../contexts/ConfigContext'
import { AccountContext } from '../../contexts/AccountContext'

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment, MenuItem, Select, InputLabel, FormControl, Stack, Typography} from '@mui/material';

const AddAttatchment = ({open, setOpen, docId, document}) => {

  const { config } = useContext(ConfigContext);
  const { accounts, accountDispatch } = useContext(AccountContext);
  const [attachment, setAttachment] = useState(null)

  /////////////////////
  // Server interaction
  /////////////////////
  const saveDocument = (attachment) => {
    //console.log(attachment);

    var bodyFormData = new FormData();
    bodyFormData.set('clubId',  config.clubId);
    bodyFormData.set('docId',    docId);
    bodyFormData.append('attachmentFile', attachment)

    axios({
      method: 'patch',
      url: config.server+'accounting/document/addAttachment/'+config.clubId,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+config.accessToken 
              }
      })
      .then(res => {
        //console.log(res)
        if(res.status===200) {
          const result = res.data;
          //document.id=result.docId;
          //console.log(res.data)
          accountDispatch({type: 'ADD_DOCUMENT_ATTATCHMENT', docId: result.docId, docName: result.docName});      
          //setReloadTr(!reloadTr);   
        }
      })
      .catch(res => {
          //handle error
          console.log(res);
      })
  }
  

  ////////////////////
  // Hooks area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleSave = () => {
    saveDocument(attachment);
    closeDialogue();
  }

  const handleFileAttachment = (e) => {
    setAttachment(e.target.files[0]);
  }

  const closeDialogue = () => {
    setOpen(false);
  }

  ///////////
  // UI part
  ///////////
   
  const preventSave = () => {
    return false
  }

  return (
        <Dialog open={open} onClose={closeDialogue} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Legg til vedlegg</DialogTitle>
          <DialogContent>
            <Typography variant="p">Last opp vedlegg til dokument nummer {docId}</Typography>
            <div style={{display:"flex", flexDirection:"column", marginTop:"10px"}}>
              <div>
                <InputLabel id="select-standard-label">Bilag / vedlegg</InputLabel>
                <TextField id="standard-required" type="File" onChange={(e) => handleFileAttachment(e)}/>
              </div>
            </div>
        
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSave} disabled={preventSave()}>
              Lagre
            </Button>
            <Button color="primary" onClick={closeDialogue}>
              Avbryt
            </Button>
          </DialogActions>          
        </Dialog>        
    )
}

export default AddAttatchment;
