import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import { MyDataContext } from '../../contexts/MyDataContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import { getConfig } from '../../utils/Library'

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox} from '@mui/material';

const ExtraUser = ({open, setOpen, selectedUserId, setSelectedUserId}) => {
  const { myData, myDataDispatch } = useContext(MyDataContext)
  const { config } = useContext(ConfigContext)
  const USER_INIT = {
    id: null,
    name: '',
    email: '',
    phone: '',
    password: '',
    sendEmail: '',
    sendSMS: '',
    canLogin: ''
  }
  const [localUser, setLocalUser] = useState(USER_INIT)  

  /////////////////////
  // Server interaction
  /////////////////////

    // Save a new user
    const newUser = (localUser) => {
      //console.log(localUser)
      axios.post(config.server+'members/extrausers/create/'+config.clubId+'/'+config.userId, {
        newUser: localUser
        },{
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
        })
        .then(res => {
          //console.log(res.data);
          localUser.id = res.data.id;
          myDataDispatch({type: 'ADD_EXTRA_USER', user: localUser});
        })
        .catch(err => {
            console.log('Error: ',err)
        })          
    };
  
    // Update existing user
    const changeUser = (localUser) => {
      //console.log(localUser)
      axios.patch(config.server+'members/extrausers/change/'+config.clubId, {
        localUser: localUser
        },{
        'headers': {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+config.accessToken},
        })
        .then(res => {
          //console.log(res.data);
          myDataDispatch({type: 'UPDATE_EXTRA_USER', user: localUser});
        })
        .catch(err => {
            console.log('Error: ',err)
        })          
    }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    if(selectedUserId) {
      var selectedUser = myData.extraUsers.find(user => user.id==selectedUserId)
      if(selectedUser) {
        setLocalUser({id: selectedUser.id, 
                      name: selectedUser.name, 
                      email: selectedUser.email, 
                      phone: selectedUser.phone, 
                      password: selectedUser.password,
                      sendEmail: selectedUser.sendEmail,
                      sendSMS: selectedUser.sendSMS,
                      canLogin: selectedUser.canLogin})
      }
    } else {
      setLocalUser(USER_INIT);
    }
  }, [selectedUserId])

  
  ////////////////////
  // Action handlers
  ////////////////////

  const closeDialog = () => {
    console.log("Close")
    setOpen(false)
  }

  const handleSave = () => {
    console.log("Save")
    if(localUser.id) {
      changeUser(localUser)
    } else {
      newUser(localUser)
    }
    setOpen(false)
    setLocalUser(USER_INIT)
  }

  const handleChckBoxChange = (e) => {
    setLocalUser({...localUser, [e.target.name]: e.target.checked ? 'x':''})
  }


  ///////////
  // UI part
  ///////////

  return (
    <div>
      <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ekstrabruker</DialogTitle>
        <DialogContent>
          <div style={{display:"flex", flexDirection:"column"}}>
            <TextField required margin="dense" id="email" label="ePost" value={localUser.email} onChange={(e) => setLocalUser({...localUser, email:e.target.value})}/> 
            <TextField required margin="dense" id="name" label="Navn" value={localUser.name} onChange={(e) => setLocalUser({...localUser, name:e.target.value})}/>
            <TextField required margin="dense" id="mobile" label="Mobil nr" value={localUser.phone} onChange={(e) => setLocalUser({...localUser, phone:e.target.value})}/>
            <TextField required margin="dense" id="pwd" label="Passord" type="password" value={localUser.password} onChange={(e) => setLocalUser({...localUser, password:e.target.value})}/>
            <div style={{display: "flex", justifyContent: "space-evenly"}}>
              <FormControlLabel value="top" control={<Checkbox color="primary" name="sendEmail" checked={localUser.sendEmail=='x'?true:false} onChange={handleChckBoxChange} />} label="ePost" labelPlacement="top" />
              <FormControlLabel value="top" control={<Checkbox color="primary" name="sendSMS" checked={localUser.sendSMS=='x'?true:false} onChange={handleChckBoxChange} />} label="SMS" labelPlacement="top" />
              <FormControlLabel value="top" control={<Checkbox color="primary" name="canLogin" checked={localUser.canLogin=='x'?true:false} onChange={handleChckBoxChange} />} label="Login" labelPlacement="top" />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSave}>
            Lagre
          </Button>
          <Button color="primary" onClick={closeDialog}>
            Avbryt
          </Button>
        </DialogActions>          
      </Dialog>        
    </div>
  )
}

export default ExtraUser;
