import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';

import {getDateFormat} from '../../utils/Library'

import moment from "moment";
import "moment/locale/nb";

import { ConfigContext } from '../../contexts/ConfigContext'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';


import { TableContainer, Table, TableHead, TableBody, Stack } from '@mui/material';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Typography, Tooltip } from '@mui/material'
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete'


moment.locale("nb");

const MeterReading = ({open, setOpen, meterId, elPoints, setElPoints, reload, setReload}) => {

  const INIT_VALUE = {
    id:'',
    el_point_id: meterId, 
    read_date: new Date(),
    value:''
  }
  const [newValue, setNewValue] = useState(INIT_VALUE);
  const [readings, setReadings] = useState([]);
  const {config} = useContext(ConfigContext);
  const confirm = useConfirm();

  /////////////////////
  // Server interaction
  /////////////////////
  const getValues = () => {
    axios.get(config.server+'elpoints/meter/latest/'+config.clubId+'/'+meterId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        setReadings(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })            
  }

  const saveReading = (newValue) => {
    axios.post(config.server+'elpoints/meter/new/'+config.clubId, {
      reading: newValue
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        let reading = res.data.reading;
        setReadings([...readings, reading]);
        setReload(!reload);
      })
      .catch(err => {
          console.log('Error: ',err)
      })              
  }

  const deleteReading = (id) => {
    axios.delete(config.server+'elpoints/meter/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        var newReadingList = readings.filter(n => n.id != id);
        setReadings([...newReadingList]);
        setReload(!reload);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          

  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    //console.log("Henter - ", meterId);
    getValues();
  }, [meterId])
  
  ////////////////////
  // Action handlers
  ////////////////////

  // Close Dialog
  const closeDialog = () => {
    setOpen(false);
  }

  const handleSetDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setNewValue({...newValue, read_date: selectedDate.toDate()})
    } else {
      setNewValue({...newValue, read_date: null})
    }
    //setNewValue({...newValue, read_date: selectedDate.toDate()})
  }

  const handleSave = (e) => {
    saveReading(newValue);
    let el = elPoints.find(ep => ep.id==newValue.el_point_id);
    el.last_reading_value = newValue.value;
    el.last_reading_date = newValue.read_date;
    setElPoints([...elPoints]);
    //closeDialog();
    setNewValue(INIT_VALUE);
  }

  const handleDelete = (e) => {
    const id = e.currentTarget.id
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes denne avlesingen. Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        deleteReading(id)
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });

    //console.log("Delete ", e.currentTarget.id);
    //closeDialog();
    setNewValue(INIT_VALUE);
  }

  ///////////
  // UI part
  ///////////

  const measurments = readings ? (
    readings.sort((a,b) => (new Date(a.read_date) > new Date(b.read_date)) ? -1 : 1).map(read => {
      return (
        <TableRow key={read.id}>
          <TableCell>{getDateFormat(read.read_date)}</TableCell>
          <TableCell>{read.value}</TableCell>
          <TableCell>
            <Tooltip title="Slett">
              <IconButton id={read.id} onClick={(e) => handleDelete(e)} >
                <DeleteIcon color="primary" />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      )
    })
  ):(
    <TableRow key={1}>
      <TableCell colSpan={3}>Ingen tidligere avlesninger</TableCell>
    </TableRow>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
      <div>
        <Dialog open={open} onClose={closeDialog} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Måleravlesning</DialogTitle>
          <DialogContent>
            <Paper>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Dato</TableCell>
                      <TableCell>Målerstand</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {measurments}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Paper>
              <div style={{display:"flex", flexDirection:"column", marginTop:"30px"}}>
                <Stack spacing={2}>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  renderInput={(params) => <TextField {...params}/>}
                  margin="normal"
                  format="L"
                  id="date-picker-inline"
                  label="Dato"
                  mask="__.__.____"
                  value={(newValue.read_date=='' || newValue.read_date==null) ? null : newValue.read_date}
                  onChange={handleSetDate}        
                  autoOk={true}      
                />
                <TextField required id="standard-required" label="Målerstand" value={newValue.value} onChange={(e) => setNewValue({...newValue, value:e.target.value, el_point_id: meterId})} />
                </Stack>
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSave}>
              Lagre
            </Button>
            <Button color="primary" onClick={closeDialog}>
              Avbryt
            </Button>
          </DialogActions>          
        </Dialog>        
      </div>
    </LocalizationProvider>
    )
  }

export default MeterReading;
