import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import { ConfigContext } from '../../contexts/ConfigContext'
import { InvoiceContext } from '../../contexts/InvoiceContext'
import { AccountContext } from '../../contexts/AccountContext'

import {getDateFormat} from '../../utils/Library'

import InvoiceHeader from './invoiceHeader'
import InvoiceItems from './invoiceItems'
import InvoiceMemberSelect from './invoiceMemberSelect'
import InvoicePreview from './invoicePreview'
import InvoiceSend from './invoiceSend'


import {Stepper, Step, StepLabel} from '@mui/material';
import {Paper, Button, Typography, Stack} from '@mui/material';


const InvoiceConfig = ({activity, setActivity, editItem}) => {

  const { config } = useContext(ConfigContext);
  const { invoice, invoiceDispatch } = useContext(InvoiceContext);
  const { accounts } = useContext(AccountContext);

  const incomeConfig = config.clubConfig ? config.clubConfig.filter(c => c.parameter=="ACCOUNTING_DEFAULT_INCOME_ACCOUNT") : null;
  const balanceConfig = config.clubConfig ? config.clubConfig.filter(c => c.parameter=="ACCOUNTING_DEFAULT_ACCOUNTS_RECEIVABLE") : null;
  const defaultBankAccount = accounts.bankAccounts ? accounts.bankAccounts.find(act => act.standard==1) : '';
  const INIT_HEADER = {
    id:'',
    club_id: config.clubId,
    period_id: accounts.currentPeriod,
    description: '',
    due_date: null,
    income_account: incomeConfig ? parseInt(incomeConfig[0].value) : 0,
    accounts_receivable_account: balanceConfig ? parseInt(balanceConfig[0].value) : 0,
    status: 0,
    accounting_doc_no: null,
    bank_account_no: defaultBankAccount=='' ? defaultBankAccount : defaultBankAccount.account_no
  }
  //console.log(INIT_HEADER)

  // "Work on" data set
  // DSP = Display
  // CHG = Change
  const [consistentData, setConsistentData] = useState([false, false, false, false, false, false]);
  const [stateInvoiceHeader, setStateInvoiceHeader] = useState((activity=='CHG' || activity=='DSP') && invoice.invoiceHeaders ? invoice.invoiceHeaders.find(ih => ih.id==editItem) : INIT_HEADER);
  const [stateInvoiceItems, setStateInvoiceItems] = useState((activity=='CHG' || activity=='DSP') && invoice.invoiceConfig ? invoice.invoiceConfig.filter(ic => ic.invoice_id==editItem) : []);
  const [stateInvoiceMembers, setStateInvoiceMembers] = useState((activity=='CHG' || activity=='DSP') && invoice.invoiceMembers ? invoice.invoiceMembers.filter(ic => ic.invoice_id==editItem) : []);
  //const [stateInvoiceMemberItems, setStateInvoiceMemberItems] = useState(activity=='CHG'&&invoice.invoiceMemberItems ? invoice.invoiceMemberItems.filter(ic => ic.invoice_id==editItem) : []);
  const [ processing, setProcessing] = useState(true);

  // Support function

  /////////////////////
  // Server interaction
  /////////////////////

  const createHeader = (stateInvoiceHeader) => {
    setProcessing(true)
    axios.post(config.server+'invoice/invoice/header/create/'+config.clubId, { 
        clubId: config.clubId,
        invoice: stateInvoiceHeader
      },{ 
        'headers': { 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer '+config.accessToken
                },
      })
    .then(res => {
        setProcessing(false)
        if(res.status===200) {
          stateInvoiceHeader.id = res.data.invoiceId;
          setStateInvoiceHeader({...stateInvoiceHeader})
          invoiceDispatch({type: 'ADD_INVOICE_HEADER', invoice: stateInvoiceHeader})   
        }
    })
    .catch((err) => {
        console.log('Error', err);
    })
  }

  const updateHeader = (stateInvoiceHeader) => {
    //console.log(stateInvoiceHeader)
    setProcessing(true)
    axios.patch(config.server+'invoice/invoice/header/update/'+config.clubId, { 
      clubId: config.clubId,
      invoice: stateInvoiceHeader
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setProcessing(false)
      setStateInvoiceHeader({...stateInvoiceHeader})
      invoiceDispatch({type: 'UPDATE_INVOICE_HEADER', invoice: stateInvoiceHeader})  
    })
    .catch(err => {
        console.log('Error: ',err)
    })      
  }

  // Save invoice items / config 
  const saveItems = (stateInvoiceItems, invoiceId) => {
    //console.log("Saving ", stateInvoiceItems, invoiceId);
    setProcessing(true)
    axios.post(config.server+'invoice/invoice/config/'+config.clubId, { 
        clubId: config.clubId,
        invoiceId: invoiceId,
        items: stateInvoiceItems
      },{ 
        'headers': { 
                'Content-Type': 'application/json', 
                'Authorization': 'Bearer '+config.accessToken
                },
      })
    .then(res => {
        setProcessing(false)
        if(res.status===200) {
          //console.log(res.data);
          invoiceDispatch({type: 'UPDATE_INVOICE_CONFIG', config: res.data, invoiceId: invoiceId})   
        }
    })
    .catch((err) => {
        console.log('Error', err);
    })
  }

  // Save invoice receivers / members
  const saveMembers = (stateInvoiceMembers, invoiceId) => {
    setProcessing(true)
    //console.log("Saving invoice members", stateInvoiceMembers, invoiceId);
    axios.post(config.server+'invoice/invoice/members/'+config.clubId, { 
      clubId: config.clubId,
      invoiceId: invoiceId,
      periodId: accounts.currentPeriod,
      members: stateInvoiceMembers
    },{ 
      'headers': { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer '+config.accessToken
              },
    })
    .then(res => {
        setProcessing(false)
        if(res.status===200) {
          //console.log(res.data);
          invoiceDispatch({type: 'UPDATE_INVOICE_MEMBERS', invoiceMembers: res.data.updatedInvoiceMembers, invoiceMemberItems:res.data.invoiceMemberItems, invoiceId: invoiceId});
        }
    })
    .catch((err) => {
        console.log('Error', err);
    })
  }

  // Get invoice member items
  const getInvoiceMemberItems = () => {
    setProcessing(true)
    axios.get(config.server+'invoice/invoice/memberItems/'+config.clubId+'/'+accounts.selectedPeriod, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      setProcessing(false)
      invoiceDispatch({type: 'SET_INVOICE_MEMBER_ITEMS', memberItems: res.data});
    })
    .catch(err => {
        console.log('Error: ',err)
    })           
  }

  // Post to accounting
  const postInvoiceToAccounting = (stateInvoiceHeader) => {
    setProcessing(true)
    axios.post(config.server+'accounting/invoice/post/'+config.clubId, { 
      clubId: config.clubId,
      invoiceId: stateInvoiceHeader.id
    },{ 
      'headers': { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer '+config.accessToken
              },
    })
    .then(res => {
      setProcessing(false)
      if(res.status===200) {
        //console.log(res.data);
        stateInvoiceHeader.accounting_doc_no = res.data.docId;
        updateHeader(stateInvoiceHeader);
      }
    })
    .catch((err) => {
        console.log('Error', err);
    })
  }  

  // Send invoice to the members
  const send = (stateInvoiceHeader) => {
    setProcessing(true)
    axios.post(config.server+'invoice/invoice/send/'+config.clubId, { 
      clubId: config.clubId,
      invoiceId: stateInvoiceHeader.id
    },{ 
      'headers': { 
              'Content-Type': 'application/json', 
              'Authorization': 'Bearer '+config.accessToken
              },
    })
    .then(res => {
      setProcessing(false)
      if(res.status===200) {
        //console.log(res.data);
        updateHeader(stateInvoiceHeader);
      }
    })
    .catch((err) => {
        console.log('Error', err);
    })    
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    getInvoiceMemberItems();
    //console.log(invoice)
  }, [])

  useEffect(() => {
    if(activity=='DSP') {
      setConsistentData([true, true, true, true, true, true])
    }
    //console.log(invoice)
    //console.log(activity)
    //console.log(stateInvoiceHeader)
  }, [activity])


  ////////////////////
  // Action handlers
  ////////////////////

  // Handle reset
  const handleReset = () => {
    setActivity(null);
    setStateInvoiceHeader(INIT_HEADER);
  }

  const handleBack = () => {
    var newStatus = stateInvoiceHeader.status-1;
    setStateInvoiceHeader({...stateInvoiceHeader, status: newStatus})
  }

  // Pressing the "Next" button updates the status and saves data from the previous step
  const handleNext = () => {
    var newStatus = parseInt(stateInvoiceHeader.status)+1;

    // Save header information
    if(stateInvoiceHeader.status==0) {
      stateInvoiceHeader.status=newStatus;

      if(stateInvoiceHeader.accounting_doc_no || activity=="DSP") {
        // Do nothing
        setStateInvoiceHeader({...stateInvoiceHeader, status: newStatus})
      } else {
        // New invoice --> create
        if(stateInvoiceHeader.id=='') {
          createHeader(stateInvoiceHeader);
        } else {
          updateHeader(stateInvoiceHeader);
        }
      }

    // Save item/config information
    } else if(stateInvoiceHeader.status==1) {
      stateInvoiceHeader.status=newStatus;
      if(stateInvoiceHeader.accounting_doc_no || activity=="DSP") {
        // Do nothing
        setStateInvoiceHeader({...stateInvoiceHeader, status: newStatus})
      } else {
        updateHeader(stateInvoiceHeader);
        saveItems(stateInvoiceItems, stateInvoiceHeader.id);
      }

    // Save member information (invoice receivers) and generate invoice per member
    } else if(stateInvoiceHeader.status==2) {
      stateInvoiceHeader.status=newStatus;
      if(stateInvoiceHeader.accounting_doc_no || activity=="DSP") {
        // Do nothing
        setStateInvoiceHeader({...stateInvoiceHeader, status: newStatus})
      } else {      
        updateHeader(stateInvoiceHeader);
        saveMembers(stateInvoiceMembers, stateInvoiceHeader.id);
      }

    // Post invoice to accounting
    } else if(stateInvoiceHeader.status==3) {
      stateInvoiceHeader.status=newStatus;
      if(stateInvoiceHeader.accounting_doc_no || activity=="DSP") {
        // Do nothing
        setStateInvoiceHeader({...stateInvoiceHeader, status: newStatus})
      } else {
        updateHeader(stateInvoiceHeader);
        postInvoiceToAccounting(stateInvoiceHeader); 
      }     
    
    // Send invoice to members
    } else if(stateInvoiceHeader.status==4) {
      stateInvoiceHeader.status=newStatus;
      updateHeader(stateInvoiceHeader);
      send(stateInvoiceHeader); 
    }
    
    // Handle other information
    if(stateInvoiceHeader.status>4) {
      setStateInvoiceHeader({...stateInvoiceHeader, status: newStatus})
      //setConsistentData(false);
    }

  }

  ///////////
  // UI part
  ///////////

  const showHdr = stateInvoiceHeader.status==0 ? 
                  <InvoiceHeader stateInvoiceHeader={stateInvoiceHeader} 
                                 setStateInvoiceHeader={setStateInvoiceHeader} 
                                 consistentData={consistentData}
                                 setConsistentData={setConsistentData}
                  /> : null;
  const showItems = stateInvoiceHeader.status==1 ? 
                  <InvoiceItems stateInvoiceItems={stateInvoiceItems} 
                                setStateInvoiceItems={setStateInvoiceItems} 
                                consistentData={consistentData}
                                setConsistentData={setConsistentData}                                
                                stepperStatus={stateInvoiceHeader.status}
                                stateInvoiceHeader={stateInvoiceHeader}
                  /> : null;
  const showMembers = stateInvoiceHeader.status==2 ? 
                  <InvoiceMemberSelect stateInvoiceMembers={stateInvoiceMembers} 
                                setStateInvoiceMembers={setStateInvoiceMembers} 
                                consistentData={consistentData}
                                setConsistentData={setConsistentData}   
                                activity={activity} 
                                setActivity={setActivity}                             
                                stepperStatus={stateInvoiceHeader.status}
                                stateInvoiceHeader={stateInvoiceHeader}
                  /> : null;
  const showPreview =  stateInvoiceHeader.status==3 ? 
                  <InvoicePreview invoice_id={stateInvoiceHeader.id}
                                consistentData={consistentData}
                                setConsistentData={setConsistentData} 
                                stateInvoiceMembers={stateInvoiceMembers}  
                                processing={processing} 
                  /> : null;  
  const showSend =  stateInvoiceHeader.status==4 ? 
                  <InvoiceSend invoiceHeader={stateInvoiceHeader}
                                consistentData={consistentData}
                                setConsistentData={setConsistentData}   
                                stepperStatus={stateInvoiceHeader.status} 
                  /> : null;  

  const showAll = stateInvoiceHeader.status==5 ? (
    <Stack spacing={3}>
      <InvoiceHeader stateInvoiceHeader={stateInvoiceHeader} 
                     setStateInvoiceHeader={setStateInvoiceHeader} 
                     consistentData={consistentData} 
                     setConsistentData={setConsistentData} />
      <InvoiceItems stateInvoiceItems={stateInvoiceItems} 
                    setStateInvoiceItems={setStateInvoiceItems} 
                    consistentData={consistentData} 
                    setConsistentData={setConsistentData} 
                    stepperStatus={stateInvoiceHeader.status} 
                    stateInvoiceHeader={stateInvoiceHeader} />
      <InvoiceMemberSelect stateInvoiceMembers={stateInvoiceMembers} 
                           setStateInvoiceMembers={setStateInvoiceMembers} 
                           consistentData={consistentData} 
                           setConsistentData={setConsistentData} 
                           activity={activity} 
                           setActivity={setActivity} 
                           stepperStatus={stateInvoiceHeader.status} 
                           stateInvoiceHeader={stateInvoiceHeader} />
        <InvoicePreview invoice_id={stateInvoiceHeader.id}
                        consistentData={consistentData}
                        setConsistentData={setConsistentData} 
                        stateInvoiceMembers={stateInvoiceMembers} />
         <InvoiceSend invoiceHeader={stateInvoiceHeader}
                      consistentData={consistentData}
                      setConsistentData={setConsistentData} 
                      stepperStatus={stateInvoiceHeader.status} />
    </Stack>
  ) : null

  const headerText = activity=="DSP" ? "Oversikt over faktura nr "+stateInvoiceHeader.id : "Ny medlemsfaktura / kontingent"
  const navButtons = stateInvoiceHeader.status<5 ? (
    <>
      <Button style={{marginRight: "10px"}} onClick={handleReset} >Avbryt</Button>
      <Button style={{marginRight: "10px"}} disabled={stateInvoiceHeader.status === 0} onClick={handleBack} >Tilbake</Button>
      <Button variant="contained" color="primary" disabled={!consistentData[stateInvoiceHeader.status]} onClick={handleNext}>
        {stateInvoiceHeader.status === invoice.invoiceSteps.length - 1 ? 'Ferdig' : 'Neste'}
      </Button> 
    </> ) : (
    <>
      <Button style={{marginRight: "10px"}} onClick={handleReset} >Lukk</Button>
    </> )
  

  return (
      <div>
        <Paper elevation={5} style={{margin:"10px", backgroundColor: "lightgrey", minHeight: "500px", height:"82vh", display:"flex", flexDirection:"column", overflow: "auto"}}>
          <div style={{width: "100%", height: "100%"}}>
            <Typography variant="h6" sx={{marginBottom: "15px"}} >{headerText}</Typography>
            <Stepper activeStep={parseInt(stateInvoiceHeader.status)} alternativeLabel style={{backgroundColor: "lightgrey"}} >
              {invoice.invoiceSteps.map((step) => (
                <Step key={step.id} id={step.id}>
                  <StepLabel>{step.text}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div style={{width: "100%"}}>
              {stateInvoiceHeader.status === invoice.invoiceSteps.length ? (
                <div>
                  <Typography>Fakturaen er ferdig satt opp, postert i regnskapet og sendt ut til valgte medlemmer</Typography>
                  <Button onClick={handleReset}>Avslutt</Button>
                </div>
              ) : (
                <div >
                  {showHdr}
                  {showItems}
                  {showMembers}
                  {showPreview}
                  {showSend}
                  {showAll}
                  <Typography >{/*invoice.invoiceSteps[stateInvoiceHeader.status].text*/}</Typography>
                  <div style={{position:"absolute", bottom: "20px"}} >
                    {navButtons}
                  </div>
                </div>
              )}
            </div>  
          </div> 
        </Paper>     
      </div>
    )
  }

export default InvoiceConfig;
