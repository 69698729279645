import React, {useEffect, useContext, useState} from 'react'
import axiso from 'axios'

import { InvoiceContext } from '../../contexts/InvoiceContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import { Typography } from '@mui/material'

const InvoiceSend = ({invoiceHeader, consistentData, setConsistentData, stepperStatus}) => {

  const { config, configDispatch } = useContext(ConfigContext);
  const { invoice, invoiceDispatch } = useContext(InvoiceContext);
  
  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    let cons = [...consistentData];
    cons[4] = true;
    setConsistentData(cons);
    //console.log(cons)  
  }, [invoiceHeader])
  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  const outputText = stepperStatus == 4 ? 
    (<Typography variant="subtitle1">
      Medlemskontingenten er bokført som bilag nummer {invoiceHeader.accounting_doc_no}, og klar til utsending til valgte medlemmer
    </Typography>) :
    (<Typography variant="subtitle1">
      Medlemskontingenten er bokført som bilag nummer {invoiceHeader.accounting_doc_no}
    </Typography>)     

  return (
      <div style={{display: "flex", alignContent:"center", alignItems:"center", justifyContent:"center", height:"300px"}}>
        {outputText}
      </div>
    )
  }

export default InvoiceSend;
