import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'

import { ConfigContext } from '../../contexts/ConfigContext'
import { MyDataContext } from '../../contexts/MyDataContext'

import MySummary from './mySummary'
import Person from './person'
import MyProperty from './myProperty'
import MyLeases from './myLeases'
import VolWork from './volWork'
import MyMessages from './myMessages'
import MyInvoices from './myInvoices'
import MyGateNumbers from './myGateNumbers'

import { getConfig } from '../../utils/Library'

import {AppBar, Tabs, Tab}  from '@mui/material'
import {OVERVIEW, MEMBER_CARD_ID, PROPERTY_CARD_ID, MESSAGE_CARD_ID, INVOICE_CARD_ID, SNOW_CLEARING_CARD_ID, GATE_CARD_ID, EXTRA_USERS_CARD_ID, LEASE_CARD_ID, VOL_CARD_ID} from './mySummary'

const MyPage = () => {

  const [selected, setSelected] = useState(0);
  const {config, configDispatch} = useContext(ConfigContext);
  const {myData, myDataDispatch} = useContext(MyDataContext);
  
  const properties = (getConfig(config, "CLUB_CABINS") == "1") ? true : false;
  const leases = (getConfig(config, "CLUB_BOATS") == "1") ? true : false;
  const volWork = (getConfig(config, "CLUB_VOLUNTARY_WORK") == "1") ? true : false;
  const additionalUsers = (getConfig(config, "CLUB_ADDITIONAL_USERS") == "1") ? true : false;
  const gate = (getConfig(config, "CLUB_GATE") == "1") ? true : false;
  const snowClearing = (getConfig(config, "CLUB_SNOW_CLEARING") == "1") ? true : false;
  const accounting = (getConfig(config, "CLUB_ACCOUNTING") == "1") ? true : false;
  

  /////////////////////
  // Server interaction
  /////////////////////

  // Get personal data
  const getMemberData = () => {
    //console.log("Getting member data from URL ",config.server+'members/'+config.clubId+'/'+config.userId)
    axios.get(config.server+'members/'+config.clubId+'/'+config.userId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        myDataDispatch({type: 'SET_MEMBER_INFO', memberData: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  // Get property data
  const getPropertyData = () => {
    axios.get(config.server+'properties/member/all/'+config.clubId+'/'+config.userId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        myDataDispatch({type: 'SET_PROPERTY_INFO', properties: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  // Get message data
  const getMessageData = () => {
    axios.get(config.server+'msg/history/member/'+config.clubId+'/'+config.userId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        myDataDispatch({type: 'SET_MESSAGES', messages: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })              
  }

  // Get invoice data
  const getInvoiceData = () => {
    axios.get(config.server+'invoice/member/all/'+config.clubId+'/'+config.userId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        myDataDispatch({type: 'SET_INVOICES', invoices: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })              
  }

  // Get snow clearing orders
  const getSnowClearingOrders = () => {
    axios.get(config.server+'snowClearingOrders/member/property/all/'+config.clubId+'/'+config.userId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        myDataDispatch({type: 'SET_SC_ORDERS', scOrders: res.data});
        //console.log(res.data)
      })
      .catch(err => {
          console.log('Error: ',err)
      })             
  }

  // Get gate numbers
  const getGateNumbers = () => {
    axios.get(config.server+'gate/member/all/'+config.clubId+'/'+config.userId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        myDataDispatch({type: 'SET_GATE_NUMBERS', gateNumbers: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })                 
  }

  // Get extra users
  const getExtraUsers = () => {
    axios.get(config.server+'members/extrausers/get/'+config.clubId+'/'+config.userId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        myDataDispatch({type: 'SET_EXTRA_USERS', extraUsers: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })           
  }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Min side"});
    getMemberData();
    getMessageData();
    //console.log(properties)
    if(properties) {
      //console.log("Loding property data")
      getPropertyData();
    }
    if(accounting) {
      getInvoiceData();
    }
    if(snowClearing) {
      getSnowClearingOrders();
    }
    if(gate) {
      getGateNumbers();
    }
    if(additionalUsers) {
      getExtraUsers();
    }
  }, [config.clubConfig])
  

  ////////////////////
  // Action handlers
  ////////////////////

  const handleTabChange = (e) => {
    setSelected(parseInt(e.currentTarget.id));
  }

  ///////////
  // UI part
  ///////////

  //console.log(myData.person)
  const name = (myData.person) ? (
    (myData.person.companyName) ? (myData.person.companyName) : (myData.person.firstName+' '+myData.person.lastName)
  ) : "Personlig";

  /////////////////
  // Properties
  /////////////////
  const propertyTabs = (myData.properties && properties) ? (
    myData.properties.sort((a,b) => a.id>b.id?1:-1).map((property, index) => {
      let key = (PROPERTY_CARD_ID*100)+index;
      return <Tab key={key} value={key} label={property.address} id={key} />
    })
  ):(
    null
  );

  const propertyDivs = (myData.properties && properties) ? (
    myData.properties.sort((a,b) => a.id>b.id?1:-1).map((property, index) => {
      let key = (PROPERTY_CARD_ID*100)+index;
      return (
        <div key={key} id={key} hidden={(selected==(key)) ? false : true} >
          <MyProperty sc={snowClearing} propertyId={property.id} hidden={(selected==(key)) ? false : true} />
        </div>
      )
    })
  ):(
    null
  );

  //////////////////
  // Lease contracts
  //////////////////
  const leaseTab = (leases) ? (
    <Tab key={LEASE_CARD_ID} value={LEASE_CARD_ID} label="Leiekontrakter" id={LEASE_CARD_ID} />
  ) : (
    null
  );

  const leaseDiv = (leases) ? (
    <div key={LEASE_CARD_ID} id={LEASE_CARD_ID} hidden={(selected==(LEASE_CARD_ID)) ? false : true} >
      <MyLeases />
    </div>      
  ) : (
    null
  );
  
  /////////////////
  // Voluntary work
  /////////////////
  const volHrsTab = (volWork) ? (
    <Tab key={VOL_CARD_ID} value={VOL_CARD_ID} label="Dugnadsarbeid" id={VOL_CARD_ID} />       
  ) : (null);

  const volHrsDiv = (volWork) ? (
    <div key={VOL_CARD_ID} id={VOL_CARD_ID} hidden={(selected==(VOL_CARD_ID)) ? false : true} >
      <VolWork />
    </div>    
  ) : (null);


  /////////////////
  // Messages
  /////////////////
  const messageTab = (
    <Tab key={MESSAGE_CARD_ID} value={MESSAGE_CARD_ID} label="Meldinger" id={MESSAGE_CARD_ID} />       
  );

  const messageDiv = (
    <div key={MESSAGE_CARD_ID} id={MESSAGE_CARD_ID} hidden={(selected==(MESSAGE_CARD_ID)) ? false : true} >
      <MyMessages />
    </div>    
  ); 
  

  /////////////////
  // Invoices
  /////////////////
  const invoiceTab = accounting ? (
    <Tab key={INVOICE_CARD_ID} value={INVOICE_CARD_ID} label="Fakturaer" id={INVOICE_CARD_ID} />       
  ) : null;

  const invoiceDiv = accounting ? (
    <div key={INVOICE_CARD_ID} id={INVOICE_CARD_ID} hidden={(selected==(INVOICE_CARD_ID)) ? false : true} >
      <MyInvoices />
    </div>    
  ) : null; 


  /////////////////
  // Gate numbers
  /////////////////
  const gateTab = gate ? (
    <Tab key={GATE_CARD_ID} value={GATE_CARD_ID} label="Bom nummer" id={GATE_CARD_ID} />       
  ) : null;

  const gateDiv = gate ? (
    <div key={GATE_CARD_ID} id={GATE_CARD_ID} hidden={(selected==(GATE_CARD_ID)) ? false : true} >
      <MyGateNumbers />
    </div>    
  ) : null;   
  

  return (
      <div>
        <AppBar position="static" color="default">
          <Tabs value={selected} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
            <Tab label="Oversikt" value={OVERVIEW} id={OVERVIEW} />
            <Tab label={name} value={MEMBER_CARD_ID} id={MEMBER_CARD_ID} />
            {invoiceTab}
            {propertyTabs}
            {leaseTab}
            {volHrsTab}
            {messageTab}
            {gateTab}
          </Tabs>
        </AppBar>

        <div id={OVERVIEW} hidden={(selected==OVERVIEW) ? false : true} >  
          <MySummary  properties={properties} 
                      leases={leases} 
                      volWork={volWork} 
                      additionalUsers={additionalUsers} 
                      gate={gate}
                      snowClearing={snowClearing}
                      accounting={accounting}
                      setSelected={setSelected}/> 
        </div>
        <div id={MEMBER_CARD_ID} hidden={(selected==MEMBER_CARD_ID) ? false : true} > <Person additionalUsers={additionalUsers}/> </div>
        {invoiceDiv}
        {leaseDiv}
        {volHrsDiv}
        {propertyDivs}
        {messageDiv}
        {gateDiv}
      </div>
    )
}

export default MyPage;
