import React, {useEffect, useContext, useState} from 'react'
import {getDateFormat} from './../../utils/Library'

import { MyDataContext } from '../../contexts/MyDataContext'

import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

const MyInvoices = () => {
  const {myData} = useContext(MyDataContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  const invoiceItems = myData.invoices ? (
    myData.invoices.sort((a,b) => a.due_date > b.due_date ? 1 : -1).map(invoice => {
      return (
        <TableRow key={invoice.id}>
          <TableCell>{invoice.id}</TableCell>
          <TableCell>{invoice.description}</TableCell>
          <TableCell>{getDateFormat(invoice.due_date)}</TableCell>
          <TableCell align="right">{invoice.total}</TableCell>
          <TableCell align="right" style={{color: (invoice.open_amount>0?"red":"black")}} >{invoice.open_amount}</TableCell>
        </TableRow>        
      )
    })
  ) : null;

  return (
      <div style={{overflow: "scroll", maxHeight: "max-content"}}>
        <Paper>
          <TableContainer component={Paper}>
            <Table padding="none" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Faktura nr</TableCell>
                  <TableCell>Fakturatekst</TableCell>
                  <TableCell>Forfallsdato</TableCell>
                  <TableCell align="right">Beløp</TableCell>
                  <TableCell align="right">Utestående</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceItems}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    )
  }

export default MyInvoices;
