import React, { useContext, useEffect, useState } from 'react'
import { ConfigContext } from '../../contexts/ConfigContext'
import axios from 'axios'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Editor, EditorState, convertFromRaw } from "draft-js";

import {dynamicSort} from '../../utils/Library'

const News = () => {
  const { config, configDispatch } = useContext(ConfigContext);

  const [news, setNews] = useState([]);

  /////////////////////
  // Server interaction
  /////////////////////

  const getClubNews = () => {
    axios.get(config.server+'messages/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json'}
      })
      .then(res => {
        setNews(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  };

  ////////////////////
  // useEffect area
  ////////////////////

  // Load news
  useEffect(() => {
    if(config.clubId!==0) {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
      // Get club news  
      getClubNews();
    }
  }, [config.clubId]);


  ////////////////////
  // Action handlers
  ////////////////////


  ///////////
  // UI part
  ///////////

  // News message list
  const newsList  = news.length ? (
    news.sort(dynamicSort("id")).map(message => {
      if(message.type==="JSON") {
        const contentState = convertFromRaw(JSON.parse(message.content));
        const editorState = EditorState.createWithContent(contentState);

        return (
          <Card className="messageCard" key={message.id} style={{margin: 10, backgroundColor: "gainsboro"}}>
            <CardContent>
              <span className="messageCardHeader" style={{fontWeight: "bold"}}>{message.title}</span>
              <Editor editorState={editorState} readOnly={true} style={{width: "100%"}} />  
              <p className="grey-text" style={{fontSize:10 }}>Publisert {message.date} {message.initials}</p>
            </CardContent>
          </Card>            
        )
      }
      return (
        <Card className="messageCard" key={message.id} style={{margin: 5, backgroundColor: "gainsboro"}}>
          <CardContent>
            <span className="messageCardHeader" style={{fontWeight: "bold"}}>{message.title}</span>
            <div dangerouslySetInnerHTML={{ __html: message.content }} />
            <p className="grey-text" style={{fontSize:10 }}>Publisert {message.date}</p>
          </CardContent>
        </Card>
      )
    })
  ) : (
    <div className="center">
        <p>Ingen nyheter</p>    
    </div>
  );

  // Images
  const showImages = config.clubConfig.find(cc => cc.parameter=='CLUB_FRONTPAGE_IMAGE');
  const imagePosition = config.clubConfig.find(cc => cc.parameter=='FRONTPAGE_IMAGE_POSISION');
  const configImage1 = config.clubConfig.find(cc => cc.parameter=='FRONTPAGE_IMAGE_NAME_1');
  const configImage2 = config.clubConfig.find(cc => cc.parameter=='FRONTPAGE_IMAGE_NAME_2');

  const image1 = configImage1 && configImage1.value ? (
    require('./../../images/'+configImage1.value)
  ):(null);
  const image2 = configImage2 && configImage2.value ? (
    require('./../../images/'+configImage2.value)
  ):(null);

  const image1Div = image1 ? (
    <div style={{overflow: "hidden"}}>
      <img src={image1} style={{width:"100%", height:"auto", minWidth:"100px"}} />
    </div>
  ) : (null);
  const image2Div = image2 ? (
    <div style={{overflow: "hidden"}}>
      <img src={image2} style={{width:"100%", height:"auto", minWidth:"100px"}} />
    </div>
  ) : (null);

  // Screen layout
  const mainDiv = showImages && imagePosition && showImages.value=='1' ? (
    (imagePosition.value=='right') ? (
      <div style={{display:"flex", flexDirection: "row", justifyContent: "space-evenly"}}>
        <div style={{display:"flex", flexDirection: "column", overflow: "auto"}}>
          {newsList}
        </div>
        <div style={{display:"flex", flexDirection: "column", overflow: "auto"}}>
          {image1Div}
          {image2Div}             
        </div>
      </div>      
    ):(
      (imagePosition.value=='left') ? (
        <div style={{display:"flex", flexDirection: "row", justifyContent: "space-evenly"}}>
          <div style={{display:"flex", flexDirection: "column", overflow: "auto"}}>
            {image1Div}
            {image2Div}             
          </div>
          <div style={{display:"flex", flexDirection: "column", overflow: "auto"}}>
            {newsList}
          </div>
        </div>      
      ) : (
        (imagePosition.value=='top') ? (
          <div style={{display:"flex", flexDirection: "column", justifyContent: "space-evenly"}}>
            <div style={{display:"flex", flexDirection: "row", overflow: "auto"}}>
              {image1Div}
              {image2Div}             
            </div>
            <div style={{display:"flex", flexDirection: "column", overflow: "auto"}}>
              {newsList}
            </div>
          </div>      
        ) : (
          (imagePosition.value=='bottom') ? (
            <div style={{display:"flex", flexDirection: "column", justifyContent: "space-evenly"}}>
              <div style={{display:"flex", flexDirection: "column", overflow: "auto"}}>
                {newsList}
              </div>
              <div style={{display:"flex", flexDirection: "row", overflow: "auto"}}>
                {image1Div}
                {image2Div}             
              </div>
            </div>      
          ) : ( null )    
        )
      )
    )
  ) : (
    <div style={{display:"flex", flexDirection: "column", overflow: "auto"}}>
      {newsList}
    </div>    
  );

  //console.log(showImages, imagePosition, image1, image2);


  //const image = require('./../images/demoBilde.jfif');
  //const map = require('./../images/demoKart.png');

  return (
    <div>
      {mainDiv}
    </div>
  );
}

export default News;