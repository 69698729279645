import axios from 'axios'
import {getDateFormat} from './Library'

export const signIn = (config, username, password, configDispatch) => {

  //console.log('Action: Her');
  axios.post(config.server+'members/login', { 
      clubId: config.clubId,
      user: username,
      password: password
  },{ 
      'headers': { 
              'Content-Type': 'application/json' 
              },
      })
  .then(res => {
      //console.log('Result - ',res.data);
      //console.log('Result - ',res.data.token);
      if(res.status===201) {
          //console.log('Success', res.status);
          //console.log(res.data.accessToken);
          configDispatch({type: 'LOGIN_SUCCESS', data: res.data})
      }
      })
  .catch((err) => {
      console.log('Error', err);
      configDispatch({type: 'LOGIN_ERROR', err: err})
  })        
};

  // Get all members for the club
  export const getMembers = (config, memberDispatch) => {
    axios.get(config.server+'members/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        memberDispatch({type: 'SET_MEMBERS', members: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  };