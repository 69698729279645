import React, {useEffect, useContext, useState} from 'react'
import { Paper, TextField, Typography, Tooltip, Button } from '@mui/material'

import {getConfigVal} from './../../utils/Library'
import { ConfigContext } from '../../contexts/ConfigContext'

const SmsConfig = ({saveAll}) => {

  const { config, configDispatch } = useContext(ConfigContext);

  /////////////////////
  // Server interaction
  /////////////////////


  ////////////////////
  // useEffect area
  ////////////////////

  
  ////////////////////
  // Action handlers
  ////////////////////
  const handleConfigChange = (e) => {
    var str = e.target.value
    if(str && str.length<12) {
      configDispatch({type: 'SET_NEW_CLUB_CONFIG_VALUE', param: e.target.name, newValue: e.target.value})
    }
  }

  ///////////
  // UI part
  ///////////

  return (
      <div>
        <div style={{display: "flex", flexDirection:"column"}}>
          <Paper elevation={6} style={{margin: "5px", padding: "10px", maxWidth:"400px"}}>
            <Typography variant="subtitle1" style={{fontWeight:"bold"}}>SMS konfigurering</Typography>
            <Tooltip title="SMS avsender - kan være maksimalt 11 bokstaver/tall. Æøå, eller tegn som -, .+! osv ikke tillatt. Dette skyldes begrensninger i telenettet" >
              <TextField
                    key={1} required margin="dense" id="sender" label="Avsender" name="CLUB_SMS_SENDER"
                    fullWidth value={getConfigVal(config, "CLUB_SMS_SENDER")}    
                    disabled={parseInt(config.accessLevel)===999 ? false:true}      
                    onChange={handleConfigChange}
                  />
            </Tooltip>          
          </Paper>
        </div>
        <Button color="primary" onClick={(e) => saveAll(e)}>
          Lagre
        </Button>         
      </div>
    )
  }

export default SmsConfig;
