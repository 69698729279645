import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios'

import { InvoiceContext } from '../../contexts/InvoiceContext'
import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'

import { Paper, Typography, FormControlLabel, Switch, FormControl, InputLabel, Select, Input, MenuItem, Checkbox, ListItemText, Chip } from '@mui/material'

const InvoiceMemberSelect = ({stateInvoiceMembers, setStateInvoiceMembers, consistentData, setConsistentData, activity, setActivity, stepperStatus, stateInvoiceHeader}) => {

  const { config, configDispatch } = useContext(ConfigContext);
  const { invoice, invoiceDispatch } = useContext(InvoiceContext);
  const { members, memberDispatch } = useContext(MemberContext);

  // Lists 
  const [roles, setRoles] = useState([]);
  const [memberRoles, setMemberRoles] = useState([]);
  //const [members, setMembers] = useState([]);

  // Switches
  const [selectAll, setSelectAll] = useState(false);
  const [selectGroups, setSelectGroups] = useState(false);
  const [selectMembers, setSelectMembers] = useState(selectAll===false && selectGroups===false && stateInvoiceMembers.length>0 ? true : false);

  // Show selected members
  //const [allSelected, setAllSelected] = useState([]);
  const [selectedGroupList, setSelectedGroupList] = useState([]);
  const [selectedMemberList, setSelectedMemberList] = useState(selectAll===false && selectGroups===false && stateInvoiceMembers.length>0 ? stateInvoiceMembers.map(m => parseInt(m.member_id)) : []);

  /////////////////////
  // Server interaction
  /////////////////////

  const getAllRoles = () => {
    axios.get(config.server+'roles/all/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data)
      setRoles(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })               
  }


  const getAllMemberRoles = () => {
    axios.get(config.server+'roles/memberRoles/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data)
      setMemberRoles(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })                   
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    getAllRoles();
    //getAllMembers();
    getAllMemberRoles();
    //console.log("useEffect INITIAL")
    setStateInvoiceMembers(invoice.invoiceMembers.filter(im => im.invoice_id==stateInvoiceHeader.id))
  }, [])

  useEffect(() => {
    if(stateInvoiceMembers && stateInvoiceMembers.length>0){
      let cons = [...consistentData];
      cons[2] = true;
      setConsistentData(cons);
      //console.log(cons)  
      //console.log(stateInvoiceMembers)
    }
  }, [stateInvoiceMembers])
  
  ////////////////////
  // Action handlers
  ////////////////////

  // Switch - all members
  const handleSelectAll = (e) => {
    if(stateInvoiceHeader.accounting_doc_no) {
      // Do nothing
    } else {
      setSelectAll(e.target.checked);
      if(e.target.checked) {
        setSelectGroups(false);
        setSelectMembers(false);
        //setAllSelected([...members]);
        var newMembers = [];
        members.map(m => newMembers.push({...m, invoice_id:'', total:'', status:''}))
        setStateInvoiceMembers(newMembers);
        setSelectedMemberList([]);
        setSelectedGroupList([]);
      } else {
        //setAllSelected([])
        setStateInvoiceMembers([])
      }  
    }
  }

  // Switch - Groups
  const handleSelectGroups = (e) => {
    if(stateInvoiceHeader.accounting_doc_no || activity=="DSP") {
      // Do nothing
    } else {
      setSelectGroups(e.target.checked);
      if(e.target.checked) {
        setSelectAll(false);
        setSelectMembers(false);
        setSelectedMemberList([]);
        //setAllSelected([]);
        setStateInvoiceMembers([]);
      } else {
        //setAllSelected([]);
        setStateInvoiceMembers([]);
      }  
    }    
  }

  // Switch - individual members
  const handleSelectMembers = (e) => {
    if(stateInvoiceHeader.accounting_doc_no || activity=="DSP") {
      // Do nothing
    } else {
      setSelectMembers(e.target.checked);
      if(e.target.checked) {
        setSelectAll(false);
        setSelectGroups(false);
        setSelectedGroupList([]);
        //setAllSelected([]);
        setStateInvoiceMembers([]);
      } else {
        //setAllSelected([]);
        setStateInvoiceMembers([]);
      }
    }    
  }

  // Selected groups
  const handleSelectedGroupList = (e) => {
    if(stateInvoiceHeader.accounting_doc_no || activity=="DSP") {
      // Do nothing
    } else {
      var selectedList = e.target.value;
      console.log(selectedList, stateInvoiceMembers);
      setSelectedGroupList(selectedList);
      if(selectedList.length==0) {
        //setAllSelected([]);
        setStateInvoiceMembers([]);
      } else {
        var memberList = [];
        selectedList.map(roleId => {
          memberRoles.filter(mr => mr.role_id==roleId).map(roleMember => {
            var memberObj = members.find(m => m.id==roleMember.member_id);
            if(memberObj) {
              memberList.push(memberObj)
            }
          })
        })
        console.log(memberList);
        //setAllSelected(memberList);  
        var newMembers = [];
        memberList.map(m => newMembers.push({...m, invoice_id:'', total:'', status:''}))
        setStateInvoiceMembers(newMembers);
      }
    }    
  }

  // Selected members
  const handleSelectedMemberList = (e) => {
    if(stateInvoiceHeader.accounting_doc_no) {
      // Do nothing
    } else {
      var selectedList = e.target.value;
      //console.log(selectedList);
      setSelectedMemberList(selectedList);
      if(selectedList.length==0) {
        //setAllSelected([]);
        setStateInvoiceMembers([]);
      } else {
        var memberList = [];
        selectedList.map(memberId => {
          var memberObj = members.find(m => m.id==memberId);
          if(memberObj) {
            memberList.push(memberObj)
          }
        })
        //console.log(memberList);
        //setAllSelected(memberList);  
        var newMembers = [];
        memberList.map(m => newMembers.push({...m, invoice_id:'', total:'', status:''}))
        setStateInvoiceMembers(newMembers);
      }    
    }    
  }

  ///////////
  // UI part
  ///////////

  //const selected = allSelected ? (allSelected.sort((a,b) => a.lastName > b.lastName ? 1 : -1).map(member => {
  const selected = stateInvoiceMembers ? (stateInvoiceMembers.sort((a,b) => a.lastName > b.lastName ? 1 : -1).map(member => {
    //console.log(member);
    var name = member.companyName!='' ? member.companyName : member.firstName+' '+member.lastName;
    return (
      <Chip style={{margin: "2px"}} key={member.member_id} label={name} />
    )
  })) : (null);
  
  return (
      <div>
        <Paper elevation={5} disabled={stateInvoiceHeader.accounting_doc_no} sx={{margin:"10px", padding:"10px", backgroundColor: "lightgrey"}}>
          <Typography variant="h6">Velg mottakere</Typography>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
            <Paper elevation={5} style={{margin:"10px", backgroundColor: "lightgrey", minWidth:"300px"}} >
              <div style={{display:"flex", flexDirection:"column"}}>
                <FormControlLabel label={"Alle"}  control={
                  <Switch
                    checked={selectAll}
                    onChange={handleSelectAll}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />}
                />     
              </div>
            </Paper>
            

            <Paper elevation={5} style={{margin:"10px", backgroundColor: "lightgrey", minWidth:"300px"}} >
              <div>
                <FormControlLabel label={"En eller flere grupper"}  control={
                  <Switch
                    checked={selectGroups}
                    onChange={handleSelectGroups}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />}
                />
              </div>
              <div>
                <FormControl style={{minWidth: 160, maxWidth: 300, margin: 1}} disabled={!selectGroups} >
                  <InputLabel id="memberGroupLabel">Medlemsgruppe</InputLabel>
                  <Select
                    labelId="memberGroupLabel"
                    id="memberGroupSelect"
                    multiple
                    value={selectedGroupList}
                    onChange={handleSelectedGroupList}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role.id} id={role.id} value={role.id}>
                        <Checkbox checked={selectedGroupList.indexOf(role.id) > -1} />
                        <ListItemText primary={role.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Paper>

            
            <Paper elevation={5} style={{margin:"10px", backgroundColor: "lightgrey", minWidth:"300px"}} >
              <div>
                <FormControlLabel label={"En eller flere enkeltpersoner"}  control={
                  <Switch
                    checked={selectMembers}
                    onChange={handleSelectMembers}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />}
                />
              </div>
              <div>
                <FormControl style={{minWidth: 160, maxWidth: 300, margin: 1}} disabled={!selectMembers} >
                  <InputLabel id="memberListLabel">Alle medlemmer</InputLabel>
                  <Select
                    labelId="memberListLabel"
                    id="memberListSelect"
                    multiple
                    value={selectedMemberList}
                    onChange={handleSelectedMemberList}
                    input={<Input />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {members.map((m) => (
                      <MenuItem key={m.member_id} id={m.member_id} value={m.member_id}>
                        <Checkbox checked={selectedMemberList.indexOf(m.id) > -1} />
                        <ListItemText primary={m.companyName ? m.companyName : m.firstName+' '+m.lastName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Paper>
          </div>   
          <div style={{display:"flex", flexWrap:"wrap", margin:"10px"}}>
            {selected} 
          </div> 
        </Paper>
      </div>
    )
  }

export default InvoiceMemberSelect;
