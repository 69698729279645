import React, {useContext} from 'react'
import axios from 'axios'
import { Dialog, DialogTitle, DialogContent, TextField, Grid, Button, DialogActions, Checkbox, FormControlLabel } from '@mui/material'

import moment from "moment";
import "moment/locale/nb";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import { useConfirm } from 'material-ui-confirm';

import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'

moment.locale("nb");

const EditDialog = ({open, setOpen, member, setMember, INIT_MEMEBER, editState}) => {

  const confirm = useConfirm();  

  const { config, configDispatch } = useContext(ConfigContext);
  const { members, memberDispatch } = useContext(MemberContext);  

  /////////////////////
  // Server interaction
  /////////////////////

  // Save a new member
  const addNewMember = (member, action) => {
    //console.log(member, action)
    axios.post(config.server+'members/new/'+config.clubId, { 
        member: member,
        action: action
      },{
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        console.log(res.data);
        memberDispatch({type: 'ADD_MEMBER', newMember: res.data.newMember});
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  // Save changes to an existing member
  const changeMember = (member, action) => {
    //console.log(member, action);
    axios.patch(config.server+'members/changeMember/'+config.clubId, { 
      member: member,
      action: action
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data);
      memberDispatch({type: 'UPDATE_MEMBER', member: member});
    })
    .catch(err => {
        console.log('Error: ',err)
    })      
  }

  // Mark a member as deleted
  const deleteMember = (member) => {
    axios.delete(config.server+'members/deleteMember/'+config.clubId+'/'+member.id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log(res.data);
      member.deleted='x';
      memberDispatch({type: 'UPDATE_MEMBER', member: member});
      setMember(INIT_MEMEBER);  
    })
    .catch(err => {
      console.log('Error: ',err)
    })        
  }

  // Get all valid roles for the club
  /*const getRoles = () => {
    axios.get(config.server+'roles/all/'+config.clubId, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        setRoles(res.data);
      })
      .catch(err => {
        console.log('Error: ',err)
      })  
  }*/

  // Get city based on zip code
  const getCity = (zipCode) => {
    //console.log(editState)
    axios.get("https://api.bring.com/shippingguide/api/postalCode.json?pnr="+zipCode+"&clientUrl=www.bryggesoft.no", {
      'headers': {
          'Content-Type': 'application/json'}
      })
      .then(res => {
        //console.log(res.data);
        if(res.data.valid===true) {
          setMember({...member, 'city': res.data.result, 'zipCode': zipCode});  
        }
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }  

  ////////////////////
  // useEffect area
  ////////////////////


  ////////////////////
  // Action handlers
  ////////////////////

  const handleCancel = () => {
    setOpen(false);
    setMember(INIT_MEMEBER);
  };

  const handleSave = (action) => {
    //console.log(action, editState);
    setOpen(false);
    if(editState==='ADD') {
      addNewMember(member, action);
    }
    if(editState==='EDIT') {
      if(action==='CHG' || action ==='CHG_PWD') {
        changeMember(member, action);
      }
      if(action==="DEL") {
        confirm({ 
          title: "Er du sikker?",
          description: "Hvis du bekrefter så slettes dette medlemmet. Det er ikke mulig å angre",
          cancellationText: "Avbryt",
          confirmationText: "Ja"  
        })
        .then(() => { 
          // Delete member
          deleteMember(member);
        })
        .catch(() => { 
          //console.log("Sletting avbrutt")  
        });
      }
    }
    setMember(INIT_MEMEBER);  
  }

  const setZipCode = (e) => {
    const newZip = e.target.value;
    if(newZip.length === 4) {
      getCity(newZip);
    } else {
      setMember({...member, 'zipCode': newZip});  
    }
  }  

  const handleRoleCheckbox = (e) => {
    const newRole = [...member.memberRoles];
    const assigned = e.target.checked ? 1 : 0;
    newRole.map(role => {
      if(e.currentTarget.value == role.id) {
        role.assigned = assigned; 
        setMember({...member, memberRoles: newRole});     
      }
    })
  }

  const handleSetDate = (selectedDate) => {
    if(selectedDate && selectedDate.isValid) {
      setMember({...member, regDate: selectedDate.toDate()})
    }
  }  

  ///////////
  // UI part
  ///////////  

  // Dialogue heading
  const diaTitle = (editState==='ADD') ? (
    "Nytt medlem"
  ) : (
    "Endre eller slette medlem"
  );

  
  const delBtnStr = member.amount_due>0 ? null : 
    <Button onClick={() => handleSave("DEL")} color="primary" >
      Slett medlem
    </Button>
  ;

  // Dialogue toolbar
  const diaToolbar = (editState==='ADD') ? (
      <DialogActions>
        <Button onClick={() => handleSave("NEW")} color="primary">
          Lagre
        </Button>
        <Button onClick={() => handleSave("NEW_PWD")} color="primary">
          Lagre og send passord
        </Button>
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
  ) : (
      <DialogActions>
        <Button onClick={() => handleSave("CHG")} color="primary">
          Lagre endringer
        </Button>
        <Button onClick={() => handleSave("CHG_PWD")} color="primary">
          Lagre og send nytt passord
        </Button>
        {delBtnStr}
        <Button onClick={handleCancel} color="primary">
          Avbryt
        </Button>
      </DialogActions>
  )

  // Roles 
  //console.log(member);
  const rolesUI = member.memberRoles ? (
    member.memberRoles.sort((a,b) => a.id>b.id?1:-1).map(role => {
      //console.log(role)
      return (
        <Grid item xs={4} key={role.id}>
         <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleRoleCheckbox(e)}
                value={role.id}
                checked={role.assigned===1 ? true : false}
                name={role.name}
                color="primary"
              />
            }
            label={role.name}
          />          
        </Grid>
      )
    })
  ) : (
    null
  );


  return (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{diaTitle}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment} locale={"nb"}>
        <div style={{display:"flex", flexFlow:"flex-start"}}>
          <div style={{width: 200}}>
            <TextField
              key={1}
              required 
              margin="dense"
              id="header"
              label="Fornavn"
              fullWidth
              value={member.firstName}          
              onChange={(e) => setMember({...member, 'firstName': e.target.value})}
              autoFocus
            /> 
          </div>
          <div style={{width: 200, marginLeft:10}}>  
            <TextField
              key={2}
              required 
              margin="dense"
              id="header"
              label="Etternavn"
              fullWidth
              value={member.lastName}                    
              onChange={(e) => setMember({...member, 'lastName': e.target.value})}
            />   
          </div>    
        </div>
        <div key={3}>
          <TextField 
            margin="dense"
            id="header"
            label="Firmanavn"
            fullWidth
            value={member.companyName}                    
            onChange={(e) => setMember({...member, 'companyName': e.target.value})}
          />      
        </div>
        <TextField
          key={4}
          required 
          margin="dense"
          id="header"
          label="Bostedsadresse"
          fullWidth
          value={member.address}                    
          onChange={(e) => setMember({...member, 'address': e.target.value})}
        />  
        <div style={{display:"flex", flexFlow:"flex-start"}}>
          <div style={{width: 100}} key={5}>
            <TextField
              required 
              margin="dense"
              id="header"
              label="Postnr"
              fullWidth
              value={member.zipCode}          
              onChange={(e) => setZipCode(e)}
            />         
          </div>
          <div key={6} style={{width: 300, marginLeft:20}}>
            <TextField
              required 
              margin="dense"
              id="header"
              label="By/Sted"
              fullWidth
              value={member.city}          
              onChange={(e) => setMember({...member, 'city': e.target.value})}
            />         
          </div>
        </div>       
        <div style={{display:"flex", flexFlow:"flex-start"}}>
          <div style={{width: 200}} key={5}>
            <TextField
              key={7}
              required 
              margin="dense"
              id="header"
              label="Mobilnummer"
              fullWidth
              value={member.mobile}          
              onChange={(e) => setMember({...member, 'mobile': e.target.value})}
            />         
          </div>
          <div style={{width: 300, marginLeft:10}} key={8}>
            <TextField
              required 
              margin="dense"
              id="header"
              label="ePost adresse"
              fullWidth
              value={member.eMail}          
              onChange={(e) => setMember({...member, 'eMail': e.target.value})}
            />     
          </div>
        </div>
        <DatePicker
                disableToolbar
                required
                variant="inline"
                renderInput={(params) => <TextField {...params}/>}
                margin="normal"
                format="L"
                id="date-picker-inline"
                label="Reg dato"
                mask="__.__.____"
                value={member.regDate=='' || member.regDate==null ? null : member.regDate}                
                onChange={handleSetDate}        
                autoOk={true}      
              />        
        <div key={10}>
          <TextField 
            margin="dense"
            id="header"
            label="Kommentar"
            fullWidth
            value={member.comment}          
            onChange={(e) => setMember({...member, 'comment': e.target.value})}
          />  
        </div>
        <hr />
        <div style={{margin: 4}}>
          <Grid container spacing={1}>
              {rolesUI}
          </Grid>
        </div>
      </LocalizationProvider>
      </DialogContent>
      {diaToolbar}
    </Dialog>
  )
}

export default EditDialog
