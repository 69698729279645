//import { map } from "core-js/core/array"


const accountReducer = (state, action) => {
  //console.log("Reducer", action.type, action);
  switch(action.type) {
    case 'SET_ACCUMULATED':
      return ( {...state, accumulated: action.accumulated} )
    case 'SET_CURRENT_PERIOD':
      return ({...state, currentPeriod: action.period, selectedPeriod: action.period})
    case 'SET_SELECTED_PERIOD':
      return ({...state, selectedPeriod: action.period})
    case 'SET_ALL_PERIODS':
      return ({...state, allPeriods: action.periods})
    case 'SET_BANK_ACCOUNTS':
      return ( {...state, bankAccounts: action.bankAccounts} )
    case 'NEW_PERIOD':
      const newPeriods = [...state.allPeriods, action.period];
      return ({...state, allPeriods: newPeriods})
    case 'UPDATE_PERIOD':
      const updatedPeriods = state.allPeriods.filter(per => per.id!=action.period.id);
      updatedPeriods.push(action.period);
      return ({...state, allPeriods: updatedPeriods})
    case 'DELETE_PERIOD':
      const deletesPeriods = state.allPeriods.filter(per => per.id!=action.periodId);
      return ({...state, allPeriods: deletesPeriods})
    case 'ACTIVATE_PERIOD':
      return ({...state, currentPeriod: action.period, selectedPeriod: action.period})
    case 'SET_PERIOD_POSTINGS':
      return ({...state, transactions: action.transactions})
    case 'ADD_POSTED_DOCUMENT':
      const doc = action.doc;
      //console.log(doc);

      // Find fromAccount object in accumulated array
      const oldFromAccount = state.accumulated.find(act => (act.account_id===action.doc.fromAcct && act.period_id===state.currentPeriod));
      const fromAccount = {...oldFromAccount};
      //console.log(fromAccount)
      if(fromAccount) {
        //console.log(doc.amount, fromAccount.amount)
        fromAccount.amount+=parseFloat(doc.amount);
        fromAccount.period_accumulated+=parseFloat(doc.amount);  
        //console.log(fromAccount.amount, fromAccount.period_accumulated)
      } else {
        // Need to add a new object here
        console.log("Should not end up here")
      }
      
      const oldToAccount = state.accumulated.find(act => (act.account_id===action.doc.toAcct && act.period_id===state.currentPeriod));
      const toAccount = {...oldToAccount};
      //console.log(toAccount)
      if(toAccount) {
        //console.log(doc.amount, toAccount.amount)
        toAccount.amount-=parseFloat(doc.amount);
        toAccount.period_accumulated-=parseFloat(doc.amount);  
        //console.log(toAccount.amount, toAccount.period_accumulated)
      } else {
        // Need to add a new object here
        console.log("Should not end up here")
      }
      //newState.transactions.push(doc); // Re-read from the database

      // Remove fromAccount and toAcctount from accumulated array and add the two new objects
      const newAccumulated = state.accumulated.filter(item => ((item.account_id!=doc.toAcct && item.period_id===state.currentPeriod) && (item.account_id!=doc.fromAcct && item.period_id===state.currentPeriod)))
      newAccumulated.push(fromAccount, toAccount)
      //console.log(newAccumulated);

      const newState = {...state, accumulated: newAccumulated};
      //console.log(newState)
      return (newState)
    case 'SET_ACCOUNT_GROUPS':
      return ({...state, accountGroups: action.accountGroups})
    case 'ADD_DOCUMENT_ATTATCHMENT':
      const docsToBeUpdatedo = state.transactions.filter(doc => doc.id==action.docId)
      docsToBeUpdatedo.map(doc => {
        doc.ref = action.docName
      })
      const filteredTransactions = state.transactions.filter(doc => doc.id!=action.docId)
      const newTrans = [...filteredTransactions, ...docsToBeUpdatedo]
      return ({...state, transactions: newTrans})
      //return state;
    default:
      return state;
  }
}

export default accountReducer;