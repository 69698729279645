import React, { useEffect, useContext } from 'react'
import { ConfigContext } from '../contexts/ConfigContext'
import { useHistory } from 'react-router-dom';

const CLUB_ID = 11 // Stangelandsvågen småbåtforening


const Stangelandsvagen = () => {
    const { config, configDispatch } = useContext(ConfigContext);
    let history = useHistory();

    useEffect(() => {
        // Set club ID
        configDispatch({type: 'SET_CLUB_ID', clubId: CLUB_ID});

        // Redirect to front page
        history.push('/');
    }, [])

    return (
        <div>
            Loading basic club information, club id = {config.clubId} 
        </div>
    )
}

export default Stangelandsvagen;