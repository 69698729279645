import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';

import {getDateFormat} from '../../utils/Library'
import {getMembers} from '../../utils/Server'

import { ConfigContext } from '../../contexts/ConfigContext'
import { MemberContext } from '../../contexts/MemberContext'

import ElPointLeaseEdit from './elPointLeaseEdit'

import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { Paper } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';


const ElPointLease = () => {

  const { config, configDispatch } = useContext(ConfigContext);
  const { members, memberDispatch } = useContext(MemberContext);
  const [leaseList, setLeaseList] = useState([]);
  const [leaseEditOpen, setLeaseEditOpen] = useState(false);
  const [selectedLease, setSelectedLease] = useState(null);
  const [reload, setReload] = useState(false);
  const confirm = useConfirm();

  /////////////////////
  // Server interaction
  /////////////////////
  const getLeaseList = () => {
    axios.get(config.server+'elpoints/lease/all/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log(res.data);
        setLeaseList(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })                
  }

  const deleteLease = (id) => {
    axios.delete(config.server+'elpoints/lease/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        setReload(!reload);
      })
      .catch(err => {
          console.log('Error: ',err)
      })            
  }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Leiekontrakter for el. punkter"});
    getLeaseList();
    getMembers(config, memberDispatch);
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }    
  }, [])

  useEffect(() => {
    getLeaseList();
    //console.log("getLeaseList")
  }, [reload])  
  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleAdd = () => {
    //console.log("Add lease");
    setLeaseEditOpen(true);
  }

  const handleEdit = (e) => {
    //console.log("Edit lease no ", e.currentTarget.id);
    setSelectedLease(leaseList.find(l => l.id==e.currentTarget.id));
    setLeaseEditOpen(true);
  }

  const handleDelete = (e) => {
    const id = e.currentTarget.id
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes denne leieavtalen. Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        deleteLease(id);
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
  }


  ///////////
  // UI part
  ///////////

  const leases = leaseList ? (
    leaseList.map(lease => {
      //console.log(lease);
      const btn = lease.attachment ? <Button color="primary" href={config.server.substring(0, config.server.length - 1)+lease.attachment} target="_blank">Vedlegg</Button> : null
      //const delete = lease.completed==1 ? () : null;
      return (
        <TableRow key={lease.id}>
          <TableCell>{lease.el_point_name}</TableCell>
          <TableCell>{lease.company_name ? lease.company_name : lease.member_name}</TableCell>
          <TableCell>{getDateFormat(lease.valid_from)}</TableCell>
          <TableCell>{lease.meter_start}</TableCell>
          <TableCell>{lease.planned_consumption}</TableCell>
          <TableCell>{lease.completed=='0'? 'Nei':'Ja'}</TableCell>
          <TableCell>{lease.valid_to ? getDateFormat(lease.valid_to):null}</TableCell>
          <TableCell>{lease.meter_end}</TableCell>
          <TableCell>{btn}</TableCell>
          <TableCell>
            <Tooltip title="Endre kontraktinfomasjon" aria-label="add">
              <EditIcon color="primary" fontSize="small" value={lease.id} id={lease.id} onClick={handleEdit} />
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Slett leiekontrakten" aria-label="add">
              <DeleteIcon color="primary" fontSize="small" value={lease.id} id={lease.id} onClick={handleDelete} />
            </Tooltip>
          </TableCell>
        </TableRow>
      )
    })
  ) : ("Det er ingen leieavtaler i øyeblikket");


  return (
      <div>
        <Paper>
          <Fab color="primary" aria-label="add" >
              <AddIcon onClick={handleAdd}/>
          </Fab>
          <ElPointLeaseEdit 
              open={leaseEditOpen} 
              setOpen={setLeaseEditOpen} 
              selectedLease={selectedLease} 
              setSelectedLease={setSelectedLease} 
              reload={reload}
              setReload={setReload} />
          <TableContainer>
            <Table component={Paper} size="small" padding="none">
              <TableHead>
                <TableRow>
                  <TableCell>El. punkt</TableCell>
                  <TableCell>Medlem</TableCell>
                  <TableCell>Gyldig fra</TableCell>
                  <TableCell>Start målerstand</TableCell>
                  <TableCell>Estimert årlig forbruk (kWh)</TableCell>
                  <TableCell>Avsluttet</TableCell>
                  <TableCell>Avsluttet dato</TableCell>
                  <TableCell>Slutt målerstand</TableCell>
                  <TableCell>Vedlegg</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leases}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    )
  }

export default ElPointLease;
