import React, {useState, useContext, useEffect} from 'react'
import axios from 'axios'
import { useConfirm } from 'material-ui-confirm';

import { ConfigContext } from '../../contexts/ConfigContext'
import { DmsContext } from '../../contexts/DmsContext'

import FolderContent from './folderContent'
import FolderDialog from './folderDialog'

import { Paper, TextField, Typography, Button, TableContainer, Table, TableRow, TableCell, TableBody } from '@mui/material'
import TreeView from '@mui/lab/TreeView' 
import TreeItem from '@mui/lab/TreeItem' 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

const DmsMainPage = () => {
  
  const { config, configDispatch } = useContext(ConfigContext);
  const { dms, dmsDispatch } = useContext(DmsContext);
  const [folderDialogOpen, setFolderDialogOpen] = useState(false);
  const confirm = useConfirm();


  /////////////////////
  // Server interaction
  /////////////////////

  const getFolders = () => {
    //console.log("Get folders");
    axios.get(config.server+'docsys/folders/all/'+config.clubId, {
    //axios.get(config.server+'dms/folders/all/1', {
        'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //setFolders(res.data);
        //console.log(res.data);
        dmsDispatch({type: 'SET_FOLDERS', folders: res.data});
        //console.log(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  const getFolderContent = (selected) => {
    axios.get(config.server+'docsys/documents/folder/'+config.clubId+'/'+selected, {
    //axios.get(config.server+'dms/documents/folder/1/'+selected, {
      'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      dmsDispatch({type: 'SET_FOLDER_CONTENT', content: res.data});
      //setFolderContent(res.data);
      //console.log(res.data);
    })
    .catch(err => {
        console.log('Error: ',err)
    })          
  }

  const deleteFolder = (id) => {
    axios.delete(config.server+'docsys/folders/delete/'+config.clubId+'/'+id, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken
        },
      })
      .then(res => {
        dmsDispatch({type: "DELETE_FOLDER", id: id});
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Dokumentarkiv"});
    getFolders();
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }
  }, [])

  useEffect(() => {
    //console.log(dms.selected);
    if(dms.selected && dms.selected.length>0) {
      //console.log("Check pass");
      getFolderContent(dms.selected);
    }
  }, [dms.selected])
  
  ////////////////////
  // Action handlers
  ////////////////////
  
  const handleToggle = (event, nodeIds) => {
    dmsDispatch({type: 'SET_EXPANDED', expanded: nodeIds});
    //setExpanded(nodeIds);
    //console.log(nodeIds)
  };

  const handleSelect = (event, nodeIds) => {
    dmsDispatch({type: 'SET_SELECTED', selected: nodeIds});
    //setSelected(nodeIds);
    //console.log(nodeIds)
  };

  const handleAddFolder = () => {
    //console.log("Ny mappe dialog");
    dmsDispatch({type: 'SET_ACTION_NEW'});
    setFolderDialogOpen(true);
  }

  const handleEditFolder = (e) => {
    //console.log("Endre mappe dialog", dms.selected);
    dmsDispatch({type: 'SET_ACTION_CHG'});
    setFolderDialogOpen(true);
  }

  const handleDeleteFolder = (e) => {
    const id = e.currentTarget.id
    confirm({ 
      title: "Er du sikker?",
      description: "Hvis du bekrefter så slettes denne mappen. Det er ikke mulig å angre",
      cancellationText: "Avbryt",
      confirmationText: "Ja"  
    })
      .then(() => { 
        deleteFolder(dms.selected);
      })
      .catch(() => { 
        console.log("Sletting avbrutt")  
      });
  }


  ///////////
  // UI part
  ///////////

  //console.log(folders);
  const folderNodes = (data) => (
    data.map(folder => {
      //console.log(folder);
      return (
        <TreeItem nodeId={folder.id.toString()} label={folder.name} key={folder.id} >
          {folderNodes((dms.folders.filter(f => f.parent_folder==folder.id).sort((a,b) => a.name>b.name ? 1 : -1)))}
        </TreeItem>
      )
    })
  );


  const folderInfo = dms.selected ? (
    dms.folders.filter(f => f.id==dms.selected).map(folder => {

      // Folder edit button
      const editBtn = (folder.id!=0) ? (
        <Tooltip title="Endre mappe" aria-label="edit">
          <EditIcon color="primary" onClick={handleEditFolder}/>
        </Tooltip>       
      ) : (null)

      // Folder delete button - only show for empty folders with no sub-folders
      let subFolders = dms.folders.filter(f=>f.parent_folder==folder.id);
      //console.log(folder, subFolders, dms.selectedFolderContent);
      const deleteBtn = (folder.id!=0 && dms.selectedFolderContent.length==0 && subFolders.length==0) ? (
        <Tooltip title="Slett mappe" aria-label="delete">
          <DeleteIcon color="primary" onClick={handleDeleteFolder}/>
        </Tooltip>       
      ) : (null)

      return (
        <TableRow key={folder.id}>
          <TableCell>{folder.name}</TableCell>
          <TableCell>{folder.description}</TableCell>
          <TableCell>{'Tilgang for '+ folder.access.toLowerCase()}</TableCell>
          <TableCell>{folder.menu==1 ? 'Vises i menyen' : 'Vises ikke i menyen'}</TableCell>
          <TableCell>
            {editBtn}
          </TableCell>
          <TableCell>
            {deleteBtn}
          </TableCell>
        </TableRow>
      )
    })
  ) : (null);
  
  return (
      <div style={{display:"flex"}}>
        <FolderDialog folderDialogOpen={folderDialogOpen} setFolderDialogOpen={setFolderDialogOpen} />
        <Paper elevation={5} style={{margin:"10px", maxWidth:"300px", flexGrow:1, backgroundColor: "lightgrey"}}>
          <Tooltip title="Opprett ny mappe" aria-label="add">
            <Fab color="primary" aria-label="add" size="small">
                <AddIcon onClick={handleAddFolder}/>
            </Fab>
          </Tooltip>          
          <TreeView 
              defaultCollapseIcon={<ExpandMoreIcon />} 
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={dms.expanded}
              selected={dms.selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
          >
            {folderNodes(dms.folders.filter(f => f.parent_folder==-1).sort((a,b) => a.name>b.name ? 1 : -1))}
          </TreeView>
        </Paper>
        <div style={{flexGrow: 2, display:"flex", flexDirection:"column"}}>
          <Paper elevation={5} style={{margin:"10px", backgroundColor: "lightgrey"}}>
            <Typography align="left" variant="h6" >Valgt mappe</Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  {folderInfo}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <FolderContent />
        </div>
      </div>
    )
  }

export default DmsMainPage;
