import React, {useEffect, useContext, useState} from 'react'
import axios from 'axios'
import {AppBar, Tabs, Tab} from '@mui/material'

import { ConfigContext } from '../../contexts/ConfigContext'
import BasicConfig from './basicConfig'
import SmsConfig from './smsConfig'
import AccountingConfig from './accountingConfig'
import SnowClearingConfig from './snowClearingConfig'
import GateConfig from './gateConfig'
import HomePageConfig from './homePageConfig'
import {getConfig} from './../../utils/Library'

const ConfigOverview = () => {

  const { config, configDispatch } = useContext(ConfigContext);
  const [selectedTab, setSlectedTab] = useState(0);

  /////////////////////
  // Server interaction
  /////////////////////
  const saveAll = () => {
    //console.log("Save all")
    saveClubInfo();
    saveConfig();
  }

  const saveConfig = () => {
    axios.post(config.server+'config/save/all/'+config.clubId, { 
      clubConfig: config.clubConfig
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log("Data saved, ", res)
    })
    .catch(err => {
        console.log('Error: ',err)
    })      
  }

  const saveClubInfo = () => {
    axios.patch(config.server+'clubs/update/basics/'+config.clubId, { 
      clubName: config.clubName,
      clubShortName: config.clubShortName,
      clubOrgNo: config.clubOrgNo,
      clubPlace: config.clubPlace
    },{
    'headers': {
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer '+config.accessToken},
    })
    .then(res => {
      //console.log("Data saved, ", res)
    })
    .catch(err => {
        console.log('Error: ',err)
    })              
  }


  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Administrasjon"});
    //getMembers(config, memberDispatch);
    //getBoatSpaces();
    return () => {
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: null});
    }
  }, [config.clubId])
  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleTabSwitch = (e, newValue) => {
    //console.log(newValue)
    setSlectedTab(newValue);
  }

  ///////////
  // UI part
  ///////////
  return (
      <div>
        <AppBar position="static" color="default">
          <Tabs value={selectedTab} onChange={handleTabSwitch} indicatorColor="primary" textColor="primary" variant="fullWidth">
            <Tab label="Basis" value={0} id={0} />
            <Tab label="SMS tjeneste" value={1} id={1} disabled={getConfig(config, "CLUB_SMS_SERVICE")==1?false:true} />
            <Tab label="Regnskap" value={2} id={2} disabled={getConfig(config, "CLUB_ACCOUNTING")==1?false:true} />
            <Tab label="Brøyting" value={3} id={3} disabled={getConfig(config, "CLUB_SNOW_CLEARING")==1?false:true} />
            <Tab label="Veibom" value={4} id={4} disabled={getConfig(config, "CLUB_GATE")==1?false:true} />
            <Tab label="Hjemmesiden" value={5} id={5} disabled={getConfig(config, "CLUB_FRONTPAGE_IMAGE")==1?false:true} />
          </Tabs>
        </AppBar>
        <div id={0} hidden={(selectedTab==0) ? false : true} >
          <BasicConfig value={selectedTab} index={0} hidden={(selectedTab==0) ? false : true} saveAll={saveAll} />
        </div>
        <div id={1} hidden={(selectedTab==1) ? false : true} >
          <SmsConfig value={selectedTab} index={1} saveAll={saveAll} />
        </div>
        <div id={2} hidden={(selectedTab==2) ? false : true} >
          <AccountingConfig value={selectedTab} index={2} saveAll={saveAll} />
        </div>
        <div id={3} hidden={(selectedTab==3) ? false : true} >
          <SnowClearingConfig value={selectedTab} index={3} saveAll={saveAll} />
        </div>
        <div id={4} hidden={(selectedTab==4) ? false : true} >
          <GateConfig value={selectedTab} index={4} saveAll={saveAll} />
        </div>
        <div id={5} hidden={(selectedTab==5) ? false : true} >
          <HomePageConfig value={selectedTab} index={5} saveAll={saveAll} />
        </div>
      </div>
    )
  }

export default ConfigOverview;
