import React, {useEffect, useState, useContext} from 'react'
import axios from 'axios'
import {numberFormat, getDateFormat} from '../../utils/Library'

import { ConfigContext } from '../../contexts/ConfigContext'
import { AccountContext } from '../../contexts/AccountContext'

import PostDocument from './postDocument'
import PostingList from './postingList'
import ShowTrend from './showTrend'
import ManagePeriods from './managePeriods'
import ManageAccounts from './manageAccounts'
import Budget from './budget'

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import { Typography, Tooltip } from '@mui/material'
import {MenuItem, Select, Button} from '@mui/material';

import PostAddIcon from '@mui/icons-material/PostAdd'; // Poster et nytt dokument
import ReorderIcon from '@mui/icons-material/Reorder'; // Vis posteringsliste
import EqualizerIcon from '@mui/icons-material/Equalizer'; // Vis trendgraf
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom'


const AccountingOverview = () => {

  const { config, configDispatch } = useContext(ConfigContext);
  const { accounts, accountDispatch } = useContext(AccountContext);

  const [reload, setReload] = useState(true);
  const [manDocOpen , setManDocOpen] = useState(false);
  const [postingListOpen, setPostingListOpen] = useState(false);
  const [trendOpen, setTrendOpen] = useState(false);
  const [budgetOpen, setBugdetOpen] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [managePeriodsOpen, setManagePeriodsOpen] = useState(false);
  const [manageAccountsOpen, setManageAccountsOpen] = useState(false);
  const [reloadTr, setReloadTr] = useState(false);


  /////////////////////
  // Server interaction
  /////////////////////

  // Get account groups
  const getAccountGroups = () => {
    axios.get(config.server+'accounting/account/groups/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log("getAccountGroups - ",res.data);
        accountDispatch({type: 'SET_ACCOUNT_GROUPS', accountGroups: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }


  // Get period overview  
  const getPeriodOverview = () => {
    axios.get(config.server+'accounting/period/overview/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        //console.log("getPeriodOverview - ",res.data);
        if(res.data) {
          res.data.map(p => {
            if(p.active==1) {
              accountDispatch({type: 'SET_CURRENT_PERIOD', period: p.id});
              //accountDispatch({type: 'SET_SELECTED_PERIOD', period: p.id});
            }
          })
        }
        accountDispatch({type: 'SET_ALL_PERIODS', periods: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  // Get accumulated overview
  const getAccumulatedOverview = () => {
    axios.get(config.server+'accounting/overview/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        accountDispatch({type: 'SET_ACCUMULATED', accumulated: res.data});
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  // Get accumulated overview
  const getPeriodPostings = () => {
    //console.log(accounts.selectedPeriod);
    axios.get(config.server+'accounting/document/all/'+config.clubId+'/'+accounts.selectedPeriod, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        accountDispatch({type: 'SET_PERIOD_POSTINGS', transactions: res.data});
        //console.log(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })      
  }

  const getBankAccounts = () => {
    //console.log(accounts.selectedPeriod);
    axios.get(config.server+'accounting/account/bankAccounts/'+config.clubId, {
      'headers': {
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer '+config.accessToken},
      })
      .then(res => {
        accountDispatch({type: 'SET_BANK_ACCOUNTS', bankAccounts: res.data});
        //console.log(res.data);
      })
      .catch(err => {
          console.log('Error: ',err)
      })          
  }

  ////////////////////
  // useEffect area
  ////////////////////
  useEffect(() => {
    configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: "Regnskap"});
    getAccountGroups();
    getBankAccounts();
  }, [])

  useEffect(() => {
    if(reload) {
      getPeriodOverview();
      getAccumulatedOverview();  
    }
    setReload(false)
    //console.log("useEffect - reload ", reload);
  }, [reload])

  useEffect(() => {
    const period = accounts.allPeriods.filter(p => p.id==accounts.selectedPeriod)[0];
    if(period) {
      const heading = "Regnskap - periode " + period.description + " (" + getDateFormat(period.start_date) +"-"+ getDateFormat(period.end_date) + ")";
      configDispatch({type: 'SET_CURRENT_FUNCTION', functionName: heading});
    }
    if(accounts.selectedPeriod && accounts.selectedPeriod!=0) {
      //console.log("useEffect - getPeriodPostings() for period ", accounts.selectedPeriod);  
      getPeriodPostings();
    }
    //console.log("useEffect - bunn")
  }, [accounts.selectedPeriod, accounts.allPeriods, reloadTr])

  
  ////////////////////
  // Action handlers
  ////////////////////

  const handleSelectPeriod = (e) => {
    //console.log(e.target.value);
    accountDispatch({type: 'SET_SELECTED_PERIOD', period: e.target.value});
  }

  const handleCostPosting = (e) => {
    console.log(e.currentTarget.id)
    setSelectedAccount(e.currentTarget.id);
    setManDocOpen(true);
  }

  const handleShowPostings = (e) => {
    //console.log(e.currentTarget.id);
    if(e.currentTarget.id) {
      setSelectedAccount(e.currentTarget.id);
    } else {
      setSelectedAccount("All");
    }
    setPostingListOpen(true);
  }

  const handleShowAccountTrend = (e) => {
    //console.log(e.currentTarget.id);
    setSelectedAccount(e.currentTarget.id);
    setSelectedGroup(null);
    setTrendOpen(true);
  }

  const handleShowGroupTrend = (e) => {
    setSelectedAccount(null);
    setSelectedGroup(e.currentTarget.id);
    setTrendOpen(true);
  }

  ///////////
  // UI part
  ///////////

  // Assets - filter by selected period and account class 1
  var assetTotal = 0;
  const assetOverview = accounts ? (
    accounts.accumulated.filter(act1 => act1.period_id==accounts.selectedPeriod).filter(act2 => act2.account_group_id==1).sort((a,b) => a.account_id>b.account_id ? 1 : -1).map(act => {
      assetTotal=assetTotal+parseFloat(act.amount);
      return (
        <TableRow key={act.account_id}>
          <TableCell>{act.account_id}</TableCell>
          <TableCell>{act.account_name}</TableCell>
          <TableCell align="right">{numberFormat(act.amount)}</TableCell>
          <TableCell><Tooltip title="Vis posteringer"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowPostings(e)}><ReorderIcon color="primary" /></IconButton></Tooltip></TableCell>
          <TableCell><Tooltip title="Vis trend"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowAccountTrend(e)} ><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>          
        </TableRow>
      )
    })
  ):(
    null
  )
  const assetSumRow = (
    <TableRow key={19990}>
      <TableCell></TableCell>
      <TableCell style={{fontWeight:'Bold'}}>Sum eiendeler</TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat(assetTotal)}</TableCell>
      <TableCell></TableCell>
      <TableCell><Tooltip title="Vis trend"><IconButton id={1} size="small" onClick={(e) => handleShowGroupTrend(e)} ><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>      
    </TableRow>
  )

  // Debt and equity - filter by selected period and account class 2
  var debtTotal = 0;
  const debtOverview = accounts ? (
    accounts.accumulated.filter(act1 => act1.period_id==accounts.selectedPeriod).filter(act2 => act2.account_group_id==2).sort((a,b) => a.account_id>b.account_id ? 1 : -1).map(act => {
      debtTotal=debtTotal+parseFloat(act.amount);
      return (
        <TableRow key={act.account_id}>
          <TableCell>{act.account_id}</TableCell>
          <TableCell>{act.account_name}</TableCell>
          <TableCell align="right">{numberFormat(act.amount*-1)}</TableCell>
          <TableCell><Tooltip title="Vis posteringer"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowPostings(e)}><ReorderIcon color="primary" /></IconButton></Tooltip></TableCell>
          <TableCell><Tooltip title="Vis trend"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowAccountTrend(e)} ><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>          
        </TableRow>
      )
    })
  ):(
    null
  )
  const debtSumRow = (
    <TableRow key={29990}>
      <TableCell></TableCell>
      <TableCell style={{fontWeight:'Bold'}}>Sum gjeld og egenkapital</TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat(debtTotal*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell><Tooltip title="Vis trend"><IconButton id={2} size="small" onClick={(e) => handleShowGroupTrend(e)} ><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>      
    </TableRow>
  )

  // Income - filter by selected period and account class 3
  var incomeTotal = 0;
  var incomeBudget = 0;
  const incomeOverview = accounts ? (
    accounts.accumulated.filter(act1 => act1.period_id==accounts.selectedPeriod).filter(act2 => act2.account_group_id==3).sort((a,b) => a.account_id>b.account_id ? 1 : -1).map(act => {
      incomeTotal=incomeTotal+parseFloat(act.amount);
      incomeBudget=incomeBudget+parseFloat(act.period_budget);
      //console.log(act.amount, act.period_budget)
      //console.log(incomeTotal, incomeBudget)
      return (
        <TableRow key={act.account_id} >
          <TableCell>{act.account_id}</TableCell>
          <TableCell>{act.account_name}</TableCell>
          <TableCell align="right">{numberFormat(act.amount*-1)}</TableCell>
          <TableCell></TableCell>
          <TableCell align="right">{numberFormat(act.period_budget*-1)}</TableCell>
          <TableCell><Tooltip title="Vis posteringer"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowPostings(e)}><ReorderIcon color="primary" /></IconButton></Tooltip></TableCell>
          <TableCell><Tooltip title="Vis trend"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowAccountTrend(e)} ><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>          
        </TableRow>
      )
    })
  ):(
    null
  )
  const incomeSumRow = (
    <TableRow key={39990}>
      <TableCell></TableCell>
      <TableCell style={{fontWeight:'Bold'}}>Sum inntekter</TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat(incomeTotal*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat(incomeBudget*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell><Tooltip title="Vis trend"><IconButton id={3} size="small" onClick={(e) => handleShowGroupTrend(e)} ><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>      
    </TableRow>
  )


  // Costs - filter by selected period and account class 4  
  var costTotal = 0;
  var costBudget = 0;
  const postCostButton = (account_id) => {
    if(accounts.currentPeriod==accounts.selectedPeriod) {
      return <IconButton id={account_id} size="small" onClick={(e) => handleCostPosting(e)}><PostAddIcon color="primary" /></IconButton>
    } else {
      return <IconButton id={account_id} disabled size="small" onClick={(e) => handleCostPosting(e)}><PostAddIcon color="primary" /></IconButton>
    }
  }  
    
  const costOverview = accounts ? (
    accounts.accumulated.filter(act1 => act1.period_id==accounts.selectedPeriod).filter(act2 => act2.account_group_id==4).sort((a,b) => a.account_id>b.account_id ? 1 : -1).map(act => {
      costTotal=costTotal+parseFloat(act.amount);
      costBudget=costBudget+parseFloat(act.period_budget);
      return (
        <TableRow key={act.account_id}>
          <TableCell>{act.account_id}</TableCell>
          <TableCell>{act.account_name}</TableCell>
          <TableCell align="right">{numberFormat(act.amount)}</TableCell>
          <TableCell><Tooltip title="Registrer bilag">{postCostButton(act.account_id)}</Tooltip></TableCell>
          <TableCell align="right">{numberFormat(act.period_budget)}</TableCell>
          <TableCell><Tooltip title="Vis posteringer"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowPostings(e)}><ReorderIcon color="primary" /></IconButton></Tooltip></TableCell>
          <TableCell><Tooltip title="Vis trend"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowAccountTrend(e)} ><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>
        </TableRow>
      )
    })
  ):(
    null
  )
  const costSumRow = (
    <TableRow key={79990}>
      <TableCell></TableCell>
      <TableCell style={{fontWeight:'Bold'}}>Sum kostnader</TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat(costTotal)}</TableCell>
      <TableCell></TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat(costBudget)}</TableCell>
      <TableCell></TableCell>
      <TableCell><Tooltip title="Vis trend"><IconButton id={4} size="small" onClick={(e) => handleShowGroupTrend(e)} ><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>      
    </TableRow>
  )

  const operatingProfit = (
    <TableRow key={99991}>
      <TableCell></TableCell>
      <TableCell style={{fontWeight:'Bold'}}>Driftsresultat</TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat((incomeTotal+costTotal)*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat((incomeBudget+costBudget)*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>      
    </TableRow>
  )

  // Capital income - filter by selected period and account class 5  
  var capTotal = 0;
  var capBudget = 0;
  const capitalIncome = accounts ? (
    accounts.accumulated.filter(act1 => act1.period_id==accounts.selectedPeriod).filter(act2 => act2.account_group_id==5).sort((a,b) => a.account_id>b.account_id ? 1 : -1).map(act => {
      capTotal=capTotal+parseFloat(act.amount);
      capBudget=capBudget+parseFloat(act.period_budget);
      return (
        <TableRow key={act.account_id}>
          <TableCell>{act.account_id}</TableCell>
          <TableCell>{act.account_name}</TableCell>
          <TableCell align="right">{numberFormat(act.amount*-1)}</TableCell>
          <TableCell><Tooltip title="Registrer bilag"><IconButton id={act.account_id} size="small" onClick={(e) => handleCostPosting(e)}><PostAddIcon color="primary" /></IconButton></Tooltip></TableCell>
          <TableCell align="right">{numberFormat(act.period_budget*-1)}</TableCell>
          <TableCell><Tooltip title="Vis posteringer"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowPostings(e)}><ReorderIcon color="primary" /></IconButton></Tooltip></TableCell>
          <TableCell><Tooltip title="Vis trend"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowAccountTrend(e)}><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>          
        </TableRow>
      )
    })
  ):(
    null
  )

  // Capital costs - filter by selected period and account class 6  
  const capitalCosts = accounts ? (
    accounts.accumulated.filter(act1 => act1.period_id==accounts.selectedPeriod).filter(act2 => act2.account_group_id==6).sort((a,b) => a.account_id>b.account_id ? 1 : -1).map(act => {
      capTotal=capTotal+parseFloat(act.amount);
      capBudget=capBudget+parseFloat(act.period_budget);
      return (
        <TableRow key={act.account_id}>
          <TableCell>{act.account_id}</TableCell>
          <TableCell>{act.account_name}</TableCell>
          <TableCell align="right">{numberFormat(act.amount)}</TableCell>
          <TableCell><Tooltip title="Registrer bilag"><IconButton id={act.account_id} size="small" onClick={(e) => handleCostPosting(e)}><PostAddIcon color="primary" /></IconButton></Tooltip></TableCell>
          <TableCell align="right">{numberFormat(act.period_budget)}</TableCell>
          <TableCell><Tooltip title="Vis posteringer"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowPostings(e)}><ReorderIcon color="primary" /></IconButton></Tooltip></TableCell>
          <TableCell><Tooltip title="Vis trend"><IconButton id={act.account_id} size="small" onClick={(e) => handleShowAccountTrend(e)}><EqualizerIcon color="primary" /></IconButton></Tooltip></TableCell>          
        </TableRow>
      )
    })
  ):(
    null
  )  

  const result = incomeTotal+costTotal+capTotal;
  const resultBudget = incomeBudget+costBudget+capBudget;
  const resultTotal = (
    <TableRow key={99992}>
      <TableCell></TableCell>
      <TableCell style={{fontWeight:'Bold'}}>Resultat</TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat((result)*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell align="right" style={{fontWeight:'Bold'}}>{numberFormat((resultBudget)*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>      
    </TableRow>
  )
  const resultBalance = (
    <TableRow key={99993} style={{height: 10}}>
      <TableCell></TableCell>
      <TableCell>Resultat</TableCell>
      <TableCell align="right">{numberFormat((result)*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>      
    </TableRow>
  )
  
  //console.log(Math.round(((debtTotal+result)*-1)*100)/100, Math.round(assetTotal*100)/100)
  const unbalanced = (Math.round(((debtTotal+result)*-1)*100)/100 == Math.round(assetTotal*100)/100) ? "black" : "red"
  //console.log(unbalanced)
  const balance = (
    <TableRow key={99994}>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell align="right" style={{fontWeight:'Bold', color: unbalanced}}>{numberFormat((debtTotal+result)*-1)}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>      
    </TableRow>
  )

  // Period selection
  const periodSelectorItems = accounts.allPeriods ? (
    accounts.allPeriods.map(p => {
      const color = p.id==accounts.currentPeriod ? "silver" : "white";
      return (
        <MenuItem key={p.id} value={p.id} style={{backgroundColor: {color}}}>{p.description}</MenuItem>
      )
    })
  ) : (
    null
  )


  // Only allow to post docuemnts in the current period
  const manualPosting = (accounts.selectedPeriod && accounts.currentPeriod==accounts.selectedPeriod) ? 
    <Button variant="contained" sx={{height: "30px", fontSize:10 }} onClick={() => setManDocOpen(true)}>Manuelt bilag</Button> 
    : 
    <Button variant="contained" disabled sx={{height: "30px", fontSize:10 }} onClick={() => setManDocOpen(true)}>Manuelt bilag</Button> 

  // Message - no period defined
  const createPeriodWarning = accounts.currentPeriod ? null : <Typography variant="h6" style={{color: "red"}}>  Ingen definerte perioder - du må definere og aktivere en periode for å komme videre   </Typography> 

  return (
    <div key={0}>
      <PostDocument open={manDocOpen} setOpen={setManDocOpen} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} reloadTr={reloadTr} setReloadTr={setReloadTr} />
      <PostingList open={postingListOpen} setOpen={setPostingListOpen} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} reload={reloadTr} setReload={setReloadTr}/>
      <ShowTrend open={trendOpen} setOpen={setTrendOpen} selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} />
      <ManagePeriods open={managePeriodsOpen} setOpen={setManagePeriodsOpen} reload={reload} setReload={setReload} />
      <ManageAccounts open={manageAccountsOpen} setOpen={setManageAccountsOpen} reload={reload} setReload={setReload} />
      <Budget open={budgetOpen} setOpen={setBugdetOpen} reload={reload} setReload={setReload} />
      <Paper style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
        <Tooltip title="Her kan du føre manuelle bilag i regnskapet">
          {manualPosting}
        </Tooltip>
        <Tooltip title="Vis posteringsliste for valgte periode">
          <Button variant="contained" size="small" sx={{height: "30px", fontSize:10 }} onClick={handleShowPostings}>Posteringsliste</Button>
        </Tooltip>
        <Tooltip title="Utestående fordringer">
          <Link to="/adm/accountsPayable" style={{textDecoration: "none"}}>
            <Button variant="contained" size="small" sx={{height: "30px", fontSize:10 }} >Utestående fordringer</Button>
          </Link>
        </Tooltip>
        <Tooltip title="Regnskapsperioder: Definere nye, endre eller aktivere">
          <Button variant="contained" size="small" sx={{height: "30px", fontSize:10 }} onClick={() => setManagePeriodsOpen(true)}>Periodeoversikt</Button>
        </Tooltip>
        <Tooltip title="Budsjettere for aktiv periode">
          <Button variant="contained" size="small" sx={{height: "30px", fontSize:10 }} onClick={() => setBugdetOpen(true)}>Budsjett</Button>
        </Tooltip>
        <Tooltip title="Endre kontoplan">
          <Button variant="contained" size="small" sx={{height: "30px", fontSize:10 }} onClick={() => setManageAccountsOpen(true)}>Kontooversikt</Button>
        </Tooltip>
        <Tooltip title="Legg inn årets medlemskontingent">
          <Link to="/adm/invoice" style={{textDecoration: "none"}}>
            <Button variant="contained" size="small" sx={{height: "30px", fontSize:10 }}>Medlemskontingent</Button>
          </Link>
        </Tooltip>
        <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" size="small" value={accounts.selectedPeriod} onChange={handleSelectPeriod} style={{marginBottom: 10, fontSize:10 }}>
          {periodSelectorItems}
        </Select>      
      </Paper>
      {createPeriodWarning}
      <Paper>
        <Typography variant="h5">Balanseoversikt</Typography>
        <TableContainer component={Paper}>
          <Table style={{minWidth: 400 }} size="small" padding="none"> 
            <TableHead>
              <TableRow style={{backgroundColor: "silver"}} >
                <TableCell></TableCell>
                <TableCell>Konto</TableCell>
                <TableCell align="right">Saldo</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assetOverview}
              {assetSumRow}
              {debtOverview}
              {debtSumRow}
              {resultBalance}
              {balance}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper style={{marginTop: 40, marginBottom: 40}}>
        <Typography variant="h5">Resultatoversikt</Typography>
        <TableContainer component={Paper}>
          <Table size="small" padding="none" >
            <TableHead>
              <TableRow style={{backgroundColor: "silver"}}>
                <TableCell></TableCell>
                <TableCell>Konto</TableCell>
                <TableCell align="right">Saldo</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">Budsjett</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>   
            <TableBody>       
              {incomeOverview}
              {incomeSumRow}
              {costOverview}
              {costSumRow}
              {operatingProfit}
              {capitalIncome}
              {capitalCosts}
              {resultTotal}
            </TableBody>
          </Table>
        </TableContainer>        
      </Paper>  
    </div>
  )
}

export default AccountingOverview;
